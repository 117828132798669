import { Checkbox, Flex, FormControl, FormLabel } from '@chakra-ui/react'
import React from 'react'

const ForceSelect = ({ value, handleToggle, id }) => {
  return (
    <Flex h='100%'>
      <FormControl
        w='fit-content'
        display='flex'
        flexDirection='column'
        h='100%'
        justifyContent='space-between'
      >
        <FormLabel
          htmlFor={`force-${id}`}
          whiteSpace='nowrap'
          cursor='pointer'
          mr='0'
          flex={1}
        >
          Force
        </FormLabel>

        <Checkbox
          size='lg'
          colorScheme='purple'
          isChecked={value}
          id={`force-${id}`}
          display='flex'
          onChange={handleToggle}
        />
      </FormControl>
    </Flex>
  )
}

export default ForceSelect
