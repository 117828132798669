import { Flex, Heading, Image } from '@chakra-ui/react'

const InfoElement = ({ title, image, isSmall = false }) => {
  return (
    <Flex
      py={isSmall ? '0' : '44px'}
      direction='column'
      w='100%'
      align='center'
      gap='24px'
    >
      <Image
        src={image}
        alt='no content'
        loading='eager'
        maxW={isSmall ? '80px' : '150px'}
        maxH={isSmall ? '80px' : '150px'}
      />

      <Heading
        size={isSmall ? 'poppins18' : 'poppins28'}
        variant='primary-black-text'
        maxW='451px'
        textAlign='center'
      >
        {title}
      </Heading>
    </Flex>
  )
}

export default InfoElement
