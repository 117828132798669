import React, { memo, useCallback, useState, useEffect } from 'react'
import SearchBar from '../../../../components/UI/SearchBar'
import BookCreatorItem from '../BookCreatorItem'
import { Button, Flex } from '@chakra-ui/react'
import { DEBOUNCE_DELAY } from 'constants/app'
import { debounce } from 'lodash'
import ReactPaginate from 'react-paginate'

const AUTHORS_PER_PAGE = 5

const BookCreatorModal = ({
  handleHideCreatorUpdateModal,
  usersList,
  handleBookCreatorUpdate,
  handleShowWriterCreateModal,
  handleSearchChange,
}) => {
  const [visibleAuthors, setVisibleAuthors] = useState([])
  const [itemOffset, setItemOffset] = useState(0)
  const [search, setSearch] = useState('')

  const handlePageClick = event => {
    const newOffset = (event.selected * AUTHORS_PER_PAGE) % usersList.length

    setItemOffset(newOffset)
  }

  const handleSearchInput = e => {
    const searchValue = e ? e.target.value : ''

    setSearch(searchValue)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchChange = useCallback(
    debounce(value => handleSearchChange(value), DEBOUNCE_DELAY),
    [],
  )

  useEffect(() => {
    debouncedSearchChange(search)
  }, [search, debouncedSearchChange])

  useEffect(() => {
    const endOffset = itemOffset + AUTHORS_PER_PAGE

    setVisibleAuthors(usersList.slice(itemOffset, endOffset))
  }, [itemOffset, usersList])

  const pageCount = Math.ceil(usersList.length / AUTHORS_PER_PAGE)

  return (
    <Flex direction='column' maxH='50vh'>
      <Flex direction='column'>
        <div>Select creator</div>
        <Flex justify='space-between' mb='12px'>
          <SearchBar
            search={search}
            onChange={handleSearchInput}
            placeholder='Search author name...'
          />

          <Flex direction='row' gap='6px'>
            <Button
              variant='defaultButton'
              onClick={handleShowWriterCreateModal}
              w='fit-content'
            >
              Create writer
            </Button>

            <Button
              variant='outlineButton'
              onClick={handleHideCreatorUpdateModal}
              w='fit-content'
              p='0 16px'
            >
              Close selector
            </Button>
          </Flex>
        </Flex>
      </Flex>

      <Flex m='0 auto' justify='flex-start' mb='20px'>
        {visibleAuthors && pageCount > 1 && (
          <ReactPaginate
            onPageChange={handlePageClick}
            pageRangeDisplayed={4}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel='< '
            nextLabel=' >'
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link'
            nextClassName='page-item'
            nextLinkClassName='page-link'
            breakLabel='...'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            containerClassName='pagination'
            activeClassName='active'
            renderOnZeroPageCount={null}
          />
        )}
      </Flex>

      <Flex direction='column' overflowY='auto' gap='6px' pb='20px'>
        {visibleAuthors.map(user => (
          <BookCreatorItem
            key={user.user_id}
            handleBookCreatorUpdate={() => {
              handleBookCreatorUpdate(user)
            }}
            {...user}
          />
        ))}
      </Flex>
    </Flex>
  )
}

export default memo(BookCreatorModal)
