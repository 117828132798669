import { createSelector } from 'reselect'

const getLocalizations = state => state.localizations

export const getLocalizationsList = createSelector(
  getLocalizations,
  localizations => Object.values(localizations),
)

export const getSectionsLocalizationsList = createSelector(
  getLocalizationsList,
  localizations =>
    localizations.filter(
      localization =>
        localization.sections.length > 0 ||
        (!localization.sections.length && !localization.lists.length),
    ),
)

export const getListsLocalizationsList = createSelector(
  getLocalizationsList,
  localizations =>
    localizations.filter(
      localization =>
        (!localization.sections.length && localization.lists.length > 0) ||
        (!localization.sections.length && !localization.lists.length),
    ),
)

export const getLocalizationTitles = createSelector(
  getLocalizationsList,
  localizations => localizations.map(localization => localization.title),
)
