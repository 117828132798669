import React, { memo, useCallback, useEffect, useRef } from 'react'
import classNames from 'classnames'
import getAreaCoords from '../../../utils/getAreaCoords'
import isInside from '../../../utils/isInside'
import styles from './styles.module.scss'

const DropDown = ({ show, handleDropDownClose, children, className }) => {
  const dropDownRef = useRef(null)

  const handleDropDownBlur = useCallback(
    e => {
      const { clientX, clientY } = e
      const currentPointerPosition = {
        clientX,
        clientY,
      }
      if (dropDownRef.current) {
        const dropDownCoords = getAreaCoords(
          dropDownRef.current.getBoundingClientRect(),
        )

        const isCurrentPointerIsInsideDropdown = isInside(
          currentPointerPosition,
          dropDownCoords,
        )
        if (!isCurrentPointerIsInsideDropdown) {
          handleDropDownClose()
        }
      }
    },
    [handleDropDownClose],
  )

  useEffect(() => {
    if (show) {
      document.body.addEventListener('click', handleDropDownBlur)
      return () =>
        document.body.removeEventListener('click', handleDropDownBlur)
    }
  }, [handleDropDownBlur, show])

  return (
    show && (
      <div
        className={classNames(styles.dropDown, styles[className])}
        ref={dropDownRef}
      >
        {children}
      </div>
    )
  )
}

DropDown.Option = memo(({ children: option, onClick, className }) => {
  return (
    <div
      className={classNames(styles.dropDownOption, styles[className])}
      onClick={onClick}
    >
      {option}
    </div>
  )
})
DropDown.Option.displayName = 'option'

export default DropDown
