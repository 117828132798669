import { Checkbox, FormControl, FormLabel, Icon } from '@chakra-ui/react'
import React from 'react'
import { BsTrash } from 'react-icons/bs'

const DeletedCheckBox = ({ value, toggleDeleted }) => {
  return (
    <FormControl w='fit-content'>
      <FormLabel htmlFor='deleted' whiteSpace='nowrap'>
        Show Deleted
      </FormLabel>
      <Checkbox
        size='lg'
        colorScheme='purple'
        isChecked={value}
        id='deleted'
        display='flex'
        onChange={toggleDeleted}
      >
        <Icon as={BsTrash} w='24px' h='24px' />
      </Checkbox>
    </FormControl>
  )
}

export default DeletedCheckBox
