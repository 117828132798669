import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { colors } from 'shared/style/colors'

const ErrorToast = ({ title, reason, onClose }) => {
  return (
    <Flex
      w='550px'
      minH='134px'
      pos='relative'
      bg={colors['primitive-red-light-red-100']}
      borderRadius='16px'
      boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
      align='center'
    >
      <Box p='27px 24px'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='80'
          height='80'
          viewBox='0 0 80 80'
          fill='none'
        >
          <path
            d='M39.9994 6.66602C21.6327 6.66602 6.66602 21.6327 6.66602 39.9994C6.66602 58.366 21.6327 73.3327 39.9994 73.3327C58.366 73.3327 73.3327 58.366 73.3327 39.9994C73.3327 21.6327 58.366 6.66602 39.9994 6.66602ZM37.4993 26.666C37.4993 25.2994 38.6327 24.166 39.9994 24.166C41.366 24.166 42.4994 25.2994 42.4994 26.666V43.3327C42.4994 44.6994 41.366 45.8327 39.9994 45.8327C38.6327 45.8327 37.4993 44.6994 37.4993 43.3327V26.666ZM43.066 54.5993C42.8993 55.0327 42.666 55.366 42.366 55.6993C42.0327 55.9993 41.666 56.2327 41.266 56.3994C40.866 56.566 40.4327 56.666 39.9994 56.666C39.566 56.666 39.1327 56.566 38.7327 56.3994C38.3327 56.2327 37.966 55.9993 37.6327 55.6993C37.3327 55.366 37.0993 55.0327 36.9327 54.5993C36.766 54.1993 36.666 53.766 36.666 53.3327C36.666 52.8994 36.766 52.466 36.9327 52.066C37.0993 51.666 37.3327 51.2994 37.6327 50.966C37.966 50.666 38.3327 50.4327 38.7327 50.266C39.5327 49.9327 40.466 49.9327 41.266 50.266C41.666 50.4327 42.0327 50.666 42.366 50.966C42.666 51.2994 42.8993 51.666 43.066 52.066C43.2327 52.466 43.3327 52.8994 43.3327 53.3327C43.3327 53.766 43.2327 54.1993 43.066 54.5993Z'
            fill='#FC2762'
          />
        </svg>
      </Box>

      <Flex direction='column' py='24px'>
        <Heading
          variant='primary-black-text'
          size='poppins24'
          lineHeight='34px'
          mb='12px'
        >
          {title}
        </Heading>

        <Text
          size='poppins16normal'
          variant='primary-black-text'
          lineHeight='28px'
          letterSpacing='-0.016px'
        >
          {reason}
        </Text>
      </Flex>

      <Image
        pos='absolute'
        src='/images/close-circle.svg'
        alt='close icon'
        top='24px'
        right='24px'
        cursor='pointer'
        onClick={onClose}
      />
    </Flex>
  )
}

export default ErrorToast
