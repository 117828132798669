import { Button, Flex } from '@chakra-ui/react'
import React from 'react'
import { colors } from 'shared/style/colors'

const UnlockFullControls = ({
  handleUnlock,
  handleLock,
  isLockDisabled,
  isUnlockDisabled,
}) => {
  return (
    <Flex gap='23px' mb='24px'>
      <Button
        variant='defaultButton'
        onClick={handleUnlock}
        isDisabled={isUnlockDisabled}
        w='204px'
      >
        Unlock Full
      </Button>

      <Button
        variant='defaultButton'
        onClick={handleLock}
        w='204px'
        bg={colors['light-text-text-danger']}
        _hover={{
          bg: `${colors['light-red-700']} !important`,
        }}
        isDisabled={isLockDisabled}
      >
        Lock Full
      </Button>
    </Flex>
  )
}

export default UnlockFullControls
