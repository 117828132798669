import React, { memo } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { BannerTypes } from '../../../../../../constants/banners'
import './styles.scss'

const AppendBannerModal = ({
  updatedList,
  isShow,
  onHide,
  getIsSelectedBanner,
  selectBanner,
  filteredBanners,
  onSubmit,
  getSelectedBannerIndex,
}) => {
  return (
    <Modal show={isShow} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Add banner to &quot;
          {updatedList.title}&quot; section
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='modal-banner-list'>
          {filteredBanners.map(banner => (
            <div
              className={`banner ${
                getIsSelectedBanner(banner) ? 'banner--selected' : ''
              }`}
              key={banner.banner_id}
              onClick={() => selectBanner(banner)}
            >
              <div className='banner-info-container'>
                <div>
                  <div className='banner-info'>
                    <span>banner_type: {banner.banner_type}</span>
                    <br />
                    <span>banner_url: {banner.cover_url}</span>
                  </div>
                  {banner.banner_type === BannerTypes.BOOKS && (
                    <p>Book: {banner.book?.title}</p>
                  )}
                  {banner.banner_type === BannerTypes.ADVERTISEMENTS && (
                    <p>Advertisement: {banner.advertisement?.title}</p>
                  )}
                </div>
                <div className='banner-selected-index'>
                  {getSelectedBannerIndex(banner) !== -1 &&
                    getSelectedBannerIndex(banner) + 1}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={onSubmit}>
          Add banners
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default memo(AppendBannerModal)
