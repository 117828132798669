import { Flex, Input, Text } from '@chakra-ui/react'
import React from 'react'
import { colors } from 'shared/style/colors'

const Coins = ({ coins, handleInput, isStatic }) => {
  return (
    <Flex align='baseline' gap='24px'>
      <Text
        size='poppins18'
        fontWeight={400}
        minW='130px'
        variant='primary-black-text'
        letterSpacing='-0.018px'
      >
        Coin balance:
      </Text>

      {!isStatic && (
        <Input
          placeholder='user balance'
          variant='loginField'
          p='12px 24px'
          h='46px'
          w='250px'
          value={coins}
          style={{
            caretColor: colors['primary-violet-100'],
          }}
          autoComplete='off'
          onChange={handleInput}
        />
      )}

      {isStatic && (
        <Text
          size='poppins18'
          fontWeight={600}
          variant='primary-black-text'
          letterSpacing='-0.018px'
        >
          {coins}
        </Text>
      )}
    </Flex>
  )
}

export default Coins
