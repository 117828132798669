import { Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { colors } from 'shared/style/colors'

const TagsSummary = ({ selectedTags, handleRemoveTag, handleOnDragTags }) => {
  return (
    <Flex gap='20px'>
      <Heading
        size='poppins20'
        variant='primary-black-text'
        lineHeight='38px'
        letterSpacing='0'
        textAlign='left'
        w='120px'
        minW='120px'
      >
        Tags
      </Heading>

      <Flex mb='24px'>
        <DragDropContext onDragEnd={handleOnDragTags}>
          <Droppable droppableId='tags' style={{ transform: 'none' }}>
            {provided => (
              <Flex
                gap='10px'
                direction='column'
                wrap='wrap'
                {...provided.droppableProps}
                ref={provided.innerRef}
                userSelect='none'
              >
                {selectedTags.map((item, index) => (
                  <Draggable
                    key={item.tag_id}
                    draggableId={item.tag_id}
                    index={index}
                  >
                    {provided => (
                      <Flex
                        gap='8px'
                        border={`1px solid ${colors['primary-gray-30']}`}
                        p='8px 12px'
                        boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
                        borderRadius='8px'
                        cursor='pointer'
                        _hover={{
                          bg: 'rgba(36, 28, 88, 0.03)',
                        }}
                        onClick={() => handleRemoveTag(item)}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <Flex w='100%' gap='10px' direction='column'>
                          <Text
                            size='poppins14'
                            variant='primary-black-text'
                            fontWeight={600}
                          >
                            {item.title}
                          </Text>
                        </Flex>
                      </Flex>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Flex>
            )}
          </Droppable>
        </DragDropContext>
      </Flex>
    </Flex>
  )
}

export default TagsSummary
