import { getIsAlreadyExistEmail } from '../api/users'

const EMAIL_LENGTH = 15

const generateEmail = async () => {
  const chars = 'abcdefghijklmnopqrstuvwxyz1234567890'
  let email = ''
  for (let i = 0; i < EMAIL_LENGTH; i++) {
    email += chars[Math.floor(Math.random() * chars.length)]
  }
  const { valid } = await getIsAlreadyExistEmail(email).then(res => res.data)
  if (typeof valid !== 'boolean') {
    throw new Error('bad response')
  }
  if (!valid) {
    return email + '@good-episode.com'
  }

  return generateEmail()
}

export default generateEmail
