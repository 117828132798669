import React, { memo, useEffect, useRef } from 'react'
import { Button } from 'react-bootstrap'
import './styles.scss'
import { useDrag, useDrop } from 'react-dnd'

const SeriesBookItem = ({
  book,
  onDelete,
  index,
  handleSeriesBookOrderDragEnd,
  series,
  handleSeriesBookOrderUpdate,
}) => {
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: series.series_id,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / +'2'
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      handleSeriesBookOrderUpdate(dragIndex, hoverIndex, series.series_id)

      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: series.series_id,
    item: () => {
      return { id: book.book_id, index }
    },
    end: () => {
      handleSeriesBookOrderDragEnd(series)
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  drag(drop(ref))

  const opacity = isDragging ? '0.5' : 1
  const cursor = !isDragging ? 'grab' : ''

  useEffect(() => {
    if (isDragging) {
      document.body.style.cursor = 'grabbing'
      return
    }
    document.body.style.cursor = ''
  }, [isDragging])

  return (
    <div
      className='book'
      ref={ref}
      style={{ opacity, cursor }}
      data-handler-id={handlerId}
    >
      <img
        src={book.cover_link || '/images/book.png'}
        alt='book-cover'
        className='book__image'
      />
      <div className='book-info'>
        <div>{book.title}</div>
        <div>status: {book.status}</div>
      </div>
      <Button variant='danger' onClick={onDelete} style={{ height: 40 }}>
        Delete
      </Button>
    </div>
  )
}

export default memo(SeriesBookItem)
