import { Box, Flex, Text } from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import NextActiveIcon from '../Icons/NextActiveIcon'
import NextDisabledIcon from '../Icons/NextDisabledIcon'
import PrevActiveIcon from '../Icons/PrevActiveIcon'
import PrevDisabledIcon from '../Icons/PrevDisabledIcon'

const Pagination = ({ changePage, page, total, perPage }) => {
  const [prevHover, setPrevHover] = useState(false)
  const [nextHover, setNextHover] = useState(false)

  const nextActive = useMemo(
    () => Math.floor(total / perPage) > page,
    [total, perPage, page],
  )

  const nextIcon = useMemo(() => {
    if (nextActive) {
      if (nextHover) {
        return NextActiveIcon('#0087FF')
      }

      return NextActiveIcon()
    }

    return NextDisabledIcon()
  }, [nextActive, nextHover])

  const prevActive = page > 0
  const prevIcon = useMemo(() => {
    if (prevActive) {
      if (prevHover) {
        return PrevActiveIcon('#0087FF')
      }

      return PrevActiveIcon()
    }

    return PrevDisabledIcon()
  }, [prevActive, prevHover])

  return (
    <Flex gap='24px' align='center' p='10px' alignSelf='flex-end'>
      <Box
        onMouseEnter={() => setPrevHover(true)}
        onMouseLeave={() => setPrevHover(false)}
        cursor={prevActive ? 'pointer' : 'not-allowed'}
        onClick={prevActive ? () => changePage(-1) : () => {}}
      >
        {prevIcon}
      </Box>

      <Text
        variant='primary-gray-100'
        size='poppins16normal'
        lineHeight='36px'
        letterSpacing='-0.01em'
      >
        {`${page + 1}`}
      </Text>

      <Box
        onMouseEnter={() => setNextHover(true)}
        onMouseLeave={() => setNextHover(false)}
        cursor={nextActive ? 'pointer' : 'not-allowed'}
        onClick={nextActive ? () => changePage(1) : () => {}}
      >
        {nextIcon}
      </Box>
    </Flex>
  )
}

export default Pagination
