import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import getAreaCoords from '../../../../utils/getAreaCoords'
import isInside from '../../../../utils/isInside'
import styles from './styles.module.scss'
import SearchBar from '../../../../components/UI/SearchBar'
import { getAdvertisementsList } from '../../../../redux/slices/advertisements/selectors'

const FormBannerAdvertisementSelect = () => {
  const [field, meta] = useField({ name: 'attachment' })
  const { setFieldValue } = useFormikContext()
  const selectDropDownRef = useRef(null)
  const advertisements = useSelector(getAdvertisementsList)

  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const [search, setSearch] = useState('')

  const filteredAdvertisements = useMemo(
    () =>
      advertisements.filter(
        advertisement =>
          advertisement.title.toLowerCase().includes(search.toLowerCase()) &&
          advertisement.advertisement_id !== field?.value?.advertisement_id,
      ),
    [advertisements, field?.value?.advertisement_id, search],
  )

  const handleSelectOpen = useCallback(() => setIsOpenSelect(true), [])

  const handleSelectClose = useCallback(() => setIsOpenSelect(false), [])

  const handleSearchChange = useCallback(e => setSearch(e.target.value), [])

  const handleBannerAdvertisementSelect = useCallback(
    book => {
      setFieldValue('attachment', book)
      handleSelectClose()
      setSearch('')
    },
    [handleSelectClose, setFieldValue],
  )

  const handleDropDownBlur = useCallback(
    e => {
      const { clientX, clientY } = e
      const currentPointerPosition = {
        clientX,
        clientY,
      }
      if (selectDropDownRef.current) {
        const dropDownCoords = getAreaCoords(
          selectDropDownRef.current.getBoundingClientRect(),
        )

        const isCurrentPointerIsInsideDropdown = isInside(
          currentPointerPosition,
          dropDownCoords,
        )

        if (!isCurrentPointerIsInsideDropdown) {
          handleSelectClose()
        }
      }
    },
    [handleSelectClose],
  )

  useEffect(() => {
    if (isOpenSelect) {
      document.body.addEventListener('click', handleDropDownBlur)
      return () => {
        document.body.removeEventListener('click', handleDropDownBlur)
      }
    }
  }, [handleDropDownBlur, isOpenSelect])

  useEffect(() => {
    return () => setFieldValue('attachment', null)
  }, [setFieldValue])

  return (
    <div className={styles.select} onClick={handleSelectOpen}>
      {!field.value ? (
        'Select a advertisement'
      ) : (
        <div className={styles.bannerAdvertisement}>
          <div className={styles.bannerAdvertisementTitle}>
            <b>Advertisement title: </b>
            {field.value?.title}
          </div>
          <div className={styles.bannerAdvertisementLink}>
            <b>Advertisement link: </b>
            <a href={field.value?.link} target='_blank' rel='noreferrer'>
              {field.value?.link}
            </a>
          </div>
        </div>
      )}
      {isOpenSelect && (
        <div className={styles.selectDropDown} ref={selectDropDownRef}>
          <SearchBar search={search} onChange={handleSearchChange} />
          <div className={styles.advertisementList}>
            {filteredAdvertisements.map(advertisement => (
              <div
                className={styles.advertisement}
                key={advertisement.advertisement_id}
                onClick={e => {
                  e.stopPropagation()
                  handleBannerAdvertisementSelect(advertisement)
                }}
              >
                <div className={styles.advertisementTitle}>
                  <b>Advertisement title: </b>
                  {advertisement.title}
                </div>
                <div className={styles.advertisementLink}>
                  <b>Advertisement link: </b>
                  <a href={advertisement.link} target='_blank' rel='noreferrer'>
                    {advertisement.link}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {meta.error && meta.touched && (
        <p className={styles.error}>{meta.error}</p>
      )}
    </div>
  )
}

export default FormBannerAdvertisementSelect

/*   {values.banner_type === BannerTypes.ADVERTISEMENTS && (
                <div
                  className='modal-advertisement-list-container'
                  style={{ marginBottom: 10 }}
                >
                  {R.isEmpty(bannerAdvertisement) ? (
                    <p
                      className='modal-advertisement-empty'
                      onClick={openBannerAdvertisementSelect}
                    >
                      Select a advertisement
                    </p>
                  ) : (
                    <div
                      className='modal-advertisement-selected'
                      onClick={openBannerAdvertisementSelect}
                    >
                      {bannerAdvertisement.title}
                    </div>
                  )}
                  {isOpenBannerAdvertisementSelect && (
                    <div className='modal-advertisement-dropdown'>
                      {advertisements.map(advertisement => (
                        <div
                          className='advertisement'
                          key={advertisement.advertisement_id}
                          onClick={() =>
                            onBannerAdvertisementChange(advertisement)
                          }
                        >
                          {advertisement.title}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )} */
