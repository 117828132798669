import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  createGenre,
  createTag,
  fetchBookPremiumTypes,
  fetchGenres,
  fetchSectionTypes,
  fetchTags,
} from '../../../../api/types'

const fetchGenresThunk = createAsyncThunk(
  'types/fetchGenres',
  async () => await fetchGenres().then(res => res.data),
)

const createGenreThunk = createAsyncThunk('types/createGenre', async genre => {
  const { genre_id } = await createGenre(genre).then(res => res.data)
  return { genre_id, ...genre }
})

const fetchTagsThunk = createAsyncThunk(
  'types/fetchTags',
  async () => await fetchTags().then(res => res.data),
)

const createTagThunk = createAsyncThunk('types/createTag', async tag => {
  const { tag_id } = await createTag(tag).then(res => res.data)
  return { tag_id, ...tag }
})

const fetchSectionTypesThunk = createAsyncThunk(
  'types/fetchSectionTypes',
  async () => await fetchSectionTypes().then(res => res.data),
)

const fetchBookPremiumTypesThunk = createAsyncThunk(
  'types/fetchBookPremiumTypes',
  async () => await fetchBookPremiumTypes().then(res => res.data),
)

export {
  fetchGenresThunk,
  createGenreThunk,
  fetchTagsThunk,
  createTagThunk,
  fetchBookPremiumTypesThunk,
  fetchSectionTypesThunk,
}
