import axiosApiInstance from 'utils/axios-instance'

const fetchGenres = () => axiosApiInstance.get('genres')

const createGenre = genre => axiosApiInstance.post('genres', genre)

const fetchTags = () => axiosApiInstance.get('tags')

const createTag = tag => axiosApiInstance.post('tags', tag)

const fetchSectionTypes = () => axiosApiInstance.get('types/section/types')

const fetchBookPremiumTypes = () => axiosApiInstance.get('types/book/type')

export {
  fetchGenres,
  createGenre,
  fetchTags,
  createTag,
  fetchSectionTypes,
  fetchBookPremiumTypes,
}
