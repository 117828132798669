import React, { memo } from 'react'
import { Button, Modal } from 'react-bootstrap'
import './styles.scss'

const AppendGenreModal = ({
  isOpen,
  hideHandler,
  genres,
  updatedList,
  updateGenreList,
  selectGenre,
  getIsSelectedGenre,
  getSelectedGenreIndex,
}) => (
  <Modal show={isOpen} onHide={hideHandler} centered>
    <Modal.Header closeButton>
      <Modal.Title>
        Add genres to &quot;
        {updatedList.title}&quot; list
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className='modal-genre-list'>
        {genres.map(genre => (
          <div
            key={genre.genre_id}
            className={`modal-genre ${
              getIsSelectedGenre(genre) ? 'modal-genre--selected' : ''
            }`}
            onClick={() => selectGenre(genre)}
          >
            <div className='genre-info'>
              <div className='genre-info__title'>{genre.title}</div>
              <div className='genre-info__index'>
                {getSelectedGenreIndex(genre) !== -1 &&
                  getSelectedGenreIndex(genre) + 1}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='primary' onClick={updateGenreList}>
        Add genres
      </Button>
    </Modal.Footer>
  </Modal>
)

export default memo(AppendGenreModal)
