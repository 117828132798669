import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import { colors } from 'shared/style/colors'
import SimpleBarReact from 'simplebar-react'
import ExistedAudio from '../ExistedAudio'
import AudioOption from '../AudioOption'
import { MAX_FILES } from '..'
import AuthAlert from 'screens/Authorization/components/AuthAlert'
import { GET_CHAPTER_FIELDS } from 'services/chapters/graphql'
import { useLazyQuery } from '@apollo/client'
import { removeChapterAudio, uploadChapterAudio } from 'api/books'

const OneChapterFlow = ({
  isBookLoading,
  book,
  isChaptersLoading,
  languages,
  loading,
  allChapters,
}) => {
  const [files, setFiles] = useState(null)
  const [isExisted, setIsExisted] = useState(false)
  const [selected, setSelected] = useState(null)
  const [selectedLocales, setSelectedLocales] = useState([])
  const [availableLocales, setAvailableLocales] = useState([])
  const [visibleChapters, setVisibleChapters] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [removeAudio, setRemoveAudio] = useState([])

  const handleRemoveTrack = useCallback(code => {
    setRemoveAudio(curr => [...curr, code])
  }, [])

  const handleClearRemoved = useCallback(() => {
    setRemoveAudio([])
  }, [])

  const inputRef = useRef(null)
  const toast = useToast()

  const [
    getChapterFields,
    { data: chapterFields, loading: isChaptersFieldsLoading },
  ] = useLazyQuery(GET_CHAPTER_FIELDS)

  const handleSelectChapter = useCallback(chapter => {
    setSelected(chapter)
  }, [])

  const handleClearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }

    setFiles(null)
  }

  const handleAddLocale = locale => {
    setSelectedLocales(curr => [...curr, locale])
  }

  const handleClearData = useCallback(() => {
    setFiles(null)
    setSelectedLocales([])
  }, [])

  const handleRemoveLocale = locale => {
    setSelectedLocales(curr => curr.filter(item => item.code !== locale.code))
  }

  const handleDragOver = event => {
    event.preventDefault()
  }

  const handleDragLeave = event => {
    event.preventDefault()
  }

  const handleDrop = async event => {
    event.preventDefault()

    const droppedFiles = Array.from(event.dataTransfer.files)

    if (Object.entries(droppedFiles).length > MAX_FILES) {
      toast({
        position: 'top',
        render: ({ onClose }) => (
          <Box mt='61px'>
            <AuthAlert
              onClose={onClose}
              title='Too much files!'
              reason='You can upload 20 files at once'
            />
          </Box>
        ),
        duration: 5000,
      })

      return
    }
    setFiles(droppedFiles)
  }

  const handleSubmit = useCallback(async () => {
    if (selected && selectedLocales.length > 0) {
      setIsUploading(true)
      const formdata = new FormData()

      selectedLocales.forEach(locale => {
        formdata.append(locale.code, locale.file)
      })

      try {
        const res = await uploadChapterAudio({
          formdata,
          chapter_id: selected.chapter_id,
        })

        await getChapterFields({
          variables: {
            chapter_id: selected.chapter_id,
          },
        })

        toast({
          title: `Audio was succesfully uploaded for ${Object.entries(res.data)
            .map(([key]) => key)
            .join(', ')}`,
          status: 'success',
          isClosable: true,
          duration: 15000,
          position: 'top',
        })

        handleClearData()
      } catch (error) {
        toast({
          position: 'top',
          render: ({ onClose }) => (
            <Box mt='61px'>
              <AuthAlert
                onClose={onClose}
                title='Failed'
                reason={error.message}
              />
            </Box>
          ),
          duration: 5000,
        })
      } finally {
        setIsUploading(false)
      }
    }
  }, [selected, selectedLocales, getChapterFields, toast, handleClearData])

  const submitRemove = useCallback(async () => {
    for (const code of removeAudio) {
      await removeChapterAudio({
        chapter_id: selected.chapter_id,
        code,
      })
    }

    toast({
      title: `Audio was succesfully removed for ${removeAudio.join(', ')}`,
      status: 'success',
      isClosable: true,
      duration: 15000,
      position: 'top',
    })

    handleClearRemoved()

    await getChapterFields({
      variables: {
        chapter_id: selected.chapter_id,
      },
    })
  }, [removeAudio, selected, toast, handleClearRemoved, getChapterFields])

  const setChapters = async e => {
    if (Object.entries(e.target.files).length > MAX_FILES) {
      toast({
        position: 'top',
        render: ({ onClose }) => (
          <Box mt='61px'>
            <AuthAlert
              onClose={onClose}
              title='Too much files!'
              reason='You can upload 20 files at once'
            />
          </Box>
        ),
        duration: 5000,
      })

      return
    }

    if (e.target.files) {
      setFiles(e.target.files)
    }
  }

  useEffect(() => {
    if (selected) {
      getChapterFields({
        variables: {
          chapter_id: selected.chapter_id,
        },
      })

      setIsExisted(false)
    }
  }, [selected, getChapterFields])

  useEffect(() => {
    if (allChapters && !isChaptersLoading) {
      setVisibleChapters(allChapters.chapters ?? [])
    }
  }, [allChapters, isChaptersLoading, book])

  useEffect(() => {
    if (languages && !loading) {
      let existedLocales = []

      if (chapterFields && !isChaptersFieldsLoading) {
        existedLocales = Object.keys(
          chapterFields.chapter.audio_translation_links,
        )
      }

      setAvailableLocales(
        (languages.languages || [])
          .filter(
            item =>
              !selectedLocales.some(selected => selected.code === item.code) &&
              !existedLocales.some(existed => existed === item.code),
          )
          .sort((a, b) => a.title.localeCompare(b.title)),
      )
    }
  }, [
    languages,
    loading,
    selectedLocales,
    chapterFields,
    isChaptersFieldsLoading,
  ])

  return (
    <>
      <Flex gap='12px'>
        {selected && (
          <IconButton
            isRound={true}
            variant='solid'
            colorScheme='teal'
            aria-label='Done'
            fontSize='20px'
            icon={<ArrowBackIcon />}
            onClick={() => {
              handleSelectChapter(null)
              handleClearInput()
            }}
          />
        )}

        {!isBookLoading && (
          <Heading
            size='poppins24'
            variant='primary-black-text'
            lineHeight='38px'
            letterSpacing='0'
            textAlign='left'
          >
            {selected
              ? `${selected.chapter_order}. ${selected.title} - ${selected.chapter_id}`
              : `Select chapter from the "${book?.title}"`}
          </Heading>
        )}

        {isBookLoading && (
          <Flex pos='relative' w='100%' h='360px'>
            <LoadingSpinner />
          </Flex>
        )}
      </Flex>

      {selected && (
        <Flex gap='20px' pt='14px'>
          <FormControl display='flex' alignItems='center'>
            <FormLabel htmlFor='content-toggler' mb='0'>
              Upload
            </FormLabel>
            <Switch
              id='content-toggler'
              value={isExisted}
              onChange={() => setIsExisted(curr => !curr)}
            />
            <FormLabel htmlFor='content-toggler' mb='0' ml='12px'>
              Existed
            </FormLabel>
          </FormControl>
        </Flex>
      )}

      {selected && isUploading && (
        <Flex pos='relative' w='100%' h='360px'>
          <LoadingSpinner />
        </Flex>
      )}

      {selected && !isUploading && (
        <Flex
          w='100%'
          justify={
            chapterFields?.chapter.audio_translation_links
              ? 'flex-start'
              : 'center'
          }
          h='440px'
          align='center'
          direction='column'
          gap='20px'
          pt='12px'
        >
          {isExisted &&
            chapterFields &&
            Object.entries(chapterFields.chapter.audio_translation_links)
              .length > 0 && (
              <Flex
                bg={colors.white}
                direction='column'
                align='flex-start'
                alignSelf='flex-start'
                maxH='416px'
                w='100%'
                overflowY='hidden'
                p='12px'
                gap='20px'
              >
                <SimpleBarReact
                  autoHide={true}
                  style={{
                    minHeight: '330px',
                    maxHeight: '330px',
                    width: '100%',
                    overflowX: 'hidden',
                  }}
                >
                  <Flex direction='column' gap='20px'>
                    {Object.entries(
                      chapterFields.chapter.audio_translation_links,
                    )
                      .filter(
                        ([code]) => !removeAudio.some(item => item === code),
                      )
                      .map(([code, src]) => {
                        return (
                          <ExistedAudio
                            key={code}
                            code={code}
                            src={src}
                            handleRemoveTrack={handleRemoveTrack}
                          />
                        )
                      })}
                  </Flex>
                </SimpleBarReact>

                <Flex alignSelf='flex-end' gap='12px'>
                  <Button
                    type='button'
                    variant='outlineButton'
                    w='204px'
                    isDisabled={removeAudio.length === 0}
                    onClick={handleClearRemoved}
                  >
                    Reset
                  </Button>

                  <Button
                    type='button'
                    variant='defaultButton'
                    w='204px'
                    isDisabled={removeAudio.length === 0}
                    onClick={submitRemove}
                  >
                    Save
                  </Button>
                </Flex>
              </Flex>
            )}

          {!files && !isExisted && (
            <FormLabel
              m='0'
              cursor='pointer'
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <Flex
                borderRadius='8px'
                p='58px 36px'
                mt='30px'
                maxW='180px'
                border={`1px dashed ${colors['primary-violet-100']}`}
                direction='column'
                align='center'
                background='rgba(255, 255, 255, 0.50)'
              >
                <Image src='/images/upload.svg' alt='upload icon' mb='23px' />

                <Text
                  variant='primary-black-text'
                  size='poppins16'
                  fontWeight={600}
                  lineHeight='22px'
                  letterSpacing='-0.016px'
                  textAlign='center'
                  mb='11px'
                >
                  Select file
                  <br />
                  or drag it here
                </Text>
              </Flex>

              <input
                ref={inputRef}
                type='file'
                multiple
                id='file-input'
                accept='audio/*'
                onChange={setChapters}
                defaultValue=''
              />
            </FormLabel>
          )}

          {files && !isExisted && (
            <Flex
              bg={colors.white}
              direction='column'
              align='flex-start'
              alignSelf='flex-start'
              maxH='416px'
              w='100%'
              overflowY='hidden'
              p='12px'
              gap='20px'
            >
              <SimpleBarReact
                autoHide={true}
                style={{
                  minHeight: '330px',
                  maxHeight: '330px',
                  width: '100%',
                  overflowX: 'hidden',
                }}
              >
                {Object.entries(files).map(file => {
                  return (
                    <AudioOption
                      key={file[0]}
                      file={file}
                      availableLocales={availableLocales}
                      handleAddLocale={handleAddLocale}
                      selectedLang={selectedLocales.find(
                        locale => locale.file.name === file[1].name,
                      )}
                      handleRemoveLocale={handleRemoveLocale}
                    />
                  )
                })}
              </SimpleBarReact>

              <Flex alignSelf='flex-end' gap='12px'>
                <Button
                  type='button'
                  variant='outlineButton'
                  w='204px'
                  isDisabled={!files}
                  onClick={handleClearData}
                >
                  Clear data
                </Button>

                <Button
                  type='button'
                  variant='defaultButton'
                  w='204px'
                  isDisabled={
                    !files ||
                    selectedLocales.length !== Object.entries(files).length
                  }
                  onClick={handleSubmit}
                >
                  Upload
                </Button>
              </Flex>
            </Flex>
          )}
        </Flex>
      )}

      {!selected && (
        <Flex
          bg={colors.white}
          direction='column'
          maxH='416px'
          w='100%'
          borderRadius='8px'
          overflowY='hidden'
        >
          <SimpleBarReact
            autoHide={true}
            style={{
              maxHeight: '360px',
              overflowX: 'hidden',
            }}
          >
            {(isChaptersLoading || isChaptersFieldsLoading) && !isBookLoading && (
              <Flex pos='relative' w='100%' h='360px'>
                <LoadingSpinner />
              </Flex>
            )}

            {!isChaptersLoading &&
              visibleChapters.map(chapter => {
                return (
                  <Flex
                    key={chapter.chapter_id}
                    gap='4px'
                    onClick={() => handleSelectChapter(chapter)}
                    p='10px 12px'
                    align='center'
                    _hover={{
                      bg: colors['primary-violet-20'],
                      '&>p': {
                        color: colors['primary-violet-100'],
                      },
                    }}
                    borderRadius='8px'
                    cursor='pointer'
                  >
                    <Text
                      size='poppins18'
                      variant='primary-black-text'
                      fontWeight={400}
                      lineHeight='32px'
                      letterSpacing='-0.018px'
                      maxW='900px'
                      overflow='hidden'
                      textOverflow='ellipsis'
                      whiteSpace='nowrap'
                      _hover={{
                        bg: colors['primary-violet-20'],
                        color: colors['primary-violet-100'],
                      }}
                    >
                      {chapter.chapter_order}. {chapter.title} -{' '}
                      {chapter.chapter_id}
                    </Text>
                  </Flex>
                )
              })}
          </SimpleBarReact>
        </Flex>
      )}
    </>
  )
}

export default OneChapterFlow
