import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  createBanner,
  deleteBannerById,
  fetchBanners,
  updateBanner,
  updateBannerCover,
} from '../../../../api/banners'

export const fetchBannersThunk = createAsyncThunk('banners/fetch', async () => {
  try {
    return await fetchBanners().then(res => res.data)
  } catch (error) {
    console.error(error)
  }
})

export const createBannerThunk = createAsyncThunk(
  'banners/create',
  async banner => {
    const { banner_id } = await createBanner(banner).then(res => res.data)
    return { banner_id, ...banner }
  },
)

export const updateBannerCoverThunk = createAsyncThunk(
  'banners/updateCover',
  async ({ banner_id, formdata }) => {
    try {
      const { link } = await updateBannerCover({
        banner_id,
        formdata,
      }).then(res => res.data)
      return { banner_id, link }
    } catch (error) {
      console.error(error)

      throw error
    }
  },
)

export const deleteBannerByIdThunk = createAsyncThunk(
  'banners/delete',
  async banner_id => {
    try {
      await deleteBannerById(banner_id)
      return banner_id
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)

export const updateBannerThunk = createAsyncThunk(
  'banners/update',
  async banner => {
    try {
      await updateBanner(banner)
      return banner
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)
