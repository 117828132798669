import axiosFileApiInstance from 'utils/axios-file-instance'
import axiosApiInstance from 'utils/axios-instance'

export const fetchBanners = () => axiosApiInstance.get('banners')

export const createBanner = banner => axiosApiInstance.post('banners', banner)

export const updateBanner = banner => axiosApiInstance.put('banners', banner)

export const deleteBannerById = banner_id =>
  axiosApiInstance.delete('banners', {
    banner_id,
  })

export const updateBannerCover = ({ banner_id, formdata }) =>
  axiosFileApiInstance.post(`banner_link/${banner_id}`, formdata)

export const uploadBannerLocalCover = ({ banner_id, locale, formdata }) =>
  axiosFileApiInstance.post(
    `banner/${banner_id}/locale/${locale}/cover`,
    formdata,
  )
