import React, { useCallback, useEffect, useState } from 'react'
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import CreateTagModal from './Modals/CreateTagModal'
import {
  createGenreThunk,
  createTagThunk,
} from '../../redux/slices/types/thunks'
import CreateGenreModal from './Modals/CreateGenreModal'
import CreateAdvertisementModal from './Modals/CreateAdvertisementModal'
import { createAdvertisementThunk } from '../../redux/slices/advertisements/thunks'
import './styles.scss'

const ControlPanel = () => {
  const dispatch = useDispatch()

  const [isShowTagModal, setIsShowTagModal] = useState(false)
  const [isShowGenreModal, setIsShowGenreModal] = useState(false)
  const [isShowAdvertisementModal, setIsShowAdvertisementModal] =
    useState(false)
  const [tagName, setTagName] = useState('')
  const [isOpenBannerBookSelect, setIsOpenBannerBookSelect] = useState(false)
  const [isOpenBannerAdvertisementSelect, setIsOpenBannerAdvertisementSelect] =
    useState(false)
  const [advertisementTitle, setAdvertisementTitle] = useState('')
  const [advertisementLink, setAdvertisementLink] = useState('')

  const onTagNameChange = useCallback(e => setTagName(e.target.value), [])

  const onAdvertisementTitleChange = useCallback(
    e => setAdvertisementTitle(e.target.value),
    [],
  )

  const onAdvertisementLinkChange = useCallback(
    e => setAdvertisementLink(e.target.value),
    [],
  )

  const showTagModal = useCallback(() => {
    setIsShowTagModal(true)
  }, [])

  const hideTagModal = useCallback(() => {
    setIsShowTagModal(false)
    setTagName('')
  }, [])

  const showGenreModal = useCallback(() => {
    setIsShowGenreModal(true)
  }, [])

  const hideGenreModal = useCallback(() => {
    setIsShowGenreModal(false)
  }, [])

  const showAdvertisementModal = useCallback(() => {
    setIsShowAdvertisementModal(true)
  }, [])

  const hideAdvertisementModal = useCallback(() => {
    setIsShowAdvertisementModal(false)
    setAdvertisementTitle('')
    setAdvertisementLink('')
  }, [])

  const closeBannerAdvertisementSelect = useCallback(() => {
    setIsOpenBannerAdvertisementSelect(false)
  }, [])

  const closeBannerBookSelect = useCallback(() => {
    setIsOpenBannerBookSelect(false)
  }, [])

  const onBlurBookSelect = useCallback(
    e => {
      if (e.target.className !== 'book') {
        closeBannerBookSelect()
      }
    },
    [closeBannerBookSelect],
  )

  const onBlurAdvertisementSelect = useCallback(
    e => {
      if (e.target.className !== 'book') {
        closeBannerAdvertisementSelect()
      }
    },
    [closeBannerAdvertisementSelect],
  )

  const createTag = useCallback(() => {
    dispatch(createTagThunk({ title: tagName }))
    hideTagModal()
  }, [dispatch, hideTagModal, tagName])

  const createGenre = useCallback(
    genre => {
      dispatch(createGenreThunk(genre))
      hideGenreModal()
    },
    [dispatch, hideGenreModal],
  )

  const createAdvertisementHandler = useCallback(() => {
    if (advertisementTitle && advertisementLink) {
      const advertisement = {
        title: advertisementTitle,
        link: advertisementLink,
      }
      dispatch(createAdvertisementThunk(advertisement))
      hideAdvertisementModal()
    }
  }, [advertisementTitle, advertisementLink, dispatch, hideAdvertisementModal])

  useEffect(() => {
    if (isOpenBannerBookSelect) {
      document.body.addEventListener('click', onBlurBookSelect)
      return () => {
        document.body.removeEventListener('click', onBlurBookSelect)
      }
    }
    return () => {
      document.body.removeEventListener('click', onBlurBookSelect)
    }
  }, [isOpenBannerBookSelect, onBlurBookSelect])

  useEffect(() => {
    if (isOpenBannerAdvertisementSelect) {
      document.body.addEventListener('click', onBlurAdvertisementSelect)
      return () => {
        document.body.removeEventListener('click', onBlurAdvertisementSelect)
      }
    }
    return () => {
      document.body.removeEventListener('click', onBlurAdvertisementSelect)
    }
  }, [isOpenBannerAdvertisementSelect, onBlurAdvertisementSelect])

  return (
    <div className='control-panel'>
      <ButtonToolbar>
        <ButtonGroup className='me-2'>
          <Button variant='primary' onClick={showTagModal}>
            Create tag
          </Button>
        </ButtonGroup>
        <ButtonGroup className='me-2'>
          <Button variant='primary' onClick={showGenreModal}>
            Create genre
          </Button>
        </ButtonGroup>
        <ButtonGroup className='me-2'>
          <Button variant='primary' onClick={showAdvertisementModal}>
            Create advertisement
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
      <CreateTagModal
        isShow={isShowTagModal}
        onHide={hideTagModal}
        onSubmit={createTag}
        value={tagName}
        onChange={onTagNameChange}
      />
      <CreateGenreModal
        isShow={isShowGenreModal}
        onHide={hideGenreModal}
        onSubmit={createGenre}
      />
      <CreateAdvertisementModal
        isShow={isShowAdvertisementModal}
        onHide={hideAdvertisementModal}
        valueLink={advertisementLink}
        valueTitle={advertisementTitle}
        onChangeTitle={onAdvertisementTitleChange}
        onChangeLink={onAdvertisementLinkChange}
        onSubmit={createAdvertisementHandler}
      />
    </div>
  )
}

export default ControlPanel
