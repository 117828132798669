import React, { memo } from 'react'
import { Button, Modal } from 'react-bootstrap'
import SearchBar from '../../../../../components/UI/SearchBar'
import './styles.scss'
import { Flex } from '@chakra-ui/react'

const UpdateSeriesModal = ({
  updatedSeries,
  hideHandler,
  show,
  filteredNotSelectedUpdatedSeriesBooks,
  handleSelectBookToAdd,
  onSubmit,
  handleGetSelectedBookIndex,
  seriesBookSearch,
  handleSeriesBookSearchChange,
  setShowDeleted,
  showDeleted,
}) => (
  <Modal show={show} onHide={hideHandler} centered>
    <Modal.Header closeButton>
      <Modal.Title>
        Add books to &quot;
        {updatedSeries.title}&quot; section
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Flex gap='8px' align='center'>
        <SearchBar
          seriesValue={seriesBookSearch}
          onChange={handleSeriesBookSearchChange}
        />
        <label>
          <input
            type='checkbox'
            checked={showDeleted}
            onChange={() => setShowDeleted(!showDeleted)}
            style={{
              marginRight: '4px',
            }}
          />
          show deleted
        </label>
      </Flex>
      <div className='modal-series-book-list'>
        {filteredNotSelectedUpdatedSeriesBooks.map(book => (
          <div
            className={`series-book ${
              handleGetSelectedBookIndex(book.book_id) > -1
                ? 'series-book--selected'
                : ''
            }`}
            key={book.book_id}
            onClick={() => handleSelectBookToAdd(book.book_id)}
          >
            <img
              src={book.cover_link || '/images/book.png'}
              alt='series-book-cover'
              className='series-book__image'
            />
            <div className='series-book-info'>
              <div className='series-book-info__title'>
                <div>{book.title}</div>
                <div>status: {book.status}</div>
              </div>
              <div className='series-book-info__index'>
                {handleGetSelectedBookIndex(book.book_id) > -1 &&
                  handleGetSelectedBookIndex(book.book_id) + 1}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='primary' onClick={onSubmit}>
        Add books
      </Button>
    </Modal.Footer>
  </Modal>
)

export default memo(UpdateSeriesModal)
