import { HttpStatuses } from 'constants/http'
import { create } from 'axios'
import AuthService from 'services/auth'
import { API_URL } from 'shared/get-env'

const axiosFileApiInstance = create({
  baseURL: API_URL + 'file/upload',
})

axiosFileApiInstance.interceptors.request.use(
  async config => {
    config.headers = {
      Authorization: AuthService.token || '',
      'Content-Type': 'application/json',
    }
    return config
  },
  error => {
    Promise.reject(error)
  },
)

axiosFileApiInstance.interceptors.response.use(
  response => {
    return response
  },
  async function (error) {
    const originalRequest = error.config
    if (
      error.response.status === HttpStatuses.NOT_AUTHORIZED &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true
      AuthService.removeToken()
      return axiosFileApiInstance(originalRequest)
    }
    return Promise.reject(error)
  },
)

export default axiosFileApiInstance
