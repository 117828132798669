import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Input,
  Text,
} from '@chakra-ui/react'
import ArrowIcon from 'components/UI/Icons/ArrowIcon'
import React, { memo, useState } from 'react'
import { colors } from 'shared/style/colors'
import ScreenTypeSelector from '../ScreenTypeSelector'
import DefaultSelector from '../DefaultSelector'

const BaseSettings = memo(
  ({
    place,
    title,
    toggleScreenType,
    handleInputTitle,
    isDefault,
    toggleDefault,
  }) => {
    const [hover, setHover] = useState(false)

    return (
      <AccordionItem border='none'>
        {({ isExpanded }) => (
          <Flex
            bg='white'
            w='100%'
            borderRadius='8px'
            direction='column'
            border={
              hover || isExpanded
                ? `1px solid ${colors['primary-violet-100']}`
                : `1px solid ${colors['primary-gray-30']}`
            }
          >
            <AccordionButton
              _hover={{
                bg: 'white',
              }}
              p='8px 44px'
              m='0'
              borderRadius='8px'
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              outline='none'
              _focus={{
                boxShadow: 'none',
              }}
            >
              <Flex direction='column' flex={1} gap='12px'>
                <Heading
                  size='poppins24'
                  variant='primary-black-text'
                  color={
                    hover || isExpanded
                      ? colors['primary-blue-100']
                      : colors['primary-black-text']
                  }
                  lineHeight='38px'
                  letterSpacing='0'
                  textAlign='left'
                >
                  Core Settings
                </Heading>
              </Flex>

              {isExpanded ? (
                <Box
                  p='10px'
                  bg={colors['primary-violet-10']}
                  borderRadius='50%'
                >
                  <Box transform='rotate(180deg)'>
                    <ArrowIcon
                      fill={
                        hover
                          ? colors['button-hover']
                          : colors['primary-violet-100']
                      }
                    />
                  </Box>
                </Box>
              ) : (
                <Box
                  p='10px'
                  bg={colors['primary-violet-10']}
                  borderRadius='50%'
                >
                  <Box>
                    <ArrowIcon
                      fill={
                        hover
                          ? colors['button-hover']
                          : colors['primary-violet-100']
                      }
                    />
                  </Box>
                </Box>
              )}
            </AccordionButton>
            <AccordionPanel>
              <Flex direction='column'>
                <Flex w='100%' gap='48px'>
                  <Flex direction='column' w='50%'>
                    <Text
                      size='poppins16normal'
                      variant='primary-black-text'
                      whiteSpace='nowrap'
                      w='100%'
                      mb='12px'
                    >
                      Screen title
                    </Text>

                    <Input
                      type='text'
                      placeholder='Title'
                      variant='loginField'
                      value={title}
                      onChange={handleInputTitle}
                      mb={title ? '8px' : '24px'}
                    />
                  </Flex>
                </Flex>
                <Flex w='100%' h='max-content' gap='48px' align='center'>
                  <Flex direction='column' w='50%'>
                    <Text
                      size='poppins16normal'
                      variant='primary-black-text'
                      whiteSpace='nowrap'
                      w='100%'
                      mb='12px'
                    >
                      Screen place type
                    </Text>

                    <Box mb='24px' w='100%'>
                      <ScreenTypeSelector
                        toggleScreenType={toggleScreenType}
                        place={place}
                        isModal
                      />
                    </Box>
                  </Flex>

                  <Flex
                    direction='column'
                    flexGrow={1}
                    w='50%'
                    h='100%'
                    minH='100%'
                    justify='center'
                    align='center'
                  >
                    <DefaultSelector
                      value={isDefault}
                      handleToggle={toggleDefault}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </AccordionPanel>
          </Flex>
        )}
      </AccordionItem>
    )
  },
)

export default BaseSettings
