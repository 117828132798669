import React, { memo } from 'react'

import { BannerTypes } from '../../../../constants/banners'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { useField } from 'formik'

const FormBannerTypeSelect = () => {
  const [field, meta] = useField({ name: 'banner_type' })

  return (
    <div className={styles.fieldContainer}>
      <label htmlFor='banner_type' className={styles.label}>
        Banner type:
      </label>
      <select
        id='banner_type'
        className={classNames(styles.field, {
          [styles.fieldError]: meta.error && meta.touched,
        })}
        {...field}
      >
        {Object.keys(BannerTypes).map((type, index) => (
          <option key={type + index} value={type}>
            {type}
          </option>
        ))}
      </select>
      {meta.error && meta.touched && (
        <p className={styles.error}>{meta.error}</p>
      )}
    </div>
  )
}

export default memo(FormBannerTypeSelect)
