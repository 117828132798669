const FilledPinIcon = () => {
  return (
    <svg
      enableBackground='new 0 0 128 128'
      viewBox='0 0 128 128'
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
    >
      <g transform='matrix(1,0,0,1,0,0)'>
        <g id='Icon' display='none'>
          <g display='inline'>
            <g>
              <g>
                <path
                  d='m45.54 46.53 35.19-27.96 29.11 29.12-28.09 35.36'
                  style={{
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10,
                  }}
                  fill='none'
                  fillOpacity='1'
                  stroke='#045e13'
                  strokeOpacity='1'
                  dataOriginalStrokeColor='#000000ff'
                  strokeWidth='2'
                  dataOriginalStrokeWidth='2'
                />
              </g>
              <g>
                <path
                  d='m83.019 3.927-7.237 7.237c-.679.679-.679 1.781 0 2.46l39.012 39.012c.679.679 1.781.679 2.46 0l7.237-7.237c.679-.679.679-1.781 0-2.46l-39.012-39.012c-.68-.68-1.781-.68-2.46 0z'
                  style={{
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10,
                  }}
                  fill='none'
                  fillOpacity='1'
                  stroke='#045e13'
                  strokeOpacity='1'
                  dataOriginalStrokeColor='#000000ff'
                  strokeWidth='2'
                  dataOriginalStrokeWidth='2'
                />
              </g>
              <g>
                <path
                  d='m35.581 40.747c-8.37-2.764-14.64-1.705-18.807 3.177-1.67 1.956-1.401 4.919.417 6.737l59.714 59.714c2.249 2.249 6.072 1.966 7.804-.703 2.322-3.579 3.074-7.662 2.746-12.091-.496-6.706-3.701-12.925-8.456-17.68l-32.094-32.092c-3.184-3.184-7.049-5.65-11.324-7.062z'
                  style={{
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeMiterlimit: 10,
                  }}
                  fill='none'
                  fillOpacity='1'
                  stroke='#045e13'
                  strokeOpacity='1'
                  dataOriginalStrokeColor='#000000ff'
                  strokeWidth='2'
                  dataOriginalStrokeWidth='2'
                />
              </g>
              <path
                d='m40.957 74.428-28.118 28.118-9.839 22.037 22.037-9.84 28.117-28.117z'
                style={{
                  strokeLinecap: 'round',
                  strokeLinejoin: 'round',
                  strokeMiterlimit: 10,
                }}
                fill='none'
                fillOpacity='1'
                stroke='#045e13'
                strokeOpacity='1'
                dataOriginalStrokeColor='#000000ff'
                strokeWidth='2'
                dataOriginalStrokeWidth='2'
              />
            </g>
          </g>
        </g>
        <g id='Glyph' display='none'>
          <g display='inline'>
            <g>
              <path
                d='m108.68 50.76-24.39 30.69c-.95-1.28-2.01-2.5-3.17-3.66l-32.09-32.1c-.59-.6-1.21-1.17-1.86-1.73l30.49-24.22z'
                fill='#045e13'
                fillOpacity='1'
                dataOriginalColor='#000000ff'
                stroke='none'
                strokeOpacity='1'
              />
            </g>
            <g>
              <path
                d='m88.45 97.51c.37 4.97-.58 9.13-2.9 12.71-.97 1.48-2.55 2.44-4.34 2.63-.22.03-.44.04-.66.04-1.63 0-3.19-.64-4.35-1.81l-59.72-59.71c-2.23-2.23-2.44-5.79-.47-8.09 4.44-5.2 11.13-6.37 19.88-3.48 2.16.71 4.25 1.7 6.22 2.93s3.83 2.69 5.5 4.37l32.1 32.1c5.14 5.14 8.25 11.64 8.74 18.31z'
                fill='#045e13'
                fillOpacity='1'
                dataOriginalColor='#000000ff'
                stroke='none'
                strokeOpacity='1'
              />
              <path
                d='m126 44.17c0 .73-.29 1.42-.8 1.94l-7.24 7.23c-.52.52-1.2.81-1.94.81-.73 0-1.42-.29-1.93-.81l-39.02-39.01c-1.06-1.07-1.06-2.81 0-3.87l7.24-7.24c1.04-1.04 2.84-1.03 3.88 0l39.01 39.01c.51.52.8 1.21.8 1.94z'
                fill='#045e13'
                fillOpacity='1'
                dataOriginalColor='#000000ff'
                stroke='none'
                strokeOpacity='1'
              />
            </g>
            <g>
              <path
                d='m51.74 89.45-26 26c-.08.09-.18.16-.29.21l-22.04 9.84c-.13.05-.27.08-.41.08-.26 0-.52-.1-.71-.29-.29-.29-.37-.74-.2-1.12l9.84-22.03c.05-.11.12-.22.2-.3l26-26z'
                fill='#045e13'
                fillOpacity='1'
                dataOriginalColor='#000000ff'
                stroke='none'
                strokeOpacity='1'
              />
            </g>
          </g>
        </g>
        <g id='Line_color'>
          <g>
            <g>
              <path
                d='m38.772 51.914 41.96-33.34 29.111 29.112-33.34 41.959z'
                fill='#2c8c4b'
                fillOpacity='1'
                dataOriginalColor='#8891bfff'
                stroke='none'
                strokeOpacity='1'
              />
              <g>
                <path
                  d='m39.833 52.975c4.718-3.749 9.436-7.497 14.153-11.246 7.529-5.982 15.057-11.964 22.586-17.946 1.74-1.383 3.48-2.765 5.22-4.148-.707 0-1.414 0-2.121 0 8.484 8.484 16.967 16.967 25.451 25.451l3.66 3.66c0-.707 0-1.414 0-2.121-3.749 4.718-7.497 9.436-11.246 14.153-5.982 7.529-11.964 15.057-17.946 22.586-1.383 1.74-2.765 3.48-4.148 5.22h2.121c-4.236-4.236-8.472-8.472-12.709-12.709-6.787-6.787-13.575-13.575-20.362-20.362-1.554-1.554-3.107-3.107-4.661-4.661-1.367-1.367-3.489.754-2.121 2.121l12.709 12.709 20.362 20.362 4.661 4.661c.525.525 1.615.637 2.121 0 3.749-4.718 7.497-9.436 11.246-14.153 5.982-7.529 11.964-15.057 17.946-22.586 1.383-1.74 2.765-3.48 4.148-5.22.504-.635.626-1.495 0-2.121-8.484-8.484-16.967-16.967-25.451-25.451-1.22-1.22-2.44-2.44-3.66-3.66-.621-.621-1.486-.505-2.121 0-4.718 3.749-9.436 7.497-14.153 11.246-7.529 5.982-15.057 11.964-22.586 17.946-1.74 1.383-3.48 2.765-5.22 4.148-1.512 1.2.624 3.31 2.121 2.121z'
                  fill='#045e13'
                  fillOpacity='1'
                  dataOriginalColor='#000000ff'
                  stroke='none'
                  strokeOpacity='1'
                />
              </g>
            </g>
            <g>
              <path
                d='m83.019 3.927-7.237 7.237c-.679.679-.679 1.781 0 2.46l39.012 39.012c.679.679 1.781.679 2.46 0l7.237-7.237c.679-.679.679-1.781 0-2.46l-39.012-39.012c-.68-.68-1.781-.68-2.46 0z'
                fill='#4cc457'
                fillOpacity='1'
                dataOriginalColor='#787fa7ff'
                stroke='none'
                strokeOpacity='1'
              />
              <g>
                <path
                  d='m81.958 2.866c-1.795 1.795-3.591 3.591-5.386 5.386-.614.614-1.27 1.206-1.851 1.851-1.251 1.389-1.199 3.267.051 4.632 2.176 2.376 4.579 4.579 6.856 6.856 8.885 8.885 17.771 17.771 26.656 26.656 1.368 1.368 2.735 2.735 4.103 4.103.749.749 1.551 1.769 2.583 2.113 1.62.541 2.865-.289 3.946-1.37 1.005-1.005 2.011-2.011 3.016-3.016 1.038-1.038 2.076-2.076 3.115-3.115 1.05-1.05 1.788-2.302 1.272-3.848-.347-1.041-1.393-1.863-2.149-2.618-1.398-1.398-2.796-2.796-4.195-4.195-4.35-4.35-8.7-8.7-13.049-13.049-4.493-4.493-8.986-8.986-13.48-13.48-2.282-2.282-4.49-4.689-6.87-6.87-1.41-1.292-3.175-1.214-4.618-.036-1.496 1.222.637 3.333 2.121 2.121.484-.395 5.119 4.78 5.536 5.197 4.037 4.037 8.073 8.073 12.11 12.11 7.056 7.056 14.113 14.113 21.169 21.169.125.125.46.348.519.519.169.488-.596.968-.924 1.296-1.946 1.946-3.892 3.892-5.838 5.838-.309.309-.456.592-.864.392-.245-.12-.501-.501-.692-.692-1.084-1.084-2.169-2.169-3.253-3.253-8.304-8.304-16.608-16.608-24.912-24.912-3.003-3.003-6.006-6.006-9.009-9.009-.401-.401-1.294-.944-.993-1.505.435-.811 1.6-1.6 2.245-2.245 1.094-1.094 2.189-2.189 3.283-3.283.541-.541 1.082-1.082 1.622-1.622 1.368-1.367-.752-3.488-2.12-2.121z'
                  fill='#045e13'
                  fillOpacity='1'
                  dataOriginalColor='#000000ff'
                  stroke='none'
                  strokeOpacity='1'
                />
              </g>
            </g>
            <g>
              <path
                d='m35.581 40.747c-8.37-2.764-14.64-1.705-18.807 3.177-1.67 1.956-1.401 4.919.417 6.737l59.714 59.714c2.249 2.249 6.072 1.966 7.804-.703 2.322-3.579 3.074-7.662 2.746-12.091-.496-6.706-3.701-12.925-8.456-17.68l-32.094-32.092c-3.184-3.184-7.049-5.65-11.324-7.062z'
                fill='#4cc457'
                fillOpacity='1'
                dataOriginalColor='#787fa7ff'
                stroke='none'
                strokeOpacity='1'
              />
              <g>
                <path
                  d='m35.979 39.301c-5.538-1.794-12.043-2.564-17.159.824-2.328 1.542-4.561 3.745-4.656 6.7-.061 1.882.754 3.628 2.035 4.966 2.326 2.432 4.765 4.765 7.144 7.144 5.011 5.011 10.023 10.023 15.034 15.034 5.809 5.809 11.617 11.617 17.426 17.426l14.32 14.32 4.306 4.306c.751.751 1.482 1.58 2.357 2.189 2.296 1.598 5.504 1.549 7.724-.159 2.437-1.875 3.66-5.577 4.163-8.494 1.148-6.661-.596-13.616-4.221-19.261-3.018-4.7-7.487-8.548-11.414-12.476-5.61-5.61-11.22-11.22-16.829-16.829-3.637-3.637-7.157-7.706-11.236-10.868-2.719-2.108-5.736-3.718-8.994-4.822-1.833-.621-2.619 2.275-.798 2.893 4.251 1.441 7.906 3.919 11.063 7.076l4.32 4.32c5.2 5.2 10.4 10.4 15.6 15.6l11.415 11.415c3.061 3.061 5.582 6.636 7.028 10.743 1.084 3.08 1.579 6.328 1.376 9.591-.092 1.477-.365 2.934-.821 4.342-.466 1.438-1.17 3.339-2.374 4.328-3.208 2.635-6.513-1.984-8.537-4.008-4.08-4.08-8.161-8.161-12.241-12.241-10.681-10.681-21.363-21.363-32.044-32.044-3.383-3.383-6.766-6.766-10.149-10.149-.52-.52-1.065-1.025-1.565-1.565-2.131-2.306-.698-4.737 1.394-6.392 1.926-1.523 4.327-2.31 6.764-2.438 2.999-.157 5.936.503 8.773 1.422 1.84.596 2.628-2.3.796-2.893z'
                  fill='#045e13'
                  fillOpacity='1'
                  dataOriginalColor='#000000ff'
                  stroke='none'
                  strokeOpacity='1'
                />
              </g>
            </g>
            <g>
              <path
                d='m40.957 74.428-28.118 28.118-9.839 22.037 22.037-9.84 28.117-28.117z'
                fill='#aed4af'
                fillOpacity='1'
                dataOriginalColor='#dbdbdbff'
                stroke='none'
                strokeOpacity='1'
              />
              <g>
                <path
                  d='m39.896 73.368c-6.441 6.441-12.883 12.883-19.324 19.324-2.842 2.842-5.752 5.632-8.534 8.534-.574.599-.82 1.294-1.158 2.05-1.015 2.273-2.03 4.547-3.045 6.82-2.043 4.576-4.087 9.153-6.13 13.729-.629 1.409.641 2.682 2.052 2.052 3.863-1.725 7.725-3.449 11.588-5.174 2.568-1.146 5.135-2.293 7.703-3.439.981-.438 2.242-.779 3.073-1.484.809-.687 1.527-1.527 2.276-2.276 1.655-1.655 3.309-3.309 4.964-4.964 4.265-4.265 8.53-8.53 12.795-12.795 2.686-2.686 5.373-5.373 8.059-8.059.573-.573.574-1.547 0-2.121-4.066-4.066-8.132-8.132-12.198-12.198-1.367-1.367-3.489.754-2.121 2.121 4.066 4.066 8.132 8.132 12.198 12.198 0-.707 0-1.414 0-2.121-6.414 6.414-12.828 12.828-19.242 19.242-2.85 2.85-5.775 5.642-8.558 8.558-.085.089-.19.169-.266.266-.142.18.416-.179.175-.148-.464.059-1.05.469-1.473.658-2.29 1.022-4.58 2.045-6.869 3.067-4.539 2.027-9.079 4.054-13.618 6.08.684.684 1.368 1.368 2.052 2.052 1.725-3.863 3.449-7.725 5.174-11.588 1.123-2.515 2.246-5.029 3.368-7.544.346-.776.693-1.552 1.039-2.328.064-.144.203-.342.223-.5.034-.274-.341.335-.156.182.846-.694 1.586-1.586 2.359-2.359 1.646-1.646 3.293-3.293 4.939-4.939 4.266-4.266 8.531-8.531 12.797-12.797 2.659-2.659 5.319-5.319 7.978-7.978 1.368-1.367-.752-3.489-2.12-2.121z'
                  fill='#045e13'
                  fillOpacity='1'
                  dataOriginalColor='#000000ff'
                  stroke='none'
                  strokeOpacity='1'
                />
              </g>
            </g>
          </g>
        </g>
        <g id='Flat' display='none'>
          <g display='inline'>
            <path
              d='m38.358 51.716 42.648-33.887 29.589 29.589-33.887 42.648z'
              fill='#2c8c4b'
              fillOpacity='1'
              dataOriginalColor='#8891bfff'
              stroke='none'
              strokeOpacity='1'
            />
            <path
              d='m83.331 2.942-7.356 7.356c-.69.69-.69 1.81 0 2.5l39.651 39.651c.69.69 1.81.69 2.5 0l7.356-7.356c.69-.69.69-1.81 0-2.5l-39.651-39.651c-.691-.69-1.81-.69-2.5 0z'
              fill='#4cc457'
              fillOpacity='1'
              dataOriginalColor='#787fa7ff'
              stroke='none'
              strokeOpacity='1'
            />
            <path
              d='m35.115 40.366c-8.508-2.809-14.88-1.733-19.116 3.229-1.697 1.988-1.424 4.999.424 6.848l60.693 60.693c2.286 2.286 6.172 1.998 7.931-.714 2.36-3.637 3.124-7.787 2.791-12.289-.504-6.816-3.762-13.137-8.594-17.97l-32.619-32.619c-3.236-3.236-7.165-5.743-11.51-7.178z'
              fill='#4cc457'
              fillOpacity='1'
              dataOriginalColor='#787fa7ff'
              stroke='none'
              strokeOpacity='1'
            />
            <path
              d='m40.579 74.599-28.578 28.579-10.001 22.398 22.398-10.001 28.579-28.578z'
              fill='#aed4af'
              fillOpacity='1'
              dataOriginalColor='#dbdbdbff'
              stroke='none'
              strokeOpacity='1'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default FilledPinIcon
