import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from 'redux/store'

/**
 * @hoc Redux initialization provider
 */
const withRedux = component => () =>
  (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {component()}
      </PersistGate>
    </Provider>
  )

export default withRedux
