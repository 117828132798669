import { createSlice } from '@reduxjs/toolkit'
import * as R from 'ramda'
import {
  createBannerThunk,
  deleteBannerByIdThunk,
  fetchBannersThunk,
  updateBannerCoverThunk,
  updateBannerThunk,
} from './thunks'

export const STATE_KEY = 'banners'

const initialState = {}

const bannersSlice = createSlice({
  name: STATE_KEY,
  initialState,
  extraReducers: {
    [fetchBannersThunk.fulfilled]: (_, action) => {
      return R.indexBy(R.prop('banner_id'), action.payload)
    },
    [createBannerThunk.fulfilled]: (state, action) => {
      const banner = action.payload
      state[banner.banner_id] = banner
    },
    [updateBannerCoverThunk.fulfilled]: (state, action) => {
      const { banner_id, link } = action.payload
      state[banner_id].cover_url = link
    },
    [deleteBannerByIdThunk.fulfilled]: (state, { payload: banner_id }) => {
      delete state[banner_id]
    },
    [updateBannerThunk.fulfilled]: (state, { payload: banner }) => {
      state[banner.banner_id] = { ...state[banner.banner_id], ...banner }
    },
  },
})

export const bannersReducer = bannersSlice.reducer
