import React from 'react'
import LoginForm from '../LoginForm'
import { Box, Flex, Image } from '@chakra-ui/react'

const AuthLayout = ({
  email,
  onEmailChange,
  onPasswordChange,
  password,
  onLoginFormSubmit,
  isLoading,
}) => (
  <Flex w='100%' h='100%' justify='center' align='center' pos='relative'>
    <Image
      position='absolute'
      src='/images/bg.png'
      zIndex={-1}
      h='100vh'
      w='100vw'
      objectPosition='center'
      objectFit='cover'
      loading='eager'
    />

    <Box w='50%' />

    <LoginForm
      email={email}
      onEmailChange={onEmailChange}
      onPasswordChange={onPasswordChange}
      password={password}
      onLoginFormSubmit={onLoginFormSubmit}
      isLoading={isLoading}
    />
  </Flex>
)

export default React.memo(AuthLayout)
