import React from 'react'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import AuthSevice from 'services/auth'
import { API_GRAPHQL_URL, API2_GRAPHQL_URL } from 'shared/get-env'
import { API_V2 } from 'constants/apollo'

/**
 * Инициализация API.baseUrl
 */

const httpLink = createHttpLink({
  uri: API_GRAPHQL_URL,
})

const httpLinkV2 = createHttpLink({
  uri: API2_GRAPHQL_URL,
})

/**
 * Логика авторизации
 * FIXME: вынести в features/auth?
 */
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: AuthSevice.token || '',
    },
  }
})

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    errorPolicy: 'all',
  },
}

export const apolloClient = new ApolloClient({
  link: ApolloLink.split(
    operation => operation.getContext()?.clientName === API_V2,
    authLink.concat(httpLinkV2), // if api v2 in context
    authLink.concat(httpLink),
  ),
  cache: new InMemoryCache({
    addTypename: false,
    possibleTypes: {
      pagination: ['PaginationResponse'],
    },
  }),
  defaultOptions,
})

/**
 * @hoc Инициализация подключения apollo для работы с API
 */
const withApollo = component => () =>
  <ApolloProvider client={apolloClient}>{component()}</ApolloProvider>

export default withApollo
