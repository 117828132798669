import { Flex, Image, Text } from '@chakra-ui/react'
import { memo } from 'react'
import { colors } from 'shared/style/colors'

const RoleLogo = memo(({ isUserAdmin }) => {
  return (
    <Flex
      minW='90px'
      p='12px'
      align='center'
      justify='center'
      direction='column'
      mb={isUserAdmin ? '0' : '49px'}
      gap='4px'
    >
      <Flex
        w='38px'
        h='38px'
        borderRadius='50%'
        bg={colors['primary-violet-10']}
        align='center'
        justify='center'
      >
        <Image
          w='34px'
          h='34px'
          src='/images/hero/heart.svg'
          alt='heart icon'
        />
      </Flex>

      <Text size='poppins12' variant='primary-violet-100' lineHeight='24px'>
        {isUserAdmin ? 'Admin' : 'Support'}
      </Text>
    </Flex>
  )
})

export default RoleLogo
