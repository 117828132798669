import React, { memo } from 'react'
import { Button, Modal } from 'react-bootstrap'
import SearchBar from '../../../../../../components/UI/SearchBar'
import './styles.scss'
import { Flex, Text } from '@chakra-ui/react'
import LoadingSpinner from 'components/UI/LoadingSpinner'

const AppendBookModal = ({
  updatedList,
  hideHandler,
  isOpen,
  filteredBooks,
  getIsSelectedBook,
  selectBook,
  updateBooksList,
  getSelectedBookIndex,
  seriesBookSearch,
  handleSeriesBookSearchChange,
  isSearchedBooksLoading,
  setShowDeleted,
  showDeleted,
}) => (
  <Modal
    show={isOpen}
    onHide={hideHandler}
    centered
    style={{
      zIndex: 10000,
    }}
  >
    <Modal.Header closeButton>
      <Modal.Title>
        Add books to &quot;
        {updatedList.title}&quot; list
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Flex gap='8px' align='center'>
        <SearchBar
          seriesValue={seriesBookSearch}
          onChange={handleSeriesBookSearchChange}
        />
        <label>
          <input
            type='checkbox'
            checked={showDeleted}
            onChange={() => setShowDeleted(!showDeleted)}
            style={{
              marginRight: '4px',
            }}
          />
          show deleted
        </label>
      </Flex>
      {!seriesBookSearch && (
        <Text as='p' size='poppins16normal' variant='primary-black-text'>
          Start search to filter books
        </Text>
      )}
      <div className='modal-book-list'>
        {isSearchedBooksLoading && <LoadingSpinner />}

        {!isSearchedBooksLoading &&
          filteredBooks.map(book => (
            <div
              className={`book ${
                getIsSelectedBook(book) ? 'book--selected' : ''
              }`}
              key={book.book_id}
              onClick={() => selectBook(book)}
            >
              <img
                src={book.cover_link || '/images/book.png'}
                alt='book-cover'
                className='book__image'
              />
              <div className='book-info'>
                <div className='book-info__title'>
                  <div>{book.title}</div>
                  <div>Status: {book.status}</div>
                </div>
                <div className='book-info__index'>
                  {getSelectedBookIndex(book) !== -1 &&
                    getSelectedBookIndex(book) + 1}
                </div>
              </div>
            </div>
          ))}
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button variant='primary' onClick={updateBooksList}>
        Add books
      </Button>
    </Modal.Footer>
  </Modal>
)

export default memo(AppendBookModal)
