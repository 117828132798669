export const ALERT_FADE_TIMEOUT = 3000

export const AlertMessages = Object.freeze({
  CLEAR_CACHE_SUCCESS: 'Сache cleared successfully!',
})

export const AlertVariantType = Object.freeze({
  ERROR: 'error',
  SUCCESS: 'success',
})
