import { Flex, IconButton, Image, Text } from '@chakra-ui/react'
import { FaRegTrashAlt } from 'react-icons/fa'
import React from 'react'

const ExistedAudio = ({ code, src, handleRemoveTrack, chapter_id }) => {
  const handleRemove = () => {
    if (chapter_id) {
      handleRemoveTrack(chapter_id)
    } else {
      handleRemoveTrack(code)
    }
  }

  return (
    <figure
      style={{
        display: 'flex',
        gap: '16px',
        width: '100%',
        alignItems: 'center',
        paddingRight: '30px',
      }}
    >
      <figcaption>
        <Flex gap='4px' p='10px 12px' align='center'>
          <Image
            w='31px'
            h='22px'
            src={`/images/flags/${code.toLowerCase()}.png`}
            alt={code}
          />

          <Text
            size='poppins18'
            variant='primary-black-text'
            fontWeight={400}
            lineHeight='32px'
            letterSpacing='-0.018px'
            cursor='pointer'
            w='fit-content'
            pr='12px'
          >
            {code.toUpperCase()}
          </Text>
        </Flex>
      </figcaption>
      <audio style={{ width: '100%' }} controls src={src}></audio>
      <IconButton
        aria-label='delete audio'
        icon={<FaRegTrashAlt />}
        bg='transparent'
        fontSize={32}
        onClick={handleRemove}
      />
    </figure>
  )
}

export default ExistedAudio
