import React, { memo } from 'react'
import {
  ButtonGroup,
  ButtonToolbar,
  Form,
  Modal,
  Button,
} from 'react-bootstrap'

const CreateAdvertisementModal = ({
  isShow,
  onHide,
  valueLink,
  valueTitle,
  onChangeTitle,
  onChangeLink,
  onSubmit,
}) => (
  <Modal show={isShow} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>Add a new advertisement</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form.Control
        type='text'
        placeholder='Enter advertisement name'
        value={valueTitle}
        onChange={onChangeTitle}
        style={{ marginBottom: 10 }}
      />
      <Form.Control
        type='text'
        placeholder='Enter advertisement link'
        value={valueLink}
        onChange={onChangeLink}
      />
    </Modal.Body>
    <Modal.Footer>
      <ButtonToolbar>
        <ButtonGroup className='me-2'>
          <Button variant='primary' onClick={onHide}>
            Cancel
          </Button>
        </ButtonGroup>
        <ButtonGroup className='me-2'>
          <Button variant='primary' onClick={onSubmit}>
            Save
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    </Modal.Footer>
  </Modal>
)

export default memo(CreateAdvertisementModal)
