import { Flex, Image, Tooltip } from '@chakra-ui/react'
import React from 'react'

const AddTranslationButton = ({ add }) => {
  return (
    <Flex>
      <Tooltip
        label='Add title translation'
        variant='createStory'
        placement='bottom-start'
      >
        <Flex cursor='pointer' align='center' onClick={add}>
          <Image
            src='/images/screens/plus.png'
            alt='plus'
            cursor='pointer'
            w='24px'
            h='24px'
          />
          <Image
            src='/images/screens/translate.png'
            alt='translate'
            cursor='pointer'
            w='36px'
            h='36px'
          />
        </Flex>
      </Tooltip>
    </Flex>
  )
}

export default AddTranslationButton
