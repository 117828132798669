import { Flex } from '@chakra-ui/react'
import { TabButton } from './TabButton/TabButton'
import { useMemo, useState } from 'react'

export const TabNavigation = ({ buttons, active, status, isStatus }) => {
  const [hover, setHover] = useState(false)
  const toCompare = isStatus ? status : active

  const sortedButtons = useMemo(() => {
    return [...buttons].sort((itA, itB) => {
      if (itA[0].toLowerCase() === toCompare) return -1
      if (itB[0].toLowerCase() === toCompare) return 1

      return 0
    })
  }, [buttons, toCompare])

  return (
    <Flex w='fit-content' direction='column' gap='24px'>
      <Flex
        maxW='100%'
        w='fit-content'
        justify='start'
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setTimeout(setHover(false))}
      >
        {sortedButtons.map(([key, value], ind, all) => {
          const isActive = isStatus
            ? key.toLowerCase() === status
            : key.toLowerCase() === active
          const hasActive = all
            .map(([key]) => key.toLowerCase())
            .includes(isStatus ? status : active)

          return (
            <TabButton
              key={key}
              button={value}
              isActive={isActive}
              hasActive={hasActive}
              isContainerHover={hover}
              setHover={setHover}
              ind={ind}
              isStatus={isStatus}
              active={active}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}
