import { createAsyncThunk } from '@reduxjs/toolkit'
import BookService from 'services/book'
import {
  fetchBooksByGenreId,
  setReleaseBookDate,
  updateBook,
  uploadBookFullCover,
} from '../../../../api/books'

export const fetchAllBooksThunk = createAsyncThunk(
  'books/fetchAll',
  async () => {
    try {
      return await BookService.fetchBooks()
    } catch (error) {}
  },
)

export const fetchBooksBySearchThunk = createAsyncThunk(
  'books/fetchBySearch',
  async variables => {
    try {
      return await BookService.searchBooks(variables)
    } catch (error) {}
  },
)

export const fetchBooksByGenreIdThunk = createAsyncThunk(
  'books/fetchByGenre',
  async genre_id => {
    const books = await fetchBooksByGenreId(genre_id).then(res => res.data)
    return { genre_id, books }
  },
)

export const updateBookThunk = createAsyncThunk('book/update', async book => {
  try {
    return await updateBook(book).then(res => res.data)
  } catch (error) {
    console.error(error)
    throw error
  }
})

export const setReleaseBookDateThunk = createAsyncThunk(
  'book/setReleaseDate',
  async book => {
    try {
      await setReleaseBookDate({ ...book, status: 'APPROVED' })
    } catch (error) {
      throw new Error(error)
    }
  },
)

export const updateBookFullCover = createAsyncThunk(
  'book/cover/full',
  async ({ book_id, formdata }) => {
    try {
      const res = await uploadBookFullCover({ book_id, formdata })
      return res.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)
