const { gql } = require('@apollo/client')

export const GET_ALL_SCREENS = gql`
  query Screens($screen: ScreenIdentityParams!, $pagination: Pagination) {
    items: screens(screen: $screen, pagination: $pagination) {
      screens {
        id
        isDefault
        title
        slug
        type
        screenSections {
          sectionId
          position
        }
      }
      pagination {
        ... on PaginationResponse {
          count
          offset
          total
        }
      }
    }
  }
`

export const CREATE_SCREEN = gql`
  mutation CreateScreen($screen: CreateScreen!) {
    screen: createScreen(screen: $screen) {
      id
      title
      type
      isDefault
      slug
      screenSections {
        screenId
        sectionId
        position
      }
    }
  }
`

export const REMOVE_SCREEN = gql`
  mutation RemoveScreen($screen: ScreenIdentity!) {
    result: removeScreen(screen: $screen) {
      success
    }
  }
`

export const UPDATE_SCREEN = gql`
  mutation UpdateScreen($screen: ScreenIdentity!, $params: UpdateScreen!) {
    screen: updateScreen(screen: $screen, params: $params) {
      id
    }
  }
`
