import { HttpStatuses } from 'constants/http'
import { create } from 'axios'
import AuthService from 'services/auth'
import { API_URL } from 'shared/get-env'

const axiosApiInstance = create({
  baseURL: API_URL,
})

axiosApiInstance.interceptors.request.use(
  async config => {
    config.headers = {
      Authorization: AuthService.token || '',
      'Content-Type': 'application/json',
    }
    return config
  },
  error => {
    Promise.reject(error)
  },
)

axiosApiInstance.interceptors.response.use(
  response => {
    return response
  },
  async function (error) {
    const originalRequest = error.config
    if (
      error.response.status === HttpStatuses.NOT_AUTHORIZED &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true
      AuthService.removeCredentials()
      return axiosApiInstance(originalRequest)
    }

    return Promise.reject(error.response.data)
  },
)

export default axiosApiInstance
