import React, { useCallback, useEffect, useMemo, useState } from 'react'
import BooksPageLayout from './components/BooksPageLayout'
import BooksModal from './containers/BookModal'
import { debounce } from 'lodash'
import { DEBOUNCE_DELAY } from 'constants/app'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { useLazyQuery } from '@apollo/client'
import { GET_BOOKS_BY_SEARCH } from 'services/book/graphql'

export const BOOK_STATUS_VALUE = {
  DRAFT: 0,
  TO_MODERATION: 2,
  TO_RELEASE: 4,
  ON_MODERATION: 3,
  REJECTED: 1,
  APPROVED: 5,
}

export const BOOKS_PER_PAGE = 10
export const BOOKS_LIMIT = 50

const BooksPanel = () => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [searchValue, setSearchValue] = useState('')
  const [query, setQuery] = useState('')
  const [selectedBook, setSelectedBook] = useState(null)
  const [itemOffset, setItemOffset] = useState(0)
  const [visibleBooks, setVisibleBooks] = useState([])
  const [booksLimit, setBooksLimit] = useState(BOOKS_LIMIT)
  const [deleted, setDeleted] = useState(false)
  const [author, setAuthor] = useState('')

  const [getAllBooks, { data: allBooks, loading: isAllBooksLoading }] =
    useLazyQuery(GET_BOOKS_BY_SEARCH)

  const filteredBooks = useMemo(
    () =>
      allBooks
        ? allBooks.booksSearch
            .filter(({ creator_id: { first_name, last_name } }) =>
              `${first_name} ${last_name}`
                .toLowerCase()
                .includes(author.toLowerCase()),
            )
            .sort((bookA, bookB) => {
              return (
                BOOK_STATUS_VALUE[bookB.status] -
                BOOK_STATUS_VALUE[bookA.status]
              )
            })
        : [],
    [allBooks, author],
  )

  const toggleDeleted = () => {
    setDeleted(curr => !curr)
  }

  const slideLimitValue = value => {
    setBooksLimit(value)
  }

  const handleBookModalShow = useCallback(
    book => {
      onOpen()
      setSelectedBook(book)
    },
    [onOpen],
  )

  const hideBookModal = useCallback(() => {
    onClose()
    setSelectedBook(null)
  }, [onClose])

  const handleSearchChange = useCallback(value => {
    setSearchValue(value)
  }, [])

  const handleAuthorChange = useCallback(value => {
    setAuthor(value)
  }, [])

  const handleSetQuery = useCallback(value => {
    setQuery(value)
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchChange = useCallback(
    debounce(value => handleSetQuery(value), DEBOUNCE_DELAY),
    [],
  )

  useEffect(() => {
    debouncedSearchChange(searchValue)
  }, [searchValue, debouncedSearchChange])

  const handleCopyBookIdClipboard = useCallback(
    text => {
      navigator.clipboard.writeText(text)
      toast({
        title: `Book Id copied to clipboard!`,
        status: 'success',
        isClosable: true,
        duration: 1000,
        position: 'top',
      })
    },
    [toast],
  )

  const handlePageClick = event => {
    const newOffset = (event.selected * BOOKS_PER_PAGE) % filteredBooks.length

    setItemOffset(newOffset)
  }

  useEffect(() => {
    const endOffset = itemOffset + BOOKS_PER_PAGE

    setVisibleBooks(filteredBooks.slice(itemOffset, endOffset))
  }, [itemOffset, filteredBooks])

  useEffect(() => {
    if (query || author) {
      getAllBooks({
        variables: {
          deleted,
          title: query,
          limit: booksLimit,
        },
      })
    }
  }, [query, getAllBooks, booksLimit, deleted, author])

  return (
    <>
      <BooksPageLayout
        books={visibleBooks}
        isAllBooksLoading={isAllBooksLoading}
        pageCount={Math.ceil(filteredBooks.length / BOOKS_PER_PAGE)}
        search={searchValue}
        deleted={deleted}
        booksLimit={booksLimit}
        slideLimitValue={slideLimitValue}
        toggleDeleted={toggleDeleted}
        handleSearchChange={handleSearchChange}
        handleCopyBookIdClipboard={handleCopyBookIdClipboard}
        handleBookModalShow={handleBookModalShow}
        handlePageClick={handlePageClick}
        handleAuthorChange={handleAuthorChange}
      />

      {isOpen && (
        <BooksModal
          isOpen={isOpen}
          query={query}
          book={selectedBook}
          hideBookModal={hideBookModal}
          deleted={deleted}
          booksLimit={booksLimit}
        />
      )}
    </>
  )
}

export default BooksPanel
