import { useQuery } from '@apollo/client'
import {
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import { apolloClient } from 'app/hocs/with-apollo'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import React, { useEffect, useState } from 'react'
import { ModalBody } from 'react-bootstrap'
import ListPanel from 'screens/Sections/OldSections'
import { GET_LIST } from 'services/lists/graphql'

const ListModal = ({ isOpen, onClose, list_id }) => {
  const [list, setList] = useState(null)
  const [display, setDisplay] = useState('flex')
  const { data, loading } = useQuery(GET_LIST, {
    variables: {
      list_id,
    },
    fetchPolicy: 'cache-and-network',
  })

  const toggleModal = () => {
    if (display === 'flex') {
      setDisplay('none')
    } else {
      setDisplay('flex')
    }
  }

  useEffect(() => {
    const modal = document.querySelector('.chakra-modal__content-container')
    const overlay = document.querySelector('.chakra-modal__overlay')

    if (display === 'flex') {
      apolloClient.query({
        query: GET_LIST,
        variables: { list_id },
        fetchPolicy: 'network-only',
      })
    }

    if (modal) {
      overlay.style.display = display
      modal.style.display = display
    }
  }, [display, list_id])

  useEffect(() => {
    if (data && !loading) {
      setList(data.lists[0])
    }
  }, [loading, data])

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalBody w='800px' minW='800px'>
        <ModalContent p='50px 30px 30px' w='800px' minW='800px' maxH='90vh'>
          <ModalCloseButton />

          <Flex w='100%' direction='column' maxH='90vh'>
            {loading && <LoadingSpinner />}
            {!loading && list && (
              <ListPanel list={list} toggleModal={toggleModal} />
            )}
          </Flex>
        </ModalContent>
      </ModalBody>
    </Modal>
  )
}

export default ListModal
