import React, { memo } from 'react'
import BooksList from '../BooksList'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import TitleSearch from './TitleSearch'
import { Flex } from '@chakra-ui/react'
import DeletedCheckBox from './DeletedCheckBox'
import BooksLimitSlider from './BooksLimitSlider'
import AuthorSearch from './AuthorSearch'

const BooksPageLayout = ({
  books,
  search,
  deleted,
  toggleDeleted,
  handleSearchChange,
  handleCopyBookIdClipboard,
  handleBookModalShow,
  handlePageClick,
  handleAuthorChange,
  pageCount,
  isAllBooksLoading,
  slideLimitValue,
  booksLimit,
}) => (
  <Flex flex={1} overflowY='hidden' direction='column'>
    {isAllBooksLoading && <LoadingSpinner />}

    <Flex gap='20px' p='10px 20px 0'>
      <TitleSearch search={search} onChange={handleSearchChange} />

      <AuthorSearch onChange={handleAuthorChange} />

      <DeletedCheckBox value={deleted} toggleDeleted={toggleDeleted} />

      <BooksLimitSlider
        slideLimitValue={slideLimitValue}
        booksLimit={booksLimit}
      />
    </Flex>

    {!isAllBooksLoading && (
      <BooksList
        books={books}
        handleCopyBookIdClipboard={handleCopyBookIdClipboard}
        handleBookModalShow={handleBookModalShow}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />
    )}
  </Flex>
)

export default memo(BooksPageLayout)
