import { Box, Flex, Image, Text } from '@chakra-ui/react'
import SelectArrowIcon from 'components/UI/Icons/SelectArrowIcon'
import { memo, useCallback, useEffect, useState } from 'react'
import { colors } from 'shared/style/colors'
import SimpleBarReact from 'simplebar-react'

const LocalesSelector = memo(({ availableLocales, addLocales }) => {
  const [isLocalesShown, setIsLocalesShown] = useState(false)
  const [focused, setFocused] = useState('')

  const localesWrapper = `locales-select-wrapper`

  const handleHideList = useCallback(() => {
    setIsLocalesShown(false)
  }, [])

  const handleTriggerChaptersList = useCallback(() => {
    if (isLocalesShown) {
      handleHideList()
    } else {
      setIsLocalesShown(true)
    }
  }, [handleHideList, isLocalesShown])

  const handleSelectLocale = useCallback(
    locale => {
      addLocales(locale)
    },
    [addLocales],
  )

  useEffect(() => {
    const handleClick = e => {
      const target = e.target

      if (
        target.id === localesWrapper ||
        target.parentElement?.id === localesWrapper ||
        target.parentNode?.parentElement?.id === localesWrapper
      ) {
        handleTriggerChaptersList()
      } else {
        handleHideList()
      }
    }

    window.addEventListener('click', handleClick)

    return () => {
      window.removeEventListener('click', handleClick)
    }
  })

  useEffect(() => {
    const handleClick = e => {
      if (e.code.includes('Key')) {
        const byName = availableLocales.filter(
          item => item.title.slice(0, 1) === e.code[3],
        )

        if (byName.length > 0) {
          setFocused(byName[0].title.replace(/[ ,]/g, ''))
        } else {
          setFocused('')
        }
      }
    }

    if (isLocalesShown) {
      window.addEventListener('keypress', handleClick)
    }

    return () => {
      window.removeEventListener('keypress', handleClick)
    }
  }, [availableLocales, isLocalesShown])

  useEffect(() => {
    if (isLocalesShown && focused) {
      const visibleRef = document.querySelector(`.${focused}`)

      if (visibleRef) {
        visibleRef?.scrollIntoView({ block: 'center' })
      }
    }
  }, [isLocalesShown, focused])

  return (
    <Flex
      pos='relative'
      p='19px 44px 19px 18px'
      w='100%'
      h='fit-content'
      bg={colors['primary-violet-10']}
      borderRadius='8px'
      cursor='pointer'
      id={localesWrapper}
    >
      <Text
        size='poppins18'
        variant={'primary-gray-100'}
        lineHeight='32px'
        fontWeight={400}
        maxW='438px'
        overflow='hidden'
        textOverflow='ellipsis'
        whiteSpace='nowrap'
      >
        Select locale
      </Text>

      <Box
        pos='absolute'
        right='10px'
        top='50%'
        transform={
          isLocalesShown ? 'translateY(-50%)' : 'rotate(180deg) translateY(50%)'
        }
      >
        {SelectArrowIcon()}
      </Box>

      {isLocalesShown && (
        <Flex
          bg={colors.white}
          direction='column'
          maxH='416px'
          w='100%'
          pos='absolute'
          borderRadius='8px'
          border={`1px solid ${colors.stroke}`}
          boxShadow='0px 2px 5px 0px rgba(37, 85, 255, 0.05)'
          bottom={0}
          left={0}
          zIndex={10}
          transform='translateY(100%)'
          overflowY='hidden'
        >
          <SimpleBarReact
            autoHide={true}
            style={{
              maxHeight: '260px',
              overflowX: 'hidden',
            }}
          >
            {availableLocales.map(locale => {
              return (
                <Flex
                  className={locale.title.replace(/[ ,]/g, '')}
                  key={locale.code}
                  gap='4px'
                  onClick={() => handleSelectLocale(locale)}
                  p='10px 12px'
                  align='center'
                  _hover={{
                    bg: colors['primary-violet-20'],
                    color: colors['primary-violet-100'],
                  }}
                >
                  <Image
                    w='31px'
                    h='22px'
                    src={`/images/flags/${locale.code.toLowerCase()}.png`}
                    alt={locale.title}
                  />

                  <Text
                    size='poppins18'
                    variant='primary-black-text'
                    fontWeight={400}
                    lineHeight='32px'
                    letterSpacing='-0.018px'
                    maxW='500px'
                    cursor='pointer'
                    overflow='hidden'
                    textOverflow='ellipsis'
                    whiteSpace='nowrap'
                    color='inherit'
                  >
                    {locale.title} - {locale.code}
                  </Text>
                </Flex>
              )
            })}
          </SimpleBarReact>
        </Flex>
      )}
    </Flex>
  )
})

export default LocalesSelector
