const { gql } = require('@apollo/client')

export const GET_GENRES = gql`
  query Genres {
    genres {
      genre_id
      title
    }
  }
`
