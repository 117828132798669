import axiosApiInstance from 'utils/axios-instance'

export const fetchSeries = () => axiosApiInstance.get('series')

export const createSeries = series => axiosApiInstance.post('series', series)

export const deleteSeriesById = series_id =>
  axiosApiInstance.delete(`series/${series_id}`)

export const updateSeries = series => axiosApiInstance.put('series', series)
