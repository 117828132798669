import './styles.scss'

import { Button, Modal } from 'react-bootstrap'
import React, { memo } from 'react'

const AppendTagModal = ({
  updatedList,
  isShow,
  onHide,
  filteredTags,
  onSubmit,
  selectTag,
  getIsSelectedTag,
  getSelectedTagIndex,
}) => {
  return (
    <Modal show={isShow} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          Add tag to &quot;
          {updatedList.title}&quot; section
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='modal-tag-list'>
          {filteredTags.map(tag => (
            <div
              className={`tag ${getIsSelectedTag(tag) ? 'tag--selected' : ''}`}
              key={tag.tag_id}
              onClick={() => selectTag(tag)}
            >
              <div className='tag-info-container'>
                {tag.title}
                <div className='tag-selected-index'>
                  {getSelectedTagIndex(tag) !== -1 &&
                    getSelectedTagIndex(tag) + 1}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={onSubmit}>
          Add tag
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default memo(AppendTagModal)
