import { useLazyQuery, useMutation } from '@apollo/client'
import { Flex, Heading, useToast } from '@chakra-ui/react'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import { API_V2 } from 'constants/apollo'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  APPROVE_COMMENT,
  GET_COMMENTS,
  REJECT_COMMENT,
  TRANSLATE_COMMENT,
  UPDATE_COMMENT,
} from 'services/comments/graphql'
import InfoElement from '../InfoElement/InfoElement'
import CommentReview from '../CommentReview'

const ITEMS = 20

const CommentsModerationLayout = ({ status }) => {
  const [comments, setComments] = useState([])
  const [firstLoad, setFirstLoad] = useState(false)
  const [progress, setProgress] = useState(0)
  const [total, setTotal] = useState(0)
  const [reviewIndex, setReviewIndex] = useState(0)
  const [loading, setLoading] = useState(false)
  const [allLoaded, setAllLoaded] = useState(false)
  const toast = useToast()

  const initialVariables = useMemo(
    () => ({
      params: {
        statuses: [status.toUpperCase()],
      },
      pagination: {
        offset: 0,
        count: ITEMS,
      },
    }),
    [status],
  )

  const comment = useMemo(() => {
    return comments.length > 0 ? comments[reviewIndex] : null
  }, [comments, reviewIndex])

  const [loadComments, { loading: isDataLoading }] = useLazyQuery(
    GET_COMMENTS,
    {
      fetchPolicy: 'no-cache',
      context: {
        clientName: API_V2,
      },
    },
  )
  const [approveComment] = useMutation(APPROVE_COMMENT, {
    context: {
      clientName: API_V2,
    },
  })
  const [rejectComment] = useMutation(REJECT_COMMENT, {
    context: {
      clientName: API_V2,
    },
  })
  const [updateComment] = useMutation(UPDATE_COMMENT, {
    context: {
      clientName: API_V2,
    },
  })
  const [translateComment] = useMutation(TRANSLATE_COMMENT, {
    context: {
      clientName: API_V2,
    },
  })

  function skipComment() {
    setReviewIndex(curr => curr + 1)
  }

  function goBack() {
    setReviewIndex(curr => curr - 1)
  }

  async function handleTranslate(id) {
    try {
      const {
        data: { item },
      } = await translateComment({
        variables: {
          id,
        },
        context: {
          clientName: API_V2,
        },
      })

      if (item.comment.id) {
        toast({ title: 'Translated', status: 'success', isClosable: true })
        setComments(curr =>
          curr.map((c, i) =>
            i === reviewIndex ? { ...c, ...item.comment } : c,
          ),
        )
        return item.comment
      } else {
        throw new Error('error')
      }
    } catch (error) {
      toast({
        title: 'Failed',
        description: 'Failed to translate comment',
        status: 'error',
        isClosable: true,
      })
    }
  }

  async function handleUpdate(id, content) {
    try {
      const {
        data: { item },
      } = await updateComment({
        variables: {
          id,
          content,
        },
        context: {
          clientName: API_V2,
        },
      })

      if (item.comment.id && item.comment.content === content) {
        toast({ title: 'Updated', status: 'success', isClosable: true })
        setComments(curr =>
          curr.map((c, i) =>
            i === reviewIndex
              ? { ...c, content, updatedAt: item.comment.updatedAt }
              : c,
          ),
        )
        return true
      } else {
        throw new Error('error')
      }
    } catch (error) {
      toast({
        title: 'Failed',
        description: 'Failed to update comment',
        status: 'error',
        isClosable: true,
      })
    }

    return false
  }

  async function handleReject(id, info) {
    const rejectVariables = {
      id,
      info: info || {},
    }

    try {
      const {
        data: { result },
      } = await rejectComment({
        variables: {
          ...rejectVariables,
        },
        context: {
          clientName: API_V2,
        },
      })

      if (result.success) {
        toast({ title: 'Rejected', status: 'success', isClosable: true })
        setComments(curr => curr.filter(item => item.id !== id))
        setProgress(curr => curr + 1)

        if (allLoaded && reviewIndex === comments.length - 1) {
          setReviewIndex(curr => curr - 1)
        }
      } else {
        throw new Error('error')
      }
    } catch (error) {
      toast({
        title: 'Failed',
        description: 'Failed to reject comment',
        status: 'error',
        isClosable: true,
      })
    }
  }

  async function handleApprove(id) {
    try {
      const {
        data: { result },
      } = await approveComment({
        variables: {
          id,
        },
        context: {
          clientName: API_V2,
        },
      })

      if (result.success) {
        toast({ title: 'Approved', status: 'success', isClosable: true })
        setComments(curr => curr.filter(item => item.id !== id))
        setProgress(curr => curr + 1)

        if (allLoaded && reviewIndex === comments.length - 1) {
          setReviewIndex(curr => curr - 1)
        }
      } else {
        throw new Error('error')
      }
    } catch (error) {
      toast({
        title: 'Failed',
        description: 'Failed to approve comment',
        status: 'error',
        isClosable: true,
      })
    }
  }

  const loadFirstPage = useCallback(async () => {
    const {
      data: { comments },
    } = await loadComments({
      variables: {
        ...initialVariables,
      },
      fetchPolicy: 'no-cache',
      context: {
        clientName: API_V2,
      },
    })

    setComments(comments.comments)
    setTotal(comments.pagination.total)

    if (!comments.pagination.total || comments.pagination.total <= ITEMS) {
      setAllLoaded(true)
    }

    setFirstLoad(true)
  }, [initialVariables, loadComments])

  const fetchMoreRequests = useCallback(async () => {
    setLoading(true)

    const newOffset = comments.length

    const {
      data: { comments: newComments },
    } = await loadComments({
      variables: {
        ...initialVariables,
        pagination: {
          offset: newOffset,
          count: ITEMS,
        },
      },
      fetchPolicy: 'no-cache',
      context: {
        clientName: API_V2,
      },
    })

    if (newComments.comments) {
      setComments(curr => [...curr, ...newComments.comments])

      if (newComments.comments.length < ITEMS) {
        setAllLoaded(true)
      }
    } else {
      setReviewIndex(curr => (curr > 0 ? curr - 1 : curr))
      setAllLoaded(true)
    }

    setLoading(false)
  }, [initialVariables, loadComments, comments])

  useEffect(() => {
    if (!firstLoad) {
      loadFirstPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoad])

  useEffect(() => {
    if (!comment && !allLoaded && firstLoad) {
      fetchMoreRequests()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment])

  useEffect(() => {
    setProgress(0)
    setReviewIndex(0)
    setAllLoaded(false)

    if (firstLoad) {
      setFirstLoad(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const isLoad = loading || isDataLoading
  const hasReviewTasks = !!total && progress !== total
  const hasNoReviewTasks = !total || progress === total

  return (
    <Flex w='100%' h='100%' direction='column' overflow='hidden'>
      {isLoad && <LoadingSpinner />}

      {!!total && !isLoad && (
        <Flex w='100%' justify='center'>
          <Heading
            size='poppins20'
            variant='primary-black-text'
            lineHeight='38px'
            letterSpacing='0'
            textAlign='center'
          >
            Review progress: {progress} / {total}
          </Heading>
        </Flex>
      )}

      {hasNoReviewTasks && !isLoad && (
        <InfoElement
          title={`No comments for review ${progress ? 'left' : ''}`}
          image='/images/hero/in_review.png'
        />
      )}

      {!isLoad && hasReviewTasks && comment && (
        <CommentReview
          comment={comment}
          handleReject={handleReject}
          handleApprove={() => handleApprove(comment.id)}
          handleUpdate={handleUpdate}
          skipComment={skipComment}
          handleTranslate={handleTranslate}
          goBack={goBack}
          reviewIndex={reviewIndex}
          canSkip={!allLoaded || reviewIndex !== comments.length - 1}
        />
      )}
    </Flex>
  )
}

export default CommentsModerationLayout
