import './styles.scss'

import React, { memo, useCallback, useEffect, useState } from 'react'

import { Accordion, Button, ButtonGroup } from 'react-bootstrap'
import AppendBannerModal from '../Modals/AppendBannerModal'
import AppendBookModal from '../Modals/AppendBookModal'
import AppendGenreModal from '../Modals/AppendGenreModal'
import AppendSurveyModal from '../Modals/AppendSurveyModal'
import AppendTagModal from '../Modals/AppendTagModal'
import BannerSection from '../BannerSection'
import BooksSection from '../BookSectionItem'
import ContinueReadingSection from '../ContinueReadingSection'
import GenresSection from '../GenresSection'
import RecommendedSection from '../RecommendedSection'
import { SectionTypes } from '../../../../../constants/section'
import SurveySection from '../SurveySection'
import TagsSection from '../TagsSection'
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  useDisclosure,
} from '@chakra-ui/react'
import ReactPaginate from 'react-paginate'
import { debounce } from 'lodash'
import { DEBOUNCE_DELAY } from 'constants/app'
import { MdClear } from 'react-icons/md'
import { colors } from 'shared/style/colors'
import { baseScrollBar } from 'constants/scrollbar'
import CreateListModal from 'components/ControlPanel/Modals/create-list-modal'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { listValidationSchema } from 'components/ControlPanel/utils/validationSchemas'
import { createListThunk } from 'redux/slices/lists/thunks'
import { getSectionTypes } from 'redux/slices/types/selectors'

const LISTS_PER_PAGE = 10

const SectionsList = ({
  closeAppendBookModal,
  closeAppendGenreModal,
  deleteList,
  deleteListContentOption,
  filteredBooks,
  filteredGeneres,
  getIsSelectedBook,
  getIsSelectedGenre,
  isOpenAppendBookModal,
  isOpenAppendGenreModal,
  openAppendBookModal,
  openAppendGenreModal,
  selectBook,
  selectGenre,
  updateGenreList,
  updateBooksList,
  updateListItemsOrder,
  updatedList,
  lists,
  updateTagList,
  showAppendSurveyModal,
  hideAppendSurveyModal,
  isShowAppendSurveyModal,
  newSurvey,
  onNewSurveyChange,
  updateSurveySection,
  deleteListSurveyLink,
  filteredBanners,
  isShowAppendBannerModal,
  showAppendBannerModal,
  hideAppendBannerModal,
  getIsSelectedBanner,
  selectBanner,
  updateBannersSection,
  booksByGenreList,
  openGenreBooks,
  getSelectedGenreIndex,
  getSelectedBookIndex,
  getSelectedBannerIndex,
  seriesBookSearch,
  handleSeriesBookSearchChange,
  moveSurveys,
  isShowTagModal,
  openTagModal,
  closeTagModal,
  filteredTags,
  getIsSelectedTag,
  getSelectedTagIndex,
  selectTag,
  isSearchedBooksLoading,
  setShowDeleted,
  showDeleted,
}) => {
  const [visibleLists, setVisibleLists] = useState([])
  const [itemOffset, setItemOffset] = useState(0)
  const [sortedLists, setSortedLists] = useState([])
  const [listType, setListType] = useState()
  const [search, setSearch] = useState('')
  const [query, setQuery] = useState('')

  const [idSearch, setIdSearch] = useState('')
  const [idQuery, setIdQuery] = useState('')

  const dispatch = useDispatch()

  const sectionTypes = useSelector(getSectionTypes)

  const {
    isOpen: isOpenCreateListModal,
    onOpen: onOpenCreateListModal,
    onClose: onCloseCreateListModal,
  } = useDisclosure()

  const createListFormik = useFormik({
    initialValues: {
      list_type: '',
      title: '',
      description: '',
      isAudioList: false,
    },
    onSubmit: async (list, { resetForm }) => {
      await dispatch(createListThunk(list))
      onCloseCreateListModal()
      resetForm()
    },
    validationSchema: listValidationSchema,
  })

  const handleCreateListModalClose = useCallback(() => {
    onCloseCreateListModal()
    createListFormik.resetForm()
  }, [createListFormik, onCloseCreateListModal])

  const handleSearchInput = e => {
    const searchValue = e ? e.target.value : ''

    setSearch(searchValue)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchChange = useCallback(
    debounce(value => setQuery(value), DEBOUNCE_DELAY),
    [],
  )

  useEffect(() => {
    debouncedSearchChange(search)
  }, [search, debouncedSearchChange])

  const handleIdSearchInput = e => {
    const searchValue = e ? e.target.value : ''

    setIdSearch(searchValue)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedIdSearchChange = useCallback(
    debounce(value => setIdQuery(value), DEBOUNCE_DELAY),
    [],
  )

  useEffect(() => {
    debouncedIdSearchChange(idSearch)
  }, [idSearch, debouncedIdSearchChange])

  const handlePageClick = event => {
    const newOffset = (event.selected * LISTS_PER_PAGE) % lists.length

    setItemOffset(newOffset)
  }

  const handleSelectListType = e => {
    setListType(e.target.value)
  }

  const handleListsSort = useCallback(() => {
    const listsCopy = JSON.parse(JSON.stringify(lists))
    const workingList = [...listsCopy].filter(list => {
      if (listType && list.list_type !== listType) {
        return false
      }

      const queryInLowerCase = query.toLowerCase()
      const title = list.title.toLowerCase()
      const description = list.description.toLowerCase()

      return (
        (title.includes(queryInLowerCase) ||
          description.includes(queryInLowerCase)) &&
        (!idQuery || list.list_id.includes(idQuery))
      )
    })

    workingList.sort((listA, listB) => {
      if (listA.booksOrder.length > 0 || listB.booksOrder.length > 0) {
        return listB.booksOrder.length - listA.booksOrder.length
      }

      if (listA.bannersOrder.length > 0 || listB.bannersOrder.length > 0) {
        return listB.bannersOrder.length - listA.bannersOrder.length
      }

      if (listA.genresOrder.length > 0 || listB.genresOrder.length > 0) {
        return listB.genresOrder.length - listA.genresOrder.length
      }

      if (listA.tagsOrder.length > 0 || listB.tagsOrder.length > 0) {
        return listB.tagsOrder.length - listA.tagsOrder.length
      }

      return 0
    })

    setSortedLists(workingList)
  }, [lists, listType, query, idQuery])

  useEffect(() => {
    const endOffset = itemOffset + LISTS_PER_PAGE

    setVisibleLists(sortedLists.slice(itemOffset, endOffset))
  }, [itemOffset, lists, sortedLists, query, listType, idQuery])

  useEffect(() => {
    if (lists.length > 0) {
      handleListsSort()
      handlePageClick({
        selected: 0,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lists, handleListsSort, listType, query, idQuery])

  const pageCount = Math.ceil(sortedLists.length / LISTS_PER_PAGE)

  return (
    <Flex flex={1} direction='column' p='0 20px 30px' overflow='hidden'>
      <Flex justify='space-between' align='center'>
        {lists.length > 1 && (
          <Flex mb='20px' gap='20px'>
            <FormControl maxW='250px'>
              <FormLabel htmlFor='type'>Select List Type</FormLabel>
              <Select
                placeholder='Select type'
                maxW='350px'
                id='type'
                onChange={handleSelectListType}
              >
                {Object.values(sectionTypes).map(type => {
                  return (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  )
                })}
              </Select>
            </FormControl>

            <FormControl maxW='250px'>
              <FormLabel htmlFor='type'>List Title or Description</FormLabel>

              <InputGroup>
                <Input
                  type='text'
                  placeholder='Search...'
                  variant='loginField'
                  value={search}
                  onChange={handleSearchInput}
                  p='0 8px'
                  h='40px'
                  maxH='40px'
                />

                {search && search.length > 0 && (
                  <InputRightElement right='18px' top='7px' w='24px' h='24px'>
                    <Icon
                      as={MdClear}
                      fill={colors['primary-violet-100']}
                      w='24px'
                      h='24px'
                      cursor='pointer'
                      onClick={() => handleSearchInput(null)}
                    />
                  </InputRightElement>
                )}
              </InputGroup>
            </FormControl>

            <FormControl maxW='450px'>
              <FormLabel htmlFor='type'>LIST ID</FormLabel>

              <InputGroup>
                <Input
                  type='text'
                  placeholder='Search...'
                  variant='loginField'
                  value={idSearch}
                  onChange={handleIdSearchInput}
                  p='0 8px'
                  h='40px'
                  maxH='40px'
                />

                {idSearch && idSearch.length > 0 && (
                  <InputRightElement right='18px' top='7px' w='24px' h='24px'>
                    <Icon
                      as={MdClear}
                      fill={colors['primary-violet-100']}
                      w='24px'
                      h='24px'
                      cursor='pointer'
                      onClick={() => handleIdSearchInput(null)}
                    />
                  </InputRightElement>
                )}
              </InputGroup>
            </FormControl>
          </Flex>
        )}

        <ButtonGroup className='me-2' style={{ height: 'fit-content' }}>
          <Button variant='primary' onClick={onOpenCreateListModal}>
            Create list
          </Button>
        </ButtonGroup>
      </Flex>
      <Flex justify='center' mb='20px'>
        {sortedLists && pageCount > 1 && (
          <ReactPaginate
            onPageChange={handlePageClick}
            pageRangeDisplayed={10}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel='< '
            nextLabel=' >'
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link'
            nextClassName='page-item'
            nextLinkClassName='page-link'
            breakLabel='...'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            containerClassName='pagination'
            activeClassName='active'
            renderOnZeroPageCount={null}
          />
        )}
      </Flex>

      <Flex flex={1} overflow='auto' sx={baseScrollBar} w='100%'>
        <Accordion
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          {visibleLists.map(list => (
            <Box key={list.list_id} w='100%'>
              {(list.list_type.includes('BOOKS') ||
                list.list_type === SectionTypes.COMING_SOON ||
                list.list_type === SectionTypes.FREE_FOR_LIMITED ||
                list.list_type === SectionTypes.BOOKS_PREMIUM ||
                list.list_type === SectionTypes.MOST_POPULAR ||
                list.list_type === SectionTypes.NEW_RELEASES_LIST) && (
                <BooksSection
                  list={list}
                  openAppendBookModal={() => openAppendBookModal(list)}
                  deleteList={e => {
                    e.stopPropagation()
                    deleteList(list.list_id)
                  }}
                  deleteListContentOption={deleteListContentOption}
                />
              )}
              {list.list_type.includes(SectionTypes.GENRES) && (
                <GenresSection
                  list={list}
                  deleteList={e => {
                    e.stopPropagation()
                    deleteList(list.list_id)
                  }}
                  deleteListContentOption={deleteListContentOption}
                  openAppendGenreModal={() => openAppendGenreModal(list)}
                  booksByGenreList={booksByGenreList}
                  openGenreBooks={openGenreBooks}
                />
              )}
              {list.list_type === SectionTypes.TAGS && (
                <TagsSection
                  list={list}
                  deleteList={e => {
                    e.stopPropagation()
                    deleteList(list.list_id)
                  }}
                  deleteListContentOption={deleteListContentOption}
                  openAppendTagModal={() => openTagModal(list)}
                />
              )}
              {list.list_type === SectionTypes.SURVEY && (
                <SurveySection
                  list={list}
                  deleteList={e => {
                    e.stopPropagation()
                    deleteList(list.list_id)
                  }}
                  showAppendSurveyModal={() => showAppendSurveyModal(list)}
                  deleteListSurveyLink={deleteListSurveyLink}
                  moveSurveys={moveSurveys}
                  updateListItemsOrder={updateListItemsOrder}
                />
              )}
              {list.list_type.includes('BANNER') && (
                <BannerSection
                  list={list}
                  deleteList={e => {
                    e.stopPropagation()
                    deleteList(list.list_id)
                  }}
                  showAppendBannerModal={() => showAppendBannerModal(list)}
                  deleteListContentOption={deleteListContentOption}
                />
              )}

              {(list.list_type === SectionTypes.CONTINUE_READING ||
                list.list_type === SectionTypes.BOOKS_BECAUSE_YOU_LIKE) && (
                <ContinueReadingSection
                  list={list}
                  deleteList={e => {
                    e.stopPropagation()
                    deleteList(list.list_id)
                  }}
                />
              )}
              {list.list_type === SectionTypes.RECOMMENDED && (
                <RecommendedSection
                  list={list}
                  deleteList={e => {
                    e.stopPropagation()
                    deleteList(list.list_id)
                  }}
                />
              )}
            </Box>
          ))}

          <AppendBookModal
            isOpen={isOpenAppendBookModal}
            hideHandler={closeAppendBookModal}
            updatedList={updatedList}
            filteredBooks={filteredBooks}
            getIsSelectedBook={getIsSelectedBook}
            selectBook={selectBook}
            updateBooksList={updateBooksList}
            getSelectedBookIndex={getSelectedBookIndex}
            seriesBookSearch={seriesBookSearch}
            handleSeriesBookSearchChange={handleSeriesBookSearchChange}
            isSearchedBooksLoading={isSearchedBooksLoading}
            setShowDeleted={setShowDeleted}
            showDeleted={showDeleted}
          />
          <AppendGenreModal
            isOpen={isOpenAppendGenreModal}
            hideHandler={closeAppendGenreModal}
            genres={filteredGeneres}
            updatedList={updatedList}
            updateGenreList={updateGenreList}
            selectGenre={selectGenre}
            getIsSelectedGenre={getIsSelectedGenre}
            getSelectedGenreIndex={getSelectedGenreIndex}
          />
          <AppendSurveyModal
            updatedList={updatedList}
            onHide={hideAppendSurveyModal}
            isShow={isShowAppendSurveyModal}
            value={newSurvey}
            onChange={onNewSurveyChange}
            onSubmit={updateSurveySection}
          />
          <AppendBannerModal
            updatedList={updatedList}
            isShow={isShowAppendBannerModal}
            onHide={hideAppendBannerModal}
            getIsSelectedBanner={getIsSelectedBanner}
            selectBanner={selectBanner}
            filteredBanners={filteredBanners}
            onSubmit={updateBannersSection}
            getSelectedBannerIndex={getSelectedBannerIndex}
          />
          <AppendTagModal
            updatedList={updatedList}
            isShow={isShowTagModal}
            onHide={closeTagModal}
            filteredTags={filteredTags}
            onSubmit={updateTagList}
            selectTag={selectTag}
            getIsSelectedTag={getIsSelectedTag}
            getSelectedTagIndex={getSelectedTagIndex}
          />

          <CreateListModal
            isOpen={isOpenCreateListModal}
            onClose={handleCreateListModalClose}
            createListFormik={createListFormik}
            sectionTypes={sectionTypes}
          />
        </Accordion>
      </Flex>
    </Flex>
  )
}

export default memo(SectionsList)
