import React, { memo } from 'react'
import styles from './styles.module.scss'
import BannerItem from '../BannerItem'

const BannersList = ({ banners }) => {
  return (
    <div className={styles.bannersList}>
      {banners.map(banner => (
        <BannerItem key={banner.banner_id} {...banner} />
      ))}
    </div>
  )
}

export default memo(BannersList)
