import { Box } from '@chakra-ui/react'
import { useState } from 'react'
import { colors } from 'shared/style/colors'
import BackIcon from '../BackIcon'

const BackButton = ({ onClick }) => {
  const [hover, setHover] = useState(false)

  return (
    <Box
      m='auto'
      cursor='pointer'
      w='24px'
      h='24px'
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {BackIcon(
        hover ? colors['primary-violet-100'] : colors['primary-black-text'],
      )}
    </Box>
  )
}

export default BackButton
