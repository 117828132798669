import { createSelector } from 'reselect'
import * as R from 'ramda'

const getState = state => state.books

export const getBooks = createSelector(getState, state => state.list)
export const getGenreBooks = createSelector(getState, state => state.byGenres)

export const getBooksList = createSelector(getBooks, books => {
  return Object.values(books)
})

export const getBookById = createSelector(
  getBooks,
  (_, book_id) => book_id,
  (state, book_id) => state[book_id],
)

export const getBooksByGenreList = createSelector(
  getGenreBooks,
  (_, genre_id) => genre_id,
  (books, genre_id) => {
    const genreBooks = R.pathOr({}, [genre_id], books)
    return Object.values(genreBooks)
  },
)
