import { Button, ButtonGroup, ButtonToolbar, Modal } from 'react-bootstrap'
import { Form, Formik } from 'formik'
import React, { memo } from 'react'

import { BannerTypes } from '../../../../../constants/banners'
import FormBannerAdvertisementSelect from '../../FormBannerAdveritsementSelect'
import FormBannerBookSelect from '../../FormBannerBookSelect'
import FormBannerTypeSelect from '../../FormBannerTypeSelect'
import FormField from '../../../../../components/FormControls/FormField'
import FormFileInput from '../../../../../components/FormControls/FormFileInput'
import FormGroup from '../../../../../components/FormControls/FormGroup'
import { updateBannerValidationScheme } from '../../../models'

const UpdateBannerModal = ({
  isShow,
  onHide,
  onSubmit,
  banner_type,
  advertisement,
  book,
}) => (
  <Modal show={isShow} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>Update banner</Modal.Title>
    </Modal.Header>
    <Formik
      initialValues={{
        cover_url: '',
        file: null,
        banner_type,
        attachment: banner_type === BannerTypes.BOOKS ? book : advertisement,
      }}
      validationSchema={updateBannerValidationScheme}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <Form>
          <Modal.Body style={{ paddingBottom: 35 }}>
            <FormField label='Cover url' name='cover_url' />
            <FormGroup columns={2}>
              <FormFileInput label='Banner cover' name='cover_url' />
              <FormBannerTypeSelect />
            </FormGroup>
            {values.banner_type === BannerTypes.BOOKS && (
              <FormBannerBookSelect />
            )}
            {values.banner_type === BannerTypes.ADVERTISEMENTS && (
              <FormBannerAdvertisementSelect />
            )}
          </Modal.Body>
          <Modal.Footer>
            <ButtonToolbar>
              <ButtonGroup className='me-2'>
                <Button variant='danger' onClick={onHide}>
                  Cancel
                </Button>
              </ButtonGroup>
              <ButtonGroup className='me-2'>
                <Button variant='primary' type='submit'>
                  Save
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  </Modal>
)

export default memo(UpdateBannerModal)
