import { colors } from '../colors'

export const defaultButton = {
  width: '100%',
  borderRadius: '8px',
  height: '48px',
  backgroundColor: colors['primary-violet-100'],
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  color: colors.white,
  fontFamily: `'Poppins', sans-serif`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ':hover': {
    backgroundColor: colors['button-hover'],
  },
  ':focus-visible': {
    outline: 'none',
    boxShadow: 'none',
  },
  ':active': {
    outline: 'none',
    boxShadow: 'none',
  },
  ':focus': {
    outline: 'none',
    boxShadow: 'none',
  },
  ':disabled': {
    opacity: 1,
    backgroundColor: colors['primary-gray-60'],
    ':hover': {
      backgroundColor: colors['primary-gray-60'],
    },
  },
}

export const outlineButton = {
  width: '100%',
  padding: '12px 59px',
  borderRadius: '8px',
  border: `1px solid ${colors['primary-violet-100']}`,
  height: '46px',
  backgroundColor: colors.white,
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  letterSpacing: '-0.016px',
  color: colors['primary-violet-100'],
  fontFamily: `'Poppins', sans-serif`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  outline: 'unset',
  ':hover': {
    borderColor: colors['button-hover'],
    color: colors['button-hover'],
  },
  ':focus-visible': {
    outline: 'none',
    boxShadow: 'none',
  },
  ':disabled': {
    opacity: 1,
    backgroundColor: colors['primary-gray-60'],
    color: colors.white,
    borderColor: colors['primary-gray-60'],
    ':hover': {
      backgroundColor: colors['primary-gray-60'],
    },
  },
}
