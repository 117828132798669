import React from 'react'
import { Button, ButtonGroup, ButtonToolbar, Modal } from 'react-bootstrap'
import { Form, Formik } from 'formik'
import { createUserValidationSchema } from '../../models'

import styles from './styles.module.scss'
import FormField from '../../../../components/FormControls/FormField'
import { Flex } from '@chakra-ui/react'
import LoadingSpinner from 'components/UI/LoadingSpinner'

const NewWriterModal = ({ isShow, onHide, onSubmit, isUserCreating }) => {
  return (
    <Flex>
      {isUserCreating && <LoadingSpinner />}
      <Modal.Title>
        <b>Create Author</b>
      </Modal.Title>
      <Formik
        validateOnChange={false}
        validationSchema={createUserValidationSchema}
        initialValues={{
          first_name: '',
          last_name: '',
        }}
        onSubmit={onSubmit}
      >
        <Form>
          <Modal.Body>
            <div className={styles.formGroup}>
              <FormField name='first_name' label='First name' />
              <FormField name='last_name' label='Last name' />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ButtonToolbar>
              <ButtonGroup className='me-2'>
                <Button
                  variant='danger'
                  onClick={onHide}
                  disabled={isUserCreating}
                >
                  Cancel
                </Button>
              </ButtonGroup>
              <ButtonGroup className='me-2'>
                <Button
                  variant='primary'
                  type='submit'
                  disabled={isUserCreating}
                >
                  Save
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Modal.Footer>
        </Form>
      </Formik>
    </Flex>
  )
}

export default NewWriterModal
