import { Checkbox, Flex, FormControl, FormLabel } from '@chakra-ui/react'
import React from 'react'

const IncludeChaptersSelect = ({ value, handleToggle, id }) => {
  return (
    <Flex>
      <FormControl w='fit-content'>
        <FormLabel
          htmlFor={`force-${id}`}
          whiteSpace='nowrap'
          cursor='pointer'
          mr='0'
        >
          Include
          <br />
          chapters
        </FormLabel>

        <Checkbox
          size='lg'
          colorScheme='purple'
          isChecked={value}
          id={`force-${id}`}
          display='flex'
          onChange={handleToggle}
        />
      </FormControl>
    </Flex>
  )
}

export default IncludeChaptersSelect
