export const SECTIONS_TABS = {
  lists: {
    id: '1',
    title: 'Lists manager',
    description: 'API 1.0 Lists manager',
    query: 'lists',
    icon: '/images/screens/section_old.png',
  },
  sections: {
    id: '2',
    title: 'Sections manager',
    description: 'API 2.0 Sections manager',
    query: 'sections',
    icon: '/images/screens/section_new.png',
  },
}

export const ScreenContentTypes = Object.freeze({
  BANNERS: 'BANNERS',
  BOOKS: 'BOOKS',
  GENRES: 'GENRES',
  TAGS: 'TAGS',
})

export const screenContentSection = ['BANNERS', 'BOOKS', 'GENRES', 'TAGS']

// BOOKS_SMALL    BOOKS_MEDIUM    BOOKS_LARGE    BOOKS_CARDS    BOOKS_5x3    BOOKS_3x4    BOOKS_3xN    BANNERS_TOP    GENRES    TAGS
export const SectionTypes = Object.freeze({
  BOOKS_SMALL: 'BOOKS_SMALL',
  BOOKS_MEDIUM: 'BOOKS_MEDIUM',
  BOOKS_LARGE: 'BOOKS_LARGE',
  BOOKS_CARDS: 'BOOKS_CARDS',
  BOOKS_5x3: 'BOOKS_5x3',
  BOOKS_3x4: 'BOOKS_3x4',
  BOOKS_3xN: 'BOOKS_3xN',
  BANNERS_TOP: 'BANNERS_TOP',
  // ARCHIVE: 'ARCHIVE',
  // BANNERS: 'BANNERS',
  // BOOKS: 'BOOKS',
  // CONTINUE_READING: 'CONTINUE_READING',
  GENRES: 'GENRES',
  // GRIDS: 'GRIDS',
  // PACKAGES: 'PACKAGES',
  // RECENT_BOOKS: 'RECENT_BOOKS',
  // RECOMMENDED: 'RECOMMENDED',
  // REWARDS: 'REWARDS',
  TAGS: 'TAGS',
  // WANT_TO_READ: 'WANT_TO_READ',
})

export const booksSections = [
  'BOOKS_SMALL',
  'BOOKS_MEDIUM',
  'BOOKS_LARGE',
  'BOOKS_CARDS',
  'BOOKS_5x3',
  'BOOKS_3x4',
  'BOOKS_3xN',
  // 'ARCHIVE',
  // 'BOOKS',
  // 'CONTINUE_READING',
  // 'RECENT_BOOKS',
  // 'RECOMMENDED',
  // 'WANT_TO_READ',
  // 'GRIDS',
]

export const genresSections = ['GENRES']

export const tagsSections = ['TAGS']

export const bannerSections = ['BANNERS_TOP']
