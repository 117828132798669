import * as Yup from 'yup'

export const createBannerValidationScheme = Yup.object({
  cover_url: Yup.string(),
  file: Yup.mixed().nullable(true),
  banner_type: Yup.string().required('Required'),
  attachment: Yup.object().required('Required').nullable(true),
})

export const updateBannerValidationScheme = Yup.object({
  cover_url: Yup.string(),
  file: Yup.mixed().nullable(true),
  banner_type: Yup.string().required('Required'),
  attachment: Yup.object().required('Required').nullable(true),
})
