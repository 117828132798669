import { Flex, Heading, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { colors } from 'shared/style/colors'

const BooksSummary = ({
  selectedStories,
  handleRemoveStory,
  handleOnDragBook,
}) => {
  return (
    <Flex gap='20px'>
      <Heading
        size='poppins20'
        variant='primary-black-text'
        lineHeight='38px'
        letterSpacing='0'
        textAlign='left'
        w='120px'
        minW='120px'
      >
        Books
      </Heading>

      <Flex>
        <DragDropContext onDragEnd={handleOnDragBook}>
          <Droppable droppableId='books' style={{ transform: 'none' }}>
            {provided => (
              <Flex
                gap='10px'
                direction='column'
                wrap='wrap'
                {...provided.droppableProps}
                ref={provided.innerRef}
                userSelect='none'
              >
                {selectedStories.map((item, index) => (
                  <Draggable
                    key={item.book_id}
                    draggableId={item.book_id}
                    index={index}
                  >
                    {provided => {
                      const author =
                        item.penName ||
                        `${item.creator_id.first_name} ${item.creator_id.last_name}`

                      return (
                        <Flex
                          h='80px'
                          gap='8px'
                          border={`1px solid ${colors['primary-gray-30']}`}
                          p='8px 12px'
                          boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
                          borderRadius='8px'
                          cursor='pointer'
                          _hover={{
                            bg: 'rgba(36, 28, 88, 0.03)',
                          }}
                          onClick={() => handleRemoveStory(item)}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <Image
                            src={item.cover_link || '/images/book.png'}
                            alt='book-cover'
                            h='60px'
                            aspectratio='2/3'
                            objectFit='cover'
                          />
                          <Flex
                            w='100%'
                            direction='column'
                            justify='space-between'
                          >
                            <Text
                              size='poppins14'
                              variant='primary-black-text'
                              fontWeight={600}
                            >
                              {item.title}
                            </Text>

                            <Text
                              size='poppins14'
                              variant='primary-black-text'
                              alignSelf='end'
                            >
                              {author}
                            </Text>
                          </Flex>
                        </Flex>
                      )
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Flex>
            )}
          </Droppable>
        </DragDropContext>
      </Flex>
    </Flex>
  )
}

export default BooksSummary
