import React from 'react'
import useLockBodyScroll from '../../../hooks/useLockBodyScroll'

import './styles.scss'

const LoadingSpinner = () => {
  useLockBodyScroll()

  return (
    <div className='loading-spinner-container'>
      <div className='loading-spinner' />
    </div>
  )
}

export default LoadingSpinner
