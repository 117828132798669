import { createSelector } from 'reselect'

const getState = state => state.series

export const getSeriesList = createSelector(getState, state =>
  Object.values(state),
)

export const getSeriesById = createSelector(
  getState,
  (_, series_id) => series_id,
  (state, series_id) => state[series_id],
)
