/* eslint-disable no-magic-numbers */
import { Flex, Heading, Image, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { colors } from 'shared/style/colors'

export const TabButton = ({
  button,
  isActive,
  hasActive,
  isContainerHover,
  setHover,
  isStatus,
  active,
}) => {
  const navigate = useNavigate()

  const [translateProp, visibility, zIndex] = useMemo(() => {
    if (!isContainerHover && hasActive) {
      if (isActive) {
        return ['translateX(0)', 'flex', 3]
      }

      if (!isActive) {
        return ['translateX(0)', 'none', 1]
      }
    }

    return ['translateX(0)', 'flex', 2]
  }, [isContainerHover, isActive, hasActive])

  const handleTabNavigate = () => {
    if (!isStatus) {
      if (isActive) {
        navigate(window.location.pathname)
        setHover(false)

        return
      }

      setHover(false)
      navigate(
        `${window.location.pathname}?active=${button.title.toLowerCase()}`,
      )

      return
    }

    if (isActive) {
      navigate(`${window.location.pathname}?active=${active}`)
      setHover(false)

      return
    }

    setHover(false)
    navigate(
      `${
        window.location.pathname
      }?active=${active}&status=${button.title.toLowerCase()}`,
    )
  }

  return (
    <Flex
      p='6px 12px'
      gap='24px'
      align='center'
      border={`1px solid`}
      borderColor={
        isActive ? colors['primary-violet-100'] : colors['primary-gray-30']
      }
      bg={isActive ? 'rgba(241, 233, 252, 1)' : colors.white}
      borderRadius='6px'
      cursor='pointer'
      transition='all 0.4s ease'
      _hover={
        isActive
          ? {}
          : {
              bg: 'rgba(241, 233, 252, 1)',
              borderColor: colors['primary-violet-100'],
              zIndex: 20,
            }
      }
      mr='6px'
      boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
      transform={translateProp}
      zIndex={zIndex}
      display={visibility}
      onClick={handleTabNavigate}
    >
      <Image w='44px' h='44px' src={button.icon} />

      <Flex direction='column' gap='6px' py='6px'>
        <Heading size='poppins28' variant='primary-black-text'>
          {button.title}
        </Heading>

        {!isStatus && (
          <Text
            size='poppins16normal'
            variant='primary-black-text'
            lineHeight='28px'
            letterSpacing='-0.016px'
            whiteSpace='nowrap'
          >
            {button.description}
          </Text>
        )}
      </Flex>
    </Flex>
  )
}
