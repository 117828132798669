import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react'
import React from 'react'

const MIN_LIMIT = 20
const MED_LIMIT = 50
const ALL = 10000
const LIMIT_OPTIONS = [MIN_LIMIT, MED_LIMIT, ALL]

const BooksLimitSlider = ({ slideLimitValue, booksLimit }) => {
  const handleChangeLimit = val => {
    slideLimitValue(+val)
  }
  return (
    <FormControl w='fit-content'>
      <FormLabel htmlFor='deleted' whiteSpace='nowrap'>
        Set Limit
      </FormLabel>

      <RadioGroup onChange={handleChangeLimit} value={booksLimit}>
        <Stack direction='row'>
          {LIMIT_OPTIONS.map(val => {
            return (
              <Radio value={val} key={val}>
                {val !== ALL ? val : 'All books'}
              </Radio>
            )
          })}
        </Stack>
      </RadioGroup>
    </FormControl>
  )
}

export default BooksLimitSlider
