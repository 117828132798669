import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react'
import ArrowIcon from 'components/UI/Icons/ArrowIcon'
import React, { memo, useEffect, useState } from 'react'
import { colors } from 'shared/style/colors'
import { useQuery } from '@apollo/client'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import { GET_GENRES } from 'services/genres/graphql'

const GenresSelector = memo(({ selectedGenres, handleAddGenre }) => {
  const [hover, setHover] = useState(false)
  const [visibleGenres, setVisibleGenres] = useState([])

  const { data: allGenres, loading: isGenresLoading } = useQuery(GET_GENRES, {
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (allGenres && !isGenresLoading) {
      const ignoreList = [
        ...selectedGenres.map(item => ({ genre_id: item.genre_id })),
      ]
      setVisibleGenres(
        (allGenres.genres ?? []).filter(
          item =>
            !ignoreList.some(selected => selected.genre_id === item.genre_id),
        ),
      )
    }
  }, [allGenres, isGenresLoading, selectedGenres])

  return (
    <AccordionItem border='none'>
      {({ isExpanded }) => (
        <Flex
          bg='white'
          w='100%'
          borderRadius='8px'
          direction='column'
          border={
            hover || isExpanded
              ? `1px solid ${colors['primary-violet-100']}`
              : `1px solid ${colors['primary-gray-30']}`
          }
        >
          <AccordionButton
            _hover={{
              bg: 'white',
            }}
            p='8px 44px'
            m='0'
            borderRadius='8px'
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            outline='none'
            _focus={{
              boxShadow: 'none',
            }}
          >
            <Flex direction='column' flex={1} gap='12px'>
              <Heading
                size='poppins24'
                variant='primary-black-text'
                color={
                  hover || isExpanded
                    ? colors['primary-blue-100']
                    : colors['primary-black-text']
                }
                lineHeight='38px'
                letterSpacing='0'
                textAlign='left'
              >
                Genres
              </Heading>
            </Flex>

            {isExpanded ? (
              <Box p='10px' bg={colors['primary-violet-10']} borderRadius='50%'>
                <Box transform='rotate(180deg)'>
                  <ArrowIcon
                    fill={
                      hover
                        ? colors['button-hover']
                        : colors['primary-violet-100']
                    }
                  />
                </Box>
              </Box>
            ) : (
              <Box p='10px' bg={colors['primary-violet-10']} borderRadius='50%'>
                <Box>
                  <ArrowIcon
                    fill={
                      hover
                        ? colors['button-hover']
                        : colors['primary-violet-100']
                    }
                  />
                </Box>
              </Box>
            )}
          </AccordionButton>
          <AccordionPanel>
            {isGenresLoading && <LoadingSpinner />}

            <Flex gap='10px' wrap='wrap'>
              {visibleGenres.map(item => (
                <Flex
                  key={item.genre_id}
                  gap='8px'
                  border={`1px solid ${colors['primary-gray-30']}`}
                  p='8px 12px'
                  boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
                  borderRadius='8px'
                  cursor='pointer'
                  _hover={{
                    bg: 'rgba(36, 28, 88, 0.03)',
                  }}
                  onClick={() => handleAddGenre(item)}
                >
                  <Flex w='100%' gap='10px' direction='column'>
                    <Text
                      size='poppins14'
                      variant='primary-black-text'
                      fontWeight={600}
                    >
                      {item.title}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </AccordionPanel>
        </Flex>
      )}
    </AccordionItem>
  )
})

export default GenresSelector
