const { gql } = require('@apollo/client')

export const GET_SECTIONS = gql`
  query Sections($section: SectionParams!, $pagination: Pagination) {
    items: sections(section: $section, pagination: $pagination) {
      sections {
        id
        title
        description
        descriptionTranslates {
          locale
          value
        }
        type
        titleTranslates {
          locale
          value
        }
        contentType
        slug
        sectionBanners {
          bannerId
          banner {
            id
            coverUrl
            type
            title
          }
          sectionId
          position
        }
        sectionBooks {
          bookId
          book {
            id
            title
            coverLink
            author {
              penName
            }
          }
          sectionId
          position
        }
        sectionGenres {
          genreId
          genre {
            id
            title
          }
          sectionId
          position
        }
        sectionTags {
          tagId
          tag {
            id
            title
          }
          position
        }
      }
      pagination {
        ... on PaginationResponse {
          count
          offset
          total
        }
      }
    }
  }
`

export const CREATE_SECTION = gql`
  mutation CreateSection($section: CreateSection!) {
    section: createSection(section: $section) {
      id
    }
  }
`

export const REMOVE_SECTION = gql`
  mutation RemoveSection($section: SectionIdentity!) {
    result: removeSection(section: $section) {
      success
    }
  }
`

export const UPDATE_SECTION = gql`
  mutation UpdateSection($section: SectionIdentity!, $params: UpdateSection!) {
    section: updateSection(section: $section, params: $params) {
      id
    }
  }
`
