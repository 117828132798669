import React, { memo } from 'react'
import { Button } from 'react-bootstrap'
import './styles.scss'
import { BannerTypes } from '../../../../../constants/banners'
import { Flex, Image } from '@chakra-ui/react'

const BannerItem = ({
  banner,
  deleteListContentOption,
  list,
  draggableProps,
  dragHandleProps,
  elementRef,
}) => {
  return (
    <div
      className='banner'
      ref={elementRef}
      {...draggableProps}
      {...dragHandleProps}
    >
      <Flex gap='10px'>
        <Image
          src={banner.cover_url || '/images/book.png'}
          alt='banner-cover'
          w='100px'
        />
        <div className='banner-info'>
          <p>banner_type: {banner.banner_type}</p>
          <p>
            cover_url: <a href={banner.cover_url}>{banner.cover_url}</a>
          </p>
          {banner.banner_type === BannerTypes.BOOKS && (
            <p>Book: {banner.book?.title}</p>
          )}
          {banner.banner_type === BannerTypes.ADVERTISEMENTS && (
            <p>Advertisement: {banner.advertisement?.title}</p>
          )}
        </div>
      </Flex>
      <Button
        variant='danger'
        onClick={() => deleteListContentOption(list, banner.banner_id)}
        style={{ height: 40, margin: 0 }}
      >
        Delete
      </Button>
    </div>
  )
}

export default memo(BannerItem)
