import { Flex } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { COMMENTS_STATUSES_TABS, COMMENTS_TABS } from 'constants/comments'
import useSearchQuery from 'hooks/useSearchQuery'
import InfoElement from '../InfoElement/InfoElement'
import { TabNavigation } from '../TabNavigation/TabNavigation'
import CommentsContent from '../CommentsContent'

const CommentsLayout = () => {
  const { active, status } = useSearchQuery()
  const navigate = useNavigate()
  const isComments = active === COMMENTS_TABS.comments.title.toLowerCase()

  useEffect(() => {
    if (!Object.keys(COMMENTS_TABS).includes(active)) {
      navigate('/comments')
    }
  }, [active, navigate])

  return (
    <Flex
      w='100%'
      h='100%'
      bg='linear-gradient(135deg, #F3F1FF 0%, #F0FBFF 100%)'
      position='relative'
      direction='column'
      overflow='hidden'
    >
      <Flex
        mt='8px'
        direction='column'
        pr='34px'
        gap='12px'
        h='100%'
        w='100%'
        overflow='hidden'
      >
        <Flex w='100%'>
          <TabNavigation
            buttons={Object.entries(COMMENTS_TABS)}
            active={active || ''}
            status={status || ''}
          />

          {isComments && (
            <TabNavigation
              buttons={Object.entries(COMMENTS_STATUSES_TABS)}
              status={status || ''}
              active={active || ''}
              isStatus
            />
          )}
        </Flex>

        {!active && (
          <InfoElement
            title='Please, select the tab you need'
            image='/images/hero/denied.png'
          />
        )}

        {isComments && !status && (
          <InfoElement
            title='Please, select the status you need'
            image='/images/hero/in_review.png'
          />
        )}

        {((active && !isComments) || (active && status)) && (
          <CommentsContent isComments={isComments} status={status || ''} />
        )}
      </Flex>
    </Flex>
  )
}

export default CommentsLayout
