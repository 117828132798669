import { createSelector } from 'reselect'

const getState = state => state.types

export const getTags = createSelector(getState, state => state.tags)
export const getGenres = createSelector(getState, state => state.genres)
export const getSectionTypes = createSelector(
  getState,
  state => state.sectionTypes,
)
export const getBookPremiumTypes = createSelector(
  getState,
  state => state.bookPremiumTypes,
)
