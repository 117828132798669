import { combineReducers } from 'redux'
import { usersReducer, STATE_KEY as USERS_STATE_KEY } from './slices/users'
import { booksReducer, STATE_KEY as BOOKS_STATE_KEY } from './slices/books'
import { typesReducer, STATE_KEY as TYPES_STATE_KEY } from './slices/types'
import { listsReducer, STATE_KEY as LISTS_STATE_KEY } from './slices/lists'
import {
  localizationsReducer,
  STATE_KEY as LOCALIZATIONS_STATE_KEY,
} from './slices/localizations'
import {
  bannersReducer,
  STATE_KEY as BANNERS_STATE_KEY,
} from './slices/banners'
import {
  advertisementsReducer,
  STATE_KEY as ADVERTISEMENTS_STATE_KEY,
} from './slices/advertisements'
import { seriesReducer, STATE_KEY as SERIES_STATE_KEY } from './slices/series'

const rootReducer = combineReducers({
  [BOOKS_STATE_KEY]: booksReducer,
  [LOCALIZATIONS_STATE_KEY]: localizationsReducer,
  [LISTS_STATE_KEY]: listsReducer,
  [BANNERS_STATE_KEY]: bannersReducer,
  [SERIES_STATE_KEY]: seriesReducer,
  [ADVERTISEMENTS_STATE_KEY]: advertisementsReducer,
  [TYPES_STATE_KEY]: typesReducer,
  [USERS_STATE_KEY]: usersReducer,
})

export default rootReducer
