import './styles.scss'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  createLocalizationThunk,
  deleteLocalizationByIdThunk,
  updateLocalizationThunk,
} from '../../../../../redux/slices/localizations/thunks'
import { useDispatch, useSelector } from 'react-redux'
import { Accordion, Button, ButtonGroup } from 'react-bootstrap'
import AppendSectionModal from '../../components/Modals/AppendSection'
import LocalizationItem from '../../components/LocalizationItem'
import SearchBar from '../../../../../components/UI/SearchBar'
import { getLists } from 'redux/slices/lists/selectors'
import ReactPaginate from 'react-paginate'
import { Flex } from '@chakra-ui/react'
import { useMutation, useQuery } from '@apollo/client'
import {
  CREATE_LOCALIZATION,
  GET_LOCALIZATIONS,
  UPDATE_LOCALIZATION,
} from 'services/localization/graphql'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import CreateLocalizationModal from 'components/ControlPanel/Modals/CreateLocalizationModal'
import { apolloClient } from 'app/hocs/with-apollo'
import { getLocalizationTitles } from 'redux/slices/localizations/selectors'

const LOCALIZATIONS_PER_PAGE = 5

const Localizations = () => {
  const dispatch = useDispatch()

  // const localizations = useSelector(getListsLocalizationsList)
  const lists = useSelector(getLists)
  const localizationTitles = useSelector(getLocalizationTitles)

  const [updatedLocalization, setUpdatedLocalization] = useState({})
  useState(false)
  const [isOpenUpdateLocalizationModal, setIsOpenUpdateLocalizationModal] =
    useState(false)
  const [selectedToAddLists, setSelectedToAddLists] = useState([])
  const [searchLocalization, setSearchLocalization] = useState('')
  const [searchLists, setSearchLists] = useState('')
  const [visibleLocalizations, setVisibleLocalizations] = useState([])
  const [itemOffset, setItemOffset] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isShowLocalizationModal, setIsShowLocalizationModal] = useState(false)
  const [localizationName, setLocalizationName] = useState('')
  const [isLocalizationsLoading, setIsLocalizationsLoading] = useState(false)

  const {
    data: allLoks,
    loading: isLocalisationsLoading,
    refetch: refetchLocalizations,
  } = useQuery(GET_LOCALIZATIONS, {
    variables: {
      page: 0,
      limit: 1000,
    },
    fetchPolicy: 'cache-and-network',
  })

  const [createLocalization, { loading: isCreationLoading }] = useMutation(
    CREATE_LOCALIZATION,
    {
      refetch: [
        {
          query: GET_LOCALIZATIONS,
          variables: {
            page: 0,
            limit: 1000,
          },
        },
      ],
    },
  )

  const [updateLocalization, { loading: isUpdateLoading }] = useMutation(
    UPDATE_LOCALIZATION,
    {
      refetch: [
        {
          query: GET_LOCALIZATIONS,
          variables: {
            page: 0,
            limit: 1000,
          },
        },
      ],
    },
  )

  const isLoadingOn =
    isLocalisationsLoading || isCreationLoading || isUpdateLoading

  const showLocalizationModal = useCallback(() => {
    setIsShowLocalizationModal(true)
  }, [])

  const hideLocalizationModal = useCallback(() => {
    setIsShowLocalizationModal(false)
    setLocalizationName('')
  }, [])

  const createLocalizationHandler = useCallback(async () => {
    setIsLocalizationsLoading(true)
    const localizationExists = localizationTitles.includes(localizationName)
    if (!localizationExists) {
      await dispatch(createLocalizationThunk({ title: localizationName }))
      await apolloClient.query({
        query: GET_LOCALIZATIONS,
        variables: {
          page: 0,
          limit: 1000,
        },
        fetchPolicy: 'network-only',
      })
      hideLocalizationModal()
    } else {
      alert('Localization is already exists')
    }
    setIsLocalizationsLoading(false)
  }, [localizationTitles, localizationName, dispatch, hideLocalizationModal])

  const onLocalizationNameChange = useCallback(
    e => setLocalizationName(e.target.value),
    [],
  )

  const filteredLocalizations = useMemo(
    () =>
      allLoks
        ? allLoks.localizations.filter(localization =>
            localization.title
              .toLowerCase()
              .includes(searchLocalization.toLowerCase()),
          )
        : [],
    [allLoks, searchLocalization],
  )

  const displayedLists = useMemo(
    () =>
      lists
        .filter(
          list =>
            !updatedLocalization.lists?.some(
              updatedList => updatedList.list?.list_id === list.list_id,
            ),
        )
        .filter(list =>
          list.title.toLowerCase().includes(searchLists.toLowerCase()),
        ),
    [lists, updatedLocalization.lists, searchLists],
  )

  const handleSearchLocalizationChange = useCallback(e => {
    setSearchLocalization(e.target.value)
    setItemOffset(0)
  }, [])

  const handleSearchListsChange = useCallback(e => {
    setSearchLists(e.target.value)
  }, [])

  const openUpdateLocalizationModal = useCallback(
    localization => {
      setIsOpenUpdateLocalizationModal(true)
      setUpdatedLocalization(localization)
    },
    [setIsOpenUpdateLocalizationModal, setUpdatedLocalization],
  )

  const closeUpdateLocalizationModal = useCallback(() => {
    setIsOpenUpdateLocalizationModal(false)
    setSearchLists('')
    setSelectedToAddLists([])
  }, [setIsOpenUpdateLocalizationModal, setSelectedToAddLists])

  const deleteLocalization = useCallback(
    async localization_id => {
      const isConfirm = window.confirm('Are you sure?')
      if (isConfirm) {
        await dispatch(deleteLocalizationByIdThunk(localization_id))
        refetchLocalizations({
          page: 0,
          limit: 1000,
        })
      }
    },
    [dispatch, refetchLocalizations],
  )

  const getIsSelectedList = useCallback(
    list => selectedToAddLists.some(sect => list.list_id === sect.list_id),
    [selectedToAddLists],
  )

  const getSelectedListIndex = useCallback(
    list => {
      const listIndex = selectedToAddLists.findIndex(
        data => list.list_id === data.list_id,
      )
      if (listIndex > -1) {
        return listIndex + 1
      }
    },
    [selectedToAddLists],
  )

  const selectList = useCallback(
    list => {
      if (getIsSelectedList(list)) {
        setSelectedToAddLists(
          selectedToAddLists.filter(l => l.list_id !== list.list_id),
        )
        return
      }
      setSelectedToAddLists([...selectedToAddLists, list])
    },
    [setSelectedToAddLists, selectedToAddLists, getIsSelectedList],
  )

  const appendListToLocalization = useCallback(async () => {
    const localization = {
      localization_id: updatedLocalization.localization_id,
      lists: [
        ...updatedLocalization.lists,
        ...selectedToAddLists.map(({ list_id }, index) => ({
          list: { list_id },
          order_number: updatedLocalization.lists.length + index,
        })),
      ],
    }
    await dispatch(updateLocalizationThunk(localization))
    refetchLocalizations({
      page: 0,
      limit: 1000,
    })
    closeUpdateLocalizationModal()
  }, [
    updatedLocalization,
    selectedToAddLists,
    dispatch,
    closeUpdateLocalizationModal,
    refetchLocalizations,
  ])

  const deleteListFromLocalization = (localization, list_order) => {
    const isConfirm = window.confirm('Are you sure?')
    if (isConfirm) {
      const updatedLocalization = {
        localization_id: localization.localization_id,
        title: localization.title,
        lists: localization.lists
          .filter(list => list.order_number !== list_order)
          .map(({ list }, order_number) => ({
            list,
            order_number,
          })),
      }
      dispatch(updateLocalizationThunk(updatedLocalization))
      refetchLocalizations({
        page: 0,
        limit: 1000,
      })
    }
  }

  const handleOnDragListLocalization = useCallback(
    async (result, localization) => {
      const { source, destination } = result

      if (!destination || source.index === destination.index) return

      setIsLoading(true)

      const items = [...localization.lists].sort(
        (listA, listB) => listA.order_number - listB.order_number,
      )

      const [reorderedItem] = items.splice(source.index, 1)
      items.splice(destination.index, 0, reorderedItem)

      const updatedLocalization = {
        localization_id: localization.localization_id,
        title: localization.title,
        lists: items.map(({ list }, order_number) => ({
          list,
          order_number,
        })),
      }

      await dispatch(updateLocalizationThunk(updatedLocalization))
      refetchLocalizations({
        page: 0,
        limit: 1000,
      })
      setIsLoading(false)
    },
    [dispatch, refetchLocalizations],
  )

  const handleLocalizationListsOrder = useCallback(
    async localization => {
      await dispatch(
        updateLocalizationThunk({ ...localization, sections: undefined }),
      )
    },
    [dispatch],
  )

  const handlePageClick = event => {
    const newOffset =
      (event.selected * LOCALIZATIONS_PER_PAGE) % filteredLocalizations.length

    setItemOffset(newOffset)
  }

  useEffect(() => {
    const endOffset = itemOffset + LOCALIZATIONS_PER_PAGE

    setVisibleLocalizations(filteredLocalizations.slice(itemOffset, endOffset))
  }, [itemOffset, filteredLocalizations, searchLists])

  const pageCount = Math.ceil(
    filteredLocalizations.length / LOCALIZATIONS_PER_PAGE,
  )

  return (
    <>
      <div className='localizations'>
        <Flex p='10px 20px' direction='column' gap='20px'>
          <Flex justify='space-between'>
            <SearchBar
              search={searchLocalization}
              onChange={handleSearchLocalizationChange}
            />

            <ButtonGroup className='me-2'>
              <Button variant='primary' onClick={showLocalizationModal}>
                Create mainscreen
              </Button>
            </ButtonGroup>
          </Flex>

          {isLoadingOn && <LoadingSpinner />}

          <Flex m='0 auto' justify='flex-start' mb='20px' overflow='hidden'>
            {filteredLocalizations && pageCount > 1 && (
              <ReactPaginate
                onPageChange={handlePageClick}
                pageRangeDisplayed={10}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel='< '
                nextLabel=' >'
                pageClassName='page-item'
                pageLinkClassName='page-link'
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextClassName='page-item'
                nextLinkClassName='page-link'
                breakLabel='...'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                containerClassName='pagination'
                activeClassName='active'
                renderOnZeroPageCount={null}
              />
            )}
          </Flex>
        </Flex>

        {!isLoadingOn && filteredLocalizations.length === 0 && (
          <Flex p='20px'>No localizations by your search</Flex>
        )}
        <div className='localizations-list'>
          <Accordion>
            {visibleLocalizations.map((localization, index) => {
              return (
                <LocalizationItem
                  key={localization.localization_id}
                  index={index}
                  handleOnDragListLocalization={handleOnDragListLocalization}
                  localization={localization}
                  handleLocalizationListsOrder={handleLocalizationListsOrder}
                  deleteLocalization={deleteLocalization}
                  deleteListFromLocalization={deleteListFromLocalization}
                  openUpdateLocalizationModal={openUpdateLocalizationModal}
                  isLoading={isLoading}
                  refetchLocalizations={refetchLocalizations}
                  createLocalization={createLocalization}
                  updateLocalization={updateLocalization}
                  isLoadingOn={isLoadingOn}
                />
              )
            })}
          </Accordion>
        </div>
      </div>

      <AppendSectionModal
        isShow={isOpenUpdateLocalizationModal}
        hideHandler={closeUpdateLocalizationModal}
        updatedLocalization={updatedLocalization}
        appendListToLocalization={appendListToLocalization}
        lists={displayedLists}
        selectList={selectList}
        getIsSelectedList={getIsSelectedList}
        getSelectedListIndex={getSelectedListIndex}
        searchLists={searchLists}
        handleSearchListsChange={handleSearchListsChange}
      />

      <CreateLocalizationModal
        isShow={isShowLocalizationModal}
        onHide={hideLocalizationModal}
        onSumbit={createLocalizationHandler}
        value={localizationName}
        onChange={onLocalizationNameChange}
        isLocalizationsLoading={isLocalizationsLoading}
      />
    </>
  )
}

export default Localizations
