import React, { useMemo } from 'react'
import { ButtonGroup, ButtonToolbar, Modal, Button } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import FormField from '../../../../../../components/FormControls/FormField'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { getLocalizationTitles } from '../../../../../../redux/slices/localizations/selectors'
import LoadingSpinner from 'components/UI/LoadingSpinner'

const CreateBannerModal = ({
  isShow,
  onHide,
  onSubmit,
  localizationTitle,
  isLoadingOn,
}) => {
  const localizationTitles = useSelector(getLocalizationTitles)

  const localizationValidationScheme = useMemo(
    () =>
      Yup.object({
        title: Yup.string()
          .required('Required')
          .test(
            'unique error',
            'Localization is already exists',
            value => !localizationTitles.includes(value),
          ),
      }),
    [localizationTitles],
  )

  return (
    <Modal show={isShow} onHide={onHide} centered>
      {isLoadingOn && <LoadingSpinner />}
      <Modal.Header closeButton>
        <Modal.Title>
          Copy localization <i>&quot;{localizationTitle}&quot;</i>
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          title: '',
        }}
        validationSchema={localizationValidationScheme}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form>
            <Modal.Body style={{ paddingBottom: 35 }}>
              <FormField name='title' label='Localization title' />
            </Modal.Body>
            <Modal.Footer>
              <ButtonToolbar>
                <ButtonGroup className='me-2'>
                  <Button variant='danger' onClick={onHide}>
                    Cancel
                  </Button>
                </ButtonGroup>
                <ButtonGroup className='me-2'>
                  <Button variant='primary' type='submit'>
                    Save
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
export default CreateBannerModal
