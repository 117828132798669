const { gql } = require('@apollo/client')

export const GET_TAGS = gql`
  query Tags {
    tags(limit: 1000, orders: [{ field: "title", type: "ASC" }]) {
      tag_id
      title
    }
  }
`
