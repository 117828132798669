import LoadingSpinner from 'components/UI/LoadingSpinner'
import React, { memo } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

const CreateLocalizationModal = ({
  isShow,
  onHide,
  onSumbit,
  value,
  onChange,
  isLocalizationsLoading,
}) => (
  <Modal show={isShow} onHide={onHide} centered>
    {isLocalizationsLoading && <LoadingSpinner />}
    <Modal.Header closeButton>
      <Modal.Title>Create a mainscreen</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form.Control
        type='text'
        placeholder='Enter localization name'
        value={value}
        onChange={onChange}
      />
    </Modal.Body>
    <Modal.Footer>
      <Button variant='primary' onClick={onSumbit}>
        Add new mainscreen
      </Button>
    </Modal.Footer>
  </Modal>
)

export default memo(CreateLocalizationModal)
