import {
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { MdClear } from 'react-icons/md'
import { colors } from 'shared/style/colors'

const NewBlacklistField = ({ newItem, onChange, onOpen }) => {
  const handleInput = e => {
    const searchValue = e ? e.target.value : ''

    onChange(searchValue)
  }

  return (
    <Flex gap='12px' w='100%' align='center' mb='12px' maxW='800px'>
      <Text
        size='poppins16normal'
        variant='primary-black-text'
        w='fit-content'
        whiteSpace='nowrap'
      >
        New Blacklist Item
      </Text>

      <InputGroup>
        <Input
          type='text'
          placeholder='Item...'
          variant='loginField'
          value={newItem}
          onChange={handleInput}
        />

        {newItem && newItem.length > 0 && (
          <InputRightElement right='18px' top='12px' w='24px' h='24px'>
            <Icon
              as={MdClear}
              fill={colors['primary-violet-100']}
              w='24px'
              h='24px'
              cursor='pointer'
              onClick={() => handleInput(null)}
            />
          </InputRightElement>
        )}
      </InputGroup>

      <Button
        variant='defaultButton'
        p='12px 24px'
        w='fit-content'
        onClick={onOpen}
      >
        ADD
      </Button>
    </Flex>
  )
}

export default NewBlacklistField
