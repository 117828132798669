import { createAsyncThunk } from '@reduxjs/toolkit'
import { createUser, fetchUsers, loginUser } from '../../../../api/users'
import { clearCache } from '../../../../api/cache'

export const loginUserThunk = createAsyncThunk(
  'users/login',
  async user =>
    await loginUser(user)
      .then(res => res.data)
      .catch(err => {
        throw new Error(err.error)
      }),
)

export const fetchUsersThunk = createAsyncThunk(
  'users/fetch',
  async () => await fetchUsers().then(res => res.data),
)

export const createUserThunk = createAsyncThunk('users/create', async user => {
  try {
    return await createUser(user).then(res => res.data)
  } catch (error) {
    throw new Error('Create user Error')
  }
})

export const clearCacheThunk = createAsyncThunk('api/clearCache', async () => {
  try {
    return await clearCache()
  } catch (error) {
    console.error(error)
    throw error
  }
})
