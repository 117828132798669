import { createSlice } from '@reduxjs/toolkit'
import { fetchUsersThunk, loginUserThunk } from './thunks'
import * as R from 'ramda'

export const STATE_KEY = 'users'

const initialState = {
  currentUser: {
    access_token: '',
  },
  allUsers: {},
}

const usersSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    logout: () => {
      return initialState
    },
  },
  extraReducers: {
    [loginUserThunk.fulfilled]: (state, action) => {
      const { access_token } = action.payload
      state.currentUser.access_token = 'Bearer ' + access_token
    },
    [fetchUsersThunk.fulfilled]: (state, action) => {
      state.allUsers = R.indexBy(R.prop('user_id'), action.payload)
    },
  },
})

export const { logout } = usersSlice.actions

export const usersReducer = usersSlice.reducer
