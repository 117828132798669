import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import moment from 'moment'
import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import {
  GET_FULL_BOOK,
  UPDATE_BOOK_ADMIN_PROTECTED,
  UPDATE_BOOK_FIELDS,
} from 'services/book/graphql'

const BookFields = ({
  is_ongoing,
  book_id,
  last_release,
  isBookLoading,
  isListenable,
  restrict,
  giftsCount,
}) => {
  const [isOngoing, setIsOngoing] = useState(false)
  const [newRestrict, setNewRestrict] = useState(undefined)
  const [isBookListenable, setIsBookListenable] = useState(false)
  const [lastUpdate, setLastUpdate] = useState('')
  const [gifts, setGifts] = useState(0)
  const [canSave, setCanSave] = useState(false)
  const toast = useToast()

  const [updateBookProtected] = useMutation(UPDATE_BOOK_ADMIN_PROTECTED, {
    refetchQueries: [
      {
        query: GET_FULL_BOOK,
        variables: {
          book_id,
        },
      },
    ],
  })

  const [updateSelectedBook] = useMutation(UPDATE_BOOK_FIELDS, {
    refetchQueries: [
      {
        query: GET_FULL_BOOK,
        variables: {
          book_id,
        },
      },
    ],
  })

  const toggleOngoing = () => {
    setIsOngoing(curr => !curr)
  }

  const toggleListenable = () => {
    setIsBookListenable(curr => !curr)
  }

  const handleSetDate = e => {
    setLastUpdate(e.target.value)
  }

  const handleSetGifts = e => {
    setGifts(Number(e.target.value))
  }

  const handleChangeRestrict = value => {
    setNewRestrict(Number(value))
  }

  const handleUpdateBook = async () => {
    const variables = {
      book_id,
    }

    if (isOngoing !== is_ongoing) {
      variables.is_ongoing = isOngoing
    }

    if (lastUpdate !== moment(last_release).format('YYYY-MM-DD')) {
      if (lastUpdate) {
        variables.last_release = new Date(lastUpdate)
      }
    }

    if (
      Object.hasOwnProperty.call(variables, 'is_ongoing') ||
      Object.hasOwnProperty.call(variables, 'last_release')
    ) {
      const {
        data: { book: updatedBookFields },
      } = await updateSelectedBook({
        variables,
      })

      if (updatedBookFields.book_id) {
        toast({
          title: 'Book fields was succesfully updated',
          status: 'success',
          isClosable: true,
          duration: 6000,
          position: 'top',
        })
      } else {
        toast({
          title: 'Book fields update error',
          status: 'warning',
          isClosable: true,
          duration: 6000,
          position: 'top',
        })
      }
    }

    const initialBook = {}

    if (isBookListenable !== isListenable) {
      initialBook.isListenable = isBookListenable
    }

    if (newRestrict !== restrict) {
      initialBook.restrict = newRestrict
    }

    if (giftsCount !== gifts && !isNaN(gifts)) {
      initialBook.giftsCount = gifts
    }

    if (!lodash.isEmpty(initialBook)) {
      const {
        data: { book: updatedBookFields },
      } = await updateBookProtected({
        variables: {
          book: {
            book_id,
            ...initialBook,
          },
        },
      })

      if (Object.hasOwnProperty.call(initialBook, 'restrict')) {
        if (updatedBookFields.restrict === initialBook.restrict) {
          toast({
            title: `Restrict age has been successfully change from ${restrict} to ${newRestrict}`,
            status: 'success',
            isClosable: true,
            duration: 6000,
            position: 'top',
          })
        } else {
          toast({
            title: 'Book restrict field update error',
            status: 'warning',
            isClosable: true,
            duration: 6000,
            position: 'top',
          })
        }
      }

      if (Object.hasOwnProperty.call(initialBook, 'giftsCount')) {
        if (updatedBookFields.giftsCount === Number(initialBook.giftsCount)) {
          toast({
            title: `Gifts Count has been successfully change from ${
              giftsCount ?? 0
            } to ${gifts}`,
            status: 'success',
            isClosable: true,
            duration: 6000,
            position: 'top',
          })
        } else {
          toast({
            title: 'Gifts count field update error',
            status: 'warning',
            isClosable: true,
            duration: 6000,
            position: 'top',
          })
        }
      }

      if (Object.hasOwnProperty.call(initialBook, 'isListenable')) {
        if (updatedBookFields.isListenable === initialBook.isListenable) {
          toast({
            title: `Audio book was succesfully ${
              isBookListenable ? 'ON' : 'OFF'
            }`,
            status: 'success',
            isClosable: true,
            duration: 6000,
            position: 'top',
          })
        } else {
          toast({
            title: 'Book isListenable field update error',
            status: 'warning',
            isClosable: true,
            duration: 6000,
            position: 'top',
          })
        }
      }
    }
  }

  useEffect(() => {
    if (!isBookLoading) {
      setIsOngoing(is_ongoing)
      setIsBookListenable(isListenable)
      setNewRestrict(restrict)
      setGifts(giftsCount || 0)

      if (last_release) setLastUpdate(moment(last_release).format('YYYY-MM-DD'))
    }
  }, [
    isBookLoading,
    is_ongoing,
    last_release,
    isListenable,
    restrict,
    giftsCount,
  ])

  useEffect(() => {
    setCanSave(
      isOngoing !== is_ongoing ||
        isBookListenable !== isListenable ||
        (lastUpdate &&
          moment(last_release).format('YYYY-MM-DD') !== lastUpdate) ||
        newRestrict !== restrict ||
        giftsCount !== gifts,
    )
  }, [
    is_ongoing,
    last_release,
    isOngoing,
    lastUpdate,
    isBookListenable,
    isListenable,
    newRestrict,
    restrict,
    giftsCount,
    gifts,
  ])

  return (
    <>
      {isBookLoading && <LoadingSpinner />}

      {!isBookLoading && (
        <Flex direction='column' gap='12px' mb='24px'>
          <Heading
            size='poppins24'
            variant='primary-black-text'
            textAlign='center'
          >
            Fields Settings
          </Heading>
          <Flex gap='20px'>
            <Box w='fit-content' minW='300px'>
              <Text
                mb='12px'
                size='poppins16normal'
                variant='primary-black-text'
              >
                Last release date
              </Text>

              <Input
                type='date'
                placeholder='Search...'
                variant='loginField'
                value={lastUpdate}
                onChange={handleSetDate}
              />
            </Box>

            <FormControl
              w='fit-content'
              display='flex'
              flexDirection='column'
              alignItems='center'
            >
              <FormLabel htmlFor='ongoing'>is ongoing</FormLabel>
              <Checkbox
                size='lg'
                colorScheme='purple'
                isChecked={isOngoing}
                id='ongoing'
                display='flex'
                onChange={toggleOngoing}
              />
            </FormControl>

            <FormControl
              w='fit-content'
              display='flex'
              flexDirection='column'
              alignItems='center'
            >
              <FormLabel htmlFor='listenable'>is listenable</FormLabel>
              <Checkbox
                size='lg'
                colorScheme='purple'
                isChecked={isBookListenable}
                id='listenable'
                display='flex'
                onChange={toggleListenable}
              />
            </FormControl>

            <FormControl
              w='fit-content'
              display='flex'
              flexDirection='column'
              alignItems='center'
            >
              <FormLabel htmlFor='restrict'>Restrict</FormLabel>
              <Select
                id='restrict'
                value={newRestrict}
                onChange={e => handleChangeRestrict(e.target.value)}
              >
                <option value={4}>4</option>
                <option value={12}>12</option>
                <option value={16}>16</option>
                <option value={18}>18</option>
              </Select>
            </FormControl>

            <Box w='fit-content' minW='300px'>
              <Text
                mb='12px'
                size='poppins16normal'
                variant='primary-black-text'
              >
                Gifts Count
              </Text>

              <Input
                type='number'
                placeholder='amount...'
                variant='loginField'
                value={gifts}
                onChange={handleSetGifts}
                w='fit-content'
              />
            </Box>
          </Flex>

          <Button
            type='button'
            variant='defaultButton'
            w='fit-content'
            isDisabled={!canSave}
            onClick={handleUpdateBook}
          >
            Save
          </Button>
        </Flex>
      )}
    </>
  )
}

export default BookFields
