import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react'
import ArrowIcon from 'components/UI/Icons/ArrowIcon'
import React, { useMemo, useState } from 'react'
import { colors } from 'shared/style/colors'
import SectionsSummary from '../SectionsSummary'

const Selection = ({
  place,
  title,
  isDefault,
  handleSubmit,
  selectedSections,
  canUpdateData,
  handleClearData,
  handleRemoveSection,
  handleOnDragSection,
  isManager,
}) => {
  const [hover, setHover] = useState(false)

  const isSubmitEnabled = useMemo(() => {
    return place && title
  }, [place, title])

  const isClearEnabled = useMemo(() => {
    return selectedSections.length !== 0 || place || title || isDefault
  }, [selectedSections, place, title, isDefault])

  return (
    <AccordionItem border='none'>
      {({ isExpanded }) => (
        <Flex
          bg='white'
          w='100%'
          borderRadius='8px'
          direction='column'
          border={
            hover || isExpanded
              ? `1px solid ${colors['primary-violet-100']}`
              : `1px solid ${colors['primary-gray-30']}`
          }
        >
          <AccordionButton
            _hover={{
              bg: 'white',
            }}
            p='8px 44px'
            m='0'
            borderRadius='8px'
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            outline='none'
            _focus={{
              boxShadow: 'none',
            }}
          >
            <Flex direction='column' flex={1} gap='12px'>
              <Heading
                size='poppins24'
                variant='primary-black-text'
                color={
                  hover || isExpanded
                    ? colors['primary-blue-100']
                    : colors['primary-black-text']
                }
                lineHeight='38px'
                letterSpacing='0'
                textAlign='left'
              >
                {isManager ? 'Update Screen Summary' : 'Create Screen Summary'}
              </Heading>
            </Flex>

            {isExpanded ? (
              <Box p='10px' bg={colors['primary-violet-10']} borderRadius='50%'>
                <Box transform='rotate(180deg)'>
                  <ArrowIcon
                    fill={
                      hover
                        ? colors['button-hover']
                        : colors['primary-violet-100']
                    }
                  />
                </Box>
              </Box>
            ) : (
              <Box p='10px' bg={colors['primary-violet-10']} borderRadius='50%'>
                <Box>
                  <ArrowIcon
                    fill={
                      hover
                        ? colors['button-hover']
                        : colors['primary-violet-100']
                    }
                  />
                </Box>
              </Box>
            )}
          </AccordionButton>

          <AccordionPanel>
            <Flex
              bg='white'
              w='100%'
              borderRadius='8px'
              direction='column'
              gap='8px'
            >
              <Flex w='100%' gap='12px'>
                <Flex
                  w='50%'
                  direction='column'
                  alignSelf='flex-start'
                  borderRight='1px solid #E2E8F0'
                >
                  <Flex gap='20px' align='center'>
                    <Text
                      size='poppins16normal'
                      variant='primary-black-text'
                      whiteSpace='nowrap'
                    >
                      Screen title:
                    </Text>

                    <Heading
                      size='poppins20'
                      variant='primary-black-text'
                      lineHeight='38px'
                      letterSpacing='0'
                      textAlign='left'
                    >
                      {title || ''}
                    </Heading>
                  </Flex>
                </Flex>
              </Flex>

              <Divider />

              <Flex w='100%' gap='12px'>
                <Flex gap='20px' align='center' w='50%'>
                  <Text
                    size='poppins16normal'
                    variant='primary-black-text'
                    whiteSpace='nowrap'
                  >
                    Screen type:
                  </Text>

                  <Heading
                    size='poppins20'
                    variant='primary-black-text'
                    lineHeight='38px'
                    letterSpacing='0'
                    textAlign='left'
                  >
                    {place || ''}
                  </Heading>
                </Flex>

                <Flex gap='20px' align='center' w='50%'>
                  <Text
                    size='poppins16normal'
                    variant='primary-black-text'
                    whiteSpace='nowrap'
                  >
                    Is default:
                  </Text>

                  <Heading
                    size='poppins20'
                    variant='primary-black-text'
                    lineHeight='38px'
                    letterSpacing='0'
                    textAlign='left'
                  >
                    {isDefault ? 'Yes' : 'No'}
                  </Heading>
                </Flex>
              </Flex>

              <Divider />

              <Flex gap='36px'>
                <SectionsSummary
                  selectedSections={selectedSections}
                  handleRemoveSection={handleRemoveSection}
                  handleOnDragSection={handleOnDragSection}
                />
              </Flex>

              <Flex gap='23px' alignSelf='end'>
                <Button
                  type='button'
                  variant='outlineButton'
                  w='204px'
                  isDisabled={!isClearEnabled}
                  onClick={handleClearData}
                >
                  Clear data
                </Button>

                <Button
                  type='button'
                  variant='defaultButton'
                  w='204px'
                  isDisabled={!isSubmitEnabled || !canUpdateData}
                  onClick={handleSubmit}
                >
                  {isManager ? 'Update' : 'Create'}
                </Button>
              </Flex>
            </Flex>
          </AccordionPanel>
        </Flex>
      )}
    </AccordionItem>
  )
}

export default Selection
