import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react'
import ArrowIcon from 'components/UI/Icons/ArrowIcon'
import { DEBOUNCE_DELAY } from 'constants/app'
import React, { useCallback, useEffect, useState } from 'react'
import { colors } from 'shared/style/colors'
import { debounce } from 'lodash'
import { useLazyQuery } from '@apollo/client'
import TitleSearch from 'screens/Books/components/BooksPageLayout/TitleSearch'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import { GET_SECTIONS } from 'services/sections/graphql'
import { API_V2 } from 'constants/apollo'
import SectionModule from './SectionModule'

const SectionsSelector = ({ selectedSections, handleAddSection }) => {
  const [hover, setHover] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [query, setQuery] = useState('')
  const [visibleSections, setVisibleSections] = useState([])

  const [getSectionsBySlug, { data: sections, loading: isSectionsLoading }] =
    useLazyQuery(GET_SECTIONS)

  const handleSearchChange = useCallback(value => {
    setSearchValue(value)
  }, [])

  const handleSetQuery = useCallback(value => {
    setQuery(value)
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchChange = useCallback(
    debounce(value => handleSetQuery(value), DEBOUNCE_DELAY),
    [],
  )

  useEffect(() => {
    debouncedSearchChange(searchValue)
  }, [searchValue, debouncedSearchChange])

  useEffect(() => {
    if (query) {
      getSectionsBySlug({
        variables: {
          pagination: {
            offset: 0,
            count: 50,
          },
          section: {
            slugId: query,
          },
        },
        context: {
          clientName: API_V2,
        },
        fetchPolicy: 'cache-and-network',
      })
    }
  }, [query, getSectionsBySlug])

  useEffect(() => {
    if (sections && !isSectionsLoading) {
      const ignoreList = [...selectedSections.map(item => ({ id: item.id }))]
      setVisibleSections(
        query
          ? (sections.items.sections ?? []).filter(
              item => !ignoreList.some(selected => selected.id === item.id),
            )
          : [],
      )
    }
  }, [sections, isSectionsLoading, query, selectedSections])

  return (
    <AccordionItem border='none'>
      {({ isExpanded }) => (
        <Flex
          bg='white'
          w='100%'
          borderRadius='8px'
          direction='column'
          border={
            hover || isExpanded
              ? `1px solid ${colors['primary-violet-100']}`
              : `1px solid ${colors['primary-gray-30']}`
          }
        >
          <AccordionButton
            _hover={{
              bg: 'white',
            }}
            p='8px 44px'
            m='0'
            borderRadius='8px'
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            outline='none'
            _focus={{
              boxShadow: 'none',
            }}
          >
            <Flex direction='column' flex={1} gap='12px'>
              <Heading
                size='poppins24'
                variant='primary-black-text'
                color={
                  hover || isExpanded
                    ? colors['primary-blue-100']
                    : colors['primary-black-text']
                }
                lineHeight='38px'
                letterSpacing='0'
                textAlign='left'
              >
                Sections
              </Heading>
            </Flex>

            {isExpanded ? (
              <Box p='10px' bg={colors['primary-violet-10']} borderRadius='50%'>
                <Box transform='rotate(180deg)'>
                  <ArrowIcon
                    fill={
                      hover
                        ? colors['button-hover']
                        : colors['primary-violet-100']
                    }
                  />
                </Box>
              </Box>
            ) : (
              <Box p='10px' bg={colors['primary-violet-10']} borderRadius='50%'>
                <Box>
                  <ArrowIcon
                    fill={
                      hover
                        ? colors['button-hover']
                        : colors['primary-violet-100']
                    }
                  />
                </Box>
              </Box>
            )}
          </AccordionButton>
          <AccordionPanel>
            {isSectionsLoading && <LoadingSpinner />}

            <TitleSearch search={searchValue} onChange={handleSearchChange} />

            <Flex gap='10px' wrap='wrap'>
              {visibleSections.map(item => (
                <Flex key={item.id} w='100%' align='center' gap='12px'>
                  <SectionModule item={item} isPreview />

                  <Image
                    w='44px'
                    h='35px'
                    src={`/images/screens/adding.png`}
                    alt='add'
                    onClick={() => handleAddSection(item)}
                    cursor='pointer'
                  />
                </Flex>
              ))}

              {visibleSections.length === 0 && query && !isSectionsLoading && (
                <Text color='black'>No sections by your query</Text>
              )}
            </Flex>
          </AccordionPanel>
        </Flex>
      )}
    </AccordionItem>
  )
}

export default SectionsSelector
