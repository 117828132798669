import { createSlice } from '@reduxjs/toolkit'
import * as R from 'ramda'
import { createAdvertisementThunk, fetchAdvertisementsThunk } from './thunks'

export const STATE_KEY = 'advertisements'

const initialState = {}

const advertisementsSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAdvertisementsThunk.fulfilled]: (_, action) => {
      return R.indexBy(R.prop('advertisement_id'), action.payload)
    },
    [createAdvertisementThunk.fulfilled]: (state, action) => {
      const advertisement = action.payload
      state[advertisement.advertisement_id] = advertisement
    },
  },
})

export const advertisementsReducer = advertisementsSlice.reducer
