class AuthService {
  constructor() {
    this.token = null
    this.role = null
  }

  setCredentials({ token, role }) {
    if (token) {
      this.token = 'Bearer ' + token
      localStorage.setItem('access_token', 'Bearer ' + token)
    }

    if (role) {
      this.role = role
      localStorage.setItem('role', role)
    }
  }

  removeCredentials() {
    this.token = null
    this.role = null
    localStorage.removeItem('access_token')
    localStorage.removeItem('role')
  }

  getTokenFromStorage() {
    this.token = localStorage.getItem('access_token') || null
  }

  getRole() {
    this.role = localStorage.getItem('role') || null
  }
}

export default new AuthService()
