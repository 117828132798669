import { createSlice } from '@reduxjs/toolkit'
import {
  createListThunk,
  deleteListThunk,
  fetcListsThunks,
  updateListThunk,
} from './thunks'
import * as R from 'ramda'

export const STATE_KEY = 'lists'

const initialState = {}

const listsSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    updateListBooksOrder: (state, action) => {
      const { dragIndex, hoverIndex, list_id } = action.payload
      const books = R.clone(state[list_id].booksOrder)
      const tmp = books[dragIndex]
      books[dragIndex] = books[hoverIndex]
      books[hoverIndex] = tmp

      state[list_id].booksOrder = books.map(({ book }, order_number) => ({
        book,
        order_number,
      }))
    },
    updateListGenresOrder: (state, action) => {
      const { dragIndex, hoverIndex, list_id } = action.payload
      const genres = R.clone(state[list_id].genresOrder)
      const tmp = genres[dragIndex]
      genres[dragIndex] = genres[hoverIndex]
      genres[hoverIndex] = tmp
      state[list_id].genresOrder = genres.map(({ genre }, order_number) => ({
        genre,
        order_number,
      }))
    },
    updateListTagsOrder: (state, action) => {
      const { dragIndex, hoverIndex, list_id } = action.payload
      const tags = R.clone(state[list_id].tagsOrder)
      const tmp = tags[dragIndex]
      tags[dragIndex] = tags[hoverIndex]
      tags[hoverIndex] = tmp
      state[list_id].tagsOrder = tags.map(({ tag }, order_number) => ({
        tag,
        order_number,
      }))
    },
    updateListBannersOrder: (state, action) => {
      const { dragIndex, hoverIndex, list_id } = action.payload
      const banners = R.clone(state[list_id].bannersOrder)
      const tmp = banners[dragIndex]
      banners[dragIndex] = banners[hoverIndex]
      banners[hoverIndex] = tmp
      state[list_id].bannersOrder = banners.map(({ banner }, order_number) => ({
        banner,
        order_number,
      }))
    },
    updateListSurveysOrder: (state, action) => {
      const { dragIndex, hoverIndex, list_id } = action.payload
      const surveys = R.clone(state[list_id].survey_links)
      const tmp = surveys[dragIndex]
      surveys[dragIndex] = surveys[hoverIndex]
      surveys[hoverIndex] = tmp
      state[list_id].survey_links = surveys
    },
  },
  extraReducers: {
    [fetcListsThunks.fulfilled]: (_, action) =>
      action.payload.reduce(
        (acc, list) => ({ ...acc, [list.list_id]: list }),
        {},
      ),
    [createListThunk.fulfilled]: (state, action) => {
      const { list_id } = action.payload
      state[list_id] = action.payload
    },
    [updateListThunk.fulfilled]: (state, action) => {
      const { list_id } = action.payload
      if (state[list_id])
        state[list_id] = { ...state[list_id], ...action.payload }
    },
    [deleteListThunk.fulfilled]: (state, action) => {
      const { list_id } = action.payload
      delete state[list_id]
    },
  },
})

export const {
  updateListBooksOrder,
  updateListGenresOrder,
  updateListBannersOrder,
  updateListSurveysOrder,
  updateListTagsOrder,
} = listsSlice.actions

export const listsReducer = listsSlice.reducer
