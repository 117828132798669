const { gql } = require('@apollo/client')

export const GET_COMMENTS = gql`
  query allComments(
    $entity: CommentEntityIdentity
    $params: CommentParams
    $pagination: Pagination
  ) {
    comments: allComments(
      entity: $entity
      params: $params
      pagination: $pagination
    ) {
      comments {
        content
        createdAt
        updatedAt
        id
        bookId
        chapterId
        status
        isAdminEdited
        isAdminSaw
        isContentBug
        isModerated
        isTechBug
        rejectReason
        contentTranslates {
          locale
          value
        }
        author {
          id
          photoUrl
          userName
        }
        reports {
          id
          readerId
          text
          type
          updatedAt
        }
      }
      pagination {
        ... on PaginationResponse {
          offset
          count
          total
        }
      }
    }
  }
`

export const TRANSLATE_COMMENT = gql`
  mutation TranslateComment($id: String!) {
    item: translateComment(comment: { id: $id }) {
      comment {
        content
        createdAt
        updatedAt
        id
        contentTranslates {
          locale
          value
        }
      }
    }
  }
`

export const APPROVE_COMMENT = gql`
  mutation ApproveComment($id: String!) {
    result: approveComment(comment: { id: $id }) {
      success
    }
  }
`

export const REJECT_COMMENT = gql`
  mutation RejectComment($id: String!, $info: ModerationInfo!) {
    result: rejectComment(comment: { id: $id }, info: $info) {
      success
    }
  }
`

export const UPDATE_COMMENT = gql`
  mutation UpdateComment($id: String!, $content: String) {
    item: updateComment(comment: { id: $id, content: $content }) {
      comment {
        id
        content
        updatedAt
        isModerated
      }
    }
  }
`

export const GET_ALL_BLACKLIST = gql`
  query CommentsBlacklist(
    $pagination: Pagination
    $statuses: [BlacklistStatus]
  ) {
    blacklist: commentsBlacklist(pagination: $pagination, statuses: $statuses) {
      items {
        id
        createdAt
        text
        currentStatus
        changedStatus
        updatedAt
      }
      pagination {
        ... on PaginationResponse {
          offset
          count
          total
        }
      }
    }
  }
`

export const ADD_BLACKLIST_REQUEST = gql`
  mutation AddBlacklistItemRequest($item: AddBlacklistItem!) {
    blItem: addBlacklistItemRequest(item: $item) {
      id
      changedStatus
      currentStatus
      text
    }
  }
`

export const ADD_BLACKLIST_ITEM = gql`
  mutation AddBlacklistItem($item: AddBlacklistItem!) {
    result: addBlacklistItem(item: $item) {
      success
    }
  }
`

export const UPDATE_BLACKLIST_ITEM_REQUEST = gql`
  mutation UpdateBlacklistItemRequest($item: UpdateBlacklistItem!) {
    item: updateBlacklistItemRequest(item: $item) {
      id
      createdAt
      updatedAt
      text
      currentStatus
      changedStatus
    }
  }
`

export const REMOVE_BLACKLIST_ITEM_REQUEST = gql`
  mutation RemoveBlacklistItemRequest($item: BlacklistItemIdentity!) {
    result: removeBlacklistItemRequest(item: $item) {
      success
    }
  }
`

export const REMOVE_BLACKLIST_ITEM = gql`
  mutation RemoveBlackListItem($item: BlacklistItemIdentity!) {
    result: removeBlackListItem(item: $item) {
      success
    }
  }
`
