import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  createSeriesThunk,
  deleteSeriesThunk,
  updateSeriesThunk,
} from '../../redux/slices/series/thunks'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { ALERT_FADE_TIMEOUT } from '../../constants/ui'
import { Alert } from 'react-bootstrap'
import SeriesPanelLayout from './components/SeriesPanelLayout'
import { getSeriesList } from '../../redux/slices/series/selectors'
import { updateSeriesBooksOrder } from '../../redux/slices/series'
import { DEBOUNCE_DELAY } from 'constants/app'
import BookService from 'services/book'

const SeriesPanel = () => {
  const dispatch = useDispatch()

  const series = useSelector(getSeriesList)

  const [selectedToAddBooksIds, setSelectedToAddBooksIds] = useState([])
  const [updatedSeries, setUpdatedSeries] = useState({})
  const [isOpenUpdateSeriesModal, setIsOpenUpdateSeriesModal] = useState(false)
  const [isOpenCreateSeriesModal, setIsOpenCreateSeriesModal] = useState(false)
  const [isUniqueSeriesTitleAlert, setIsUniqueSeriesTitleAlert] =
    useState(false)
  const [seriesBookSearch, setSeriesBookSearch] = useState('')
  const [newSeriesTitle, setNewSeriesTitle] = useState('')
  const [showDeleted, setShowDeleted] = useState(false)

  const [searchedBooksList, setSearchedBooksList] = useState([])

  const isUniqueSeriesTitle = useMemo(
    () => !series.some(series => series.title === newSeriesTitle),
    [newSeriesTitle, series],
  )

  const handleGetSelectedBookIndex = useCallback(
    book_id =>
      selectedToAddBooksIds.findIndex(data => book_id === data.book_id),
    [selectedToAddBooksIds],
  )

  const handleSeriesBookSearchChange = useCallback(
    e => setSeriesBookSearch(e.target.value),
    [],
  )

  const debouncedSearchChange = useMemo(
    () => debounce(handleSeriesBookSearchChange, DEBOUNCE_DELAY),
    [handleSeriesBookSearchChange],
  )

  const handleNewSeriesTitleChange = useCallback(
    e => setNewSeriesTitle(e.target.value),
    [],
  )

  const handleNotSelectedSeriesBooksFilter = useCallback(
    books =>
      books.filter(
        book =>
          !updatedSeries?.booksOrder?.some(
            updBook => updBook.book.book_id === book.book_id,
          ),
      ),
    [updatedSeries],
  )

  const handleSelectBookToAdd = useCallback(
    book_id => {
      const isAlreadySelectedSeriesBook =
        handleGetSelectedBookIndex(book_id) > -1
      if (!isAlreadySelectedSeriesBook) {
        setSelectedToAddBooksIds([
          ...selectedToAddBooksIds,
          {
            book_id,
          },
        ])
        return
      }
      setSelectedToAddBooksIds(
        selectedToAddBooksIds.filter(book => book.book_id !== book_id),
      )
    },
    [handleGetSelectedBookIndex, selectedToAddBooksIds],
  )

  const handleOpenCreateSeriesModal = useCallback(() => {
    setIsOpenCreateSeriesModal(true)
  }, [])

  const handleCloseCreateSeriesModal = useCallback(() => {
    setIsOpenCreateSeriesModal(false)
  }, [])

  const handleOpenUpdateSeriesModal = useCallback(series => {
    setUpdatedSeries(series)
    setIsOpenUpdateSeriesModal(true)
  }, [])

  const handleCloseUpdateSeriesModal = useCallback(() => {
    setIsOpenUpdateSeriesModal(false)
    setUpdatedSeries({})
    setSelectedToAddBooksIds([])
  }, [])

  const handleSeriesCreate = useCallback(() => {
    if (newSeriesTitle.trim().length) {
      if (isUniqueSeriesTitle) {
        const series = { title: newSeriesTitle.trim() }
        dispatch(createSeriesThunk(series))
        setNewSeriesTitle('')
        handleCloseCreateSeriesModal()
        return
      }

      setIsUniqueSeriesTitleAlert(true)
      setNewSeriesTitle('')
    }
  }, [
    dispatch,
    handleCloseCreateSeriesModal,
    isUniqueSeriesTitle,
    newSeriesTitle,
  ])

  const handleSeriesDelete = useCallback(
    series_id => {
      const isConfirm = window.confirm('Are you sure?')
      if (isConfirm) {
        dispatch(deleteSeriesThunk(series_id))
      }
    },
    [dispatch],
  )

  const handleSeriesUpdate = useCallback(() => {
    const booksCount = updatedSeries.booksOrder.length
    const newBooks = selectedToAddBooksIds.map((book, idx) => ({
      order_number: idx + booksCount,
      book,
    }))
    const series = {
      ...updatedSeries,
      booksOrder: [...updatedSeries.booksOrder, ...newBooks],
    }
    dispatch(updateSeriesThunk(series))
    handleCloseUpdateSeriesModal()
  }, [
    dispatch,
    handleCloseUpdateSeriesModal,
    selectedToAddBooksIds,
    updatedSeries,
  ])

  const handleSeriesBookDelete = useCallback(
    (series, book_id) => {
      const isConfirm = window.confirm('Are you sure?')
      if (isConfirm) {
        const updatedSeries = {
          ...series,
          booksOrder: series.booksOrder.filter(
            ({ book }) => book.book_id !== book_id,
          ),
        }
        dispatch(updateSeriesThunk(updatedSeries))
      }
    },
    [dispatch],
  )

  const handleSeriesBookOrderUpdate = useCallback(
    (dragIndex, hoverIndex, series_id) =>
      dispatch(updateSeriesBooksOrder({ dragIndex, hoverIndex, series_id })),
    [dispatch],
  )

  const handleSeriesBookOrderDragEnd = useCallback(
    series => dispatch(updateSeriesThunk(series)),
    [dispatch],
  )

  const handleFetchBooksBySearch = useCallback(async () => {
    const books = await BookService.searchBooks({
      title: seriesBookSearch,
      deleted: showDeleted,
    })

    setSearchedBooksList(handleNotSelectedSeriesBooksFilter(books))
  }, [handleNotSelectedSeriesBooksFilter, seriesBookSearch, showDeleted])

  useEffect(() => {
    if (isUniqueSeriesTitleAlert) {
      const timeout = setTimeout(
        () => setIsUniqueSeriesTitleAlert(false),
        ALERT_FADE_TIMEOUT,
      )
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [isUniqueSeriesTitleAlert])

  useEffect(() => {
    return () => {
      debouncedSearchChange.cancel()
    }
  }, [debouncedSearchChange])

  useEffect(() => {
    if (seriesBookSearch) {
      return handleFetchBooksBySearch()
    }
    setSearchedBooksList([])
  }, [handleFetchBooksBySearch, seriesBookSearch])

  return (
    <>
      <SeriesPanelLayout
        handleNewSeriesTitleChange={handleNewSeriesTitleChange}
        handleOpenCreateSeriesModal={handleOpenCreateSeriesModal}
        handleCloseCreateSeriesModal={handleCloseCreateSeriesModal}
        handleOpenUpdateSeriesModal={handleOpenUpdateSeriesModal}
        handleCloseUpdateSeriesModal={handleCloseUpdateSeriesModal}
        isOpenCreateSeriesModal={isOpenCreateSeriesModal}
        isOpenUpdateSeriesModal={isOpenUpdateSeriesModal}
        handleSeriesCreate={handleSeriesCreate}
        handleSeriesDelete={handleSeriesDelete}
        handleSeriesUpdate={handleSeriesUpdate}
        newSeriesTitle={newSeriesTitle}
        series={series}
        handleSelectBookToAdd={handleSelectBookToAdd}
        handleSeriesBookDelete={handleSeriesBookDelete}
        updatedSeries={updatedSeries}
        filteredNotSelectedUpdatedSeriesBooks={searchedBooksList}
        handleGetSelectedBookIndex={handleGetSelectedBookIndex}
        seriesBookSearch={seriesBookSearch}
        handleSeriesBookSearchChange={debouncedSearchChange}
        handleSeriesBookOrderUpdate={handleSeriesBookOrderUpdate}
        handleSeriesBookOrderDragEnd={handleSeriesBookOrderDragEnd}
        setShowDeleted={setShowDeleted}
        showDeleted={showDeleted}
      />
      <Alert show={isUniqueSeriesTitleAlert} variant='warning'>
        Series Title must be unique!
      </Alert>
    </>
  )
}

export default SeriesPanel
