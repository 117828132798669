import { Accordion, Flex, useToast } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { GET_ISO_LANGUAGES } from 'services/languages/graphql'
import LocalesSelector from 'components/ContentAnatomy/LocalesSelector'
import Selection from 'components/ContentAnatomy/Selection'
import BooksSelector from 'components/ContentAnatomy/BooksSelector'
import ChaptersSelector from 'components/ContentAnatomy/ChaptersSelector'
import { GENERATE_NOTIFICATIONS } from 'services/notifications/graphql'
import { baseScrollBar } from 'constants/scrollbar'

const AppNotificationsLayout = () => {
  const [selectedLocales, setSelectedLocales] = useState([])
  const [availableLocales, setAvailableLocales] = useState([])
  const [selectedStories, setSelectedStories] = useState([])
  const [selectedChapters, setSelectedChapters] = useState([])
  const toast = useToast()
  const [generateNotifications] = useMutation(GENERATE_NOTIFICATIONS)

  // #region isoLanguages

  const { data, loading } = useQuery(GET_ISO_LANGUAGES, {
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (data && !loading) {
      setAvailableLocales(
        (data.languages || []).filter(
          item =>
            !selectedLocales.some(selected => selected.code === item.code),
        ),
      )
    }
  }, [data, loading, selectedLocales])

  const handleAddLocale = useCallback(locale => {
    setSelectedLocales(curr => [...curr, locale])
  }, [])

  const handleRemoveLocale = useCallback(locale => {
    setSelectedLocales(curr => curr.filter(item => item.title !== locale.title))
  }, [])

  const handleClearLocales = useCallback(() => {
    setSelectedLocales([])
  }, [])

  // #endregion

  // #region books

  const handleAddStory = useCallback(story => {
    setSelectedStories(curr => [...curr, story])
  }, [])

  const handleRemoveStory = useCallback(story => {
    setSelectedStories(curr =>
      curr.filter(item => story.book_id !== item.book_id),
    )
  }, [])

  const handleClearStories = useCallback(() => {
    setSelectedStories([])
  }, [])

  // #endregion

  // #region chapters

  const handleAddChapter = useCallback((book, chapter) => {
    setSelectedChapters(curr => [
      ...curr,
      { ...chapter, bookTitle: book.title, bookId: book.book_id },
    ])
  }, [])

  const handleRemoveChapter = useCallback(chapter => {
    setSelectedChapters(curr =>
      curr.filter(item => chapter.chapter_id !== item.chapter_id),
    )
  }, [])

  const handleClearChapters = useCallback(() => {
    setSelectedChapters([])
  }, [])

  // #endregion

  const handleClearData = useCallback(() => {
    handleClearChapters()
    handleClearStories()
    handleClearLocales()
  }, [handleClearChapters, handleClearLocales, handleClearStories])

  const handleSubmit = useCallback(async () => {
    const isSubmitEnabled =
      selectedLocales.length !== 0 &&
      (selectedStories.length !== 0 || selectedChapters.length !== 0)

    if (isSubmitEnabled) {
      const request = {
        locales: selectedLocales.map(item => item.code),
      }

      if (selectedStories.length !== 0) {
        request.books = {
          ids: selectedStories.map(item => item.book_id),
        }
      }

      if (selectedChapters.length !== 0) {
        request.chapters = {
          ids: selectedChapters.map(item => item.chapter_id),
        }
      }

      try {
        const {
          data: { result },
        } = await generateNotifications({
          variables: {
            content: request,
          },
        })

        if (result.success) {
          toast({ title: 'Uploaded', status: 'success', isClosable: true })
          handleClearData()

          return
        }

        toast({
          title: 'Failed',
          description: 'Failed to create notifications',
          status: 'error',
          isClosable: true,
        })
      } catch (error) {
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          isClosable: true,
        })
      }
    }
  }, [
    selectedChapters,
    selectedLocales,
    selectedStories,
    generateNotifications,
    toast,
    handleClearData,
  ])

  return (
    <Flex p='10px 20px 0' direction='column' overflow='hidden'>
      <Accordion
        allowMultiple
        // allowToggle
        w='100%'
        display='flex'
        flexDirection='column'
        gap='12px'
        overflow='hidden'
      >
        <Selection
          selectedLocales={selectedLocales}
          handleRemoveLocale={handleRemoveLocale}
          selectedStories={selectedStories}
          handleRemoveStory={handleRemoveStory}
          selectedChapters={selectedChapters}
          handleRemoveChapter={handleRemoveChapter}
          handleClearData={handleClearData}
          handleSubmit={handleSubmit}
          isLocalizations={false}
        />

        <Flex
          direction='column'
          overflow='auto'
          sx={baseScrollBar}
          gap='12px'
          pb='30px'
        >
          <Flex w='500px'>
            <LocalesSelector
              availableLocales={availableLocales.sort((a, b) =>
                a.title.localeCompare(b.title),
              )}
              addLocales={handleAddLocale}
            />
          </Flex>

          <BooksSelector
            selectedStories={selectedStories}
            handleAddStory={handleAddStory}
            selectedChapters={selectedChapters}
            isLocalizations={false}
          />

          <ChaptersSelector
            handleAddChapter={handleAddChapter}
            selectedChapters={selectedChapters}
            selectedStories={selectedStories}
            isLocalizations={false}
          />
        </Flex>
      </Accordion>
    </Flex>
  )
}

export default AppNotificationsLayout
