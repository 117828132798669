import { Flex, Heading, Image, Text } from '@chakra-ui/react'
import { BannerTypes } from 'constants/banners'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { colors } from 'shared/style/colors'
import { createSelector } from '@reduxjs/toolkit'
import { getBannersList } from 'redux/slices/banners/selectors'
import { useSelector } from 'react-redux'

const stateSelector = createSelector(getBannersList, banners => ({
  banners,
}))

const BannersSummary = ({
  selectedBanners,
  handleRemoveBanner,
  handleOnDragBanner,
}) => {
  const { banners: allBanners } = useSelector(stateSelector)

  return (
    <Flex gap='20px'>
      <Heading
        size='poppins20'
        variant='primary-black-text'
        lineHeight='38px'
        letterSpacing='0'
        textAlign='left'
        w='120px'
        minW='120px'
      >
        Banners
      </Heading>

      <Flex>
        <DragDropContext onDragEnd={handleOnDragBanner}>
          <Droppable droppableId='banners' style={{ transform: 'none' }}>
            {provided => (
              <Flex
                gap='10px'
                direction='column'
                wrap='wrap'
                {...provided.droppableProps}
                ref={provided.innerRef}
                userSelect='none'
              >
                {selectedBanners.map((banner, index) => (
                  <Draggable
                    key={banner.banner_id}
                    draggableId={banner.banner_id}
                    index={index}
                  >
                    {provided => {
                      const bannerWithData = allBanners.find(
                        el => el.banner_id === banner.banner_id,
                      )
                      const item = {
                        ...banner,
                        advertisement: bannerWithData.advertisement,
                        book: bannerWithData.book,
                      }

                      return (
                        <Flex
                          h='106px'
                          gap='8px'
                          border={`1px solid ${colors['primary-gray-30']}`}
                          p='8px 12px'
                          boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
                          borderRadius='8px'
                          cursor='pointer'
                          _hover={{
                            bg: 'rgba(36, 28, 88, 0.03)',
                          }}
                          onClick={() => handleRemoveBanner(item)}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <Image
                            src={item.cover_url || '/images/book.png'}
                            alt='book-cover'
                            h='90px'
                            aspectratio='2/3'
                            objectFit='cover'
                          />

                          <Flex w='100%' gap='10px' direction='column'>
                            <Text
                              size='poppins14'
                              variant='primary-black-text'
                              fontWeight={600}
                            >
                              banner type: <b>{item.banner_type}</b>
                            </Text>

                            {(item.banner_type === BannerTypes.BOOKS ||
                              item.banner_type === BannerTypes.BOOK) &&
                              item.book && (
                                <>
                                  {item.book.title && (
                                    <Text
                                      size='poppins14'
                                      variant='primary-black-text'
                                      alignSelf='end'
                                    >
                                      Book: {item.book.title}
                                    </Text>
                                  )}

                                  {item.book.author && (
                                    <Text
                                      size='poppins14'
                                      variant='primary-black-text'
                                      alignSelf='end'
                                    >
                                      Author:{' '}
                                      {`${item.book.author.first_name} ${item.book.author.last_name}`}
                                    </Text>
                                  )}
                                </>
                              )}

                            {item.banner_type === BannerTypes.ADVERTISEMENT &&
                              item.advertisement && (
                                <Text
                                  size='poppins14'
                                  variant='primary-black-text'
                                  alignSelf='end'
                                >
                                  Advertisement: {item.advertisement.title}
                                </Text>
                              )}

                            {item.title && (
                              <Text
                                size='poppins14'
                                variant='primary-black-text'
                                alignSelf='end'
                              >
                                Title: {item.title}
                              </Text>
                            )}
                          </Flex>
                        </Flex>
                      )
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Flex>
            )}
          </Droppable>
        </DragDropContext>
      </Flex>
    </Flex>
  )
}

export default BannersSummary
