import { Flex, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { colors } from 'shared/style/colors'
import { FW } from 'shared/style/fw'
import AuthService from 'services/auth'

const LogoutButton = ({ navElement }) => {
  const { inactive, name } = navElement
  const [fill, setFill] = useState(colors['primary-black-text'])
  const [fontColor, setFontColor] = useState('primary-black-text')
  const navigate = useNavigate()

  const handleLogout = () => {
    AuthService.removeCredentials()
    navigate('/sign-in')
  }

  const handleStartHover = () => {
    setFill(colors['button-hover'])
    setFontColor('button-hover')
  }

  const handleEndHover = () => {
    setFill(colors['primary-black-text'])
    setFontColor('primary-black-text')
  }

  return (
    <Flex
      maxW='90px'
      p='12px'
      align='center'
      justify='center'
      direction='column'
      gap='4px'
      cursor='pointer'
      onClick={handleLogout}
      onMouseEnter={handleStartHover}
      onMouseLeave={handleEndHover}
    >
      <Flex w='24x' h='24px' align='center' justify='center' pos='relative'>
        {inactive(fill)}
      </Flex>

      <Text
        size='poppins12'
        variant={fontColor}
        lineHeight='24px'
        fontWeight={FW[400]}
        transition='all 0.4s ease'
      >
        {name}
      </Text>
    </Flex>
  )
}

export default LogoutButton
