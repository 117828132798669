import React, { useEffect } from 'react'
import useSearchQuery from 'hooks/useSearchQuery'
import { useNavigate } from 'react-router-dom'
import { Flex } from '@chakra-ui/react'
import InfoElement from 'screens/Comments/InfoElement/InfoElement'
import { TabNavigation } from '../../../components/UI/TabNavigation'
import { SECTIONS_TABS } from 'constants/sections'
import ListPanel from '../OldSections'
import NewSections from '../NewSections'

const SectionsLayout = () => {
  const { active, create, manage } = useSearchQuery()
  const navigate = useNavigate()

  const isContentCreation = create === 'yes'
  const isManageContent = manage === 'yes'

  const isContentWorker = isContentCreation || isManageContent

  const isOldLists = active === SECTIONS_TABS.lists.query
  const isNewSections = active === SECTIONS_TABS.sections.query

  useEffect(() => {
    if (!Object.keys(SECTIONS_TABS).includes(active)) {
      navigate('/sections')
    }
  }, [active, navigate])

  return (
    <Flex
      w='100%'
      h='100%'
      bg='linear-gradient(135deg, #F3F1FF 0%, #F0FBFF 100%)'
      position='relative'
      direction='column'
      overflow='hidden'
    >
      <Flex
        mt='8px'
        direction='column'
        pr='34px'
        gap='12px'
        h='100%'
        w='100%'
        overflow='hidden'
      >
        {!isContentWorker && (
          <Flex w='100%'>
            <TabNavigation
              buttons={Object.entries(SECTIONS_TABS)}
              active={active || ''}
            />
          </Flex>
        )}

        {!active && (
          <InfoElement
            title='Please, select the tab you need'
            image='/images/hero/denied.png'
          />
        )}

        {isOldLists && <ListPanel />}

        {isNewSections && <NewSections />}
      </Flex>
    </Flex>
  )
}

export default SectionsLayout
