import {
  Button,
  Flex,
  FormControl,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { MdClear } from 'react-icons/md'
import { colors } from 'shared/style/colors'

const ChapterIdSelector = ({
  searchId,
  handleSearchInput,
  handleAddById,
  error,
}) => {
  return (
    <Flex
      mb='24px'
      gap='12px'
      w='fit-content'
      minW='300px'
      justify='center'
      pos='relative'
    >
      <FormControl minW='450px'>
        <Text mb='12px' size='poppins16normal' variant='primary-black-text'>
          Id Search
        </Text>

        <InputGroup>
          <Input
            type='text'
            placeholder='Search...'
            variant='loginField'
            value={searchId}
            onChange={e => handleSearchInput(e.target.value)}
          />

          {searchId && searchId.length > 0 && (
            <InputRightElement right='18px' top='14px' w='24px' h='24px'>
              <Icon
                as={MdClear}
                fill={colors['primary-violet-100']}
                w='24px'
                h='24px'
                cursor='pointer'
                onClick={() => handleSearchInput('')}
              />
            </InputRightElement>
          )}
        </InputGroup>
      </FormControl>

      <Button
        type='button'
        variant='outlineButton'
        w='fit-content'
        p='0 24px'
        minH='54px'
        isDisabled={!searchId}
        onClick={handleAddById}
        alignSelf='end'
      >
        Add
      </Button>

      <Text
        size='poppins14'
        fontWeight={400}
        letterSpacing='-0.01em'
        color={colors['primary-violet-100']}
        lineHeight='20px'
        mb='-20px'
        pos='absolute'
        bottom={0}
        left={0}
      >
        {error}
      </Text>
    </Flex>
  )
}

export default ChapterIdSelector
