import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import ArrowIcon from 'components/UI/Icons/ArrowIcon'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BannersContent from 'screens/Sections/NewSections/components/SectionsContent/SectionItem/BannersContent'
import BooksContent from 'screens/Sections/NewSections/components/SectionsContent/SectionItem/BooksContent'
import GenresContent from 'screens/Sections/NewSections/components/SectionsContent/SectionItem/GenresContent'
import RemoveSectionModal from 'screens/Sections/NewSections/components/SectionsContent/SectionItem/RemoveSectionModal'
import TagsContent from 'screens/Sections/NewSections/components/SectionsContent/SectionItem/TagsContent'
import { colors } from 'shared/style/colors'

const SectionModule = ({ item, isPreview }) => {
  const [hover, setHover] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()

  const handleManageSection = () => {
    navigate(`${window.location.pathname}?active=sections&manage=yes`, {
      state: {
        item,
      },
    })
  }

  const toggleContent = useCallback(() => {
    setIsExpanded(curr => !curr)
  }, [])

  return (
    <Flex border='none' w='100%'>
      <Flex
        bg='white'
        w='100%'
        borderRadius='8px'
        direction='column'
        border={
          hover || isExpanded
            ? `1px solid ${colors['primary-violet-100']}`
            : `1px solid ${colors['primary-gray-30']}`
        }
      >
        <Flex
          _hover={{
            bg: 'white',
          }}
          p='8px 44px'
          m='0'
          borderRadius='8px'
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={toggleContent}
          outline='none'
          _focus={{
            boxShadow: 'none',
          }}
        >
          <Flex direction='column' flex={1} gap='12px'>
            <Heading
              size='poppins20'
              variant='primary-black-text'
              color={
                hover || isExpanded
                  ? colors['primary-blue-100']
                  : colors['primary-black-text']
              }
              lineHeight='38px'
              letterSpacing='0'
              textAlign='left'
              fontWeight={400}
              fontSize='16px'
              outline='none'
            >
              Slug: <b>{item.slug}</b> | Place: <b>{item.type}</b> | Content
              type: <b>{item.contentType}</b>
            </Heading>

            <Flex w='100%' gap='12px'>
              <Flex
                w='50%'
                direction='column'
                alignSelf='flex-start'
                borderRight='1px solid #E2E8F0'
              >
                <Flex gap='20px' align='center'>
                  <Text
                    size='poppins16normal'
                    variant='primary-black-text'
                    whiteSpace='nowrap'
                  >
                    Section title:
                  </Text>

                  <Heading
                    size='poppins20'
                    variant='primary-black-text'
                    lineHeight='24px'
                    letterSpacing='0'
                    textAlign='left'
                  >
                    {item.title}
                  </Heading>
                </Flex>

                {isExpanded &&
                  item.titleTranslates.map(item => {
                    return (
                      <Flex key={item.id} gap='20px' align='flex-start'>
                        <Flex gap='4px' align='flex-start'>
                          <Image
                            w='31px'
                            h='22px'
                            src={`/images/flags/${item.locale}.png`}
                            alt={item.locale}
                          />

                          <Text
                            size='poppins18'
                            variant={'primary-black-text'}
                            lineHeight='24px'
                            fontWeight={400}
                            maxW='438px'
                            minW='fit-content'
                            overflow='hidden'
                            textOverflow='ellipsis'
                            whiteSpace='nowrap'
                          >
                            {item.locale}
                          </Text>
                        </Flex>

                        <Heading
                          size='poppins20'
                          variant='primary-black-text'
                          lineHeight='24px'
                          letterSpacing='0'
                          textAlign='left'
                        >
                          {item.value}
                        </Heading>
                      </Flex>
                    )
                  })}
              </Flex>

              <Flex w='50%' alignSelf='flex-start' direction='column'>
                <Flex gap='20px' align='center' alignSelf='flex-start'>
                  <Text
                    size='poppins16normal'
                    variant='primary-black-text'
                    whiteSpace='nowrap'
                    alignSelf='flex-start'
                  >
                    Section description:
                  </Text>

                  <Heading
                    size='poppins20'
                    variant='primary-black-text'
                    lineHeight='24px'
                    letterSpacing='0'
                    textAlign='left'
                  >
                    {item.description || ''}
                  </Heading>
                </Flex>

                {isExpanded &&
                  item.descriptionTranslates.map(item => {
                    return (
                      <Flex key={item.id} gap='20px' align='flex-start'>
                        <Flex gap='4px' align='center'>
                          <Image
                            w='31px'
                            h='22px'
                            src={`/images/flags/${item.locale}.png`}
                            alt={item.locale}
                          />

                          <Text
                            size='poppins18'
                            variant={'primary-black-text'}
                            lineHeight='24px'
                            fontWeight={400}
                            maxW='438px'
                            minW='fit-content'
                            overflow='hidden'
                            textOverflow='ellipsis'
                            whiteSpace='nowrap'
                          >
                            {item.locale}
                          </Text>
                        </Flex>

                        <Heading
                          size='poppins20'
                          variant='primary-black-text'
                          lineHeight='24px'
                          letterSpacing='0'
                          textAlign='left'
                        >
                          {item.value}
                        </Heading>
                      </Flex>
                    )
                  })}
              </Flex>
            </Flex>
          </Flex>

          {isExpanded ? (
            <Box
              w='44px'
              h='44px'
              p='10px'
              bg={colors['primary-violet-10']}
              borderRadius='50%'
            >
              <Box transform='rotate(180deg)'>
                <ArrowIcon
                  fill={
                    hover
                      ? colors['button-hover']
                      : colors['primary-violet-100']
                  }
                />
              </Box>
            </Box>
          ) : (
            <Box
              w='44px'
              h='44px'
              p='10px'
              bg={colors['primary-violet-10']}
              borderRadius='50%'
            >
              <Box>
                <ArrowIcon
                  fill={
                    hover
                      ? colors['button-hover']
                      : colors['primary-violet-100']
                  }
                />
              </Box>
            </Box>
          )}
        </Flex>

        {isExpanded && (
          <Flex px='44px'>
            <Flex
              bg='white'
              w='100%'
              borderRadius='8px'
              direction='column'
              gap='8px'
            >
              <Flex gap='36px' direction='column' mb='24px'>
                {item.sectionBooks.length > 0 && (
                  <BooksContent books={item.sectionBooks} />
                )}

                {item.sectionBanners.length > 0 && (
                  <BannersContent banners={item.sectionBanners} />
                )}

                {item.sectionGenres.length > 0 && (
                  <GenresContent genres={item.sectionGenres} />
                )}

                {item.sectionTags.length > 0 && (
                  <TagsContent tags={item.sectionTags} />
                )}
              </Flex>

              {!isPreview && (
                <Flex gap='23px' alignSelf='end'>
                  <Button
                    type='button'
                    variant='outlineButton'
                    w='204px'
                    borderColor={`${colors['light-red-700']} !important`}
                    color={`${colors['light-red-700']} !important`}
                    _hover={{
                      borderColor: `${colors['light-text-text-danger']} !important`,
                      color: `${colors['light-text-text-danger']} !important`,
                    }}
                    onClick={onOpen}
                  >
                    Remove
                  </Button>

                  <Button
                    type='button'
                    variant='defaultButton'
                    w='204px'
                    onClick={handleManageSection}
                  >
                    {'Manage'}
                  </Button>
                </Flex>
              )}
            </Flex>

            {isOpen && <RemoveSectionModal onClose={onClose} section={item} />}
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default SectionModule
