import React, { memo } from 'react'
import { useField } from 'formik'
import styles from './styles.module.scss'
import classNames from 'classnames'

const FormField = ({ name, label, ...props }) => {
  const [field, meta] = useField({ name })

  return (
    <div className={styles.fieldContainer}>
      <label htmlFor={name} className={styles.label}>
        {label}:
      </label>
      <input
        id={name}
        name={name}
        className={classNames(styles.field, {
          [styles.fieldError]: meta.error && meta.touched,
        })}
        {...field}
        {...props}
      />
      {meta.error && meta.touched && (
        <p className={styles.error}>{meta.error}</p>
      )}
    </div>
  )
}

export default memo(FormField)
