import React, { useCallback, useState } from 'react'
import Selection from '../Selection'
import { Accordion, Flex, useToast } from '@chakra-ui/react'
import { baseScrollBar } from 'constants/scrollbar'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import BaseSettings from '../BaseSettings'
import { useMutation } from '@apollo/client'
import { CREATE_SCREEN, GET_ALL_SCREENS } from 'services/screens/graphql'
import { API_V2 } from 'constants/apollo'
import SectionsSelector from '../SectionsSelector'
import { useNavigate } from 'react-router-dom'

const CreateScreen = () => {
  const [type, setType] = useState('')
  const [title, setTitle] = useState('')
  const [loading, setLoading] = useState(false)
  const [isDefault, setIsDefault] = useState(false)
  const [selectedSections, setSelectedSections] = useState([])
  const toast = useToast()
  const navigate = useNavigate()

  const toggleScreenType = useCallback(type => {
    setType(type)
  }, [])

  const handleInputTitle = useCallback(e => {
    setTitle(e.target.value)
  }, [])

  const toggleDefault = useCallback(() => {
    setIsDefault(curr => !curr)
  }, [])

  const handleAddSection = useCallback(section => {
    setSelectedSections(curr => [...curr, section])
  }, [])

  const handleRemoveSection = useCallback(section => {
    setSelectedSections(curr => {
      const remain = curr.filter(item => section.id !== item.id)

      return remain
    })
  }, [])

  const handleOnDragSection = useCallback(
    async result => {
      const { source, destination } = result

      if (!destination || source.index === destination.index) return

      const items = [...(selectedSections || [])]
      const [reorderedItem] = items.splice(source.index, 1)
      items.splice(destination.index, 0, reorderedItem)

      setSelectedSections(items)
    },
    [selectedSections],
  )

  const [createScreen] = useMutation(CREATE_SCREEN, {
    refetchQueries: [GET_ALL_SCREENS],
  })

  const handleSubmit = async () => {
    if (!type || !title.trim()) {
      return
    }

    setLoading(true)
    try {
      const screenVariables = {
        screen: {
          title,
          type,
          isDefault,
        },
      }

      if (selectedSections.length > 0) {
        screenVariables.screen.sections = selectedSections.map(item => ({
          id: item.id,
        }))
      }

      const {
        data: { screen },
      } = await createScreen({
        variables: screenVariables,
        context: {
          clientName: API_V2,
        },
      })

      if (screen.id) {
        toast({ title: 'Created', status: 'success', isClosable: true })
        navigate(`${window.location.pathname}?active=screens&page=0&slug`)
      } else {
        throw new Error('error')
      }
    } catch (e) {
      toast({
        title: 'Failed',
        description: `Failed to create screen ${e.message}`,
        status: 'error',
        isClosable: true,
      })
    } finally {
      setLoading(false)
    }
  }

  const handleClearData = useCallback(() => {
    setTitle('')
    setType('')
    setIsDefault(false)
    setSelectedSections([])
  }, [])

  return (
    <Flex p='10px 20px 0' direction='column' overflow='hidden' flex={1}>
      {loading && <LoadingSpinner />}

      <Accordion
        allowMultiple
        w='100%'
        display='flex'
        flexDirection='column'
        gap='12px'
        overflow='hidden'
        h='100%'
      >
        <Flex
          direction='column'
          overflow='auto'
          sx={baseScrollBar}
          gap='12px'
          pb='30px'
          h='100%'
        >
          <Selection
            place={type}
            title={title}
            isDefault={isDefault}
            handleSubmit={handleSubmit}
            selectedSections={selectedSections}
            handleClearData={handleClearData}
            handleRemoveSection={handleRemoveSection}
            handleOnDragSection={handleOnDragSection}
            canUpdateData
          />

          <BaseSettings
            place={type}
            title={title}
            toggleScreenType={toggleScreenType}
            handleInputTitle={handleInputTitle}
            isDefault={isDefault}
            toggleDefault={toggleDefault}
          />

          <SectionsSelector
            selectedSections={selectedSections}
            handleAddSection={handleAddSection}
          />
        </Flex>
      </Accordion>
    </Flex>
  )
}

export default CreateScreen
