export const COVER_FORMATS = 'png jpeg jpg'
const ROUND_CONST = 2

const BYTE_CONST = 0.00000095367432

export class ImageValidator {
  constructor(file) {
    this.file = file
    this.isValid = true
    this.url = URL.createObjectURL(file)
    this.size = (file.size * BYTE_CONST).toFixed(ROUND_CONST)
  }

  checkMaxSize(size) {
    const isValid = this.file.size < size

    return isValid
  }

  checkImageFormat() {
    const isImage = this.file.type.includes('image/')

    if (!isImage) {
      return isImage
    }

    const isValid = COVER_FORMATS.includes(this.file.type.replace('image/', ''))

    return isValid
  }
}
