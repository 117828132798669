import { Flex } from '@chakra-ui/react'
import { TabButton } from './TabButton'

const MAX_WIDTH = 100

export const TabNavigation = ({ buttons, active }) => {
  return (
    <Flex w='100%' align='center' direction='column' gap='24px'>
      <Flex gap='12px' w='100%'>
        {buttons.map(([, value], _ind, all) => {
          const width = `${MAX_WIDTH / all.length}%`
          const isActive = value.query === active

          return (
            <TabButton
              key={value.id}
              button={value}
              width={width}
              isActive={isActive}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}
