import { colors } from '../colors'

export const webKitStyle = {
  webkitBoxShadow: `0 0 0 30px ${colors['primary-violet-10']} inset !important`,
  boxShadow: `0 0 0 30px ${colors['primary-violet-10']} inset !important`,
}

export const autofillStyle = {
  webkitBoxShadow: '0 0 0 30px #F1E9FC inset !important',
  boxShadow: '0 0 0 30px white inset !important',
}

export const loginField = {
  field: {
    padding: '8px 18px',
    width: '100%',
    height: '54px',
    fontSize: '18px',
    lineHeight: '32px',
    letterSpacing: '-0.018px',
    paddingVertical: 0,
    borderRadius: '8px',
    backgroundColor: colors['primary-violet-10'],
    color: colors['primary-black-text'],
    border: `1px solid transparent`,
    fontFamily: `'Poppins', sans-serif`,
    _hover: {
      outline: 'none',
    },
    _placeholder: {
      color: colors['primary-violet-60'],
      fontFamily: `'Poppins', sans-serif`,
      fontSize: '18px',
      letterSpacing: '-0.018px',
    },
    _autofill: webKitStyle,
    _focus: {
      outline: 'none',
      borderColor: colors['primary-violet-100'],
    },
  },
}

export const loginFieldError = {
  field: {
    ...loginField.field,
    border: `1px solid ${colors['light-text-text-danger']}`,
  },
}

export const titleField = {
  field: {
    ...loginField.field,
    height: '72px',
    _focus: {
      ...loginField.field._focus,
      caretColor: colors['primary-violet-100'],
    },
    _placeholder: {
      ...loginField.field._placeholder,
      lineHeight: '32px',
      color: colors['primary-gray-70'],
    },
  },
}

export const titleFieldError = {
  field: {
    ...titleField.field,
    border: `1px solid ${colors['light-text-text-danger']}`,
  },
}

export const tagField = {
  field: {
    padding: 0,
    height: '48px',
    fontSize: '18px',
    lineHeight: '48px',
    letterSpacing: '-0.018px',
    borderRadius: '8px',
    backgroundColor: colors['primary-violet-10'],
    color: colors['primary-black-text'],
    fontFamily: `'Poppins', sans-serif`,
    _hover: {
      outline: 'none',
    },
    _placeholder: {
      color: colors['primary-gray-70'],
      fontFamily: `'Poppins', sans-serif`,
      fontSize: '18px',
      letterSpacing: '-0.018px',
    },
    _autofill: webKitStyle,
    _focus: {
      outline: 'none',
    },
  },
}
