/* eslint-disable no-magic-numbers */
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Image as ChakraImage,
  Text,
  keyframes,
  useToast,
} from '@chakra-ui/react'
import React, { useRef, useState } from 'react'
import { colors } from 'shared/style/colors'
import { baseScrollBar } from 'constants/scrollbar'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import LocalesSelector from '../LocalesSelector'
import ErrorToast from 'screens/Books/components/BookCover/ErrorToast'
import AuthAlert from 'screens/Authorization/components/AuthAlert'
import { uploadBannerLocalCover } from 'api/banners'
import { useMutation } from '@apollo/client'
import {
  GET_BANNERS_ADMIN,
  UPDATE_BANNERS_LOCALE,
} from 'services/banners/graphql'

const UploadBannerLocal = ({
  onClose,
  selectedLocales,
  aspectRatio,
  banner_id,
}) => {
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState(null)
  const [preview, setPreview] = useState('')
  const [locale, setLocale] = useState(null)
  const inputRef = useRef(null)
  const toast = useToast()

  const [updateBannersFields] = useMutation(UPDATE_BANNERS_LOCALE, {
    refetchQueries: [GET_BANNERS_ADMIN],
  })

  const clearImage = () => {
    setFile(null)
    setPreview(null)
  }

  const addLocale = newLocale => {
    setLocale(newLocale)
    clearImage()
  }

  const handleDragOver = event => {
    event.preventDefault()
  }

  const handleDragLeave = event => {
    event.preventDefault()
  }

  const handleValidateAspectRatio = async (dataURL, aspect) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onerror = reject

      img.onload = () => {
        const imgAspect = (img.width / img.height).toFixed(3)

        if (Number(imgAspect) === aspect) {
          resolve(true)
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(false)
        }
      }

      img.src = dataURL
    }).catch(exception => {
      return exception
    })
  }

  const addFile = async file => {
    const path = URL.createObjectURL(file)

    const result = await handleValidateAspectRatio(path, aspectRatio)

    if (!result) {
      const toastId = 'wrongImageAspectRatioError'

      if (!toast.isActive(toastId)) {
        toast({
          id: toastId,
          position: 'top',
          render: ({ onClose }) => (
            <Box mt='61px'>
              <ErrorToast
                onClose={onClose}
                title='Aspect ratio error'
                reason={`The uploaded file has unsupported aspect ratio with origin`}
              />
            </Box>
          ),
          duration: 5000,
        })
      }

      return
    }

    setPreview(path)
    setFile(file)
  }

  const handleDrop = async event => {
    event.preventDefault()

    const file = event.dataTransfer.files[0]

    if (!file.type.includes('image/')) {
      return
    }

    await addFile(file)
  }

  const addImage = async e => {
    if (e.target.files) {
      const file = e.target.files[0]

      if (!file.type.includes('image/')) {
        return
      }

      await addFile(file)
    }
  }

  const hangleSubmit = async () => {
    setLoading(true)
    const formdata = new FormData()
    formdata.append('file', file)

    try {
      const { data } = await uploadBannerLocalCover({
        formdata,
        banner_id,
        locale: locale.code,
      })

      const {
        data: { item },
      } = await updateBannersFields({
        variables: {
          banner: {
            banner_id,
            locale: locale.code,
            cover_url: data.cdn_link,
          },
        },
      })

      if (item.banner_id === banner_id) {
        toast({
          title: `Banner was succesfully uploaded for ${locale.code}`,
          status: 'success',
          isClosable: true,
          duration: 15000,
          position: 'top',
        })
      } else {
        throw new Error('Failed to upload')
      }

      clearImage()
      setLocale()
      onClose()
    } catch (error) {
      toast({
        position: 'top',
        render: ({ onClose }) => (
          <Box mt='61px'>
            <AuthAlert
              onClose={onClose}
              title='Failed'
              reason={error.message}
            />
          </Box>
        ),
        duration: 5000,
      })
    } finally {
      setLoading(false)
    }
  }

  const appear = keyframes({
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const animation = `${appear} 0.3s ease-in-out 1`

  return (
    <>
      <Box
        pos='fixed'
        zIndex={1400}
        top={0}
        left={0}
        width='100vw'
        height='100vh'
        background='rgba(0, 0, 0, 0.48);'
        blendMode='multiply'
        opacity={1}
      />

      <Flex
        pos='fixed'
        zIndex={1500}
        top={0}
        left={0}
        width='100vw'
        height='100vh'
        justify='center'
        pt='61px'
        overflow='auto'
        sx={baseScrollBar}
      >
        <Flex
          bg={colors.white}
          pos='relative'
          p='72px 85px 44px'
          borderRadius='16px'
          border={`1px solid ${colors.stroke}`}
          w='600px'
          h='fit-content'
          animation={animation}
          boxShadow='0px 5px 15px 0px #241C5833'
        >
          <ChakraImage
            pos='absolute'
            src='/images/close-circle.svg'
            alt='close icon'
            top='24px'
            right='24px'
            cursor='pointer'
            onClick={onClose}
          />

          {loading && <LoadingSpinner />}

          <Flex direction='column' gap='24px'>
            <LocalesSelector
              selectedLocales={selectedLocales}
              locale={locale}
              addLocale={addLocale}
            />

            {file && preview && (
              <Flex
                width='100%'
                p='20px'
                maxH='35vh'
                pos='relative'
                borderRadius='8px'
                bg={colors.gray4b}
              >
                <ChakraImage
                  pos='absolute'
                  src='/images/close-square.svg'
                  alt='close icon'
                  top='24px'
                  right='24px'
                  cursor='pointer'
                  onClick={clearImage}
                />
                <ChakraImage src={preview} alt='preview' />
              </Flex>
            )}

            {!file && locale && (
              <FormLabel
                m='0'
                cursor='pointer'
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <Flex
                  borderRadius='8px'
                  p='58px 36px'
                  m='30px auto 20px'
                  maxW='180px'
                  border={`1px dashed ${colors['primary-violet-100']}`}
                  direction='column'
                  align='center'
                  background='rgba(255, 255, 255, 0.50)'
                >
                  <ChakraImage
                    src='/images/upload.svg'
                    alt='upload icon'
                    mb='23px'
                  />

                  <Text
                    variant='primary-black-text'
                    size='poppins16'
                    fontWeight={600}
                    lineHeight='22px'
                    letterSpacing='-0.016px'
                    textAlign='center'
                    mb='11px'
                  >
                    Select file
                    <br />
                    or drag it here
                  </Text>
                </Flex>

                <input
                  ref={inputRef}
                  type='file'
                  id='file-input'
                  accept='image/jpg, image/jpeg, image/png'
                  onChange={addImage}
                  defaultValue=''
                />
              </FormLabel>
            )}

            <Flex gap='23px'>
              <Button variant='outlineButton' onClick={onClose} w='204px'>
                Cancel
              </Button>

              <Button
                variant='defaultButton'
                onClick={hangleSubmit}
                isDisabled={!file || !locale}
                w='204px'
              >
                Confirm
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default UploadBannerLocal
