import { compose } from '@reduxjs/toolkit'
import withApollo from './with-apollo'
import withChakra from './with-chakra'
import withRedux from './with-redux'
import withRouter from './with-router'

/**
 * @hoc Инициализирующая логика приложения
 * @remark Содержит:
 * - логику подключения к API (withApollo)
 * - логику инициализации роутера (withRouter)
 */
export const withHocs = compose(withRouter, withRedux, withApollo, withChakra)
