import { Checkbox, Flex, FormControl, FormLabel } from '@chakra-ui/react'
import React from 'react'

const ModerationOption = ({ title, value, handleToggle, id }) => {
  return (
    <Flex w='100%'>
      <FormControl
        w='100%'
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        gap='8px'
        align='center'
      >
        <Checkbox
          size='lg'
          colorScheme='purple'
          isChecked={value}
          id={`force-${id}`}
          display='flex'
          onChange={handleToggle}
        />

        <FormLabel
          htmlFor={`force-${id}`}
          whiteSpace='nowrap'
          cursor='pointer'
          mr='0'
          mb='0'
          flex={1}
        >
          {title}
        </FormLabel>
      </FormControl>
    </Flex>
  )
}

export default ModerationOption
