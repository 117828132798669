import React, { memo } from 'react'
import SearchBar from '../../../../components/UI/SearchBar'
import styles from './styles.module.scss'
import { Button } from 'react-bootstrap'
import BannersList from '../BannersList'

const BannersPanelLayout = ({
  banners,
  search,
  handleBannerCreateModalOpen,
  handleSearchChange,
}) => (
  <div className={styles.bannersContainer}>
    <div className={styles.bannersControlsContainer}>
      <div className={styles.bannersControls}>
        <SearchBar search={search} onChange={handleSearchChange} />
        <Button variant='primary' onClick={handleBannerCreateModalOpen}>
          Create banner
        </Button>
      </div>
    </div>

    <BannersList banners={banners} />
  </div>
)

export default memo(BannersPanelLayout)
