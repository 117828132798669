import React, { memo } from 'react'
import {
  ButtonGroup,
  ButtonToolbar,
  Form,
  Modal,
  Button,
} from 'react-bootstrap'

const AppendSurveyModal = ({
  updatedList,
  onHide,
  isShow,
  value,
  onChange,
  onSubmit,
}) => (
  <Modal show={isShow} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>
        Add survey to &quot;
        {updatedList.title}&quot; list
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form.Control
        type='text'
        placeholder='Enter survey link'
        value={value}
        onChange={onChange}
      />
    </Modal.Body>
    <Modal.Footer>
      <ButtonToolbar>
        <ButtonGroup className='me-2'>
          <Button variant='primary' onClick={onHide}>
            Cancel
          </Button>
        </ButtonGroup>
        <ButtonGroup className='me-2'>
          <Button variant='primary' onClick={onSubmit}>
            Save
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    </Modal.Footer>
  </Modal>
)

export default memo(AppendSurveyModal)
