import React, { memo, useCallback } from 'react'
import { Accordion, Button } from 'react-bootstrap'
import './styles.scss'
import SurveyItem from '../SurveyItem'
import { Flex, Text, useToast } from '@chakra-ui/react'

const SurveySection = ({
  list,
  deleteList,
  showAppendSurveyModal,
  deleteListSurveyLink,
  moveSurveys,
  updateListItemsOrder,
}) => {
  const toast = useToast()
  const handleCopyBookIdClipboard = useCallback(
    text => {
      navigator.clipboard.writeText(text)
      toast({
        title: `Book Id copied to clipboard!`,
        status: 'success',
        isClosable: true,
        duration: 1000,
        position: 'top',
      })
    },
    [toast],
  )

  return (
    <Accordion.Item eventKey={list.list_id}>
      <Accordion.Header>
        <div style={{ flex: '1' }}>
          <Flex gap='16px'>
            <Text as='span' size='poppins18' variant='primary-black-text'>
              list_id:
            </Text>
            <Text
              as='span'
              size='poppins16normal'
              variant='primary-black-text'
              onClick={e => {
                e.stopPropagation()
                handleCopyBookIdClipboard(list.list_id)
              }}
              cursor='copy'
            >
              {list.list_id}
            </Text>
          </Flex>
          <br />
          Title: {list.title}
          <br />
          <div>Description: {list.description}</div>
          <small style={{ textTransform: 'capitalize' }}>
            Type: {list.list_type}
          </small>
        </div>
        <Button
          variant='danger'
          onClick={deleteList}
          style={{ marginTop: 0, marginRight: 30 }}
        >
          Delete list
        </Button>
      </Accordion.Header>
      <Accordion.Body>
        <div className='survey-link-list'>
          {list.survey_links.map((link, index) => (
            <SurveyItem
              key={list.list_id + link}
              link={link}
              deleteListSurveyLink={deleteListSurveyLink}
              index={index}
              moveSurveys={moveSurveys}
              list={list}
              updateListItemsOrder={updateListItemsOrder}
            />
          ))}
        </div>
        <Button onClick={showAppendSurveyModal}>Add survey</Button>
      </Accordion.Body>
    </Accordion.Item>
  )
}
export default memo(SurveySection)
