import { Flex, Image, Select, Text } from '@chakra-ui/react'
import React from 'react'

const ChapterAudioOption = ({
  file,
  availableChapters,
  handleAddChapter,
  chapter,
  handleRemoveChapter,
}) => {
  const [, value] = file

  return (
    <Flex
      gap='4px'
      p='10px 12px'
      align='center'
      borderBottom='1px solid black'
      w='100%'
      justify='space-between'
      pr='30px'
    >
      <Text
        size='poppins18'
        variant='primary-black-text'
        fontWeight={400}
        lineHeight='32px'
        letterSpacing='-0.018px'
        maxW='800px'
        w='800px'
        overflow='hidden'
        textOverflow='ellipsis'
        whiteSpace='nowrap'
      >
        {value.name}
      </Text>

      {chapter && (
        <Flex gap='4px' p='10px 12px' align='center'>
          <Text
            size='poppins14'
            variant='primary-black-text'
            fontWeight={400}
            lineHeight='32px'
            letterSpacing='-0.018px'
            cursor='pointer'
            w='fit-content'
            whiteSpace='nowrap'
            pr='12px'
          >
            {chapter.chapter_order}. {chapter.chapter_id}
          </Text>

          <Image
            w='32px'
            h='32px'
            p='5px'
            src={`/images/close-circle.svg`}
            alt={'remove'}
            cursor='pointer'
            onClick={() => handleRemoveChapter(chapter)}
          />
        </Flex>
      )}

      {!chapter && (
        <Select
          w='200px'
          placeholder='Select chapter'
          onChange={e =>
            handleAddChapter({ ...JSON.parse(e.target.value), file: value })
          }
        >
          {availableChapters.map(chapt => (
            <option value={JSON.stringify(chapt)} key={chapt.chapter_id}>
              {chapt.chapter_order}. {chapt.chapter_id}
            </option>
          ))}
        </Select>
      )}
    </Flex>
  )
}

export default ChapterAudioOption
