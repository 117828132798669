import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  createAdvertisement,
  fetchAdvertisements,
} from '../../../../api/advertisements'

export const fetchAdvertisementsThunk = createAsyncThunk(
  'advertisements/fetch',
  async () => {
    try {
      const res = await fetchAdvertisements()
      return res.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)

export const createAdvertisementThunk = createAsyncThunk(
  'advertisements/create',
  async advertisement => {
    const res = await createAdvertisement(advertisement)
    const { advertisement_id } = res.data
    return { advertisement_id, ...advertisement }
  },
)
