import { useQuery } from '@apollo/client'
import { Button, Flex, Input, useToast } from '@chakra-ui/react'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import { API_V2 } from 'constants/apollo'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import InfoElement from 'screens/Comments/InfoElement/InfoElement'
import ScreenTypeSelector from 'screens/Screens/NewScreens/component/ScreenTypeSelector'
import { GET_SECTIONS } from 'services/sections/graphql'
import CreateSection from '../components/CreateSection'
import { useNavigate } from 'react-router-dom'
import useSearchQuery from 'hooks/useSearchQuery'
import SectionsContent from '../components/SectionsContent'
// import ContentTypeSelector from '../components/ContentTypeSelector';
import { debounce } from 'lodash'
import { DEBOUNCE_DELAY } from 'constants/app'
import { colors } from 'shared/style/colors'
import ManageSection from '../components/ManageSection'

const COUNT = 10

const NewSectionsLayout = () => {
  const [place, setPlace] = useState('')
  // const [type, setType] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [, setQuery] = useState('')
  const navigate = useNavigate()
  const { create, page, slug, manage } = useSearchQuery()
  const toast = useToast()

  const isContentCreation = create === 'yes'
  const isManageContent = manage === 'yes'

  const isContentWorker = isContentCreation || isManageContent

  const initialVariables = useMemo(() => {
    const base = {
      pagination: {
        offset: isNaN(page) ? 0 : Number(page) * COUNT,
        count: COUNT,
      },
      section: {},
    }

    if (place) {
      base.section.place = place
    }

    if (slug) {
      base.section.slugId = slug
    }

    return base
  }, [place, page, slug])

  const {
    data: sections,
    loading: isSectionsLoading,
    refetch,
  } = useQuery(GET_SECTIONS, {
    variables: initialVariables,
    context: {
      clientName: API_V2,
    },
    fetchPolicy: 'cache-and-network',
  })

  const handleSearchChange = useCallback(e => {
    setSearchValue(e.target.value)
  }, [])

  const handleSetQuery = useCallback(
    value => {
      setQuery(value)
      navigate(
        `${window.location.pathname}?active=sections&page=0&slug=${value}`,
      )
    },
    [navigate],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchChange = useCallback(
    debounce(value => handleSetQuery(value), DEBOUNCE_DELAY),
    [],
  )

  const toggleScreenType = type => {
    setPlace(type)
  }

  // const toggleSectionType = (type) => {
  //   setType(type)
  // }

  const clearSelections = () => {
    toggleScreenType('')
    // toggleSectionType('')
    setSearchValue('')
  }

  const handleCreateSection = () => {
    navigate(
      `${window.location.pathname}?active=sections${
        isContentWorker ? '&page=0&slug=' : '&create=yes'
      }`,
    )
  }

  const handleChangePage = event => {
    navigate(
      `${window.location.pathname}?active=sections&page=${event.selected}&slug=${slug}`,
    )
  }

  const handleCopyToClipboard = useCallback(
    (place, text) => {
      navigator.clipboard.writeText(text)
      toast({
        title: `${place} - ${text} was copied to clipboard!`,
        status: 'success',
        isClosable: true,
        duration: 2000,
        position: 'top',
      })
    },
    [toast],
  )

  const isNoContent =
    !isSectionsLoading &&
    sections &&
    sections.items.sections.length === 0 &&
    !isContentCreation

  const handleRefetch = () => {
    refetch({
      variables: initialVariables,
      context: {
        clientName: API_V2,
      },
      fetchPolicy: 'cache-and-network',
    })
  }

  useEffect(() => {
    handleRefetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    if (!isContentWorker) {
      debouncedSearchChange(searchValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, isContentWorker])

  return (
    <Flex w='100%' h='100%' direction='column' overflow='hidden'>
      <Flex justify='space-between' align='center' mb='24px'>
        <Flex
          align='center'
          gap='12px'
          visibility={isContentWorker ? 'hidden' : 'visible'}
        >
          <Input
            h='56px'
            minH='56px'
            type='text'
            placeholder='Slug'
            variant='loginField'
            value={searchValue}
            onChange={handleSearchChange}
            _placeholder={{
              color: colors['primary-gray-100'],
            }}
            py='9px'
          />
          <ScreenTypeSelector
            toggleScreenType={toggleScreenType}
            place={place}
          />
          {/* <ContentTypeSelector toggle={toggleSectionType} type={type}/> */}
          <Button
            type='button'
            variant='outlineButton'
            w='fit-content'
            onClick={clearSelections}
          >
            Clear
          </Button>
        </Flex>

        <Button
          type='button'
          variant='defaultButton'
          w='204px'
          onClick={handleCreateSection}
        >
          {isContentWorker ? 'Close' : 'Create Section'}
        </Button>
      </Flex>

      {isSectionsLoading && <LoadingSpinner />}

      {isNoContent && (
        <InfoElement
          title={`No sections data`}
          image='/images/hero/in_review.png'
        />
      )}

      {isContentCreation && <CreateSection />}

      {isManageContent && <ManageSection />}

      {!isContentWorker && sections && sections.items.sections.length > 0 && (
        <SectionsContent
          items={sections.items.sections}
          pageCount={Math.ceil(sections.items.pagination.total / COUNT)}
          handleChangePage={handleChangePage}
          handleCopyToClipboard={handleCopyToClipboard}
          page={page}
          slug={slug}
        />
      )}
    </Flex>
  )
}

export default NewSectionsLayout
