import React, { memo } from 'react'
import BooksListItem from '../BooksListItem'
import { Flex, Text, useMediaQuery } from '@chakra-ui/react'
import ReactPaginate from 'react-paginate'
import { baseScrollBar } from 'constants/scrollbar'

const BooksList = ({
  books,
  handleCopyBookIdClipboard,
  handleBookModalShow,
  handlePageClick,
  pageCount,
}) => {
  const [isDesktop] = useMediaQuery('(min-width: 1580px)')

  return (
    <Flex
      w='100%'
      p='20px 20px 42px'
      direction='column'
      align='center'
      flex={1}
      overflow='hidden'
    >
      <Flex justify='flex-start' mb='20px'>
        {books && pageCount > 1 && (
          <ReactPaginate
            onPageChange={handlePageClick}
            pageRangeDisplayed={10}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel='< '
            nextLabel=' >'
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link'
            nextClassName='page-item'
            nextLinkClassName='page-link'
            breakLabel='...'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            containerClassName='pagination'
            activeClassName='active'
            renderOnZeroPageCount={null}
          />
        )}
      </Flex>

      <Flex
        w={isDesktop ? '1580px' : '1060px'}
        flex={1}
        overflowY='auto'
        sx={baseScrollBar}
        justify='center'
      >
        {books.length === 0 && (
          <Text color='black'>No books by your query</Text>
        )}
        <Flex flexWrap='wrap' gap='20px'>
          {books.map(book => (
            <BooksListItem
              key={book.book_id}
              {...book}
              handleCopyBookIdClipboard={() =>
                handleCopyBookIdClipboard(book.book_id)
              }
              handleBookModalShow={() => {
                handleBookModalShow(book)
              }}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default memo(BooksList)
