import React from 'react'
import ReactDOM from 'react-dom'
import './styles/styles.scss'
import App from 'app'
import 'bootstrap/dist/css/bootstrap.min.css'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import AuthService from 'services/auth'

AuthService.getTokenFromStorage()
AuthService.getRole()

ReactDOM.render(
  // <React.StrictMode>
  <DndProvider backend={HTML5Backend}>
    <App />
  </DndProvider>,
  // </React.StrictMode>,
  document.getElementById('root'),
)
