import { Checkbox, Flex, FormControl, FormLabel } from '@chakra-ui/react'
import React from 'react'

const DefaultSelector = ({ value, handleToggle }) => {
  return (
    <Flex h='100%'>
      <FormControl
        w='100%'
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyItems='center'
        gap='12px'
        h='100%'
      >
        <FormLabel
          htmlFor={`default`}
          whiteSpace='nowrap'
          cursor='pointer'
          m='0'
          h='fit-content'
        >
          Is default
        </FormLabel>

        <Checkbox
          size='lg'
          colorScheme='purple'
          isChecked={value}
          id={`default`}
          display='flex'
          onChange={handleToggle}
          h='fit-content'
        />
      </FormControl>
    </Flex>
  )
}

export default DefaultSelector
