import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import { DEBOUNCE_DELAY } from 'constants/app'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { MdClear } from 'react-icons/md'
import { colors } from 'shared/style/colors'

const AuthorSearch = ({ onChange }) => {
  const [search, setSearch] = useState('')

  const handleSearchInput = e => {
    const searchValue = e ? e.target.value : ''

    setSearch(searchValue)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchChange = useCallback(
    debounce(value => onChange(value), DEBOUNCE_DELAY),
    [],
  )

  useEffect(() => {
    debouncedSearchChange(search)
  }, [search, debouncedSearchChange])

  return (
    <Box mb='24px' w='fit-content' minW='300px'>
      <Text mb='12px' size='poppins16normal' variant='primary-black-text'>
        Author Search
      </Text>

      <InputGroup>
        <Input
          type='text'
          placeholder='Search...'
          variant='loginField'
          value={search}
          onChange={handleSearchInput}
        />

        {search && search.length > 0 && (
          <InputRightElement right='18px' top='12px' w='24px' h='24px'>
            <Icon
              as={MdClear}
              fill={colors['primary-violet-100']}
              w='24px'
              h='24px'
              cursor='pointer'
              onClick={() => handleSearchInput(null)}
            />
          </InputRightElement>
        )}
      </InputGroup>

      <Text size='poppins12' variant='primary-gray-100'>
        sort only fetched books (to sort only by author - set Limit to all)
      </Text>
    </Box>
  )
}

export default AuthorSearch
