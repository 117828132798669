const { gql } = require('@apollo/client')

export const GET_LOCALIZATIONS = gql`
  query getLocalizations($limit: Int) {
    localizations: adminLocalizations(
      limit: $limit
      where: []
      orders: []
      relations: ["lists", "lists.list"]
    ) {
      localization_id
      title
      has_recomendations
      lists {
        list {
          list_id
          list_type
          title
          description
        }
        order_number
      }
    }
  }
`

export const CREATE_LOCALIZATION = gql`
  mutation createlocalization($localization: CreateLocalizationDto!) {
    createLocalization(localization: $localization, relations: ["lists"]) {
      localization_id
      title
      lists {
        order_number
      }
    }
  }
`

export const UPDATE_LOCALIZATION = gql`
  mutation updateLocalization($localization: UpdateLocalizationDto!) {
    updateLocalization(
      localization: $localization
      relations: ["lists", "lists.list"]
    ) {
      localization_id
      title
      lists {
        list {
          list_id
          list_type
          title
          description
        }
        order_number
      }
    }
  }
`

export const DELETE_LOCALIZATION = gql`
  mutation removeLocalization($localization: RemoveLocalizationDto!) {
    removeLocalization(localization: $localization) {
      localization_id
    }
  }
`
