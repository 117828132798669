import { Flex } from '@chakra-ui/react'
import { colors } from 'shared/style/colors'
import RoleLogo from './RoleLogo'
import AuthService from 'services/auth'
import { USER_ROLES } from 'constants/users'
import LogoutButton from './LogoutButton'
import { navigationLogout } from 'constants/navbar'
import MainSection from './MainSection'

const NavigationBar = () => {
  const isUserAdmin = AuthService.role === USER_ROLES.ADMIN

  return (
    <Flex
      h='calc(100vh - 33px)'
      bg={colors.white}
      border={`1px solid ${colors['primary-gray-30']}`}
      borderRadius='8px'
      direction='column'
      justify='space-between'
    >
      <Flex direction='column' justify='space-between'>
        <RoleLogo isUserAdmin={isUserAdmin} />

        <MainSection />
      </Flex>

      <LogoutButton navElement={navigationLogout} />
    </Flex>
  )
}

export default NavigationBar
