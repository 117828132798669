import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react'
import ArrowIcon from 'components/UI/Icons/ArrowIcon'
import { DEBOUNCE_DELAY } from 'constants/app'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { colors } from 'shared/style/colors'
import { debounce } from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_BOOKS_BY_SEARCH } from 'services/book/graphql'
import { BOOKS_LIMIT } from 'screens/Books'
import TitleSearch from 'screens/Books/components/BooksPageLayout/TitleSearch'
import LoadingSpinner from 'components/UI/LoadingSpinner'

const BooksSelector = memo(
  ({ selectedStories, handleAddStory, selectedChapters, isLocalizations }) => {
    const [hover, setHover] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [query, setQuery] = useState('')
    const [visibleBooks, setVisibleBooks] = useState([])

    const [getAllBooks, { data: allBooks, loading: isAllBooksLoading }] =
      useLazyQuery(GET_BOOKS_BY_SEARCH)

    const handleSearchChange = useCallback(value => {
      setSearchValue(value)
    }, [])

    const handleSetQuery = useCallback(value => {
      setQuery(value)
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearchChange = useCallback(
      debounce(value => handleSetQuery(value), DEBOUNCE_DELAY),
      [],
    )

    useEffect(() => {
      debouncedSearchChange(searchValue)
    }, [searchValue, debouncedSearchChange])

    useEffect(() => {
      if (query) {
        getAllBooks({
          variables: {
            deleted: false,
            title: query,
            limit: BOOKS_LIMIT,
          },
        })
      }
    }, [query, getAllBooks])

    useEffect(() => {
      if (allBooks && !isAllBooksLoading) {
        const ignoreList = [
          ...selectedStories.map(item => ({ book_id: item.book_id })),
          ...(isLocalizations ? selectedChapters : []).map(item => ({
            book_id: item.bookId,
          })),
        ]
        setVisibleBooks(
          query
            ? (allBooks.booksSearch ?? []).filter(
                item =>
                  !ignoreList.some(
                    selected => selected.book_id === item.book_id,
                  ),
              )
            : [],
        )
      }
    }, [
      allBooks,
      isAllBooksLoading,
      query,
      selectedStories,
      selectedChapters,
      isLocalizations,
    ])

    return (
      <AccordionItem border='none'>
        {({ isExpanded }) => (
          <Flex
            bg='white'
            w='100%'
            borderRadius='8px'
            direction='column'
            border={
              hover || isExpanded
                ? `1px solid ${colors['primary-violet-100']}`
                : `1px solid ${colors['primary-gray-30']}`
            }
          >
            <AccordionButton
              _hover={{
                bg: 'white',
              }}
              p='8px 44px'
              m='0'
              borderRadius='8px'
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              outline='none'
              _focus={{
                boxShadow: 'none',
              }}
            >
              <Flex direction='column' flex={1} gap='12px'>
                <Heading
                  size='poppins24'
                  variant='primary-black-text'
                  color={
                    hover || isExpanded
                      ? colors['primary-blue-100']
                      : colors['primary-black-text']
                  }
                  lineHeight='38px'
                  letterSpacing='0'
                  textAlign='left'
                >
                  Books
                </Heading>
              </Flex>

              {isExpanded ? (
                <Box
                  p='10px'
                  bg={colors['primary-violet-10']}
                  borderRadius='50%'
                >
                  <Box transform='rotate(180deg)'>
                    <ArrowIcon
                      fill={
                        hover
                          ? colors['button-hover']
                          : colors['primary-violet-100']
                      }
                    />
                  </Box>
                </Box>
              ) : (
                <Box
                  p='10px'
                  bg={colors['primary-violet-10']}
                  borderRadius='50%'
                >
                  <Box>
                    <ArrowIcon
                      fill={
                        hover
                          ? colors['button-hover']
                          : colors['primary-violet-100']
                      }
                    />
                  </Box>
                </Box>
              )}
            </AccordionButton>
            <AccordionPanel>
              {isAllBooksLoading && <LoadingSpinner />}

              <TitleSearch search={searchValue} onChange={handleSearchChange} />

              <Flex gap='10px' wrap='wrap'>
                {visibleBooks.map(item => (
                  <Flex
                    key={item.book_id}
                    h='80px'
                    gap='8px'
                    border={`1px solid ${colors['primary-gray-30']}`}
                    p='8px 12px'
                    boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
                    borderRadius='8px'
                    cursor='pointer'
                    _hover={{
                      bg: 'rgba(36, 28, 88, 0.03)',
                    }}
                    onClick={() => handleAddStory(item)}
                  >
                    <Image
                      src={item.cover_link || '/images/book.png'}
                      alt='book-cover'
                      h='60px'
                      aspectRatio='2/3'
                      objectFit='cover'
                    />

                    <Flex w='100%' gap='10px' direction='column'>
                      <Text
                        size='poppins14'
                        variant='primary-black-text'
                        fontWeight={600}
                      >
                        {item.title}
                      </Text>

                      <Text
                        size='poppins14'
                        variant='primary-black-text'
                        alignSelf='end'
                      >
                        {`${item.creator_id.first_name} ${item.creator_id.last_name}`}
                      </Text>
                    </Flex>
                  </Flex>
                ))}

                {visibleBooks.length === 0 && query && !isAllBooksLoading && (
                  <Text color='black'>No books by your query</Text>
                )}
              </Flex>
            </AccordionPanel>
          </Flex>
        )}
      </AccordionItem>
    )
  },
)

export default BooksSelector
