import axiosApiInstance from 'utils/axios-instance'

export const loginUser = user => axiosApiInstance.post('user/login', user)

export const getUserInfo = user => axiosApiInstance.get('user', user)

export const fetchUsers = () => axiosApiInstance('user/admin')

export const createUser = user => axiosApiInstance.post('user', user)

export const getIsAlreadyExistEmail = email =>
  axiosApiInstance.get(`user/${email}`)
