import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Text,
  Switch,
} from '@chakra-ui/react'
import ArrowIcon from 'components/UI/Icons/ArrowIcon'
import { DEBOUNCE_DELAY } from 'constants/app'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { colors } from 'shared/style/colors'
import { debounce } from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_BOOKS_BY_SEARCH } from 'services/book/graphql'
import { BOOKS_LIMIT } from 'screens/Books'
import TitleSearch from 'screens/Books/components/BooksPageLayout/TitleSearch'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import {
  GET_AUTHOR_CHAPTERS,
  GET_CHAPTER_BY_ID,
} from 'services/chapters/graphql'
import ChapterIdSelector from './ChapterIdSelector'
import { uuidv4Regex } from 'constants/common'

const ChaptersSelector = memo(
  ({
    selectedStories,
    selectedChapters,
    handleAddChapter,
    isLocalizations,
  }) => {
    const [hover, setHover] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [query, setQuery] = useState('')
    const [visibleBooks, setVisibleBooks] = useState([])
    const [visibleChapters, setVisibleChapters] = useState([])
    const [selectedBook, setSelectedBook] = useState(null)
    const [isId, setIsId] = useState(false)
    const [searchId, setSearchId] = useState('')
    const [error, setError] = useState('')

    const [getAllBooks, { data: allBooks, loading: isAllBooksLoading }] =
      useLazyQuery(GET_BOOKS_BY_SEARCH)

    const [getAllChapters, { data: allChapters, loading: isChaptersLoading }] =
      useLazyQuery(GET_AUTHOR_CHAPTERS)

    const [getChapter, { loading: isChapterLoading }] =
      useLazyQuery(GET_CHAPTER_BY_ID)

    const handleSearchChange = useCallback(value => {
      setSearchValue(value)
    }, [])

    const handleSetQuery = useCallback(value => {
      setQuery(value)
    }, [])

    const handleOpenStory = useCallback(item => {
      setSelectedBook(item)
    }, [])

    const handleCloseStory = useCallback(() => {
      setSelectedBook(null)
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearchChange = useCallback(
      debounce(value => handleSetQuery(value), DEBOUNCE_DELAY),
      [],
    )

    const toggleSearch = useCallback(() => {
      setIsId(curr => !curr)
      setError('')

      if (isId) {
        setSearchId('')
      } else {
        setSearchValue('')
      }
    }, [isId])

    const handleIdSearchInput = useCallback(value => {
      setSearchId(value)
      setError('')
    }, [])

    const handleAddById = useCallback(async () => {
      if (uuidv4Regex.test(searchId)) {
        if (selectedChapters.some(item => item.chapter_id === searchId)) {
          setError('Chapter is already selected')

          return
        }

        try {
          const {
            data: { chapter },
          } = await getChapter({
            variables: {
              chapter_id: searchId,
            },
          })

          setError('')
          handleAddChapter({ title: '', book_id: '' }, chapter)
          setSearchId('')
        } catch (error) {
          setError('Can not find chapter with this ID')
        }
      } else {
        setError('Input ID is not valid')
      }
    }, [searchId, selectedChapters, getChapter, handleAddChapter])

    useEffect(() => {
      debouncedSearchChange(searchValue)
    }, [searchValue, debouncedSearchChange])

    useEffect(() => {
      if (query) {
        getAllBooks({
          variables: {
            deleted: false,
            title: query,
            limit: BOOKS_LIMIT,
          },
        })
      }
    }, [query, getAllBooks])

    useEffect(() => {
      if (allBooks && !isAllBooksLoading) {
        setVisibleBooks(
          query
            ? isLocalizations
              ? (allBooks.booksSearch ?? []).filter(
                  item =>
                    !selectedStories.some(
                      selected => selected.book_id === item.book_id,
                    ),
                )
              : allBooks.booksSearch ?? []
            : [],
        )
      }
    }, [allBooks, isAllBooksLoading, isLocalizations, query, selectedStories])

    useEffect(() => {
      if (allChapters && !isChaptersLoading) {
        setVisibleChapters(
          selectedBook
            ? (allChapters.chapters ?? []).filter(
                item =>
                  !selectedChapters.some(
                    selected => selected.chapter_id === item.chapter_id,
                  ),
              )
            : [],
        )
      }
    }, [allChapters, isChaptersLoading, selectedBook, selectedChapters])

    useEffect(() => {
      if (selectedBook) {
        getAllChapters({
          variables: {
            book_id: selectedBook.book_id,
          },
        })
      }
    }, [getAllChapters, selectedBook])

    return (
      <AccordionItem border='none'>
        {({ isExpanded }) => (
          <Flex
            bg='white'
            w='100%'
            borderRadius='8px'
            direction='column'
            border={
              hover || isExpanded
                ? `1px solid ${colors['primary-violet-100']}`
                : `1px solid ${colors['primary-gray-30']}`
            }
          >
            <AccordionButton
              _hover={{
                bg: 'white',
              }}
              p='8px 44px'
              m='0'
              borderRadius='8px'
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              outline='none'
              _focus={{
                boxShadow: 'none',
              }}
            >
              <Flex direction='column' flex={1} gap='12px'>
                <Heading
                  size='poppins24'
                  variant='primary-black-text'
                  color={
                    hover || isExpanded
                      ? colors['primary-blue-100']
                      : colors['primary-black-text']
                  }
                  lineHeight='38px'
                  letterSpacing='0'
                  textAlign='left'
                >
                  Chapters
                </Heading>
              </Flex>

              {isExpanded ? (
                <Box
                  p='10px'
                  bg={colors['primary-violet-10']}
                  borderRadius='50%'
                >
                  <Box transform='rotate(180deg)'>
                    <ArrowIcon
                      fill={
                        hover
                          ? colors['button-hover']
                          : colors['primary-violet-100']
                      }
                    />
                  </Box>
                </Box>
              ) : (
                <Box
                  p='10px'
                  bg={colors['primary-violet-10']}
                  borderRadius='50%'
                >
                  <Box>
                    <ArrowIcon
                      fill={
                        hover
                          ? colors['button-hover']
                          : colors['primary-violet-100']
                      }
                    />
                  </Box>
                </Box>
              )}
            </AccordionButton>
            <AccordionPanel>
              {isAllBooksLoading ||
                isChaptersLoading ||
                (isChapterLoading && <LoadingSpinner />)}

              {!selectedBook && (
                <>
                  <FormControl display='flex' alignItems='center' mb='20px'>
                    <FormLabel htmlFor='email-alerts' mr='5' mb='0'>
                      Title
                    </FormLabel>
                    <Switch
                      id='search-type'
                      value={isId}
                      onChange={toggleSearch}
                    />
                    <FormLabel htmlFor='email-alerts' ml='5' mb='0'>
                      Id
                    </FormLabel>
                  </FormControl>

                  <Flex gap='30px' w='100%'>
                    {!isId && (
                      <TitleSearch
                        search={searchValue}
                        onChange={handleSearchChange}
                      />
                    )}

                    {isId && (
                      <ChapterIdSelector
                        searchId={searchId}
                        handleSearchInput={handleIdSearchInput}
                        handleAddById={handleAddById}
                        error={error}
                      />
                    )}
                  </Flex>

                  <Flex gap='10px' wrap='wrap'>
                    {visibleBooks.map(item => (
                      <Flex
                        key={item.book_id}
                        h='80px'
                        gap='8px'
                        border={`1px solid ${colors['primary-gray-30']}`}
                        p='8px 12px'
                        boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
                        borderRadius='8px'
                        cursor='pointer'
                        _hover={{
                          bg: 'rgba(36, 28, 88, 0.03)',
                        }}
                        onClick={() => handleOpenStory(item)}
                      >
                        <Image
                          src={item.cover_link || '/images/book.png'}
                          alt='book-cover'
                          h='60px'
                          aspectRatio='2/3'
                          objectFit='cover'
                        />

                        <Flex w='100%' gap='10px' direction='column'>
                          <Text
                            size='poppins14'
                            variant='primary-black-text'
                            fontWeight={600}
                          >
                            {item.title}
                          </Text>

                          <Text
                            size='poppins14'
                            variant='primary-black-text'
                            alignSelf='end'
                          >
                            {`${item.creator_id.first_name} ${item.creator_id.last_name}`}
                          </Text>
                        </Flex>
                      </Flex>
                    ))}

                    {visibleBooks.length === 0 &&
                      query &&
                      !isAllBooksLoading && (
                        <Text color='black'>No books by your query</Text>
                      )}
                  </Flex>
                </>
              )}

              {!!selectedBook && (
                <>
                  <Image
                    w='40px'
                    h='40px'
                    src={`/images/close-circle.svg`}
                    alt={'close'}
                    onClick={handleCloseStory}
                    cursor='pointer'
                    mb='24px'
                  />

                  <Flex gap='10px' wrap='wrap'>
                    {visibleChapters.map(item => (
                      <Flex
                        key={item.chapter_id}
                        h='80px'
                        gap='8px'
                        border={`1px solid ${colors['primary-gray-30']}`}
                        p='8px 12px'
                        boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
                        borderRadius='8px'
                        cursor='pointer'
                        _hover={{
                          bg: 'rgba(36, 28, 88, 0.03)',
                        }}
                        onClick={() => handleAddChapter(selectedBook, item)}
                      >
                        <Flex w='100%' gap='4px' direction='column'>
                          <Text
                            size='poppins14'
                            variant='primary-black-text'
                            fontWeight={700}
                            alignSelf='end'
                          >
                            {selectedBook.title}
                          </Text>
                          <Text
                            size='poppins14'
                            variant='primary-black-text'
                            fontWeight={600}
                          >
                            {item.chapter_order}. {item.title}
                          </Text>

                          <Text
                            size='poppins14'
                            variant='primary-black-text'
                            lineHeight='14px'
                          >
                            {item.chapter_id}
                          </Text>
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                </>
              )}
            </AccordionPanel>
          </Flex>
        )}
      </AccordionItem>
    )
  },
)

export default ChaptersSelector
