import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  createSeries,
  deleteSeriesById,
  fetchSeries,
  updateSeries,
} from '../../../../api/series'

export const fetchSeriesThunk = createAsyncThunk(
  'series/fetch',
  async () => await fetchSeries().then(res => res.data),
)

export const createSeriesThunk = createAsyncThunk(
  'series/create',
  async series => await createSeries(series).then(res => res.data),
)

export const deleteSeriesThunk = createAsyncThunk(
  'series/delete',
  async series_id => {
    try {
      await deleteSeriesById(series_id)
      return series_id
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)

export const updateSeriesThunk = createAsyncThunk(
  'series/update',
  async series => {
    try {
      return await updateSeries(series).then(res => res.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
)
