const PinIcon = fill => {
  return (
    <svg
      id='Layer_1'
      enableBackground='new 0 0 52 52'
      viewBox='0 0 52 52'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='_x33_8'>
        <path
          d='m1.2 50.5c.3.5.9.6 1.4.3 2.8-1.7 5.6-3.5 8.3-5.3 2.5-1.7 5-3.3 7.4-5.1 1.9 2 3.9 3.9 5.8 5.9l1.2 1.1c.1.1.2.2.4.4.6.4 1.2.6 1.9.5.9-.1 1.7-.7 2-1.5l.6-1.6 2.1-5.9c2.2-2.3 4.5-4.6 6.7-6.9s4.3-4.5 6.5-6.8c.2-.1 2.6-.9 2.5-.8l1.2-.4c.2-.1.5-.2.7-.3.3-.2.5-.4.7-.7.3-.5.5-1.2.3-1.8-.1-.3-.2-.6-.4-.9s-.4-.4-.5-.6l-1.8-1.8c-3.2-3.4-9.7-10-14.4-14.5l-1.8-1.8c-.2-.1-.3-.3-.6-.5s-.6-.3-.9-.4c-.6-.2-1.3 0-1.9.3-.3.2-.5.4-.7.7s-.3.6-.3.8c-.1.2-1.3 3.8-1.3 3.7v.1c-2.3 2.1-4.5 4.3-6.7 6.4-2.3 2.2-4.7 4.5-7 6.8-.3.3-.3.6-.2.9l-5.3 1.7-1.6.5c-.6.2-1.1.9-1.3 1.6-.1.3 0 .7.1 1.1.1.3.4.7.6.9l1.1 1 5.7 6c-1.8 2.5-3.5 5-5.2 7.5-1.8 2.7-3.6 5.5-5.3 8.3-.2.4-.2.8 0 1.1zm27.9-47.1c.1-.2.1-.3.2-.4s.1-.1.2-.2c.2-.1.4-.1.6-.1.2.1.2.1.6.5 1.6 1.6 1.8 1.9 8.9 9.1 2.4 2.4 4.8 4.8 7.3 7.1l1.8 1.8c.4.4.4.4.5.6s0 .4-.1.6c-.1.1-.1.2-.2.2-.1.1-.1.1-1.6.7l-2 .8c-1.4-1.3-2.7-2.5-4.2-3.8-1-.9-2.1-1.8-3.1-2.8-.6-.5-1.1-1-1.7-1.5-1.4-1.7-2.9-3.3-4.3-4.9s-2.9-3.2-4.3-4.7c.2 0 1.4-3.1 1.4-3zm-8.4 10.8c2.1-2.2 4.2-4.4 6.2-6.5 1.5 1.4 3.1 2.9 4.7 4.3s3.2 2.9 4.9 4.3c.5.6 1 1.1 1.5 1.7.9 1 1.8 2.1 2.8 3.1 1.2 1.4 2.5 2.7 3.7 4.1-2.2 2.1-4.4 4.2-6.6 6.3-2.1 2-4.2 4.1-6.3 6.1-2.8-2.9-5.6-5.7-8.5-8.5s-5.7-5.7-8.5-8.5c2-2.2 4.1-4.3 6.1-6.4zm-13.9 12.6-1.2-1.1c-.4-.3-.5-.6-.5-1s.3-.7.6-.9c.1 0 7-3.1 6.6-2.9 0 .1.1.2.2.3 3 3.1 6.1 6.1 9.1 9.2 2.9 2.9 5.8 5.8 8.8 8.7l-2.1 5.5-.5 1.4c-.1.3-.5.4-.8.3 0 0-.1 0-.1-.1 0 0-.1-.1-.2-.2l-1.1-1.2c-3.1-3.1-6.2-6.1-9.3-9.1-3.2-3-6.3-6-9.5-8.9zm1.2 15.2c1.5-2.6 2.9-5.2 4.3-7.8.9.9 1.8 1.9 2.7 2.8l2.7 2.7c-2.6 1.4-5.1 2.8-7.7 4.3-1.7 1-3.3 2-4.9 3 .9-1.7 1.9-3.3 2.9-5z'
          fill={fill}
          style={{ transition: 'fill 0.4s ease' }}
        />
      </g>
    </svg>
  )
}

export default PinIcon
