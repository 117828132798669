import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import LoginScreen from '../screens/Authorization'
import { PrivateRoutes } from '../routes'
import { withHocs } from './hocs'

const App = () => {
  return (
    <>
      <Routes>
        <Route path='sign-in' element={<LoginScreen />} />
        <Route path='/*' element={<PrivateRoutes />} />
        <Route path='*' element={<Navigate to='sign-in' />} />
      </Routes>
    </>
  )
}

export default withHocs(App)
