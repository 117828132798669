import React, { memo } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'

const Button = ({ children, theme, type = 'button', ...props }) => {
  return (
    <button
      className={classNames(styles.button, styles[theme])}
      type={type}
      {...props}
    >
      {children}
    </button>
  )
}

export default memo(Button)
