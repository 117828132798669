import { Flex, Heading, Image } from '@chakra-ui/react'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
// import SectionItem from 'screens/Sections/NewSections/components/SectionsContent/SectionItem'
import SectionModule from '../SectionsSelector/SectionModule'

const SectionsSummary = ({
  selectedSections,
  handleRemoveSection,
  handleOnDragSection,
}) => {
  return (
    <Flex gap='20px' direction='column' w='100%'>
      <Heading
        size='poppins20'
        variant='primary-black-text'
        lineHeight='38px'
        letterSpacing='0'
        textAlign='left'
        w='120px'
        minW='120px'
      >
        Sections
      </Heading>

      <Flex>
        <DragDropContext onDragEnd={handleOnDragSection}>
          <Droppable droppableId='sections' style={{ transform: 'none' }}>
            {provided => (
              <Flex
                gap='10px'
                direction='column'
                wrap='wrap'
                w='100%'
                {...provided.droppableProps}
                ref={provided.innerRef}
                userSelect='none'
              >
                {selectedSections.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {provided => {
                      return (
                        <Flex
                          w='100%'
                          align='center'
                          gap='12px'
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <Image
                            w='44px'
                            h='35px'
                            src={`/images/screens/drag.png`}
                            alt='drag'
                          />

                          <SectionModule item={item} isPreview />

                          <Image
                            w='44px'
                            h='35px'
                            onClick={() => handleRemoveSection(item)}
                            cursor='pointer'
                            src={`/images/screens/delete_translation.png`}
                            alt='remove'
                          />
                        </Flex>
                      )
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Flex>
            )}
          </Droppable>
        </DragDropContext>
      </Flex>
    </Flex>
  )
}

export default SectionsSummary
