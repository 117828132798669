import { createSelector } from 'reselect'

const getState = state => state.users

const getCurrentUserState = createSelector(getState, state => state.currentUser)

const getUsers = createSelector(getState, state => state.allUsers)

export const getAccessToken = createSelector(
  getCurrentUserState,
  state => state.access_token,
)

export const getUsersList = createSelector(getUsers, state =>
  Object.values(state),
)
