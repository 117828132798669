import React, { memo, useCallback, useState } from 'react'
import { Accordion, Button } from 'react-bootstrap'
import './styles.scss'
import BannerItem from '../BannerItem'
import { Flex, Text, useToast } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { updateListThunk } from 'redux/slices/lists/thunks'
import { apolloClient } from 'app/hocs/with-apollo'
import { GET_LIST } from 'services/lists/graphql'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import { baseScrollBar } from 'constants/scrollbar'

const BannerSection = ({
  list,
  deleteList,
  showAppendBannerModal,
  deleteListContentOption,
}) => {
  const toast = useToast()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const handleCopyBookIdClipboard = useCallback(
    text => {
      navigator.clipboard.writeText(text)
      toast({
        title: `Book Id copied to clipboard!`,
        status: 'success',
        isClosable: true,
        duration: 1000,
        position: 'top',
      })
    },
    [toast],
  )

  const handleOnDragBanner = useCallback(
    async result => {
      const { source, destination } = result

      if (!destination || source.index === destination.index) return

      setIsLoading(true)

      const items = [...list.bannersOrder].sort(
        (orderA, orderB) => orderA.order_number - orderB.order_number,
      )
      const [reorderedItem] = items.splice(source.index, 1)
      items.splice(destination.index, 0, reorderedItem)
      const final = items.map(({ banner }, order_number) => ({
        banner: { banner_id: banner.banner_id },
        order_number,
      }))

      const updatedList = {
        list_id: list.list_id,
        title: list.title,
        bannersOrder: final,
      }

      await dispatch(updateListThunk(updatedList))
      await apolloClient.query({
        query: GET_LIST,
        variables: { list_id: list.list_id },
        fetchPolicy: 'network-only',
      })
      setIsLoading(false)
    },
    [dispatch, list],
  )

  return (
    <Accordion.Item eventKey={list.list_id}>
      <Accordion.Header>
        <div style={{ flex: '1' }}>
          <Flex gap='16px'>
            <Text as='span' size='poppins18' variant='primary-black-text'>
              list_id:
            </Text>
            <Text
              as='span'
              size='poppins16normal'
              variant='primary-black-text'
              onClick={e => {
                e.stopPropagation()
                handleCopyBookIdClipboard(list.list_id)
              }}
              cursor='copy'
            >
              {list.list_id}
            </Text>
          </Flex>
          <br />
          Title: {list.title}
          <br />
          <div>Description: {list.description}</div>
          <small style={{ textTransform: 'capitalize' }}>
            Type: {list.list_type}
          </small>
        </div>
        <Button
          variant='danger'
          onClick={deleteList}
          style={{ marginTop: 0, marginRight: 30 }}
        >
          Delete list
        </Button>
      </Accordion.Header>
      <Accordion.Body
        style={{
          height: '100%',
          maxHeight: '60vh',
          overflowY: 'auto',
        }}
      >
        <div className='section-banner-list'>
          {isLoading && <LoadingSpinner />}
          {!isLoading && (
            <DragDropContext onDragEnd={result => handleOnDragBanner(result)}>
              <Droppable
                droppableId='list-banners'
                style={{
                  transform: 'none',
                  height: '100%',
                }}
              >
                {provided => (
                  <Flex
                    direction='column'
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    userSelect='none'
                    gap='10px'
                    width='100%'
                    h='100%'
                    overflowY='auto'
                    sx={baseScrollBar}
                  >
                    {[...list.bannersOrder]
                      .sort(
                        (orderA, orderB) =>
                          orderA.order_number - orderB.order_number,
                      )
                      .map(({ banner }, index) => (
                        <Draggable
                          key={`${list.list_id}-${banner.banner_id}`}
                          draggableId={banner.banner_id}
                          index={index}
                        >
                          {provided => (
                            <BannerItem
                              elementRef={provided.innerRef}
                              draggableProps={provided.draggableProps}
                              dragHandleProps={provided.dragHandleProps}
                              banner={banner}
                              deleteListContentOption={deleteListContentOption}
                              list={list}
                            />
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </Flex>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
        <Button onClick={showAppendBannerModal}>Add banner</Button>
      </Accordion.Body>
    </Accordion.Item>
  )
}
export default memo(BannerSection)
