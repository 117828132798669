/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { colors } from 'shared/style/colors'
import BackIcon from '../BackIcon'
import axios from 'axios'
import { isEmpty } from 'lodash'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import SimpleBarReact from 'simplebar-react'
import ChapterCell from './ChapterCell'
import UnlockFullControls from './UnlockFullControls'

const uuidv4Regex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

const UnlockContent = ({
  user,
  handleClearLastReadData,
  handleUpdateLastReadData,
  handleUnlockChapter,
  handleLockChapter,
  handleUnlockBook,
  handleLockBook,
}) => {
  const [type, setType] = useState('title')
  const [target, setTarget] = useState('')
  const [focus, setFocus] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hover, setHover] = useState(false)
  const [isHide, setIsHide] = useState(true)
  const [story, setStory] = useState('')
  const [visibleBooks, setVisibleBooks] = useState([])
  const [isSearched, setIsSearched] = useState(false)
  const [chapters, setChapters] = useState([])

  const isTitle = type === 'title'
  const isId = type === 'id'

  const error = useMemo(() => {
    if (!target.trim() || focus) {
      return false
    }

    if (isId && !uuidv4Regex.test(target)) {
      return 'Input ID is not valid'
    }

    return false
  }, [isId, target, focus])

  const placeholder = useMemo(() => {
    if (isTitle) return 'Book title'

    if (isId) return 'xxxxxxxx-xxxx-xxx-xxx-xxxxxxxxxxxx'

    return 'Select field type'
  }, [isTitle, isId])

  const isUnlockDisabled = useMemo(() => {
    const paidChapters = chapters.filter(it => !it.isFree).length
    const userUnlocked = user?.userTransactions.filter(
      item => item.book_id === story,
    ).length

    if (paidChapters === 0) {
      return true
    }

    if (paidChapters === userUnlocked) {
      return true
    }

    return false
  }, [story, chapters, user])

  const isLockDisabled = useMemo(() => {
    return !user?.userTransactions.some(item => item.book_id === story)
  }, [story, user])

  const getBooks = useCallback(async () => {
    setIsLoading(true)

    const { data } = await axios.post(
      `${process.env.REACT_APP_WEB_APP_URL}/books`,
      {
        title: isTitle ? target.trim() : '',
        book_id: isId ? target.trim() : '',
      },
      {
        headers: {
          'x-auth': `Bearer ${process.env.REACT_APP_ADMIN_KEY}`,
        },
      },
    )

    setVisibleBooks(!isEmpty(data.books) ? data.books : [])

    setIsSearched(true)

    setIsLoading(false)
  }, [isId, isTitle, target])

  const getChapters = useCallback(async () => {
    setIsLoading(true)

    const { data } = await axios.post(
      `${process.env.REACT_APP_WEB_APP_URL}/books/story-chapters`,
      {
        book_id: story,
      },
      {
        headers: {
          'x-auth': `Bearer ${process.env.REACT_APP_ADMIN_KEY}`,
        },
      },
    )

    setChapters(!isEmpty(data.chapters) ? data.chapters : [])

    setIsLoading(false)
  }, [story])

  const handlePickStory = useCallback(id => {
    setStory(id)
  }, [])

  const handleSetType = useCallback(e => {
    setType(e.target.value)
  }, [])

  const handleSetTarget = useCallback(e => {
    setTarget(e.target.value)
    setIsSearched(false)
    setVisibleBooks([])
  }, [])

  const handleClearSelected = useCallback(() => {
    setStory('')
  }, [])

  const triggerUnlock = useCallback(async () => {
    await handleUnlockBook(user?.user_id, story)
  }, [handleUnlockBook, user, story])

  const triggerLock = useCallback(async () => {
    await handleLockBook(user?.user_id, story)
  }, [handleLockBook, user, story])

  useEffect(() => {
    if (!story) {
      setChapters([])
    } else {
      getChapters()
    }
  }, [story])

  return (
    <Flex
      direction='column'
      justify='start'
      align='start'
      gap='12px'
      p='10px'
      border={`1px solid ${colors['primary-gray-10']}`}
      bg={colors.white}
      borderRadius='10px'
      pos='relative'
    >
      {isLoading && <LoadingSpinner />}

      <Flex w='100%' gap='12px' justify='space-between'>
        <Flex gap='12px' align='center'>
          <Heading
            size='poppins24'
            variant='primary-black-text'
            lineHeight='36px'
            textAlign='center'
            maxW='400px'
            fontWeight={500}
          >
            Unlock Content
          </Heading>
          <Box
            pos='relative'
            cursor='pointer'
            w='24px'
            h='24px'
            transform={`rotate(${isHide ? 0 : '-90deg'})`}
            onClick={() => setIsHide(curr => !curr)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {BackIcon(
              hover
                ? colors['primary-violet-100']
                : colors['primary-black-text'],
            )}
          </Box>
        </Flex>

        {chapters.length > 0 && (
          <Image
            w='36px'
            h='36px'
            p='1px'
            src={`/images/close-circle.svg`}
            alt={'remove'}
            cursor='pointer'
            onClick={handleClearSelected}
          />
        )}
      </Flex>

      {!isHide && chapters.length === 0 && (
        <>
          <Flex w='100%' align='center' gap='4px'>
            <Select w='140px' h='54px' value={type} onChange={handleSetType}>
              <option value='email'>Title</option>
              <option value='id'>ID</option>
            </Select>

            <Input
              placeholder={placeholder}
              variant='loginField'
              p='12px 24px'
              h='54px'
              w='360px'
              value={target}
              style={{
                caretColor: colors['primary-violet-100'],
              }}
              autoComplete='off'
              onChange={handleSetTarget}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              disabled={!type}
            />

            <Button
              variant='defaultButton'
              w='fit-content'
              h='54px'
              minH='54px'
              isDisabled={!target || focus || error}
              onClick={getBooks}
            >
              Get Books
            </Button>
          </Flex>

          <Flex gap='10px' wrap='wrap'>
            {visibleBooks.map(item => (
              <Flex
                key={item.book_id}
                h='80px'
                gap='8px'
                border={`1px solid ${colors['primary-gray-30']}`}
                p='8px 12px'
                boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
                borderRadius='8px'
                cursor='pointer'
                _hover={{
                  bg: 'rgba(36, 28, 88, 0.03)',
                }}
                onClick={() => handlePickStory(item.book_id)}
              >
                <Image
                  src={item.cover_link || '/images/book.png'}
                  alt='book-cover'
                  h='60px'
                  aspectratio='2/3'
                  objectFit='cover'
                />

                <Flex w='100%' gap='10px' direction='column'>
                  <Text
                    size='poppins14'
                    variant='primary-black-text'
                    fontWeight={600}
                  >
                    {item.title}
                  </Text>

                  <Text
                    size='poppins14'
                    variant='primary-black-text'
                    alignSelf='end'
                  >
                    {`${item.user?.first_name} ${item.user?.last_name}`}
                  </Text>
                </Flex>
              </Flex>
            ))}

            {visibleBooks.length === 0 && target && isSearched && (
              <Text color='black'>No books by your query</Text>
            )}
          </Flex>

          {error && !focus && (
            <Text
              size='poppins14'
              fontWeight={400}
              letterSpacing='-0.01em'
              color={colors['primary-violet-100']}
              lineHeight='20px'
              mb='-20px'
            >
              {error}
            </Text>
          )}
        </>
      )}

      {!isHide && chapters.length > 0 && (
        <Flex
          w='100%'
          maxH={`calc(100vH - 60px - 204px - 94px - 120px)`}
          overflow='hidden'
        >
          <SimpleBarReact
            autoHide={true}
            style={{
              maxHeight: '100%',
              height: '100%',
              width: '100%',
              overflowX: 'hidden',
            }}
            id='chapterss-table-wrapper'
          >
            <UnlockFullControls
              handleUnlock={triggerUnlock}
              isUnlockDisabled={isUnlockDisabled}
              isLockDisabled={isLockDisabled}
              handleLock={triggerLock}
            />

            <TableContainer px='22px'>
              <Table variant='chapterTable'>
                <Thead>
                  <Tr py='12px'>
                    <Th w='20%' textAlign='center'></Th>
                    <Th w='15%' textAlign='center'>
                      Chapter order
                    </Th>
                    <Th w='45%' textAlign='center'>
                      Title
                    </Th>

                    <Th w='20%' textAlign='center'>
                      History
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {chapters.map((chapter, idx, all) => {
                    const isLast = idx === all.length - 1

                    return (
                      <ChapterCell
                        key={chapter.chapter_id}
                        chapter={chapter}
                        isLast={isLast}
                        transactions={user?.userTransactions || []}
                        lastRead={user?.userLastReads || []}
                        book_id={story}
                        user_id={user?.user_id}
                        handleUpdateLastReadData={handleUpdateLastReadData}
                        handleUnlockChapter={handleUnlockChapter}
                        handleLockChapter={handleLockChapter}
                        handleClearLastReadData={handleClearLastReadData}
                      />
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </SimpleBarReact>
        </Flex>
      )}
    </Flex>
  )
}

export default UnlockContent
