import React, { useEffect } from 'react'
import useSearchQuery from 'hooks/useSearchQuery'
import { useNavigate } from 'react-router-dom'
import { SCREENS_TABS } from 'constants/screens'
import { Flex } from '@chakra-ui/react'
import InfoElement from 'screens/Comments/InfoElement/InfoElement'
import { TabNavigation } from '../../../components/UI/TabNavigation'
import MainscreenLists from '../OldScreens'
import NewScreens from '../NewScreens'

const ScreensLayout = () => {
  const { active, create, manage } = useSearchQuery()
  const navigate = useNavigate()

  const isContentCreation = create === 'yes'
  const isManageContent = manage === 'yes'

  const isContentWorker = isContentCreation || isManageContent

  const isOldMainscreens = active === SCREENS_TABS.mainscreen.query
  const isNewScreens = active === SCREENS_TABS.screens.query

  useEffect(() => {
    if (!Object.keys(SCREENS_TABS).includes(active)) {
      navigate('/screens')
    }
  }, [active, navigate])

  return (
    <Flex
      w='100%'
      h='100%'
      bg='linear-gradient(135deg, #F3F1FF 0%, #F0FBFF 100%)'
      position='relative'
      direction='column'
      overflow='hidden'
    >
      <Flex
        mt='8px'
        direction='column'
        pr='34px'
        gap='12px'
        h='100%'
        w='100%'
        overflow='hidden'
      >
        {!isContentWorker && (
          <Flex w='100%'>
            <TabNavigation
              buttons={Object.entries(SCREENS_TABS)}
              active={active || ''}
            />
          </Flex>
        )}

        {!active && (
          <InfoElement
            title='Please, select the tab you need'
            image='/images/hero/denied.png'
          />
        )}

        {isOldMainscreens && <MainscreenLists />}

        {isNewScreens && <NewScreens />}
      </Flex>
    </Flex>
  )
}

export default ScreensLayout
