import * as Yup from 'yup'
import {
  MAX_FIRST_NAME_LENGTH,
  MAX_LAST_NAME_LENGTH,
} from '../../../constants/users'

export const createUserValidationSchema = Yup.object({
  first_name: Yup.string()
    .max(MAX_FIRST_NAME_LENGTH, 'Must be 15 characters or less')
    .required('Required'),
  last_name: Yup.string().max(
    MAX_LAST_NAME_LENGTH,
    'Must be 20 characters or less',
  ),
})
