import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react'
import ArrowIcon from 'components/UI/Icons/ArrowIcon'
import React, { useMemo, useState } from 'react'
import { colors } from 'shared/style/colors'
import TagsSummary from './TagsSummary'
import GenresSummary from './GenresSummary'
import BooksSummary from './BooksSummary'
import BannersSummary from './BannersSummary'

const Selection = ({
  place,
  type,
  title,
  screenType,
  description,
  selectedStories,
  selectedGenres,
  selectedBanners,
  selectedTags,
  handleRemoveStory,
  handleRemoveGenre,
  handleRemoveTag,
  handleRemoveBanner,
  handleSubmit,
  handleClearData,
  handleOnDragTags,
  handleOnDragGenres,
  handleOnDragBook,
  handleOnDragBanner,
  canUpdateData,
  titleTranslates,
  descriptionTranslates,
  isManager,
}) => {
  const [hover, setHover] = useState(false)

  const isSubmitEnabled = useMemo(() => {
    return place && title && type
  }, [place, title, type])

  const isClearEnabled = useMemo(() => {
    return (
      selectedStories.length !== 0 ||
      selectedGenres.length !== 0 ||
      selectedTags.length !== 0 ||
      selectedBanners.length !== 0 ||
      place ||
      title ||
      type ||
      screenType ||
      description
    )
  }, [
    selectedStories,
    selectedGenres,
    selectedTags,
    selectedBanners,
    place,
    title,
    type,
    description,
    screenType,
  ])

  return (
    <AccordionItem border='none'>
      {({ isExpanded }) => (
        <Flex
          bg='white'
          w='100%'
          borderRadius='8px'
          direction='column'
          border={
            hover || isExpanded
              ? `1px solid ${colors['primary-violet-100']}`
              : `1px solid ${colors['primary-gray-30']}`
          }
        >
          <AccordionButton
            _hover={{
              bg: 'white',
            }}
            p='8px 44px'
            m='0'
            borderRadius='8px'
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            outline='none'
            _focus={{
              boxShadow: 'none',
            }}
          >
            <Flex direction='column' flex={1} gap='12px'>
              <Heading
                size='poppins24'
                variant='primary-black-text'
                color={
                  hover || isExpanded
                    ? colors['primary-blue-100']
                    : colors['primary-black-text']
                }
                lineHeight='38px'
                letterSpacing='0'
                textAlign='left'
              >
                {isManager
                  ? 'Update Section Summary'
                  : 'Create Section Summary'}
              </Heading>
            </Flex>

            {isExpanded ? (
              <Box p='10px' bg={colors['primary-violet-10']} borderRadius='50%'>
                <Box transform='rotate(180deg)'>
                  <ArrowIcon
                    fill={
                      hover
                        ? colors['button-hover']
                        : colors['primary-violet-100']
                    }
                  />
                </Box>
              </Box>
            ) : (
              <Box p='10px' bg={colors['primary-violet-10']} borderRadius='50%'>
                <Box>
                  <ArrowIcon
                    fill={
                      hover
                        ? colors['button-hover']
                        : colors['primary-violet-100']
                    }
                  />
                </Box>
              </Box>
            )}
          </AccordionButton>

          <AccordionPanel>
            <Flex
              bg='white'
              w='100%'
              borderRadius='8px'
              direction='column'
              gap='8px'
            >
              <Flex w='100%' gap='12px'>
                <Flex
                  w='50%'
                  direction='column'
                  alignSelf='flex-start'
                  borderRight='1px solid #E2E8F0'
                >
                  <Flex gap='20px' align='center'>
                    <Text
                      size='poppins16normal'
                      variant='primary-black-text'
                      whiteSpace='nowrap'
                    >
                      Section title:
                    </Text>

                    <Heading
                      size='poppins20'
                      variant='primary-black-text'
                      lineHeight='38px'
                      letterSpacing='0'
                      textAlign='left'
                    >
                      {title || ''}
                    </Heading>
                  </Flex>

                  {titleTranslates.map(item => {
                    if (!item.locale || !item.value) {
                      return null
                    }

                    return (
                      <Flex key={item.id} gap='20px' align='center'>
                        <Flex gap='4px' align='center'>
                          <Image
                            w='31px'
                            h='22px'
                            src={`/images/flags/${item.locale}.png`}
                            alt={item.locale}
                          />

                          <Text
                            size='poppins18'
                            variant={'primary-black-text'}
                            lineHeight='32px'
                            fontWeight={400}
                            maxW='438px'
                            minW='fit-content'
                            overflow='hidden'
                            textOverflow='ellipsis'
                            whiteSpace='nowrap'
                          >
                            {item.locale}
                          </Text>
                        </Flex>

                        <Heading
                          size='poppins20'
                          variant='primary-black-text'
                          lineHeight='38px'
                          letterSpacing='0'
                          textAlign='left'
                        >
                          {item.value}
                        </Heading>
                      </Flex>
                    )
                  })}
                </Flex>

                <Flex w='50%' alignSelf='flex-start' direction='column'>
                  <Flex gap='20px' align='center'>
                    <Text
                      size='poppins16normal'
                      variant='primary-black-text'
                      whiteSpace='nowrap'
                    >
                      Section description:
                    </Text>

                    <Heading
                      size='poppins20'
                      variant='primary-black-text'
                      lineHeight='38px'
                      letterSpacing='0'
                      textAlign='left'
                    >
                      {description || ''}
                    </Heading>
                  </Flex>

                  {descriptionTranslates.map(item => {
                    if (!item.locale || !item.value) {
                      return null
                    }

                    return (
                      <Flex key={item.id} gap='20px' align='center'>
                        <Flex gap='4px' align='center'>
                          <Image
                            w='31px'
                            h='22px'
                            src={`/images/flags/${item.locale}.png`}
                            alt={item.locale}
                          />

                          <Text
                            size='poppins18'
                            variant={'primary-black-text'}
                            lineHeight='32px'
                            fontWeight={400}
                            maxW='438px'
                            minW='fit-content'
                            overflow='hidden'
                            textOverflow='ellipsis'
                            whiteSpace='nowrap'
                          >
                            {item.locale}
                          </Text>
                        </Flex>

                        <Heading
                          size='poppins20'
                          variant='primary-black-text'
                          lineHeight='38px'
                          letterSpacing='0'
                          textAlign='left'
                        >
                          {item.value}
                        </Heading>
                      </Flex>
                    )
                  })}
                </Flex>
              </Flex>

              <Divider />

              <Flex w='100%' gap='12px'>
                <Flex
                  gap='20px'
                  align='center'
                  w='50%'
                  borderRight='1px solid #E2E8F0'
                >
                  <Text
                    size='poppins16normal'
                    variant='primary-black-text'
                    whiteSpace='nowrap'
                  >
                    Section type:
                  </Text>

                  <Heading
                    size='poppins20'
                    variant='primary-black-text'
                    lineHeight='38px'
                    letterSpacing='0'
                    textAlign='left'
                  >
                    {type || ''}
                  </Heading>
                </Flex>

                <Flex gap='20px' align='center' w='50%'>
                  <Text
                    size='poppins16normal'
                    variant='primary-black-text'
                    whiteSpace='nowrap'
                  >
                    Screen place:
                  </Text>

                  <Heading
                    size='poppins20'
                    variant='primary-black-text'
                    lineHeight='38px'
                    letterSpacing='0'
                    textAlign='left'
                  >
                    {place || ''}
                  </Heading>
                </Flex>
              </Flex>

              <Divider />

              <Flex gap='36px'>
                <BooksSummary
                  selectedStories={selectedStories}
                  handleRemoveStory={handleRemoveStory}
                  handleOnDragBook={handleOnDragBook}
                />

                <BannersSummary
                  selectedBanners={selectedBanners}
                  handleRemoveBanner={handleRemoveBanner}
                  handleOnDragBanner={handleOnDragBanner}
                />

                <GenresSummary
                  selectedGenres={selectedGenres}
                  handleRemoveGenre={handleRemoveGenre}
                  handleOnDragGenres={handleOnDragGenres}
                />

                <TagsSummary
                  selectedTags={selectedTags}
                  handleRemoveTag={handleRemoveTag}
                  handleOnDragTags={handleOnDragTags}
                />
              </Flex>

              <Flex gap='23px' alignSelf='end'>
                <Button
                  type='button'
                  variant='outlineButton'
                  w='204px'
                  isDisabled={!isClearEnabled}
                  onClick={handleClearData}
                >
                  Clear data
                </Button>

                <Button
                  type='button'
                  variant='defaultButton'
                  w='204px'
                  isDisabled={!isSubmitEnabled || !canUpdateData}
                  onClick={handleSubmit}
                >
                  {isManager ? 'Update' : 'Create'}
                </Button>
              </Flex>
            </Flex>
          </AccordionPanel>
        </Flex>
      )}
    </AccordionItem>
  )
}

export default Selection
