import { createSlice } from '@reduxjs/toolkit'
import {
  fetchAllBooksThunk,
  fetchBooksByGenreIdThunk,
  fetchBooksBySearchThunk,
  updateBookThunk,
} from './thunks'
import * as R from 'ramda'

export const STATE_KEY = 'books'

const initialState = {
  list: {},
  byGenres: {},
}

const booksSlice = createSlice({
  name: STATE_KEY,
  initialState,
  extraReducers: {
    [fetchAllBooksThunk.fulfilled]: (state, action) => {
      state.list = R.indexBy(R.prop('book_id'), action.payload)
    },
    [fetchBooksBySearchThunk.fulfilled]: (state, action) => {
      state.list = R.indexBy(R.prop('book_id'), action.payload)
    },
    [fetchBooksByGenreIdThunk.fulfilled]: (state, action) => {
      const { genre_id, books } = action.payload
      state.byGenres[genre_id] = R.indexBy(R.prop('book_id'), books)
    },
    [updateBookThunk.fulfilled]: (state, { payload: book }) => {
      state.list[book.book_id] = { ...state.list[book.book_id], ...book }
    },
  },
})

export const { setBearerToken } = booksSlice.actions
export const booksReducer = booksSlice.reducer
