import React, { memo, useCallback } from 'react'
import { Button } from 'react-bootstrap'
import './styles.scss'
import { Flex, Text, useToast } from '@chakra-ui/react'

const ContinueReadingSection = ({ list, deleteList }) => {
  const toast = useToast()
  const handleCopyBookIdClipboard = useCallback(
    text => {
      navigator.clipboard.writeText(text)
      toast({
        title: `Book Id copied to clipboard!`,
        status: 'success',
        isClosable: true,
        duration: 1000,
        position: 'top',
      })
    },
    [toast],
  )

  return (
    <div className='continue-reading-section'>
      <div className='section-info'>
        <Flex gap='16px'>
          <Text as='span' size='poppins18' variant='primary-black-text'>
            list_id:
          </Text>
          <Text
            as='span'
            size='poppins16normal'
            variant='primary-black-text'
            onClick={e => {
              e.stopPropagation()
              handleCopyBookIdClipboard(list.list_id)
            }}
            cursor='copy'
          >
            {list.list_id}
          </Text>
        </Flex>
        <br />
        <p className='section-info__title'>{list.title}</p>
        <div>Description: {list.description}</div>
        <small style={{ textTransform: 'capitalize' }}>
          Type: {list.list_type}
        </small>
      </div>
      <Button
        variant='danger'
        onClick={deleteList}
        style={{ marginTop: 0, marginRight: 49 }}
      >
        Delete list
      </Button>
    </div>
  )
}
export default memo(ContinueReadingSection)
