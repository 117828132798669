import React, { memo } from 'react'
import { Accordion, Button } from 'react-bootstrap'
import SeriesBookItem from '../SeriesBookItem'
import './styles.scss'

const SeriesItem = ({
  series,
  handleSeriesDelete,
  handleSeriesBookDelete,
  handleOpenUpdateSeriesModal,
  handleSeriesBookOrderUpdate,
  handleSeriesBookOrderDragEnd,
}) => {
  return (
    <Accordion.Item eventKey={series.series_id} className='series-item'>
      <Accordion.Header>
        <div style={{ flex: '1' }}>Title: {series.title}</div>
        <Button
          variant='danger'
          onClick={e => {
            e.stopPropagation()
            handleSeriesDelete(series.series_id)
          }}
          style={{ marginTop: 0, marginRight: 30 }}
        >
          Delete series
        </Button>
      </Accordion.Header>
      <Accordion.Body>
        <div className='series-book-list'>
          {series.booksOrder.map(({ book }, index) => (
            <SeriesBookItem
              key={book.book_id}
              book={book}
              index={index}
              series={series}
              onDelete={() => handleSeriesBookDelete(series, book.book_id)}
              handleSeriesBookOrderUpdate={handleSeriesBookOrderUpdate}
              handleSeriesBookOrderDragEnd={handleSeriesBookOrderDragEnd}
            />
          ))}
        </div>
        <Button
          variant='secondary'
          onClick={() => handleOpenUpdateSeriesModal(series)}
          style={{ marginTop: 10 }}
        >
          Update
        </Button>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default memo(SeriesItem)
