export const SectionTypes = Object.freeze({
  BOOKS_LIST: 'BOOKS_LIST',
  BOOKS_3x4: 'BOOKS_3x4',
  BOOKS_BECAUSE_YOU_READ: 'BOOKS_BECAUSE_YOU_READ',
  BOOKS_HORIZONTAL_LARGE_LIST: 'BOOKS_HORIZONTAL_LARGE_LIST',
  RECOMMENDED: 'RECOMMENDED',
  CONTINUE_READING: 'CONTINUE_READING',
  GENRES: 'GENRES',
  TAGS: 'TAGS',
  SURVEY: 'SURVEY',
  BANNER_TOP: 'BANNER_TOP',
  BANNER_LARGE: 'BANNER_LARGE',
  BOOKS_BECAUSE_YOU_LIKE: 'BOOKS_BECAUSE_YOU_LIKE',
  COMING_SOON: 'COMING_SOON',
  FREE_FOR_LIMITED: 'FREE_FOR_LIMITED',
  BOOKS_PREMIUM: 'BOOKS_PREMIUM',
  NEW_RELEASES_LIST: 'NEW_RELEASES_LIST',
  MOST_POPULAR: 'MOST_POPULAR',
  TOP_BANNERS_V2: 'TOP_BANNERS_V2'
})
