import { Flex, Text } from '@chakra-ui/react'
import React from 'react'

const Email = ({ email }) => {
  return (
    <Flex gap='24px'>
      <Text
        size='poppins18'
        fontWeight={400}
        variant='primary-black-text'
        letterSpacing='-0.018px'
        minW='130px'
      >
        Email:
      </Text>

      <Text
        size='poppins18'
        fontWeight={600}
        variant='primary-black-text'
        letterSpacing='-0.018px'
      >
        {email}
      </Text>
    </Flex>
  )
}

export default Email
