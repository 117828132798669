import { useLazyQuery, useQuery } from '@apollo/client'
import { Flex } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { GET_AUTHOR_CHAPTERS } from 'services/chapters/graphql'
import { GET_ISO_LANGUAGES } from 'services/languages/graphql'
import OneChapterFlow from './OneChapterFlow'
import FlowToggler from './FlowToggler'
import FullBookFlow from './FullBookFlow'

export const MAX_FILES = 20

const Audio = ({ book, isBookLoading }) => {
  const [isChapterUpload, setIsChapterUpload] = useState(false)
  const [getAllChapters, { data: allChapters, loading: isChaptersLoading }] =
    useLazyQuery(GET_AUTHOR_CHAPTERS)

  const { data, loading } = useQuery(GET_ISO_LANGUAGES, {
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (book) {
      getAllChapters({
        variables: {
          book_id: book.book_id,
        },
      })
    }
  }, [getAllChapters, book])

  return (
    <Flex direction='column' height='fit-content' minH='360px'>
      <FlowToggler
        isChapterUpload={isChapterUpload}
        setIsChapterUpload={setIsChapterUpload}
      />

      {isChapterUpload && (
        <OneChapterFlow
          isBookLoading={isBookLoading}
          book={book}
          isChaptersLoading={isChaptersLoading}
          languages={data}
          loading={loading}
          allChapters={allChapters}
        />
      )}

      {!isChapterUpload && (
        <FullBookFlow
          book={book}
          isBookLoading={isBookLoading}
          languages={data}
          loading={loading}
          allChapters={allChapters}
          isChaptersLoading={isChaptersLoading}
          getAllChapters={getAllChapters}
        />
      )}
    </Flex>
  )
}

export default Audio
