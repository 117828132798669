import { useLazyQuery, useMutation } from '@apollo/client'
import { Flex, useDisclosure, useToast } from '@chakra-ui/react'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import { API_V2 } from 'constants/apollo'
import useSearchQuery from 'hooks/useSearchQuery'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ADD_BLACKLIST_ITEM,
  ADD_BLACKLIST_REQUEST,
  GET_ALL_BLACKLIST,
  REMOVE_BLACKLIST_ITEM,
  REMOVE_BLACKLIST_ITEM_REQUEST,
  UPDATE_BLACKLIST_ITEM_REQUEST,
} from 'services/comments/graphql'
import InfoElement from '../InfoElement/InfoElement'
import { colors } from 'shared/style/colors'
import Pagination from 'components/UI/Pagination'
import BlackListTable from '../BlackListTable'
import AuthSevice from 'services/auth'
import { USER_ROLES } from 'constants/users'
import NewBlacklistField from '../NewBlacklistField'
import AddBlacklistItemModal from '../AddBlacklistItemModal'
import BlacklistFilters from '../BlacklistFilters'

const ITEMS = 20

const BlacklistModerationLayout = () => {
  const { status } = useSearchQuery()
  const [newItem, setNewItem] = useState('')
  const [items, setItems] = useState([])
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const isSupport = AuthSevice.role === USER_ROLES.SUPPORT
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const shouldShowPagination = ITEMS < total

  const changePage = value => {
    setPage(curr => curr + value)
  }

  const onChange = val => {
    setNewItem(val)
  }

  const handleCancel = useCallback(() => {
    setNewItem('')
    onClose()
  }, [onClose])

  const initialVariables = useMemo(() => {
    const init = {
      pagination: {
        offset: 0,
        count: ITEMS,
      },
    }

    if (status) {
      init.statuses = status.split(',')
    }

    return init
  }, [status])

  const [load, { loading: isDataLoading }] = useLazyQuery(GET_ALL_BLACKLIST, {
    fetchPolicy: 'no-cache',
    context: {
      clientName: API_V2,
    },
  })

  const [updateRequest] = useMutation(UPDATE_BLACKLIST_ITEM_REQUEST, {
    context: {
      clientName: API_V2,
    },
  })
  const [removeRequest] = useMutation(REMOVE_BLACKLIST_ITEM_REQUEST, {
    context: {
      clientName: API_V2,
    },
  })
  const [removeBLItem] = useMutation(REMOVE_BLACKLIST_ITEM, {
    context: {
      clientName: API_V2,
    },
  })
  const [addBlacklistRequest] = useMutation(ADD_BLACKLIST_REQUEST, {
    context: {
      clientName: API_V2,
    },
  })
  const [addBlacklist] = useMutation(ADD_BLACKLIST_ITEM, {
    context: {
      clientName: API_V2,
    },
  })

  const loadRequests = useCallback(
    async offset => {
      setLoading(true)

      const init = {
        pagination: {
          offset,
          count: ITEMS,
        },
      }

      if (status) {
        init.statuses = status.split(',')
      }
      const {
        data: { blacklist },
      } = await load({
        variables: {
          ...init,
        },
        fetchPolicy: 'no-cache',
        context: {
          clientName: API_V2,
        },
      })

      if (blacklist.items) {
        setItems(blacklist.items)
        setTotal(blacklist.pagination.total)
      }

      setLoading(false)
    },
    [load, status],
  )

  const addWordToBlacklist = useCallback(async () => {
    if (isSupport) {
      if (newItem.trim()) {
        try {
          const {
            data: { blItem },
          } = await addBlacklistRequest({
            variables: {
              item: {
                status: 'APPROVED',
                text: newItem.trim(),
              },
            },
            context: {
              clientName: API_V2,
            },
          })

          if (blItem.id) {
            toast({ title: 'Created', status: 'success', isClosable: true })
            handleCancel()
            loadRequests(0)
          } else {
            throw new Error('error')
          }
        } catch (error) {
          toast({
            title: 'Failed',
            description: 'Failed to create request',
            status: 'error',
            isClosable: true,
          })
        }
      }
    } else {
      if (newItem.trim()) {
        try {
          const {
            data: { result },
          } = await addBlacklist({
            variables: {
              item: {
                status: 'APPROVED',
                text: newItem.trim(),
              },
            },
            context: {
              clientName: API_V2,
            },
          })

          if (result.success) {
            toast({ title: 'Added', status: 'success', isClosable: true })
            handleCancel()
          } else {
            throw new Error('error')
          }
        } catch (error) {
          toast({
            title: 'Failed',
            description: 'Failed to add',
            status: 'error',
            isClosable: true,
          })
        }
      }
    }

    load({
      variables: {
        ...initialVariables,
      },
    })
  }, [
    isSupport,
    newItem,
    addBlacklistRequest,
    toast,
    handleCancel,
    addBlacklist,
    load,
    initialVariables,
    loadRequests,
  ])

  const removeRequestItem = useCallback(
    async (id, isRequest) => {
      try {
        let operationResult = false

        if (isSupport || isRequest) {
          const {
            data: { result },
          } = await removeRequest({
            variables: {
              item: {
                id,
              },
            },
            context: {
              clientName: API_V2,
            },
          })

          operationResult = result.success
        } else {
          const {
            data: { result },
          } = await removeBLItem({
            variables: {
              item: {
                id,
              },
            },
            context: {
              clientName: API_V2,
            },
          })

          operationResult = result.success
        }

        if (operationResult) {
          toast({ title: 'Canceled', status: 'success', isClosable: true })
          loadRequests(page * ITEMS)
          return true
        } else {
          throw new Error('error')
        }
      } catch (error) {
        toast({
          title: 'Failed',
          description: 'Failed to cancel blacklist item request',
          status: 'error',
          isClosable: true,
        })
      }

      return false
    },
    [loadRequests, isSupport, page, removeBLItem, removeRequest, toast],
  )

  const updateRequestItem = useCallback(
    async (id, text) => {
      try {
        const {
          data: { item },
        } = await updateRequest({
          variables: {
            item: {
              id,
              text,
            },
          },
          context: {
            clientName: API_V2,
          },
        })

        if (item.id === id) {
          toast({ title: 'Updated', status: 'success', isClosable: true })
          setItems(curr =>
            curr.map((c, i) => (c.id === id ? { ...c, ...item } : c)),
          )
          return true
        } else {
          throw new Error('error')
        }
      } catch (error) {
        toast({
          title: 'Failed',
          description: 'Failed to update blacklist item',
          status: 'error',
          isClosable: true,
        })
      }

      return false
    },
    [toast, updateRequest],
  )

  const update = useCallback(
    async (id, status) => {
      try {
        const {
          data: { item },
        } = await updateRequest({
          variables: {
            item: {
              id,
              status,
            },
          },
          context: {
            clientName: API_V2,
          },
        })

        if (item.id === id) {
          toast({ title: 'Updated', status: 'success', isClosable: true })
          setItems(curr =>
            curr.map((c, i) => (c.id === id ? { ...c, ...item } : c)),
          )
          return true
        } else {
          throw new Error('error')
        }
      } catch (error) {
        toast({
          title: 'Failed',
          description: 'Failed to update blacklist item',
          status: 'error',
          isClosable: true,
        })
      }

      return false
    },
    [toast, updateRequest],
  )

  useEffect(() => {
    if (page === 0) {
      loadRequests(0)
    } else {
      loadRequests(page * ITEMS)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialVariables, page])

  const isLoad = loading || isDataLoading

  return (
    <Flex w='100%' h='100%' direction='column' overflow='hidden'>
      {isLoad && <LoadingSpinner />}

      {!isLoad && items.length === 0 && (
        <InfoElement
          title='No BlackList items'
          image='/images/hero/denied.png'
        />
      )}

      <NewBlacklistField
        newItem={newItem}
        onChange={onChange}
        onOpen={onOpen}
      />

      <BlacklistFilters status={status} />

      {items.length !== 0 && !isLoad && (
        <Flex direction='column'>
          <Flex
            w='100%'
            maxH={`calc(100vH - 66px - 66px - 120px${
              isSupport ? '' : ' - 86px'
            } - 25px)`}
            border={`1px solid ${colors['primary-gray-10']}`}
            bg={colors.white}
            borderRadius='10px'
            direction='column'
          >
            <BlackListTable
              items={items}
              updateRequestItem={updateRequestItem}
              removeRequestItem={removeRequestItem}
              update={update}
            />

            {shouldShowPagination && (
              <Pagination
                changePage={changePage}
                page={page}
                total={total}
                perPage={ITEMS}
              />
            )}
          </Flex>
        </Flex>
      )}

      {isOpen && (
        <AddBlacklistItemModal
          word={newItem.trim()}
          onClose={handleCancel}
          addWordToBlacklist={addWordToBlacklist}
          isOpen={isOpen}
          isSupport={isSupport}
        />
      )}
    </Flex>
  )
}

export default BlacklistModerationLayout
