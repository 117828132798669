import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Selection from '../Selection'
import { Accordion, Flex, useToast } from '@chakra-ui/react'
import { baseScrollBar } from 'constants/scrollbar'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import BaseSettings from '../BaseSettings'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_ALL_SCREENS, UPDATE_SCREEN } from 'services/screens/graphql'
import { API_V2 } from 'constants/apollo'
import SectionsSelector from '../SectionsSelector'
import { useLocation, useNavigate } from 'react-router-dom'
import { GET_SECTIONS } from 'services/sections/graphql'
import { isEqual } from 'lodash'

const ManageScreen = () => {
  const [type, setType] = useState('')
  const [title, setTitle] = useState('')
  const [loading, setLoading] = useState(false)
  const [isDefault, setIsDefault] = useState(false)
  const [selectedSections, setSelectedSections] = useState([])
  const toast = useToast()
  const navigate = useNavigate()
  const { state } = useLocation()

  const initialData = useMemo(() => {
    if (!state.item) return {}

    const { title, isDefault, type, screenSections } = state.item

    return {
      title,
      isDefault,
      type,
      screenSections: screenSections.map(item => ({ id: item.sectionId })),
    }
  }, [state])

  const canUpdateData = useMemo(() => {
    const newData = {
      title,
      type,
      isDefault,
      screenSections: selectedSections.map(item => ({ id: item.id })),
    }

    return !isEqual(newData, initialData)
  }, [initialData, title, isDefault, type, selectedSections])

  const toggleScreenType = type => {
    setType(type)
  }

  const handleInputTitle = e => {
    setTitle(e.target.value)
  }

  const toggleDefault = () => {
    setIsDefault(curr => !curr)
  }

  const handleAddSection = useCallback(section => {
    setSelectedSections(curr => [...curr, section])
  }, [])

  const handleRemoveSection = useCallback(section => {
    setSelectedSections(curr => {
      const remain = curr.filter(item => section.id !== item.id)

      return remain
    })
  }, [])

  const handleOnDragSection = useCallback(
    async result => {
      const { source, destination } = result

      if (!destination || source.index === destination.index) return

      const items = [...(selectedSections || [])]
      const [reorderedItem] = items.splice(source.index, 1)
      items.splice(destination.index, 0, reorderedItem)

      setSelectedSections(items)
    },
    [selectedSections],
  )

  const [updateScreen] = useMutation(UPDATE_SCREEN)

  const handleSubmit = async () => {
    if (!type || !title.trim()) {
      return
    }

    setLoading(true)
    try {
      const screenVariables = {
        params: {
          title,
          type,
          isDefault,
        },
        screen: { id: state.item.id },
      }

      if (selectedSections.length > 0) {
        screenVariables.params.sections = selectedSections.map(item => ({
          id: item.id,
        }))
      }

      const {
        data: { screen },
      } = await updateScreen({
        variables: screenVariables,
        context: {
          clientName: API_V2,
        },
        refetchQueries: [GET_ALL_SCREENS],
      })

      if (screen.id) {
        toast({ title: 'Created', status: 'success', isClosable: true })
        setTimeout(() => {
          navigate(`${window.location.pathname}?active=screens&page=0&slug`)
          // eslint-disable-next-line no-magic-numbers
        }, 1000)
      } else {
        throw new Error('error')
      }
    } catch (e) {
      toast({
        title: 'Failed',
        description: `Failed to create screen ${e.message}`,
        status: 'error',
        isClosable: true,
      })
    } finally {
      setLoading(false)
    }
  }

  const [loadSections] = useLazyQuery(GET_SECTIONS, {
    context: {
      clientName: API_V2,
    },
    fetchPolicy: 'cache-and-network',
  })

  const handleLoadSection = async screenSections => {
    const sections = []

    for (const section of screenSections) {
      const {
        data: { items },
      } = await loadSections({
        variables: {
          pagination: {
            offset: 0,
            count: 1,
          },
          section: {
            id: section.sectionId,
          },
        },
        context: {
          clientName: API_V2,
        },
      })

      sections.push(items.sections[0])
    }

    setSelectedSections(sections)
  }

  const handleClearData = () => {
    setTitle('')
    setType('')
    setIsDefault(false)
    setSelectedSections([])
  }

  useEffect(() => {
    if (!state?.item) {
      navigate(`${window.location.pathname}?active=screens&page=0&slug`)
    } else {
      const { title, isDefault, type, screenSections } = state.item

      setTitle(title)
      setType(type)
      setIsDefault(isDefault)

      if (screenSections.length > 0) {
        handleLoadSection(screenSections)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <Flex p='10px 20px 0' direction='column' overflow='hidden' flex={1}>
      {loading && <LoadingSpinner />}

      <Accordion
        allowMultiple
        w='100%'
        display='flex'
        flexDirection='column'
        gap='12px'
        overflow='hidden'
        h='100%'
      >
        <Flex
          direction='column'
          overflow='auto'
          sx={baseScrollBar}
          gap='12px'
          pb='30px'
          h='100%'
        >
          <Selection
            place={type}
            title={title}
            isDefault={isDefault}
            handleSubmit={handleSubmit}
            selectedSections={selectedSections}
            handleClearData={handleClearData}
            handleRemoveSection={handleRemoveSection}
            handleOnDragSection={handleOnDragSection}
            canUpdateData={canUpdateData}
            isManager
          />

          <BaseSettings
            place={type}
            title={title}
            toggleScreenType={toggleScreenType}
            handleInputTitle={handleInputTitle}
            isDefault={isDefault}
            toggleDefault={toggleDefault}
          />

          <SectionsSelector
            selectedSections={selectedSections}
            handleAddSection={handleAddSection}
          />
        </Flex>
      </Accordion>
    </Flex>
  )
}

export default ManageScreen
