import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Button, Flex, Heading, useToast } from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import {
  GET_BOOKS_BY_SEARCH,
  GET_FULL_BOOK,
  GET_RECOMMENDATION_BOOKS_DATA,
  NEW_UPDATE_RECOMMENDATIONS,
} from 'services/book/graphql'
import RecomendationItem from '../RecomendationItem'
import RecomendationSelector from 'screens/Books/containers/BookModal/RecomendationItem/RecomendationSelector'
import LoadingSpinner from 'components/UI/LoadingSpinner'

const MAX_RECOMMENDATIONS = 6

const Recommendations = ({ book: oneBook }) => {
  const [isSelecting, setIsSelecting] = useState(false)
  const [booksList, setBooksList] = useState([])
  const [query, setQuery] = useState('')
  const toast = useToast()
  const { recommendations, book_id } = oneBook

  const { data: recommendedBooks, loading: isRecommendationLoading } = useQuery(
    GET_RECOMMENDATION_BOOKS_DATA,
    {
      variables: {
        recommendations: recommendations.map(data => ({
          books: { book_id: data.book_id },
        })),
      },
    },
  )

  const [getBooks, { data: books, loading: isBooksLoaded }] = useLazyQuery(
    GET_BOOKS_BY_SEARCH,
    {
      fetchPolicy: 'network-only',
    },
  )

  const [changeRecommendations, { loading: isUpdateLoading }] = useMutation(
    NEW_UPDATE_RECOMMENDATIONS,
    {
      refetchQueries: [
        {
          query: GET_FULL_BOOK,
          variables: {
            book_id,
          },
        },
        {
          query: GET_RECOMMENDATION_BOOKS_DATA,
          variables: {
            recommendations: recommendations.map(data => ({
              books: { book_id: data.book_id },
            })),
          },
        },
      ],
    },
  )

  const isAlreadyRecommendation = useCallback(
    book_id =>
      recommendations
        ? recommendations.some(book => book.book_id === book_id)
        : false,
    [recommendations],
  )

  const filteredBooksList = useMemo(
    () =>
      booksList.filter(
        item =>
          !isAlreadyRecommendation(item?.book_id) && item?.book_id !== book_id,
      ),
    [book_id, isAlreadyRecommendation, booksList],
  )

  const handleQueryChange = useCallback(value => {
    setQuery(value)
  }, [])

  const handleShowBooks = useCallback(() => setIsSelecting(true), [])

  const handleHideBooks = useCallback(() => setIsSelecting(false), [])

  const handleOnDragRecomendation = useCallback(
    async result => {
      const { source, destination } = result

      if (!destination || source.index === destination.index) return

      const items = [...(recommendations || [])].map(item => ({
        book_id: item.book_id,
      }))
      const [reorderedItem] = items.splice(source.index, 1)
      items.splice(destination.index, 0, reorderedItem)

      const updatedBook = {
        book_id,
        recommendations: items.map((item, index) => ({
          book_id: item.book_id,
          sequenceNumber: index + 1,
        })),
      }

      const {
        data: { result: operationResult },
      } = await changeRecommendations({
        variables: {
          ...updatedBook,
        },
      })

      if (operationResult) {
        toast({
          title: `Book ${reorderedItem.title} was replaced!`,
          status: 'success',
          isClosable: true,
          duration: 1000,
          position: 'top',
        })
      }
    },
    [recommendations, book_id, changeRecommendations, toast],
  )

  const handleBookDeleteRecommendation = useCallback(
    async (deletedBookId, title) => {
      const isConfirm = window.confirm('Are you sure?')

      if (isConfirm) {
        const updatedBook = {
          book_id,
          recommendations: [
            {
              book_id: deletedBookId,
              delete: true,
            },
          ],
        }

        const {
          data: { result },
        } = await changeRecommendations({
          variables: {
            ...updatedBook,
          },
        })

        if (result) {
          toast({
            title: `Book ${title} was deleted from recommendations!`,
            status: 'success',
            isClosable: true,
            duration: 1000,
            position: 'top',
          })
        }
      }
    },
    [book_id, changeRecommendations, toast],
  )

  const handleBookAddRecommendation = useCallback(
    async (addedBookId, title) => {
      if (recommendations?.length >= MAX_RECOMMENDATIONS) {
        return
      }

      const updatedBook = {
        book_id: oneBook.book_id,
        recommendations: [
          {
            book_id: addedBookId,
            sequenceNumber: (recommendations || []).length + 1,
          },
        ],
      }

      const {
        data: { result },
      } = await changeRecommendations({
        variables: {
          ...updatedBook,
        },
      })

      if (result) {
        toast({
          title: `Book ${title} was added to recommendations!`,
          status: 'success',
          isClosable: true,
          duration: 1000,
          position: 'top',
        })
      }
    },
    [recommendations, oneBook, changeRecommendations, toast],
  )

  useEffect(() => {
    if (!isBooksLoaded && books) {
      setBooksList(books.booksSearch)
    }
  }, [books, isBooksLoaded])

  useEffect(() => {
    if (query) {
      getBooks({
        variables: {
          deleted: false,
          title: query,
          limit: 10000,
        },
      })
    }
  }, [query, getBooks])

  return (
    <>
      <Button
        variant='defaultButton'
        w='fit-content'
        m='0 auto 20px'
        onClick={isSelecting ? handleHideBooks : handleShowBooks}
      >
        {isSelecting ? 'Go Back' : 'Add Book'}
      </Button>

      {isUpdateLoading || (isRecommendationLoading && <LoadingSpinner />)}

      {!isSelecting && !isUpdateLoading && (
        <Flex justify='center' mb='24px'>
          <DragDropContext onDragEnd={handleOnDragRecomendation}>
            <Droppable
              droppableId='recommendations'
              style={{ transform: 'none' }}
            >
              {provided => (
                <Flex
                  direction='column'
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  userSelect='none'
                  gap='10px'
                  width='100%'
                  maxW='500px'
                >
                  {recommendations?.length > 0 &&
                    recommendedBooks &&
                    recommendedBooks.books.map((book, index) => (
                      <Draggable
                        key={book.book_id}
                        draggableId={book.book_id}
                        index={index}
                      >
                        {provided => (
                          <RecomendationItem
                            bookRef={provided.innerRef}
                            draggableProps={provided.draggableProps}
                            dragHandleProps={provided.dragHandleProps}
                            handleBookDeleteRecommendation={() =>
                              handleBookDeleteRecommendation(
                                book.book_id,
                                book.title,
                              )
                            }
                            {...book}
                          />
                        )}
                      </Draggable>
                    ))}
                  {recommendations?.length === 0 && (
                    <Heading
                      size='poppins24'
                      variant='primary-black-text'
                      textAlign='center'
                    >
                      This Book has no recommendations yet
                    </Heading>
                  )}
                  {provided.placeholder}
                </Flex>
              )}
            </Droppable>
          </DragDropContext>
        </Flex>
      )}

      {isSelecting && (
        <>
          {isUpdateLoading && <LoadingSpinner />}

          {recommendations?.length < MAX_RECOMMENDATIONS && (
            <RecomendationSelector
              booksList={filteredBooksList}
              handleQueryChange={handleQueryChange}
              handleBookAddRecommendation={handleBookAddRecommendation}
              isBooksLoaded={isBooksLoaded}
            />
          )}

          {recommendations?.length >= MAX_RECOMMENDATIONS && (
            <Heading
              size='poppins24'
              variant='primary-black-text'
              textAlign='center'
            >
              This Book has maximum recommendations
            </Heading>
          )}
        </>
      )}
    </>
  )
}

export default Recommendations
