// eslint-disable-next-line import/named
import { colors } from '../colors'
import { baseScrollBar } from 'constants/scrollbar'

export const webKitStyle = {
  webkitBoxShadow: `0 0 0 30px ${colors['primary-violet-10']} inset !important`,
  boxShadow: `0 0 0 30px ${colors['primary-violet-10']} inset !important`,
}

export const description = {
  padding: '24px',
  width: '100%',
  height: '54px',
  fontSize: '18px',
  lineHeight: '32px',
  borderRadius: '8px',
  backgroundColor: colors['primary-violet-10'],
  color: colors['primary-black-text'],
  border: `1px solid transparent`,
  fontFamily: `'Poppins', sans-serif`,
  _hover: {
    outline: 'none',
  },
  _placeholder: {
    color: colors['primary-gray-70'],
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '18px',
    letterSpacing: '-0.01em',
  },
  _autofill: webKitStyle,
  _focus: {
    outline: 'none',
    borderColor: colors['primary-violet-100'],
  },
  ...baseScrollBar,
}

export const descriptionError = {
  ...description,
  border: `1px solid ${colors['light-text-text-danger']}`,
}

export const deleteReason = {
  ...description,
  caretColor: colors['light-text-text-danger'],
  fontSize: '16px',
  lineHeight: '28px',
  _placeholder: {
    ...description._placeholder,
    fontSize: '16px',
    lineHeight: '28px',
  },
  _focus: {
    ...description._focus,
    borderColor: colors['light-text-text-danger'],
  },
  border: `1px solid ${colors['light-text-text-danger']}`,
}
