import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  keyframes,
  useToast,
  Heading,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { colors } from 'shared/style/colors'
import { baseScrollBar } from 'constants/scrollbar'
import { useMutation } from '@apollo/client'
import { API_V2 } from 'constants/apollo'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import { GET_SECTIONS, REMOVE_SECTION } from 'services/sections/graphql'

const RemoveSectionModal = ({ onClose, section }) => {
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const [remove] = useMutation(REMOVE_SECTION, {
    refetchQueries: [GET_SECTIONS],
  })

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const {
        data: { result },
      } = await remove({
        variables: {
          section: {
            id: section.id,
          },
        },
        context: {
          clientName: API_V2,
        },
      })

      if (result.success) {
        toast({ title: 'Removed', status: 'success', isClosable: true })
      } else {
        throw new Error('error')
      }
    } catch {
      toast({
        title: 'Failed',
        description: 'Failed to remove section',
        status: 'error',
        isClosable: true,
      })
    } finally {
      onClose()
      setLoading(false)
    }
  }

  const appear = keyframes({
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const animation = `${appear} 0.3s ease-in-out 1`

  return (
    <>
      <Box
        pos='fixed'
        zIndex={1400}
        top={0}
        left={0}
        width='100vw'
        height='100vh'
        background='rgba(0, 0, 0, 0.48);'
        blendMode='multiply'
        opacity={1}
      />

      <Flex
        pos='fixed'
        zIndex={1500}
        top={0}
        left={0}
        width='100vw'
        height='100vh'
        justify='center'
        pt='61px'
        overflow='auto'
        sx={baseScrollBar}
      >
        <Flex
          bg={colors.white}
          pos='relative'
          p='72px 85px 44px'
          borderRadius='16px'
          border={`1px solid ${colors.stroke}`}
          w='600px'
          h='fit-content'
          animation={animation}
          boxShadow='0px 5px 15px 0px #241C5833'
        >
          <Image
            pos='absolute'
            src='/images/close-circle.svg'
            alt='close icon'
            top='24px'
            right='24px'
            cursor='pointer'
            onClick={onClose}
          />

          {loading && <LoadingSpinner />}

          <Flex direction='column'>
            <Image
              src='/images/screens/remove_section.png'
              alt='notebook'
              w='200px'
              h='200px'
              mb='12px'
              loading='eager'
              alignSelf='center'
            />
            <Heading
              size='poppins24'
              variant='light-text-text-danger'
              lineHeight='32px'
              mb='12px'
              textAlign='center'
            >
              Delete section?
            </Heading>

            <Text
              size='poppins16normal'
              variant='primary-black-text'
              whiteSpace='nowrap'
              w='100%'
              mb='12px'
            >
              If you sure just click <b>&ldquo;Confirm&rdquo;</b> to delete
              section
            </Text>

            <Text
              size='poppins16normal'
              variant='primary-black-text'
              whiteSpace='nowrap'
              w='100%'
              mb='24px'
            >
              Slug: <b>{section.slug}</b>
              <br />
              Place: <b>{section.type}</b>
              <br />
              Content type: <b>{section.contentType}</b>
              <br />
              Title: <b>{section.title}</b>
              <br />
              Description: <b>{section.description}</b>
            </Text>

            <Flex gap='23px'>
              <Button variant='outlineButton' onClick={onClose} w='204px'>
                Cancel
              </Button>

              <Button variant='defaultButton' onClick={handleSubmit} w='204px'>
                Confirm
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default RemoveSectionModal
