import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import getAreaCoords from '../../../../utils/getAreaCoords'
import isInside from '../../../../utils/isInside'
import styles from './styles.module.scss'
import SearchBar from '../../../../components/UI/SearchBar'
import { debounce } from 'lodash'
import BookService from 'services/book'
import { DEBOUNCE_DELAY } from 'constants/app'

const FormBannerBookSelect = () => {
  const [field, meta] = useField({ name: 'attachment' })
  const { setFieldValue } = useFormikContext()
  const selectDropDownRef = useRef(null)

  const [isOpenSelect, setIsOpenSelect] = useState(false)
  const [search, setSearch] = useState('')
  const [searchedBooksList, setSearchedBooksList] = useState([])

  const handleSelectOpen = useCallback(() => setIsOpenSelect(true), [])

  const handleSelectClose = useCallback(() => setIsOpenSelect(false), [])

  const handleSearchChange = useCallback(e => setSearch(e.target.value), [])

  const debouncedSearchChange = debounce(handleSearchChange, DEBOUNCE_DELAY)

  const handleFetchBooksBySearch = useCallback(async () => {
    const books = await BookService.searchBooks({
      title: search,
    })
    setSearchedBooksList(books)
  }, [search])

  const handleBannerBookSelect = useCallback(
    book => {
      setFieldValue('attachment', book)
      handleSelectClose()
      setSearch('')
    },
    [handleSelectClose, setFieldValue],
  )

  const handleDropDownBlur = useCallback(
    e => {
      const { clientX, clientY } = e
      const currentPointerPosition = {
        clientX,
        clientY,
      }
      if (selectDropDownRef.current) {
        const dropDownCoords = getAreaCoords(
          selectDropDownRef.current.getBoundingClientRect(),
        )

        const isCurrentPointerIsInsideDropdown = isInside(
          currentPointerPosition,
          dropDownCoords,
        )

        if (!isCurrentPointerIsInsideDropdown) {
          handleSelectClose()
        }
      }
    },
    [handleSelectClose],
  )
  useEffect(() => {
    if (isOpenSelect) {
      document.body.addEventListener('click', handleDropDownBlur)
      return () => {
        document.body.removeEventListener('click', handleDropDownBlur)
      }
    }
  }, [handleDropDownBlur, isOpenSelect])

  useEffect(() => {
    return () => setFieldValue('attachment', null)
  }, [setFieldValue])

  useEffect(() => {
    return () => {
      debouncedSearchChange.cancel()
    }
  }, [debouncedSearchChange])

  useEffect(() => {
    if (search) {
      return handleFetchBooksBySearch()
    }
    setSearchedBooksList([])
  }, [handleFetchBooksBySearch, search])

  return (
    <div className={styles.select} onClick={handleSelectOpen}>
      {!field.value ? (
        'Select a book'
      ) : (
        <div className={styles.bannerBook}>
          <img
            src={field.value?.cover_link || '/images/book.png'}
            alt='book-cover'
            className={styles.bannerBookCover}
          />
          <div className={styles.bannerBookInfo}>
            <b>Book title: </b>
            {field.value?.title}
          </div>
        </div>
      )}
      {isOpenSelect && (
        <div className={styles.selectDropDown} ref={selectDropDownRef}>
          <SearchBar search={search} onChange={handleSearchChange} />
          <div className={styles.bookList}>
            {searchedBooksList.map(book => (
              <div
                className={styles.book}
                key={book.book_id}
                onClick={e => {
                  e.stopPropagation()
                  handleBannerBookSelect(book)
                }}
              >
                <img
                  src={book.cover_link || '/images/book.png'}
                  alt='book-cover'
                  className={styles.bookCover}
                />
                <div className={styles.bookInfo}>
                  <b>Book title: </b>
                  {book.title}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {meta.error && meta.touched && (
        <p className={styles.error}>{meta.error}</p>
      )}
    </div>
  )
}

export default FormBannerBookSelect
