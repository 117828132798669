import { createSlice } from '@reduxjs/toolkit'
import {
  createLocalizationThunk,
  deleteLocalizationByIdThunk,
  fetchLocalizationsThunk,
  updateLocalizationThunk,
} from './thunks'
import * as R from 'ramda'

export const STATE_KEY = 'localizations'

const initialState = []

export const localizationsSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    updateSectionsOrder: (state, action) => {
      const { dragIndex, hoverIndex, localization_id } = action.payload
      const sections = R.clone(state[localization_id].sections)
      const tmp = sections[dragIndex]
      sections[dragIndex] = sections[hoverIndex]
      sections[hoverIndex] = tmp
      state[localization_id].sections = sections.map(
        ({ section }, order_number) => ({
          section,
          order_number,
        }),
      )
    },
    updateListsOrder: (state, action) => {
      const { dragIndex, hoverIndex, localization_id } = action.payload
      const lists = R.clone(state[localization_id].lists)
      const tmp = lists[dragIndex]
      lists[dragIndex] = lists[hoverIndex]
      lists[hoverIndex] = tmp

      state[localization_id].lists = lists.map(({ list }, order_number) => ({
        list,
        order_number,
      }))
    },
  },
  extraReducers: {
    [fetchLocalizationsThunk.fulfilled]: (_, action) => {
      return R.indexBy(R.prop('localization_id'), action.payload)
    },
    [createLocalizationThunk.fulfilled]: (state, action) => {
      const localization = action.payload
      state[localization.localization_id] = { ...localization, sections: [] }
    },
    [deleteLocalizationByIdThunk.fulfilled]: (state, action) => {
      const { localization_id } = action.payload
      delete state[localization_id]
    },
    [updateLocalizationThunk.fulfilled]: (state, action) => {
      const localization = action.payload
      state[localization.localization_id] = localization
    },
  },
})

export const { updateSectionsOrder, updateListsOrder } =
  localizationsSlice.actions

export const localizationsReducer = localizationsSlice.reducer

export const { setSections } = localizationsSlice.actions
