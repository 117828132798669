import React, { memo } from 'react'
import { ButtonGroup, ButtonToolbar, Modal, Button } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import FormFileInput from '../../../../../components/FormControls/FormFileInput'
import FormGroup from '../../../../../components/FormControls/FormGroup'
import { createBannerValidationScheme } from '../../../models'
import FormBannerTypeSelect from '../../FormBannerTypeSelect'
import { BannerTypes } from '../../../../../constants/banners'
import FormBannerBookSelect from '../../FormBannerBookSelect'
import FormBannerAdvertisementSelect from '../../FormBannerAdveritsementSelect'
import FormField from '../../../../../components/FormControls/FormField'

const CreateBannerModal = ({ books, isShow, onHide, onSubmit }) => (
  <Modal show={isShow} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>Add a new banner</Modal.Title>
    </Modal.Header>
    <Formik
      initialValues={{
        cover_url: '',
        file: null,
        banner_type: BannerTypes.BOOKS,
        attachment: null,
      }}
      validationSchema={createBannerValidationScheme}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <Form>
          <Modal.Body style={{ paddingBottom: 35 }}>
            <FormField label='Cover url' name='cover_url' />
            <FormGroup columns={2}>
              <FormFileInput label='Banner cover' />
              <FormBannerTypeSelect />
            </FormGroup>
            {values.banner_type === BannerTypes.BOOKS && (
              <FormBannerBookSelect />
            )}
            {values.banner_type === BannerTypes.ADVERTISEMENTS && (
              <FormBannerAdvertisementSelect />
            )}
          </Modal.Body>
          <Modal.Footer>
            <ButtonToolbar>
              <ButtonGroup className='me-2'>
                <Button variant='danger' onClick={onHide}>
                  Cancel
                </Button>
              </ButtonGroup>
              <ButtonGroup className='me-2'>
                <Button variant='primary' type='submit'>
                  Save
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  </Modal>
)

export default memo(CreateBannerModal)
