import { Flex, Image, Select, Text } from '@chakra-ui/react'
import React from 'react'

const AudioOption = ({
  file,
  availableLocales,
  handleAddLocale,
  selectedLang,
  handleRemoveLocale,
}) => {
  const [, value] = file

  return (
    <Flex
      gap='4px'
      p='10px 12px'
      align='center'
      borderBottom='1px solid black'
      w='100%'
      justify='space-between'
      pr='30px'
    >
      <Text
        size='poppins18'
        variant='primary-black-text'
        fontWeight={400}
        lineHeight='32px'
        letterSpacing='-0.018px'
        maxW='800px'
        w='800px'
        overflow='hidden'
        textOverflow='ellipsis'
        whiteSpace='nowrap'
      >
        {value.name}
      </Text>

      {selectedLang && (
        <Flex gap='4px' p='10px 12px' align='center'>
          <Image
            w='31px'
            h='22px'
            src={`/images/flags/${selectedLang.code.toLowerCase()}.png`}
            alt={selectedLang.title}
          />

          <Text
            size='poppins18'
            variant='primary-black-text'
            fontWeight={400}
            lineHeight='32px'
            letterSpacing='-0.018px'
            cursor='pointer'
            w='fit-content'
            pr='12px'
          >
            {selectedLang.code.toUpperCase()}
          </Text>

          <Image
            w='32px'
            h='32px'
            p='5px'
            src={`/images/close-circle.svg`}
            alt={'remove'}
            cursor='pointer'
            onClick={() => handleRemoveLocale(selectedLang)}
          />
        </Flex>
      )}

      {!selectedLang && (
        <Select
          w='200px'
          placeholder='Select locale'
          onChange={e => handleAddLocale({ code: e.target.value, file: value })}
        >
          {availableLocales.map(locale => (
            <option value={locale.code} key={locale.code}>
              {locale.title} - {locale.code.toUpperCase()}
            </option>
          ))}
        </Select>
      )}
    </Flex>
  )
}

export default AudioOption
