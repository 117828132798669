import React, { memo } from 'react'

import SectionsList from '../SectionsList'

const ListsPanelLayout = ({
  lists,
  deleteList,
  filteredBooks,
  filteredGeneres,
  getIsSelectedBook,
  getIsSelectedGenre,
  openAppendBookModal,
  openAppendGenreModal,
  isOpenAppendBookModal,
  isOpenAppendGenreModal,
  deleteListContentOption,
  closeAppendBookModal,
  closeAppendGenreModal,
  updatedList,
  selectBook,
  selectGenre,
  updateGenreList,
  updateBooksList,
  updateListItemsOrder,
  showAppendSurveyModal,
  hideAppendSurveyModal,
  isShowAppendSurveyModal,
  newSurvey,
  onNewSurveyChange,
  updateSurveySection,
  deleteListSurveyLink,
  filteredBanners,
  isShowAppendBannerModal,
  showAppendBannerModal,
  hideAppendBannerModal,
  getIsSelectedBanner,
  selectBanner,
  updateBannersSection,
  booksByGenreList,
  openGenreBooks,
  getSelectedGenreIndex,
  getSelectedBookIndex,
  getSelectedBannerIndex,
  seriesBookSearch,
  handleSeriesBookSearchChange,
  moveSurveys,
  isShowTagModal,
  openTagModal,
  closeTagModal,
  filteredTags,
  getIsSelectedTag,
  getSelectedTagIndex,
  selectTag,
  updateTagList,
  setShowDeleted,
  showDeleted,
}) => (
  <>
    <SectionsList
      closeAppendBookModal={closeAppendBookModal}
      closeAppendGenreModal={closeAppendGenreModal}
      deleteList={deleteList}
      deleteListContentOption={deleteListContentOption}
      filteredBooks={filteredBooks}
      filteredGeneres={filteredGeneres}
      getIsSelectedBook={getIsSelectedBook}
      getIsSelectedGenre={getIsSelectedGenre}
      isOpenAppendBookModal={isOpenAppendBookModal}
      isOpenAppendGenreModal={isOpenAppendGenreModal}
      openAppendBookModal={openAppendBookModal}
      openAppendGenreModal={openAppendGenreModal}
      selectBook={selectBook}
      selectGenre={selectGenre}
      updateGenreList={updateGenreList}
      updateBooksList={updateBooksList}
      updateListItemsOrder={updateListItemsOrder}
      updatedList={updatedList}
      lists={lists}
      showAppendSurveyModal={showAppendSurveyModal}
      hideAppendSurveyModal={hideAppendSurveyModal}
      isShowAppendSurveyModal={isShowAppendSurveyModal}
      newSurvey={newSurvey}
      onNewSurveyChange={onNewSurveyChange}
      updateSurveySection={updateSurveySection}
      deleteListSurveyLink={deleteListSurveyLink}
      filteredBanners={filteredBanners}
      isShowAppendBannerModal={isShowAppendBannerModal}
      showAppendBannerModal={showAppendBannerModal}
      hideAppendBannerModal={hideAppendBannerModal}
      getIsSelectedBanner={getIsSelectedBanner}
      selectBanner={selectBanner}
      updateBannersSection={updateBannersSection}
      booksByGenreList={booksByGenreList}
      openGenreBooks={openGenreBooks}
      getSelectedGenreIndex={getSelectedGenreIndex}
      getSelectedBookIndex={getSelectedBookIndex}
      getSelectedBannerIndex={getSelectedBannerIndex}
      seriesBookSearch={seriesBookSearch}
      handleSeriesBookSearchChange={handleSeriesBookSearchChange}
      moveSurveys={moveSurveys}
      isShowTagModal={isShowTagModal}
      openTagModal={openTagModal}
      closeTagModal={closeTagModal}
      filteredTags={filteredTags}
      getIsSelectedTag={getIsSelectedTag}
      getSelectedTagIndex={getSelectedTagIndex}
      selectTag={selectTag}
      updateTagList={updateTagList}
      setShowDeleted={setShowDeleted}
      showDeleted={showDeleted}
    />
  </>
)

export default memo(ListsPanelLayout)
