import {
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Text,
  Textarea,
  useDisclosure,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { colors } from 'shared/style/colors'
import $ from 'jquery'
import AddBlacklistItemModal from '../AddBlacklistItemModal'
import { useLazyQuery, useMutation } from '@apollo/client'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import { GET_CHAPTER_BY_ID } from 'services/chapters/graphql'
import { GET_BOOK } from 'services/book/graphql'
import { USER_ROLES } from 'constants/users'
import AuthSevice from 'services/auth'
import ModerationOption from '../ModerationOption'
import { baseScrollBar } from 'constants/scrollbar'
import { isEmpty } from 'lodash'
import {
  ADD_BLACKLIST_ITEM,
  ADD_BLACKLIST_REQUEST,
} from 'services/comments/graphql'
import { API_V2 } from 'constants/apollo'
import moment from 'moment'

const CommentReview = ({
  comment,
  handleReject,
  handleApprove,
  handleUpdate,
  skipComment,
  handleTranslate,
  goBack,
  reviewIndex,
  canSkip,
}) => {
  const [id, setId] = useState(null)
  const [isWide] = useMediaQuery('(min-width: 1600px)')
  const [isLoaded, setIsLoaded] = useState(false)
  const [isContentBug, setIsContentBug] = useState(comment.isContentBug)
  const [isTechBug, setIsTechBug] = useState(comment.isTechBug)
  const [reason, setReason] = useState(comment.rejectReason || '')
  const [translation, setTranslation] = useState(null)
  const commentRef = useRef(null)
  const [selected, setSelected] = useState('')
  const [isImageHover, setIsImageHover] = useState('')
  const [isShowReports, setIsShowReports] = useState(false)
  const [isShowTranslates, setIsShowTranslates] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isSupport = AuthSevice.role === USER_ROLES.SUPPORT
  const [isEdit, setIsEdit] = useState(false)
  const [editedComment, setEditedComment] = useState(comment.content)
  const [commentBook, setBook] = useState(null)
  const [commentChapter, setChapter] = useState(null)
  const toast = useToast()
  const canCancelEdit = comment.content === editedComment
  const textAreaRef = useRef(null)

  const updateTime = moment(comment.updatedAt).format('YYYY-MM-DD HH:mm')

  const clickEdit = useCallback(async () => {
    if (!isEdit) {
      setIsEdit(true)
      return
    }

    if (canCancelEdit) {
      setIsEdit(false)
      return
    }

    const result = await handleUpdate(comment.id, editedComment)

    if (result) {
      setIsEdit(false)
    }
  }, [isEdit, canCancelEdit, comment, editedComment, handleUpdate])

  const cancelEdit = useCallback(() => {
    setEditedComment(comment.content)
    setIsEdit(false)
  }, [comment])

  const [loadBook, { loading: isBookLoading }] = useLazyQuery(GET_BOOK, {
    fetchPolicy: 'cache-and-network',
  })

  const [loadChapter, { loading: isChapterLoading }] = useLazyQuery(
    GET_CHAPTER_BY_ID,
    {
      fetchPolicy: 'cache-and-network',
    },
  )

  const [addBlacklistRequest] = useMutation(ADD_BLACKLIST_REQUEST)
  const [addBlacklist] = useMutation(ADD_BLACKLIST_ITEM)

  const handleReasonInput = e => {
    setReason(e.target.value)
  }

  const handleCommentInput = e => {
    setEditedComment(e.target.value)
  }

  const handleToggleContentBug = useCallback(() => {
    setIsContentBug(curr => !curr)
  }, [])

  const handleToggleTechBug = useCallback(() => {
    setIsTechBug(curr => !curr)
  }, [])

  const handleSelect = useCallback(e => {
    e.preventDefault()
  }, [])

  const handleCancel = useCallback(() => {
    setSelected('')
    onClose()
  }, [onClose])

  const rejectComment = useCallback(async () => {
    let info = {}

    if (isContentBug !== comment.isContentBug) {
      info.isContentBug = isContentBug
    }

    if (isTechBug !== comment.isTechBug) {
      info.isTechBug = isTechBug
    }

    if (reason !== comment.rejectReason) {
      info.rejectReason = reason
    }

    if (isEmpty(info)) {
      info = null
    }

    await handleReject(comment.id, info)
  }, [comment, isContentBug, isTechBug, reason, handleReject])

  const triggerReports = useCallback(() => {
    setIsShowReports(curr => !curr)
    setIsShowTranslates(false)
  }, [])

  const handleTranslateContent = useCallback(async () => {
    setIsShowReports(false)

    if (isShowTranslates) {
      setIsShowTranslates(false)

      return
    }

    if (!comment.contentTranslates || comment.contentTranslates.length === 0) {
      const item = await handleTranslate(comment.id)

      if (item.id === comment.id) {
        setTranslation(item.contentTranslates[0].value)
        setIsShowTranslates(true)
      }
    } else {
      setTranslation(comment.contentTranslates[0].value)
      setIsShowTranslates(true)
    }
  }, [handleTranslate, comment, isShowTranslates])

  const regenerateTranslation = useCallback(async () => {
    const item = await handleTranslate(comment.id)

    if (item.id === comment.id) {
      setTranslation(item.contentTranslates[0].value)
    }
  }, [handleTranslate, comment])

  const addWordToBlacklist = useCallback(async () => {
    if (isSupport) {
      if (selected) {
        try {
          const {
            data: { blItem },
          } = await addBlacklistRequest({
            variables: {
              item: {
                status: 'APPROVED',
                text: selected,
              },
            },
            context: {
              clientName: API_V2,
            },
          })

          if (blItem.id) {
            toast({ title: 'Created', status: 'success', isClosable: true })
            onClose()
          } else {
            throw new Error('error')
          }
        } catch (error) {
          toast({
            title: 'Failed',
            description: 'Failed to create request',
            status: 'error',
            isClosable: true,
          })
        }
      }
    } else {
      if (selected) {
        try {
          const {
            data: { result },
          } = await addBlacklist({
            variables: {
              item: {
                status: 'APPROVED',
                text: selected,
              },
            },
            context: {
              clientName: API_V2,
            },
          })

          if (result.success) {
            toast({ title: 'Added', status: 'success', isClosable: true })
            onClose()
          } else {
            throw new Error('error')
          }
        } catch (error) {
          toast({
            title: 'Failed',
            description: 'Failed to add',
            status: 'error',
            isClosable: true,
          })
        }
      }
    }
  }, [isSupport, selected, addBlacklistRequest, toast, onClose, addBlacklist])

  const handleLoadBook = useCallback(
    async book_id => {
      const {
        data: { book },
      } = await loadBook({
        variables: {
          book_id,
        },
      })

      setBook(book)
    },
    [loadBook],
  )

  const handleLoadChapter = useCallback(
    async (chapter_id, shouldLoadBook) => {
      const {
        data: { chapter },
      } = await loadChapter({
        variables: {
          chapter_id,
        },
      })

      setChapter(chapter)

      if (shouldLoadBook) {
        await handleLoadBook(chapter.bookId)
      }
    },
    [loadChapter, handleLoadBook],
  )

  useEffect(() => {
    const textWrapper = document.querySelector('#text-wrapper')

    $(document).ready(function () {
      $(textWrapper).bind('contextmenu', function () {
        let t = ''
        if (window.getSelection) {
          t = window.getSelection()
        } else if (document.getSelection) {
          t = document.getSelection()
        } else if (document.selection) {
          t = document.selection.createRange().text
        }

        const userSelected = comment.content
          .slice(t.anchorOffset, t.focusOffset)
          .trim()
        setSelected(userSelected)
        onOpen()
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, comment])

  useEffect(() => {
    console.log(comment)
    const isNewChapter = id !== comment.id

    const shouldRemoveBook = !comment.bookId
    const shouldLoadBook =
      (isNewChapter && comment.bookId) ||
      (comment.bookId && commentBook?.book_id !== comment.bookId)

    const shouldRemoveChapter = !comment.chapterId
    const shouldLoadChapter =
      (isNewChapter && comment.chapterId) ||
      (comment.chapterId && commentChapter?.chapter_id !== comment.chapterId)

    const shouldLoadBookFromChapter = shouldRemoveBook && shouldLoadChapter

    if (shouldRemoveBook && shouldRemoveChapter) {
      setBook(null)
    }

    if (shouldLoadBook) {
      handleLoadBook(comment.bookId)
    }

    if (shouldRemoveChapter) {
      setChapter(null)
    }

    if (shouldLoadChapter) {
      handleLoadChapter(comment.chapterId, shouldLoadBookFromChapter)
    }

    setIsContentBug(comment.isContentBug)
    setIsTechBug(comment.isTechBug)
    setEditedComment(comment.content)
    if (isNewChapter) {
      setIsShowTranslates(false)
      setIsShowReports(false)
      setId(comment.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment, loadBook, loadChapter])

  useEffect(() => {
    if (textAreaRef.current && isEdit) {
      textAreaRef.current.focus()
    }
  }, [isEdit])

  return (
    <Grid
      templateColumns='1fr minmax(400px, 2fr) 1fr'
      gap='24px'
      h='100%'
      overflow='hidden'
    >
      <Flex direction='column' gap='14px' align='center' w='100%'>
        <Flex
          border={`1px solid ${colors['primary-gray-10']}`}
          bg={colors.white}
          borderRadius='10px'
          direction='column'
          p='12px'
          h='fit-content'
          minH='304px'
          pos='relative'
          w='100%'
        >
          {commentBook && isImageHover && isWide && (
            <Image
              src={commentBook.cover_link}
              w='85%'
              h='85%'
              right={0}
              alt='cover'
              pos='absolute'
            />
          )}
          <Heading
            size='poppins20'
            variant='primary-black-text'
            lineHeight='38px'
            letterSpacing='0'
            textAlign='left'
          >
            Book:
            <Text
              as='span'
              size='poppins18'
              variant='primary-black-text'
              lineHeight='22px'
              letterSpacing='0'
              textAlign='left'
              pl='8px'
            >
              {commentBook?.title || ''}
            </Text>
          </Heading>

          {isBookLoading && <LoadingSpinner />}

          {commentBook && (
            <Flex direction='column'>
              <Flex gap='12px'>
                <Image
                  src={commentBook.cover_link}
                  w='60px'
                  h='90px'
                  alt='cover'
                  cursor='pointer'
                  onMouseEnter={() => setIsImageHover(true)}
                  onMouseLeave={() => setIsImageHover(false)}
                />
              </Flex>

              <Text
                size='poppins14'
                variant='primary-black-text'
                lineHeight='22px'
                letterSpacing='0'
                textAlign='left'
              >
                {commentBook.book_id}
              </Text>
            </Flex>
          )}

          <Heading
            size='poppins20'
            variant='primary-black-text'
            lineHeight='38px'
            letterSpacing='0'
            textAlign='left'
          >
            Chapter:
          </Heading>

          {commentChapter && (
            <Flex direction='column'>
              <Text
                size='poppins14'
                variant='primary-black-text'
                lineHeight='22px'
                letterSpacing='0'
                textAlign='left'
              >
                {commentChapter.chapter_order}. {commentChapter.title}
              </Text>

              <Text
                size='poppins14'
                variant='primary-black-text'
                lineHeight='22px'
                letterSpacing='0'
                textAlign='left'
              >
                {commentChapter.chapter_id}
              </Text>
            </Flex>
          )}

          {isChapterLoading && <LoadingSpinner />}
        </Flex>

        <Button
          variant='defaultButton'
          w='150px'
          h='150px'
          minH='150px'
          p='12px 24px'
          borderRadius='50%'
          onClick={handleApprove}
        >
          Accept
        </Button>
      </Flex>

      <Flex direction='column' h='100%' overflow='auto' sx={baseScrollBar}>
        <Flex direction='column'>
          <Heading
            size='poppins20'
            variant='primary-black-text'
            lineHeight='38px'
            letterSpacing='0'
            textAlign='left'
          >
            User:
          </Heading>
          <Flex
            border={`1px solid ${colors['primary-gray-10']}`}
            bg={colors.white}
            borderRadius='10px'
            direction='column'
            p='12px'
            mb='24px'
          >
            <Flex gap='12px'>
              {comment.author.photoUrl && (
                <Image
                  src={comment.author.photoUrl}
                  w='60px'
                  h='60px'
                  alt='user'
                  objectFit='cover'
                  objectPosition='center'
                  borderRadius='50%'
                />
              )}
              <Flex direction='column' gap='8px'>
                <Text
                  size='poppins16'
                  variant='primary-black-text'
                  lineHeight='22px'
                  letterSpacing='0'
                  textAlign='left'
                  fontWeight={600}
                >
                  <u>userName:</u> {comment.author.userName}
                </Text>

                <Text
                  size='poppins16'
                  variant='primary-black-text'
                  lineHeight='22px'
                  letterSpacing='0'
                  textAlign='left'
                  fontWeight={400}
                >
                  <u>userId:</u> {comment.author.id}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex direction='column' mb='12px'>
          <Text
            size='poppins16'
            variant='primary-black-text'
            lineHeight='22px'
            letterSpacing='0'
            fontWeight={400}
            w='100%'
            textAlign='center'
          >
            {comment.id}
          </Text>
          <Flex w='100%' justify='space-between' align='center'>
            <Heading
              size='poppins20'
              variant='primary-black-text'
              lineHeight='38px'
              letterSpacing='0'
              textAlign='left'
            >
              Content:
            </Heading>

            <Text
              size='poppins16'
              variant='primary-black-text'
              lineHeight='22px'
              letterSpacing='0'
              textAlign='left'
              fontWeight={400}
            >
              {updateTime}
            </Text>
          </Flex>

          <Flex
            border={`1px solid ${colors['primary-gray-10']}`}
            bg={colors.white}
            borderRadius='10px'
            direction='column'
            p='12px'
            mb='12px'
          >
            {isEdit ? (
              <Textarea
                variant='description'
                resize='none'
                rows={4}
                p='12px 18px'
                mb='12px'
                id='comment-textarea'
                onChange={handleCommentInput}
                value={editedComment}
                sx={baseScrollBar}
                ref={textAreaRef}
              />
            ) : (
              <Text
                size='poppins16'
                variant='primary-black-text'
                lineHeight='38px'
                letterSpacing='0'
                textAlign='left'
                onContextMenu={handleSelect}
                ref={commentRef}
                onLoad={() => setIsLoaded(true)}
                id='text-wrapper'
              >
                {comment.content}
              </Text>
            )}
          </Flex>

          <Flex justify='space-between' mb='24px'>
            <Button
              variant='outlineButton'
              w='fit-content'
              p='12px 24px'
              onClick={triggerReports}
              isDisabled={!comment.reports || comment.reports?.length === 0}
            >
              {`${isShowReports ? 'Hide' : 'Reports'}${
                comment.reports && comment.reports.length > 0
                  ? `(${comment.reports.length})`
                  : ''
              }`}
            </Button>

            <Flex gap='8px'>
              <Button
                variant='defaultButton'
                p='12px 24px'
                w='fit-content'
                onClick={goBack}
                isDisabled={reviewIndex === 0}
              >
                Prev
              </Button>

              <Button
                variant='defaultButton'
                p='12px 24px'
                w='fit-content'
                bg={colors['light-text-text-danger']}
                _hover={{
                  bg: `${colors['light-red-700']} !important`,
                }}
                onClick={skipComment}
                isDisabled={!canSkip}
              >
                Skip
              </Button>
            </Flex>

            <Flex gap='12px'>
              <Button
                variant='outlineButton'
                w='fit-content'
                p='12px 24px'
                onClick={handleTranslateContent}
              >
                {isShowTranslates ? 'Hide' : 'Translate'}
              </Button>

              {!isSupport && (
                <Button
                  variant='defaultButton'
                  w='fit-content'
                  p='12px 24px'
                  onClick={clickEdit}
                >
                  {!isEdit ? 'Edit' : canCancelEdit ? 'Cancel' : 'Save'}
                </Button>
              )}

              {!isSupport && isEdit && !canCancelEdit && (
                <Button
                  variant='defaultButton'
                  w='fit-content'
                  p='12px 24px'
                  bg={colors['light-text-text-danger']}
                  _hover={{
                    bg: `${colors['light-red-700']} !important`,
                  }}
                  onClick={cancelEdit}
                >
                  {'Cancel'}
                </Button>
              )}
            </Flex>
          </Flex>

          {isShowReports && (
            <Flex direction='column'>
              <Flex w='100%' justify='space-between' align='center'>
                <Heading
                  size='poppins20'
                  variant='primary-black-text'
                  lineHeight='38px'
                  letterSpacing='0'
                  textAlign='left'
                >
                  Reports:
                </Heading>
              </Flex>
              {comment.reports.map(item => {
                const updateReportTime = moment(item.updatedAt).format(
                  'YYYY-MM-DD HH:mm',
                )

                return (
                  <Flex
                    border={`1px solid ${colors['primary-gray-10']}`}
                    borderRadius='10px'
                    direction='column'
                    p='12px'
                    mb='12px'
                    key={item.id}
                  >
                    <Flex
                      gap='12px'
                      border={`1px solid ${colors['primary-gray-10']}`}
                      bg={colors.white}
                      p='12px'
                      borderRadius='10px'
                      justify='space-between'
                    >
                      <Flex direction='column' gap='8px'>
                        <Text
                          size='poppins16'
                          variant='primary-black-text'
                          lineHeight='22px'
                          letterSpacing='0'
                          textAlign='left'
                          fontWeight={600}
                        >
                          <u>type:</u> {item.type}
                        </Text>

                        <Text
                          size='poppins16'
                          variant='primary-black-text'
                          lineHeight='22px'
                          letterSpacing='0'
                          textAlign='left'
                          fontWeight={400}
                        >
                          <u>readerId:</u> {item.readerId}
                        </Text>
                      </Flex>

                      <Text
                        size='poppins16'
                        variant='primary-black-text'
                        lineHeight='22px'
                        letterSpacing='0'
                        textAlign='left'
                        fontWeight={400}
                      >
                        {updateReportTime}
                      </Text>
                    </Flex>

                    {item.text && (
                      <Text
                        size='poppins16'
                        variant='primary-black-text'
                        lineHeight='38px'
                        letterSpacing='0'
                        textAlign='left'
                        color='white'
                        border={`1px solid white`}
                        borderRadius='10px'
                        bg='black'
                        p='12px'
                      >
                        {item.text}
                      </Text>
                    )}
                  </Flex>
                )
              })}
            </Flex>
          )}

          {isShowTranslates && (
            <Flex direction='column'>
              <Flex w='100%' justify='space-between' align='center'>
                <Heading
                  size='poppins20'
                  variant='primary-black-text'
                  lineHeight='38px'
                  letterSpacing='0'
                  textAlign='left'
                >
                  Translation:
                </Heading>
              </Flex>
              <Flex
                border={`1px solid ${colors['primary-gray-10']}`}
                borderRadius='10px'
                direction='column'
                p='12px'
                mb='12px'
              >
                <Text
                  size='poppins16'
                  variant='primary-black-text'
                  lineHeight='38px'
                  letterSpacing='0'
                  textAlign='left'
                  color='white'
                  border={`1px solid white`}
                  borderRadius='10px'
                  bg='black'
                  p='12px'
                >
                  {translation}
                </Text>
              </Flex>
              <Button
                variant='outlineButton'
                w='fit-content'
                p='12px 24px'
                onClick={regenerateTranslation}
              >
                Regenerate
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>

      <Flex direction='column' gap='14px' align='center' w='100%'>
        <Flex
          border={`1px solid ${colors['primary-gray-10']}`}
          bg={colors.white}
          borderRadius='10px'
          direction='column'
          p='12px'
          h='fit-content'
          minH='304px'
          gap='12px'
          w='100%'
        >
          <ModerationOption
            title='is content bug'
            value={isContentBug}
            id='content-bug'
            handleToggle={handleToggleContentBug}
          />

          <ModerationOption
            title='is tech bug'
            value={isTechBug}
            id='tech-bug'
            handleToggle={handleToggleTechBug}
          />

          <Textarea
            variant='deleteReason'
            resize='none'
            placeholder='reason of rejection'
            rows={6}
            border={`1px solid ${colors['light-text-text-danger']}`}
            p='12px 18px'
            mb='12px'
            id='reject-textarea'
            onChange={handleReasonInput}
            value={reason}
            sx={baseScrollBar}
          />
        </Flex>
        <Button
          variant='defaultButton'
          w='150px'
          h='150px'
          minH='150px'
          p='12px 24px'
          borderRadius='50%'
          bg={colors['light-text-text-danger']}
          _hover={{
            bg: `${colors['light-red-700']} !important`,
          }}
          onClick={rejectComment}
        >
          Reject
        </Button>
      </Flex>

      {isOpen && (
        <AddBlacklistItemModal
          word={selected}
          onClose={handleCancel}
          addWordToBlacklist={addWordToBlacklist}
          isOpen={isOpen}
          isSupport={isSupport}
        />
      )}
    </Grid>
  )
}

export default CommentReview
