import {
  Button,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  keyframes,
} from '@chakra-ui/react'
import React from 'react'
import { colors } from 'shared/style/colors'

const AddBlacklistItemModal = ({
  word,
  isOpen,
  onClose,
  addWordToBlacklist,
  isSupport,
}) => {
  const appear = keyframes({
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant='leaveWithoutSaving'>
      <ModalOverlay />
      <ModalContent
        sx={{
          w: '600px',
          minW: '600px',
          h: 'fit-content',
          padding: '84px 85px 44px',
          opacity: 1,
          borderRadius: '16px',
          animation: `${appear} 0.2s linear`,
          boxShadow: '0px 5px 15px 0px rgba(36, 28, 88, 0.20)',
        }}
      >
        <Image
          pos='absolute'
          src='/images/close-circle.svg'
          alt='close icon'
          top='24px'
          right='24px'
          cursor='pointer'
          onClick={onClose}
        />

        <ModalBody
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontFamily: 'Nunito Sans',
            fontSize: '16px',
            lineHeight: '22px',
          }}
        >
          <Image
            src='/images/hero/marker.png'
            w='80px'
            h='80px'
            alt='delete'
            mb='36px'
          />

          <Heading
            size='poppins24'
            variant='primary-black-text'
            lineHeight='32px'
            mb='24px'
            textAlign='center'
            maxW='400px'
            fontWeight={500}
          >
            Add word:{' '}
            <strong style={{ color: colors['light-text-text-danger'] }}>
              {word}
            </strong>{' '}
            to blacklist?`
          </Heading>

          {isSupport && (
            <Text
              size='poppins16normal'
              variant='primary-black-text'
              lineHeight='28px'
              letterSpacing='-0.016px'
              textAlign='center'
              mb='24px'
            >
              After adding word blacklist request it should be approved by Admin
            </Text>
          )}

          <Flex gap='23px'>
            <Button variant='outlineButton' onClick={onClose} w='204px'>
              Cancel
            </Button>

            <Button
              variant='defaultButton'
              onClick={addWordToBlacklist}
              w='204px'
              bg={colors['light-text-text-danger']}
              _hover={{
                bg: `${colors['light-red-700']} !important`,
              }}
            >
              Add
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default AddBlacklistItemModal
