import { Flex, Text, Box, keyframes } from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { colors } from 'shared/style/colors'

const SearchToggler = ({
  handleSwitch,
  isSearchById,
  mainWidth,
  additionalWidth,
  mainTitle,
  secondaryTitle,
}) => {
  const [count, setCount] = useState(0)

  const switchToId = useCallback(() => {
    if (!isSearchById) {
      handleSwitch(true)
      setCount(1)
    }
  }, [isSearchById, handleSwitch])

  const switchToPrimary = useCallback(() => {
    if (isSearchById) {
      handleSwitch(false)
      setCount(1)
    }
  }, [isSearchById, handleSwitch])

  const animationKeyframes = keyframes`
    0% { left: ${!isSearchById ? mainWidth : 0}px; top:0px;}
    100% { left: ${!isSearchById ? 0 : mainWidth}px; top:0px; }
  `

  const animation = `${animationKeyframes} 0.5s ease-in-out ${count}`

  return (
    <Flex
      p='0 20px'
      gap='42px'
      borderRadius='8px'
      bg={colors.white}
      border={`1px solid ${colors['primary-gray-30']}`}
      w='fit-content'
      pos='relative'
    >
      <Text
        size='poppins14'
        fontWeight={500}
        color={!isSearchById ? colors.white : colors['primary-black-text']}
        lineHeight='36px'
        zIndex={3}
        onClick={switchToPrimary}
        cursor='pointer'
      >
        {mainTitle}
      </Text>

      <Text
        size='poppins14'
        fontWeight={500}
        color={isSearchById ? colors.white : colors['primary-black-text']}
        lineHeight='36px'
        zIndex={3}
        onClick={switchToId}
        cursor='pointer'
      >
        {secondaryTitle}
      </Text>

      <Box
        pos='absolute'
        h='36px'
        w={!isSearchById ? `${mainWidth}px` : `${additionalWidth}px`}
        top={0}
        left={!isSearchById ? 0 : `${mainWidth}px`}
        zIndex={2}
        bg={colors['primary-violet-100']}
        borderRadius='8px'
        animation={animation}
      />
    </Flex>
  )
}

export default SearchToggler
