import { defaultButton, outlineButton } from './button'
// import { formLabelStyles } from './formLabel';
// import { menuStyles } from './menu';
// import { selectStyles } from './select';
// import { modalStyle } from './modal';
// import { createStory } from './tooltip';
// import { docsPrimary, resetPassword } from './link';
import {
  loginField,
  loginFieldError,
  tagField,
  titleField,
  titleFieldError,
} from './input'
// import { guidline } from './list';
import { deleteReason, description, descriptionError } from './textarea'
import { headingStyle } from './heading'
import { textStyle } from './text'
import { createStory } from './tooltip'

export const components = {
  Button: {
    variants: {
      defaultButton,
      outlineButton,
    },
  },
  Tooltip: {
    variants: {
      createStory,
    },
  },
  // Menu: menuStyles,
  Text: textStyle,
  Heading: headingStyle,
  Input: {
    variants: {
      loginField,
      loginFieldError,
      titleField,
      titleFieldError,
      tagField,
    },
  },
  Textarea: {
    variants: {
      description,
      descriptionError,
      deleteReason,
    },
  },
  // Select: selectStyles,
  // FormLabel: formLabelStyles,
  // Modal: modalStyle,
  // Link: {
  // 	variants: {
  // 		docsPrimary,
  // 		resetPassword,
  // 	}
  // },
  // List: {
  // 	variants: {
  // 		guidline,
  // 	}
  // },
}
