import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react'
import ArrowIcon from 'components/UI/Icons/ArrowIcon'
import React, { memo, useEffect, useState } from 'react'
import { colors } from 'shared/style/colors'
// import LoadingSpinner from 'components/UI/LoadingSpinner'
// import { GET_BANNERS } from 'services/banners/graphql'
// import { useQuery } from '@apollo/client'
import { BannerTypes } from 'constants/banners'
import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { getBannersList } from 'redux/slices/banners/selectors'

const stateSelector = createSelector(getBannersList, banners => ({
  banners,
}))

const BannersSelector = memo(({ selectedBanners, handleAddBanner }) => {
  const [hover, setHover] = useState(false)
  const [visibleBanners, setVisibleBanners] = useState([])

  const { banners: allBanners } = useSelector(stateSelector)

  // const { data: allBanners, loading: isBannersLoading } = useQuery(
  //   GET_BANNERS,
  //   {
  //     fetchPolicy: 'cache-and-network',
  //   },
  // )

  useEffect(() => {
    // if (allBanners && !isBannersLoading) {
    if (allBanners) {
      const ignoreList = [
        ...selectedBanners.map(item => ({ banner_id: item.banner_id })),
      ]
      setVisibleBanners(
        // (allBanners.banners ?? []).filter(
        (allBanners ?? []).filter(
          item =>
            !ignoreList.some(selected => selected.banner_id === item.banner_id),
        ),
      )
    }
  }, [
    allBanners,
    // isBannersLoading,
    selectedBanners,
  ])

  return (
    <AccordionItem border='none'>
      {({ isExpanded }) => (
        <Flex
          bg='white'
          w='100%'
          borderRadius='8px'
          direction='column'
          border={
            hover || isExpanded
              ? `1px solid ${colors['primary-violet-100']}`
              : `1px solid ${colors['primary-gray-30']}`
          }
        >
          <AccordionButton
            _hover={{
              bg: 'white',
            }}
            p='8px 44px'
            m='0'
            borderRadius='8px'
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            outline='none'
            _focus={{
              boxShadow: 'none',
            }}
          >
            <Flex direction='column' flex={1} gap='12px'>
              <Heading
                size='poppins24'
                variant='primary-black-text'
                color={
                  hover || isExpanded
                    ? colors['primary-blue-100']
                    : colors['primary-black-text']
                }
                lineHeight='38px'
                letterSpacing='0'
                textAlign='left'
              >
                Banners
              </Heading>
            </Flex>

            {isExpanded ? (
              <Box p='10px' bg={colors['primary-violet-10']} borderRadius='50%'>
                <Box transform='rotate(180deg)'>
                  <ArrowIcon
                    fill={
                      hover
                        ? colors['button-hover']
                        : colors['primary-violet-100']
                    }
                  />
                </Box>
              </Box>
            ) : (
              <Box p='10px' bg={colors['primary-violet-10']} borderRadius='50%'>
                <Box>
                  <ArrowIcon
                    fill={
                      hover
                        ? colors['button-hover']
                        : colors['primary-violet-100']
                    }
                  />
                </Box>
              </Box>
            )}
          </AccordionButton>
          <AccordionPanel>
            {/* {isBannersLoading && <LoadingSpinner />} */}

            <Flex gap='10px' wrap='wrap'>
              {visibleBanners.map(item => (
                <Flex
                  key={item.banner_id}
                  h='106px'
                  gap='8px'
                  border={`1px solid ${colors['primary-gray-30']}`}
                  p='8px 12px'
                  boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
                  borderRadius='8px'
                  cursor='pointer'
                  _hover={{
                    bg: 'rgba(36, 28, 88, 0.03)',
                  }}
                  onClick={() => handleAddBanner(item)}
                >
                  <Image
                    src={item.cover_url || '/images/book.png'}
                    alt='book-cover'
                    h='90px'
                    aspectratio='2/3'
                    objectFit='cover'
                  />

                  <Flex w='100%' gap='10px' direction='column'>
                    <Text
                      size='poppins14'
                      variant='primary-black-text'
                      fontWeight={600}
                    >
                      banner type: <b>{item.banner_type}</b>
                    </Text>

                    {item.banner_type === BannerTypes.BOOKS && item.book && (
                      <>
                        {item.book.title && (
                          <Text
                            size='poppins14'
                            variant='primary-black-text'
                            alignSelf='end'
                          >
                            Book: {item.book.title}
                          </Text>
                        )}

                        {item.book.author && (
                          <Text
                            size='poppins14'
                            variant='primary-black-text'
                            alignSelf='end'
                          >
                            Author:{' '}
                            {`${item.book.author.first_name} ${item.book.author.last_name}`}
                          </Text>
                        )}
                      </>
                    )}

                    {item.banner_type === BannerTypes.ADVERTISEMENT &&
                      item.advertisement && (
                        <Text
                          size='poppins14'
                          variant='primary-black-text'
                          alignSelf='end'
                        >
                          Advertisement: {item.advertisement.title}
                        </Text>
                      )}
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </AccordionPanel>
        </Flex>
      )}
    </AccordionItem>
  )
})

export default BannersSelector
