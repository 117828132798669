import React, { memo } from 'react'
import {
  ButtonGroup,
  ButtonToolbar,
  Form,
  Modal,
  Button,
} from 'react-bootstrap'

const CreateTagModal = ({ isShow, onHide, value, onChange, onSubmit }) => (
  <Modal show={isShow} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>Add a new tag</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form.Control
        type='text'
        placeholder='Enter tag name'
        value={value}
        onChange={onChange}
      />
    </Modal.Body>
    <Modal.Footer>
      <ButtonToolbar>
        <ButtonGroup className='me-2'>
          <Button variant='primary' onClick={onHide}>
            Cancel
          </Button>
        </ButtonGroup>
        <ButtonGroup className='me-2'>
          <Button variant='primary' onClick={onSubmit}>
            Save
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    </Modal.Footer>
  </Modal>
)

export default memo(CreateTagModal)
