import { Formik, Form as FormikForm } from 'formik'
import moment from 'moment'
import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

import styles from './styles.module.scss'
import { Button } from '@chakra-ui/react'

const BookInfo = ({
  handleUpdateBookSubmit,
  limited_start,
  release_at,
  limited_end,
  type,
  bookPremiumTypes,
}) => {
  return (
    <div className={styles.bookInfo}>
      <Formik
        initialValues={{
          type,
          limited_start: moment(limited_start).format('YYYY-MM-DD') ?? '',
          limited_end: moment(limited_end).format('YYYY-MM-DD') ?? '',
          release_at: moment(release_at).format('YYYY-MM-DD') ?? '',
        }}
        onSubmit={handleUpdateBookSubmit}
      >
        {({ values, handleChange, handleBlur }) => (
          <FormikForm>
            <Form.Text>Free time trial date</Form.Text>
            <Row className='mb-3'>
              <Col>
                <Form.Group controlId='limitesStart'>
                  <Form.Select
                    name='type'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.type}
                  >
                    {bookPremiumTypes.map(type => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <Form.Group controlId='limitesStart'>
                  <Form.Label>Date start</Form.Label>
                  <Form.Control
                    type='date'
                    name='limited_start'
                    value={values.limited_start}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId='limitesdEnd'>
                  <Form.Label>Date end</Form.Label>
                  <Form.Control
                    type='date'
                    name='limited_end'
                    value={values.limited_end}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='mb-3'>
              <Form.Group controlId='releaseDate'>
                <Form.Label>Releaese date</Form.Label>
                <Form.Control
                  type='date'
                  name='release_at'
                  value={values.release_at}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Group>
            </Row>

            <Button variant='defaultButton' type='submit' w='fit-content'>
              Save
            </Button>
          </FormikForm>
        )}
      </Formik>
    </div>
  )
}

export default BookInfo
