import { Flex, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { Button, Card } from 'react-bootstrap'
import ListModal from './ListModal'

const SectionItem = ({
  list,
  listRef,
  deleteListFromLocalization,
  draggableProps,
  dragHandleProps,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Card ref={listRef} {...draggableProps} {...dragHandleProps}>
      <Card.Header>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <b>list: &nbsp; </b> {list.title}{' '}
          <b>&nbsp; &nbsp; &nbsp; Type: &nbsp; </b> {list.list_type}
        </div>
        <b>Description:</b> {list.description}
        <br />
        <Flex gap='12px'>
          <Button variant='danger' onClick={deleteListFromLocalization}>
            Delete
          </Button>

          <Button variant='outline-success' onClick={onOpen}>
            Modify
          </Button>
        </Flex>
      </Card.Header>

      <ListModal onClose={onClose} isOpen={isOpen} list_id={list.list_id} />
    </Card>
  )
}

export default SectionItem
