import React, { memo } from 'react'
import { AiTwotoneDelete } from 'react-icons/ai'
import { MdDragIndicator } from 'react-icons/md'
import './styles.scss'

const RecomendationItem = ({
  cover_link,
  title,
  handleBookDeleteRecommendation,
  bookRef,
  draggableProps,
  dragHandleProps,
}) => (
  <div
    ref={bookRef}
    className='book-draggable'
    {...draggableProps}
    {...dragHandleProps}
  >
    <MdDragIndicator className='book-draggable__icon' />
    <div className='book-draggable-content'>
      <img
        src={cover_link || '/images/book.png'}
        alt={`${title} cover`}
        className='book-draggable__cover'
      />
      <p className='book-draggable__title'>{title}</p>
    </div>
    <AiTwotoneDelete
      className='book-draggable__icon book-draggable__icon--cursor'
      onClick={handleBookDeleteRecommendation}
    />
  </div>
)

export default memo(RecomendationItem)
