import { Flex, Heading, Icon, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { colors } from 'shared/style/colors'
import { TbArrowZigZag } from 'react-icons/tb'

const BooksContent = ({ books }) => {
  return (
    <Flex gap='20px'>
      <Heading
        size='poppins20'
        variant='primary-black-text'
        lineHeight='38px'
        letterSpacing='0'
        textAlign='left'
        w='120px'
        minW='120px'
      >
        Books{' '}
        <Icon as={TbArrowZigZag} w='24px' h='24px' transform='rotate(90deg)' />
      </Heading>

      <Flex>
        <Flex gap='10px' wrap='wrap' userSelect='none'>
          {books.map(item => (
            <Flex
              key={item.bookId}
              h='80px'
              gap='8px'
              border={`1px solid ${colors['primary-gray-30']}`}
              p='8px 12px'
              boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
              borderRadius='8px'
            >
              <Image
                src={item.book.coverLink || '/images/book.png'}
                alt='book-cover'
                h='60px'
                aspectratio='2/3'
                objectFit='cover'
              />

              <Flex w='100%' gap='10px' direction='column'>
                <Text
                  size='poppins14'
                  variant='primary-black-text'
                  fontWeight={600}
                >
                  {item.book.title}
                </Text>

                <Text
                  size='poppins14'
                  variant='primary-black-text'
                  alignSelf='end'
                >
                  {`${item.book.author.penName}`}
                </Text>
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default BooksContent
