import { Box, Image } from '@chakra-ui/react'
import React, { useCallback } from 'react'

const CopyItem = ({ handleCopyToClipboard, place, text }) => {
  const handleCopy = useCallback(
    e => {
      handleCopyToClipboard(place, text)

      e.stopPropagation()
    },
    [place, text, handleCopyToClipboard],
  )

  return (
    <Box display='inline-block'>
      <Image
        w='100%'
        h='21px'
        onClick={handleCopy}
        cursor='pointer'
        src={`/images/screens/copy.png`}
        alt='remove'
      />
    </Box>
  )
}

export default CopyItem
