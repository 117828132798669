import { Accordion, Flex, useToast } from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import BooksSelector from '../../../components/ContentAnatomy/BooksSelector'
import ChaptersSelector from '../../../components/ContentAnatomy/ChaptersSelector'
import LocalesSelector from '../../../components/ContentAnatomy/LocalesSelector'
import { useMutation, useQuery } from '@apollo/client'
import { GET_ISO_LANGUAGES, LOCALIZE_CONTENT } from 'services/languages/graphql'
import { baseComponentState } from 'constants/common'
import { isEqual } from 'lodash'
import Selection from 'components/ContentAnatomy/Selection'
import { baseScrollBar } from 'constants/scrollbar'

const ContentLocalizationsLayout = () => {
  const [selectedLocales, setSelectedLocales] = useState([])
  const [availableLocales, setAvailableLocales] = useState([])
  const [selectedStories, setSelectedStories] = useState([])
  const [selectedChapters, setSelectedChapters] = useState([])
  const [selectedGenres, setSelectedGenres] = useState(baseComponentState)
  const [selectedTags, setSelectedTags] = useState(baseComponentState)
  const [selectedLists, setSelectedLists] = useState(baseComponentState)
  const [booksForce, setBooksForce] = useState(false)
  const [chaptersForce, setChaptersForce] = useState(false)
  const [chaptersGlobalForce, setChaptersGlobalForce] = useState(true)
  const toast = useToast()
  const [localizeContent] = useMutation(LOCALIZE_CONTENT)

  // #region isoLanguages

  const { data, loading } = useQuery(GET_ISO_LANGUAGES, {
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (data && !loading) {
      setAvailableLocales(
        (data.languages || []).filter(
          item =>
            !selectedLocales.some(selected => selected.code === item.code),
        ),
      )
    }
  }, [data, loading, selectedLocales])

  const handleAddLocale = useCallback(locale => {
    setSelectedLocales(curr => [...curr, locale])
  }, [])

  const handleRemoveLocale = useCallback(locale => {
    setSelectedLocales(curr => curr.filter(item => item.title !== locale.title))
  }, [])

  const handleClearLocales = useCallback(() => {
    setSelectedLocales([])
  }, [])

  // #endregion

  // #region books

  const handleAddStory = useCallback(story => {
    setSelectedStories(curr => [...curr, story])
  }, [])

  const toggleBooksForce = useCallback(() => {
    setBooksForce(curr => !curr)
  }, [])

  const toggleChaptersGlobalForce = useCallback(() => {
    setChaptersGlobalForce(curr => !curr)
  }, [])

  const handleRemoveStory = useCallback(story => {
    setSelectedStories(curr => {
      const remain = curr.filter(item => story.book_id !== item.book_id)

      if (remain.length === 0) {
        setBooksForce(false)
      }

      return remain
    })
  }, [])

  const handleClearStories = useCallback(() => {
    setSelectedStories([])
    setBooksForce(false)
    setChaptersGlobalForce(true)
  }, [])

  // #endregion

  // #region chapters

  const handleAddChapter = useCallback((book, chapter) => {
    setSelectedChapters(curr => [
      ...curr,
      { ...chapter, bookTitle: book.title, bookId: book.book_id },
    ])
  }, [])

  const toggleChaptersForce = useCallback(() => {
    setChaptersForce(curr => !curr)
  }, [])

  const handleRemoveChapter = useCallback(chapter => {
    setSelectedChapters(curr => {
      const remain = curr.filter(item => chapter.chapter_id !== item.chapter_id)

      if (remain.length === 0) {
        setChaptersForce(false)
      }

      return remain
    })
  }, [])

  const handleClearChapters = useCallback(() => {
    setSelectedChapters([])
    setChaptersForce(false)
  }, [])

  // #endregion

  // #region Compontents

  const handleToggleGenres = useCallback(data => {
    setSelectedGenres(data)
  }, [])

  const handleToggleTags = useCallback(data => {
    setSelectedTags(data)
  }, [])

  const handleToggleLists = useCallback(data => {
    setSelectedLists(data)
  }, [])

  const handleClearContent = useCallback(() => {
    setSelectedLists(baseComponentState)
    setSelectedTags(baseComponentState)
    setSelectedGenres(baseComponentState)
  }, [])

  // #endregion

  const handleClearData = useCallback(() => {
    handleClearChapters()
    handleClearStories()
    handleClearLocales()
    handleClearContent()
  }, [
    handleClearChapters,
    handleClearLocales,
    handleClearStories,
    handleClearContent,
  ])

  const handleSubmit = useCallback(async () => {
    const isSubmitEnabled =
      selectedLocales.length !== 0 &&
      (selectedStories.length !== 0 ||
        selectedChapters.length !== 0 ||
        !isEqual(selectedGenres, baseComponentState) ||
        !isEqual(selectedTags, baseComponentState) ||
        !isEqual(selectedLists, baseComponentState))

    if (isSubmitEnabled) {
      const request = {
        locales: selectedLocales.map(item => item.code),
      }

      if (selectedStories.length !== 0) {
        request.books = {
          force: booksForce,
          includeChapters: chaptersGlobalForce,
          ids: selectedStories.map(item => item.book_id),
        }
      }

      if (selectedChapters.length !== 0) {
        request.chapters = {
          force: chaptersForce,
          ids: selectedChapters.map(item => item.chapter_id),
        }
      }

      if (!isEqual(selectedGenres, baseComponentState)) {
        request.genres = {
          all: selectedGenres.all,
          force: selectedGenres.force,
          // ids: []
        }
      }

      if (!isEqual(selectedTags, baseComponentState)) {
        request.tags = {
          all: selectedTags.all,
          force: selectedTags.force,
          // ids: []
        }
      }

      if (!isEqual(selectedLists, baseComponentState)) {
        request.lists = {
          all: selectedLists.all,
          force: selectedLists.force,
          // ids: []
        }
      }

      try {
        const {
          data: { result },
        } = await localizeContent({
          variables: {
            content: request,
          },
        })

        if (result.success) {
          toast({ title: 'Uploaded', status: 'success', isClosable: true })
          handleClearData()

          return
        }

        toast({
          title: 'Failed',
          description: 'Failed to localize content',
          status: 'error',
          isClosable: true,
        })
      } catch (error) {
        console.log('catched', error.message)

        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          isClosable: true,
        })
      }
    }
  }, [
    selectedChapters,
    selectedGenres,
    selectedLists,
    selectedLocales,
    selectedStories,
    selectedTags,
    localizeContent,
    toast,
    booksForce,
    chaptersForce,
    handleClearData,
    chaptersGlobalForce,
  ])

  return (
    <Flex p='10px 20px 0' direction='column' overflow='hidden'>
      <Accordion
        allowMultiple
        // allowToggle
        w='100%'
        display='flex'
        flexDirection='column'
        gap='12px'
        overflow='hidden'
      >
        <Selection
          selectedLocales={selectedLocales}
          handleRemoveLocale={handleRemoveLocale}
          selectedStories={selectedStories}
          handleRemoveStory={handleRemoveStory}
          selectedChapters={selectedChapters}
          handleRemoveChapter={handleRemoveChapter}
          handleClearData={handleClearData}
          handleToggleGenres={handleToggleGenres}
          handleToggleTags={handleToggleTags}
          handleToggleLists={handleToggleLists}
          selectedGenres={selectedGenres}
          selectedTags={selectedTags}
          selectedLists={selectedLists}
          handleSubmit={handleSubmit}
          isLocalizations={true}
          toggleBooksForce={toggleBooksForce}
          booksForce={booksForce}
          toggleChaptersForce={toggleChaptersForce}
          chaptersForce={chaptersForce}
          chaptersGlobalForce={chaptersGlobalForce}
          toggleChaptersGlobalForce={toggleChaptersGlobalForce}
        />

        <Flex
          direction='column'
          overflow='auto'
          sx={baseScrollBar}
          gap='12px'
          pb='30px'
        >
          <Flex w='500px'>
            <LocalesSelector
              availableLocales={availableLocales.sort((a, b) =>
                a.title.localeCompare(b.title),
              )}
              addLocales={handleAddLocale}
            />
          </Flex>

          <BooksSelector
            selectedStories={selectedStories}
            handleAddStory={handleAddStory}
            selectedChapters={selectedChapters}
            isLocalizations={true}
          />

          <ChaptersSelector
            handleAddChapter={handleAddChapter}
            selectedChapters={selectedChapters}
            selectedStories={selectedStories}
            isLocalizations={true}
          />
        </Flex>
      </Accordion>
    </Flex>
  )
}

export default ContentLocalizationsLayout
