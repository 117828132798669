const { gql } = require('@apollo/client')

export const GET_ISO_LANGUAGES = gql`
  query AllSupportedLocalizations {
    languages: allSupportedLocales {
      code
      title
    }
  }
`

export const LOCALIZE_CONTENT = gql`
  mutation LocalizeContent($content: ContentLocalizationInput!) {
    result: localizeContent(content: $content) {
      success
    }
  }
`
