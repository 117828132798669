import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react'
import ArrowIcon from 'components/UI/Icons/ArrowIcon'
import React, { useMemo, useState } from 'react'
import { colors } from 'shared/style/colors'
import ComponentSelect from '../../../screens/ContentLocalizations/ComponentSelect'
import { isEqual } from 'lodash'
import { baseComponentState } from 'constants/common'
import ForceSelect from 'screens/ContentLocalizations/ForceSelect'
import IncludeChaptersSelect from 'screens/ContentLocalizations/IncludeChaptersSelect'

const Selection = ({
  handleRemoveLocale,
  selectedLocales,
  selectedStories,
  handleRemoveStory,
  selectedChapters,
  handleRemoveChapter,
  handleClearData,
  handleToggleGenres,
  handleToggleTags,
  handleToggleLists,
  selectedGenres,
  selectedTags,
  selectedLists,
  handleSubmit,
  isLocalizations,
  toggleBooksForce,
  booksForce,
  toggleChaptersForce,
  chaptersForce,
  chaptersGlobalForce,
  toggleChaptersGlobalForce,
}) => {
  const [hover, setHover] = useState(false)

  const isSubmitEnabled = useMemo(() => {
    return isLocalizations
      ? selectedLocales.length !== 0 &&
          (selectedStories.length !== 0 ||
            selectedChapters.length !== 0 ||
            !isEqual(selectedGenres, baseComponentState) ||
            !isEqual(selectedTags, baseComponentState) ||
            !isEqual(selectedLists, baseComponentState))
      : selectedLocales.length !== 0 &&
          (selectedStories.length !== 0 || selectedChapters.length !== 0)
  }, [
    isLocalizations,
    selectedChapters,
    selectedGenres,
    selectedLists,
    selectedLocales,
    selectedStories,
    selectedTags,
  ])

  const isClearEnabled = useMemo(() => {
    return isLocalizations
      ? selectedLocales.length !== 0 ||
          selectedStories.length !== 0 ||
          selectedChapters.length !== 0 ||
          !isEqual(selectedGenres, baseComponentState) ||
          !isEqual(selectedTags, baseComponentState) ||
          !isEqual(selectedLists, baseComponentState)
      : selectedLocales.length !== 0 ||
          selectedStories.length !== 0 ||
          selectedChapters.length !== 0
  }, [
    isLocalizations,
    selectedChapters,
    selectedGenres,
    selectedLists,
    selectedLocales,
    selectedStories,
    selectedTags,
  ])

  return (
    <AccordionItem border='none'>
      {({ isExpanded }) => (
        <Flex
          bg='white'
          w='100%'
          borderRadius='8px'
          direction='column'
          border={
            hover || isExpanded
              ? `1px solid ${colors['primary-violet-100']}`
              : `1px solid ${colors['primary-gray-30']}`
          }
        >
          <AccordionButton
            _hover={{
              bg: 'white',
            }}
            p='8px 44px'
            m='0'
            borderRadius='8px'
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            outline='none'
            _focus={{
              boxShadow: 'none',
            }}
          >
            <Flex direction='column' flex={1} gap='12px'>
              <Heading
                size='poppins24'
                variant='primary-black-text'
                color={
                  hover || isExpanded
                    ? colors['primary-blue-100']
                    : colors['primary-black-text']
                }
                lineHeight='38px'
                letterSpacing='0'
                textAlign='left'
              >
                {isLocalizations
                  ? 'Content Localization Summary'
                  : 'Create Notifications Summary'}
              </Heading>
            </Flex>

            {isExpanded ? (
              <Box p='10px' bg={colors['primary-violet-10']} borderRadius='50%'>
                <Box transform='rotate(180deg)'>
                  <ArrowIcon
                    fill={
                      hover
                        ? colors['button-hover']
                        : colors['primary-violet-100']
                    }
                  />
                </Box>
              </Box>
            ) : (
              <Box p='10px' bg={colors['primary-violet-10']} borderRadius='50%'>
                <Box>
                  <ArrowIcon
                    fill={
                      hover
                        ? colors['button-hover']
                        : colors['primary-violet-100']
                    }
                  />
                </Box>
              </Box>
            )}
          </AccordionButton>

          <AccordionPanel>
            <Flex
              bg='white'
              w='100%'
              borderRadius='8px'
              direction='column'
              gap='8px'
            >
              <Flex gap='20px'>
                <Heading
                  size='poppins20'
                  variant='primary-black-text'
                  lineHeight='38px'
                  letterSpacing='0'
                  textAlign='left'
                  w='120px'
                  minW='120px'
                >
                  Languages
                </Heading>

                <Flex w='100%' gap='6px' wrap='wrap'>
                  {selectedLocales.map(locale => {
                    return (
                      <Flex
                        key={locale.code}
                        gap='4px'
                        onClick={() => handleRemoveLocale(locale)}
                        p='10px 12px'
                        align='center'
                        border={`1px solid ${colors.separator}`}
                        borderRadius='16px'
                      >
                        <Image
                          w='31px'
                          h='22px'
                          src={`/images/flags/${locale.code.toLowerCase()}.png`}
                          alt={locale.title}
                        />

                        <Text
                          size='poppins18'
                          variant='primary-black-text'
                          fontWeight={400}
                          lineHeight='32px'
                          letterSpacing='-0.018px'
                          maxW='500px'
                          cursor='pointer'
                          overflow='hidden'
                          textOverflow='ellipsis'
                          whiteSpace='nowrap'
                        >
                          {locale.title} - {locale.code}
                        </Text>
                      </Flex>
                    )
                  })}
                </Flex>
              </Flex>

              <Flex gap='20px'>
                <Heading
                  size='poppins20'
                  variant='primary-black-text'
                  lineHeight='38px'
                  letterSpacing='0'
                  textAlign='left'
                  w='120px'
                  minW='120px'
                >
                  Books
                </Heading>

                <Flex gap='10px' wrap='wrap'>
                  {selectedStories.length > 0 && isLocalizations && (
                    <Flex
                      gap='20px'
                      align='center'
                      border={`1px solid ${colors['primary-gray-30']}`}
                      p='8px 12px'
                      boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
                      borderRadius='8px'
                      justify='space-between'
                      maxW='300px'
                    >
                      <ForceSelect
                        value={booksForce}
                        id='Books-force'
                        handleToggle={toggleBooksForce}
                      />

                      <IncludeChaptersSelect
                        value={chaptersGlobalForce}
                        id='include-chapters'
                        handleToggle={toggleChaptersGlobalForce}
                      />
                    </Flex>
                  )}
                  {selectedStories.map(item => (
                    <Flex
                      key={item.book_id}
                      h='80px'
                      gap='8px'
                      border={`1px solid ${colors['primary-gray-30']}`}
                      p='8px 12px'
                      boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
                      borderRadius='8px'
                      cursor='pointer'
                      _hover={{
                        bg: 'rgba(36, 28, 88, 0.03)',
                      }}
                      onClick={() => handleRemoveStory(item)}
                    >
                      <Image
                        src={item.cover_link || '/images/book.png'}
                        alt='book-cover'
                        h='60px'
                        aspectRatio='2/3'
                        objectFit='cover'
                      />

                      <Flex w='100%' gap='10px' direction='column'>
                        <Text
                          size='poppins14'
                          variant='primary-black-text'
                          fontWeight={600}
                        >
                          {item.title}
                        </Text>

                        <Text
                          size='poppins14'
                          variant='primary-black-text'
                          alignSelf='end'
                        >
                          {`${item.creator_id.first_name} ${item.creator_id.last_name}`}
                        </Text>
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </Flex>

              <Flex>
                <Heading
                  size='poppins20'
                  variant='primary-black-text'
                  lineHeight='38px'
                  letterSpacing='0'
                  textAlign='left'
                  w='120px'
                  minW='120px'
                >
                  Chapters
                </Heading>

                <Flex gap='10px' wrap='wrap'>
                  {selectedChapters.length > 0 && isLocalizations && (
                    <Flex
                      gap='20px'
                      align='center'
                      border={`1px solid ${colors['primary-gray-30']}`}
                      p='8px 12px'
                      boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
                      borderRadius='8px'
                      justify='space-between'
                      maxW='300px'
                    >
                      <ForceSelect
                        value={chaptersForce}
                        id='Books-force'
                        handleToggle={toggleChaptersForce}
                      />
                    </Flex>
                  )}
                  {selectedChapters.map(item => (
                    <Flex
                      key={item.chapter_id}
                      h='80px'
                      gap='8px'
                      border={`1px solid ${colors['primary-gray-30']}`}
                      p='8px 12px'
                      boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
                      borderRadius='8px'
                      cursor='pointer'
                      _hover={{
                        bg: 'rgba(36, 28, 88, 0.03)',
                      }}
                      onClick={() => handleRemoveChapter(item)}
                    >
                      <Flex w='100%' gap='4px' direction='column'>
                        <Text
                          size='poppins14'
                          variant='primary-black-text'
                          fontWeight={700}
                          alignSelf='end'
                        >
                          {item.bookTitle}
                        </Text>
                        <Text
                          size='poppins14'
                          variant='primary-black-text'
                          fontWeight={600}
                        >
                          {item.chapter_order}. {item.title}
                        </Text>

                        <Text
                          size='poppins14'
                          variant='primary-black-text'
                          lineHeight='14px'
                        >
                          {item.chapter_id}
                        </Text>
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </Flex>

              {isLocalizations && (
                <Flex gap='20px' w='100%' mb='20px'>
                  <Flex
                    w='33%'
                    gap='20px'
                    align='center'
                    border={`1px solid ${colors['primary-gray-30']}`}
                    p='8px 12px'
                    boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
                    borderRadius='8px'
                    justify='space-between'
                    maxW='300px'
                  >
                    <Heading
                      size='poppins20'
                      variant='primary-black-text'
                      lineHeight='38px'
                      letterSpacing='0'
                      textAlign='left'
                    >
                      Genres
                    </Heading>

                    <ComponentSelect
                      generalTitle='Select all'
                      forceTitle='Force'
                      id='genres'
                      value={selectedGenres}
                      handleToggle={handleToggleGenres}
                    />
                  </Flex>

                  <Flex
                    w='33%'
                    gap='20px'
                    align='center'
                    border={`1px solid ${colors['primary-gray-30']}`}
                    p='8px 12px'
                    boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
                    borderRadius='8px'
                    justify='space-between'
                    maxW='300px'
                  >
                    <Heading
                      size='poppins20'
                      variant='primary-black-text'
                      lineHeight='38px'
                      letterSpacing='0'
                      textAlign='left'
                    >
                      Tags
                    </Heading>

                    <ComponentSelect
                      generalTitle='Select all'
                      forceTitle='Force'
                      id='tags'
                      value={selectedTags}
                      handleToggle={handleToggleTags}
                    />
                  </Flex>

                  <Flex
                    w='33%'
                    gap='20px'
                    align='center'
                    border={`1px solid ${colors['primary-gray-30']}`}
                    p='8px 12px'
                    boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
                    borderRadius='8px'
                    justify='space-between'
                    maxW='300px'
                  >
                    <Heading
                      size='poppins20'
                      variant='primary-black-text'
                      lineHeight='38px'
                      letterSpacing='0'
                      textAlign='left'
                    >
                      Lists
                    </Heading>

                    <ComponentSelect
                      generalTitle='Select all'
                      forceTitle='Force'
                      id='lists'
                      value={selectedLists}
                      handleToggle={handleToggleLists}
                    />
                  </Flex>
                </Flex>
              )}

              <Flex gap='23px' alignSelf='end'>
                <Button
                  type='button'
                  variant='outlineButton'
                  w='204px'
                  isDisabled={!isClearEnabled}
                  onClick={handleClearData}
                >
                  Clear data
                </Button>

                <Button
                  type='button'
                  variant='defaultButton'
                  w='204px'
                  isDisabled={!isSubmitEnabled}
                  onClick={handleSubmit}
                >
                  {isLocalizations ? 'Localize' : 'Create'}
                </Button>
              </Flex>
            </Flex>
          </AccordionPanel>
        </Flex>
      )}
    </AccordionItem>
  )
}

export default Selection
