import { Flex, Heading, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { AiFillEdit } from 'react-icons/ai'
import { colors } from 'shared/style/colors'

const Author = ({
  isBookLoading,
  handleShowCreatorUpdateModal,
  title,
  creator_id,
}) => {
  return (
    <Flex direction='column' gap='12px' mb='24px'>
      <Heading size='poppins24' variant='primary-black-text' textAlign='center'>
        Title: {title}
      </Heading>

      <Flex gap='12px' align='center'>
        <Text size='poppins16normal' variant='primary-black-text'>
          <u>
            <i>Creator:</i>
          </u>{' '}
          {creator_id?.first_name + ' ' + creator_id?.last_name}
        </Text>

        <Icon
          as={AiFillEdit}
          fontSize={24}
          cursor='pointer'
          fill={colors['primary-violet-100']}
          _hover={{
            fill: colors['button-hover'],
          }}
          onClick={handleShowCreatorUpdateModal}
        />
      </Flex>
    </Flex>
  )
}

export default Author
