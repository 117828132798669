import { Flex, Image, Tooltip } from '@chakra-ui/react'
import React from 'react'

const RemoveTranslationButton = ({ remove }) => {
  return (
    <Flex>
      <Tooltip
        label='Remove translation'
        variant='createStory'
        placement='bottom-start'
      >
        <Flex cursor='pointer' align='center' onClick={remove}>
          <Image
            src='/images/screens/delete_translation.png'
            alt='remove'
            cursor='pointer'
            w='36px'
            minW='36px'
            h='54px'
          />
        </Flex>
      </Tooltip>
    </Flex>
  )
}

export default RemoveTranslationButton
