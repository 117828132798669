import { createSlice } from '@reduxjs/toolkit'
import {
  createGenreThunk,
  createTagThunk,
  fetchGenresThunk,
  fetchSectionTypesThunk,
  fetchBookPremiumTypesThunk,
  fetchTagsThunk,
} from './thunks'

export const STATE_KEY = 'types'

const initialState = {
  tags: [],
  genres: [],
  sectionTypes: [],
  bookPremiumTypes: [],
}

const typesSlice = createSlice({
  name: STATE_KEY,
  initialState,
  extraReducers: {
    [fetchGenresThunk.fulfilled]: (state, action) => {
      state.genres = action.payload
    },
    [createGenreThunk.fulfilled]: (state, action) => {
      state.genres.push(action.payload)
    },
    [fetchTagsThunk.fulfilled]: (state, action) => {
      state.tags = action.payload
    },
    [createTagThunk.fulfilled]: (state, action) => {
      state.tags.push(action.payload)
    },
    [fetchSectionTypesThunk.fulfilled]: (state, action) => {
      const sectionTypesList = Object.keys(action.payload)
      state.sectionTypes = sectionTypesList
    },
    [fetchBookPremiumTypesThunk.fulfilled]: (state, action) => {
      state.bookPremiumTypes = Object.keys(action.payload)
    },
  },
})

export const typesReducer = typesSlice.reducer
