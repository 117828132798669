import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Select,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { colors } from 'shared/style/colors'
import { MAX_FILES } from '..'
import AuthAlert from 'screens/Authorization/components/AuthAlert'
import SimpleBarReact from 'simplebar-react'
import ChapterAudioOption from '../ChapterAudioOption'
import { uploadChapterAudio } from 'api/books'
import BookAudioReview from '../BookAudioReview'

const FullBookFlow = ({
  isBookLoading,
  book,
  languages,
  loading,
  allChapters,
  isChaptersLoading,
  getAllChapters,
}) => {
  const [files, setFiles] = useState(null)
  const [isReview, setIsReview] = useState(false)
  const [selectedLocale, setSelectedLocale] = useState('en')
  const [selectedChapters, setSelectedChapters] = useState([])
  const [availableLocales, setAvailableLocales] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [chapters, setChapters] = useState([])
  const inputRef = useRef(null)
  const toast = useToast()

  const isContentLoading = isBookLoading || loading || isUploading

  const handleAddLocale = locale => {
    setSelectedLocale(locale)
  }

  const handleAddChapter = chapter => {
    setSelectedChapters(curr => [...curr, chapter])
  }

  const handleClearData = useCallback(() => {
    setFiles(null)
    setSelectedChapters([])
  }, [])

  const handleRemoveChapter = chapter => {
    setSelectedChapters(curr =>
      curr.filter(item => item.chapter_id !== chapter.chapter_id),
    )
  }

  const handleDragOver = event => {
    event.preventDefault()
  }

  const handleDragLeave = event => {
    event.preventDefault()
  }

  const handleDrop = async event => {
    event.preventDefault()

    const droppedFiles = Array.from(event.dataTransfer.files)

    if (Object.entries(droppedFiles).length > MAX_FILES) {
      toast({
        position: 'top',
        render: ({ onClose }) => (
          <Box mt='61px'>
            <AuthAlert
              onClose={onClose}
              title='Too much files!'
              reason='You can upload 20 files at once'
            />
          </Box>
        ),
        duration: 5000,
      })

      return
    }
    setFiles(droppedFiles)
  }

  const handleSubmit = useCallback(async () => {
    if (selectedLocale && selectedChapters.length > 0) {
      setIsUploading(true)
      let errors = []
      let success = []

      for (const chapter of selectedChapters) {
        const formdata = new FormData()

        formdata.append(selectedLocale, chapter.file)

        try {
          await uploadChapterAudio({
            formdata,
            chapter_id: chapter.chapter_id,
          })

          success = [...success, { chapter_order: chapter.chapter_order }]
        } catch (error) {
          errors = [...errors, { chapter_order: chapter.chapter_order }]
        }
      }

      if (success.length > 0) {
        toast({
          title: `Audio was succesfully uploaded for ${selectedLocale.toUpperCase()} locale and chapters ${success
            .map(({ chapter_order }) => chapter_order)
            .join(', ')}`,
          status: 'success',
          isClosable: true,
          duration: 15000,
          position: 'top',
        })

        await getAllChapters({
          variables: {
            book_id: book.book_id,
          },
        })
      }

      if (errors.length > 0) {
        toast({
          position: 'top',
          render: ({ onClose }) => (
            <Box mt='61px'>
              <AuthAlert
                onClose={onClose}
                title='Failed'
                reason={`Failed upload audio for ${selectedLocale.toUpperCase()} locale and chapters ${errors
                  .map(({ chapter_order }) => chapter_order)
                  .join(', ')}`}
              />
            </Box>
          ),
          duration: 5000,
        })
      }

      handleClearData()
      setIsUploading(false)
    }
  }, [
    selectedLocale,
    selectedChapters,
    handleClearData,
    toast,
    getAllChapters,
    book,
  ])

  const addChapters = async e => {
    if (Object.entries(e.target.files).length > MAX_FILES) {
      toast({
        position: 'top',
        render: ({ onClose }) => (
          <Box mt='61px'>
            <AuthAlert
              onClose={onClose}
              title='Too much files!'
              reason='You can upload 20 files at once'
            />
          </Box>
        ),
        duration: 5000,
      })

      return
    }

    if (e.target.files) {
      setFiles(e.target.files)
    }
  }

  useEffect(() => {
    if (languages && !loading) {
      setAvailableLocales(
        ([...languages.languages] || []).sort((a, b) =>
          a.title.localeCompare(b.title),
        ),
      )
    }
  }, [languages, loading, selectedLocale])

  useEffect(() => {
    if (allChapters && !isChaptersLoading) {
      setChapters(
        (allChapters.chapters || []).filter(
          chapt =>
            !selectedChapters.some(
              selected => selected.chapter_id === chapt.chapter_id,
            ),
        ),
      )
    }
  }, [allChapters, isChaptersLoading, book, selectedChapters])

  return (
    <>
      {!isContentLoading && (
        <Heading
          size='poppins24'
          variant='primary-black-text'
          lineHeight='38px'
          letterSpacing='0'
          textAlign='left'
        >
          {isReview
            ? `Upload audio chapters for the "${book?.title}"`
            : `Manage audio chapters for the "${book?.title}"`}
        </Heading>
      )}

      {isContentLoading && (
        <Flex pos='relative' w='100%' h='360px'>
          <LoadingSpinner />
        </Flex>
      )}

      {!isContentLoading && (
        <Flex gap='20px' pt='14px' justify='flex-start'>
          <FormControl display='flex' alignItems='center' w='fit-content'>
            <FormLabel htmlFor='content-toggler' mb='0'>
              Upload
            </FormLabel>
            <Switch
              id='content-toggler'
              value={isReview}
              onChange={() => setIsReview(curr => !curr)}
            />
            <FormLabel htmlFor='content-toggler' mb='0' ml='12px'>
              Review
            </FormLabel>
          </FormControl>

          <Select
            w='200px'
            placeholder='Select locale'
            value={selectedLocale}
            onChange={e => handleAddLocale(e.target.value)}
          >
            {availableLocales.map(locale => (
              <option value={locale.code} key={locale.code}>
                {locale.title} - {locale.code.toUpperCase()}
              </option>
            ))}
          </Select>
        </Flex>
      )}

      {isReview && chapters.length > 0 && !isContentLoading && (
        <BookAudioReview
          language={selectedLocale}
          chapters={allChapters.chapters}
          book_id={book.book_id}
          getAllChapters={getAllChapters}
        />
      )}

      {!isReview && !isContentLoading && (
        <>
          {!files && (
            <FormLabel
              m='0'
              cursor='pointer'
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <Flex
                borderRadius='8px'
                p='58px 36px'
                m='30px auto 20px'
                maxW='180px'
                border={`1px dashed ${colors['primary-violet-100']}`}
                direction='column'
                align='center'
                background='rgba(255, 255, 255, 0.50)'
              >
                <Image src='/images/upload.svg' alt='upload icon' mb='23px' />

                <Text
                  variant='primary-black-text'
                  size='poppins16'
                  fontWeight={600}
                  lineHeight='22px'
                  letterSpacing='-0.016px'
                  textAlign='center'
                  mb='11px'
                >
                  Select file
                  <br />
                  or drag it here
                </Text>
              </Flex>

              <input
                ref={inputRef}
                type='file'
                multiple
                id='file-input'
                accept='audio/*'
                onChange={addChapters}
                defaultValue=''
              />
            </FormLabel>
          )}

          {files && (
            <Flex
              bg={colors.white}
              direction='column'
              align='flex-start'
              alignSelf='flex-start'
              maxH='416px'
              w='100%'
              overflowY='hidden'
              p='12px'
              gap='20px'
            >
              <SimpleBarReact
                autoHide={true}
                style={{
                  minHeight: '330px',
                  maxHeight: '330px',
                  width: '100%',
                  overflowX: 'hidden',
                }}
              >
                {Object.entries(files).map(file => {
                  return (
                    <ChapterAudioOption
                      key={file[0]}
                      file={file}
                      availableChapters={chapters}
                      handleAddChapter={handleAddChapter}
                      chapter={selectedChapters.find(
                        data => data.file.name === file[1].name,
                      )}
                      handleRemoveChapter={handleRemoveChapter}
                    />
                  )
                })}
              </SimpleBarReact>

              <Flex alignSelf='flex-end' gap='12px'>
                <Button
                  type='button'
                  variant='outlineButton'
                  w='204px'
                  isDisabled={!files}
                  onClick={handleClearData}
                >
                  Clear data
                </Button>

                <Button
                  type='button'
                  variant='defaultButton'
                  w='204px'
                  isDisabled={
                    !files ||
                    selectedChapters.length !== Object.entries(files).length
                  }
                  onClick={handleSubmit}
                >
                  Upload
                </Button>
              </Flex>
            </Flex>
          )}
        </>
      )}
    </>
  )
}

export default FullBookFlow
