import { Flex } from '@chakra-ui/react'
import AuthService from 'services/auth'
import { navigation } from 'constants/navbar'
import { USER_ROLES } from 'constants/users'
import { useLocation } from 'react-router-dom'
import NavigationButton from '../NavButton'

const MainSection = () => {
  const { pathname } = useLocation()

  return (
    <Flex direction='column' gap='8px'>
      {navigation
        .filter(
          el =>
            el.permission === USER_ROLES.ALL ||
            el.permission === AuthService.role,
        )
        .map(navElement => {
          const isActive =
            pathname === navElement.path || pathname.includes(navElement.path)

          return (
            <NavigationButton
              isActive={isActive}
              navElement={navElement}
              key={navElement.name}
            />
          )
        })}
    </Flex>
  )
}

export default MainSection
