import React, { memo } from 'react'
import './styles.scss'

const BookCreatorItem = ({
  first_name,
  last_name,
  photo_link,
  email,
  role,
  handleBookCreatorUpdate,
}) => (
  <div className='creator' onClick={handleBookCreatorUpdate}>
    <img
      src={photo_link || '/images/user-pic.png'}
      alt={first_name + last_name + 'photo'}
      className='creator__photo'
    />
    <div className='creator-info'>
      <div className='creator__name'>
        <b>Name:</b> {first_name + ' ' + last_name}
      </div>
      <div className='creator__email'>
        <b>Email:</b> {email}
      </div>
      <div className={`creator__role creator__role--${role}`}>
        <b>Role:</b> {role}
      </div>
    </div>
  </div>
)

export default memo(BookCreatorItem)
