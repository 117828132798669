import * as R from 'ramda'

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  getBooksList,
  getGenreBooks,
} from '../../../redux/slices/books/selectors'
import { getGenres, getTags } from '../../../redux/slices/types/selectors'

import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'

import { SectionTypes } from '../../../constants/section'
import { createSelector } from '@reduxjs/toolkit'
import { fetchBooksByGenreIdThunk } from '../../../redux/slices/books/thunks'
import { getBannersList } from '../../../redux/slices/banners/selectors'
import useToggle from 'hooks/useToggle'
import { DEBOUNCE_DELAY } from 'constants/app'
import BookService from 'services/book'
import { getLists } from 'redux/slices/lists/selectors'
import ListsPanelLayout from './components/ListsPanelLayout'
import { deleteListThunk, updateListThunk } from 'redux/slices/lists/thunks'
import { updateListSurveysOrder } from 'redux/slices/lists'
import { apolloClient } from 'app/hocs/with-apollo'
import { GET_LIST } from 'services/lists/graphql'
import LoadingSpinner from 'components/UI/LoadingSpinner'

const stateSelector = createSelector(
  getLists,
  getGenres,
  getTags,
  getBooksList,
  getBannersList,
  getGenreBooks,
  (lists, genres, tags, books, banners, genreBook) => ({
    lists,
    genres,
    tags,
    books,
    banners,
    genreBook,
  }),
)

const ListPanel = ({ list = null, toggleModal }) => {
  const dispatch = useDispatch()

  let { lists, genres, tags, banners, genreBook } = useSelector(stateSelector)

  if (list) {
    lists = [list]
  }

  const [isShowTagModal, toggleTagModal] = useToggle(false)

  const [updatedList, setUpdatedList] = useState({})
  const [isOpenAppendBookModal, setIsOpenAppendBookModal] = useState(false)
  const [isOpenAppendGenreModal, setIsOpenAppendGenreModal] = useState(false)
  const [isShowAppendSurveyModal, setIsShowAppendSurveyModal] = useState(false)
  const [isShowAppendBannerModal, setIsShowAppendBannerModal] = useState(false)
  const [addedData, setAddedData] = useState([])
  const [newSurvey, setNewSurvey] = useState('')
  const [openedGenreId, setOpenedGenreId] = useState('')
  const [seriesBookSearch, setSeriesBookSearch] = useState('')
  const [searchedBooksList, setSearchedBooksList] = useState([])
  const [isSearchedBooksLoading, setIsBookSearchLoading] = useState(false)
  const [showDeleted, setShowDeleted] = useState(false)

  const booksByGenreList = useMemo(() => {
    const booksByGenre = R.pathOr([], [openedGenreId], genreBook)
    return Object.values(booksByGenre)
  }, [genreBook, openedGenreId])

  const filteredGeneres = useMemo(
    () =>
      genres.filter(
        genre =>
          !updatedList?.genresOrder?.some(
            updGenre => updGenre.genre.genre_id === genre.genre_id,
          ),
      ),
    [genres, updatedList],
  )

  const filteredTags = useMemo(
    () =>
      tags.filter(
        tag =>
          !updatedList?.tagsOrder?.some(
            updTag => updTag.tag.tag_id === tag.tag_id,
          ),
      ),
    [tags, updatedList?.tagsOrder],
  )

  const handleNotSelectedSeriesBooksFilter = useCallback(
    books =>
      books.filter(
        book =>
          !updatedList?.booksOrder?.some(
            updBook => updBook.book.book_id === book.book_id,
          ),
      ),
    [updatedList],
  )

  const handleSeriesBookSearchChange = useCallback(
    e => setSeriesBookSearch(e.target.value),
    [],
  )

  const filteredBanners = useMemo(
    () =>
      banners.filter(
        banner =>
          !updatedList?.bannersOrder?.some(
            updBanner => updBanner.banner.banner_id === banner.banner_id,
          ),
      ),
    [banners, updatedList],
  )

  const debouncedSearchChange = debounce(
    handleSeriesBookSearchChange,
    DEBOUNCE_DELAY,
  )

  const handleFetchBooksBySearch = useCallback(async () => {
    setIsBookSearchLoading(true)
    const books =
      updatedList.list_type === SectionTypes.COMING_SOON
        ? await BookService.searchComingSoonBooks({
            title: seriesBookSearch,
          })
        : updatedList.list_type === SectionTypes.FREE_FOR_LIMITED
        ? await BookService.searchLimitedBooks({
            title: seriesBookSearch,
          })
        : updatedList.list_type === SectionTypes.BOOKS_PREMIUM
        ? await BookService.searchPremiumBooks({
            title: seriesBookSearch,
          })
        : await BookService.searchBooks({
            title: seriesBookSearch,
            deleted: showDeleted,
          })
    setIsBookSearchLoading(false)
    setSearchedBooksList(handleNotSelectedSeriesBooksFilter(books))
  }, [
    handleNotSelectedSeriesBooksFilter,
    seriesBookSearch,
    updatedList,
    showDeleted,
  ])

  const onNewSurveyChange = useCallback(
    e => setNewSurvey(e.target.value),
    [setNewSurvey],
  )

  const openAppendGenreModal = useCallback(
    section => {
      setIsOpenAppendGenreModal(true)
      setUpdatedList(section)

      if (toggleModal) {
        toggleModal()
      }
    },
    [setIsOpenAppendGenreModal, toggleModal],
  )

  const closeAppendGenreModal = useCallback(() => {
    setIsOpenAppendGenreModal(false)
    setAddedData([])

    if (toggleModal) {
      toggleModal()
    }
  }, [setIsOpenAppendGenreModal, setAddedData, toggleModal])

  const openTagModal = useCallback(
    section => {
      toggleTagModal(true)
      setUpdatedList(section)

      if (toggleModal) {
        toggleModal()
      }
    },
    [toggleTagModal, toggleModal],
  )

  const closeTagModal = useCallback(() => {
    toggleTagModal(false)
    setAddedData([])

    if (toggleModal) {
      toggleModal()
    }
  }, [toggleTagModal, toggleModal])

  const showAppendSurveyModal = useCallback(
    section => {
      setIsShowAppendSurveyModal(true)
      setUpdatedList(section)
    },
    [setIsShowAppendSurveyModal],
  )

  const hideAppendSurveyModal = useCallback(() => {
    setIsShowAppendSurveyModal(false)
    setNewSurvey('')
  }, [setIsShowAppendSurveyModal, setNewSurvey])

  const showAppendBannerModal = useCallback(
    section => {
      setIsShowAppendBannerModal(true)
      setUpdatedList(section)

      if (toggleModal) {
        toggleModal()
      }
    },
    [setIsShowAppendBannerModal, toggleModal],
  )

  const hideAppendBannerModal = useCallback(() => {
    setIsShowAppendBannerModal(false)
    setAddedData([])

    if (toggleModal) {
      toggleModal()
    }
  }, [setIsShowAppendBannerModal, setAddedData, toggleModal])

  const getIsSelectedGenre = useCallback(
    genre => addedData.some(data => genre.genre_id === data.genre_id),
    [addedData],
  )

  const getSelectedGenreIndex = useCallback(
    genre => addedData.findIndex(data => genre.genre_id === data.genre_id),
    [addedData],
  )

  const getIsSelectedTag = useCallback(
    tag => addedData.some(data => tag.tag_id === data.tag_id),
    [addedData],
  )

  const getSelectedTagIndex = useCallback(
    tag => addedData.findIndex(data => tag.tag_id === data.tag_id),
    [addedData],
  )

  const getIsSelectedBook = useCallback(
    book => addedData.some(data => book.book_id === data.book_id),
    [addedData],
  )

  const getSelectedBookIndex = useCallback(
    book => addedData.findIndex(data => book.book_id === data.book_id),
    [addedData],
  )

  const getIsSelectedBanner = useCallback(
    banner => addedData.some(data => banner.banner_id === data.banner_id),
    [addedData],
  )

  const getSelectedBannerIndex = useCallback(
    banner => addedData.findIndex(data => banner.banner_id === data.banner_id),
    [addedData],
  )

  const selectGenre = useCallback(
    genre => {
      if (getIsSelectedGenre(genre)) {
        setAddedData(addedData.filter(data => data.genre_id !== genre.genre_id))
        return
      }
      setAddedData([...addedData, genre])
    },
    [getIsSelectedGenre, addedData],
  )

  const selectTag = useCallback(
    tag => {
      if (getIsSelectedTag(tag)) {
        setAddedData(addedData.filter(data => data.tag_id !== tag.tag_id))
        return
      }
      setAddedData([...addedData, tag])
    },
    [getIsSelectedTag, addedData],
  )

  const selectBook = useCallback(
    book => {
      if (getIsSelectedBook(book)) {
        setAddedData(addedData.filter(data => data.book_id !== book.book_id))
        return
      }
      setAddedData([...addedData, book])
    },
    [getIsSelectedBook, setAddedData, addedData],
  )

  const selectBanner = useCallback(
    banner => {
      if (getIsSelectedBanner(banner)) {
        setAddedData(
          addedData.filter(data => data.banner_id !== banner.banner_id),
        )
        return
      }
      setAddedData([...addedData, banner])
    },
    [getIsSelectedBanner, setAddedData, addedData],
  )

  const updateGenreList = useCallback(async () => {
    const oldGenres = updatedList.genresOrder.map(({ genre }, index) => ({
      genre: { genre_id: genre.genre_id },
      order_number: index,
    }))

    const newGenres = addedData.map((genre, index) => ({
      genre: { genre_id: genre.genre_id },
      order_number: updatedList.genresOrder.length + index,
    }))

    const list = {
      list_id: updatedList.list_id,
      title: updatedList.title,
      genresOrder: [...oldGenres, ...newGenres],
    }

    await dispatch(updateListThunk(list))
    await apolloClient.query({
      query: GET_LIST,
      variables: { list_id: list.list_id },
      fetchPolicy: 'network-only',
    })
    closeAppendGenreModal()
  }, [
    updatedList.genresOrder,
    updatedList.list_id,
    updatedList.title,
    addedData,
    dispatch,
    closeAppendGenreModal,
  ])

  const updateTagList = useCallback(async () => {
    const oldTags = updatedList.tagsOrder.map(({ tag }, index) => ({
      tag: { tag_id: tag.tag_id },
      order_number: index,
    }))

    const newTags = addedData.map((tag, index) => ({
      tag: { tag_id: tag.tag_id },
      order_number: updatedList.tagsOrder.length + index,
    }))

    const list = {
      list_id: updatedList.list_id,
      title: updatedList.title,
      tagsOrder: [...oldTags, ...newTags],
    }

    await dispatch(updateListThunk(list))
    await apolloClient.query({
      query: GET_LIST,
      variables: { list_id: list.list_id },
      fetchPolicy: 'network-only',
    })
    closeTagModal()
  }, [
    updatedList.tagsOrder,
    updatedList.list_id,
    updatedList.title,
    addedData,
    dispatch,
    closeTagModal,
  ])

  const updateSurveySection = useCallback(() => {
    const list = {
      list_id: updatedList.list_id,
      title: updatedList.title,
      survey_links: [...updatedList.survey_links, newSurvey],
    }
    dispatch(updateListThunk(list))
    hideAppendSurveyModal()
  }, [
    updatedList.list_id,
    updatedList.title,
    updatedList.survey_links,
    newSurvey,
    dispatch,
    hideAppendSurveyModal,
  ])

  const openAppendBookModal = useCallback(
    section => {
      setIsOpenAppendBookModal(true)
      setUpdatedList(section)

      if (toggleModal) {
        toggleModal()
      }
    },
    [setIsOpenAppendBookModal, setUpdatedList, toggleModal],
  )

  const closeAppendBookModal = useCallback(() => {
    setIsOpenAppendBookModal(false)
    setAddedData([])

    if (toggleModal) {
      toggleModal()
    }
  }, [setIsOpenAppendBookModal, setAddedData, toggleModal])

  const updateBooksList = useCallback(async () => {
    const oldBooks = updatedList.booksOrder.map(({ book }, index) => ({
      book: { book_id: book.book_id },
      order_number: index,
    }))

    const newBooks = addedData.map((book, index) => ({
      book: { book_id: book.book_id },
      order_number: updatedList.booksOrder.length + index,
    }))

    const list = {
      list_id: updatedList.list_id,
      title: updatedList.title,
      booksOrder: [...oldBooks, ...newBooks],
    }

    await dispatch(updateListThunk(list))
    await apolloClient.query({
      query: GET_LIST,
      variables: { list_id: list.list_id },
      fetchPolicy: 'network-only',
    })
    closeAppendBookModal()
  }, [
    updatedList.booksOrder,
    updatedList.list_id,
    updatedList.title,
    addedData,
    dispatch,
    closeAppendBookModal,
  ])

  const updateBannersSection = useCallback(async () => {
    const oldBanners = updatedList.bannersOrder.map(({ banner }, index) => ({
      banner: { banner_id: banner.banner_id },
      order_number: index,
    }))

    const newBanners = addedData.map((banner, index) => ({
      banner: { banner_id: banner.banner_id },
      order_number: updatedList.bannersOrder.length + index,
    }))

    const list = {
      list_id: updatedList.list_id,
      title: updatedList.title,
      bannersOrder: [...oldBanners, ...newBanners],
    }

    await dispatch(updateListThunk(list))
    await apolloClient.query({
      query: GET_LIST,
      variables: { list_id: list.list_id },
      fetchPolicy: 'network-only',
    })
    hideAppendBannerModal()
  }, [
    updatedList.bannersOrder,
    updatedList.list_id,
    updatedList.title,
    addedData,
    dispatch,
    hideAppendBannerModal,
  ])

  const deleteListContentOption = useCallback(
    async (list, deletedOptionId) => {
      const isConfirm = window.confirm('Are you sure?')
      if (isConfirm) {
        const updatedList = {
          list_id: list.list_id,
          title: list.title,
        }

        if (
          list.list_type.includes('BOOKS') ||
          list.list_type === SectionTypes.COMING_SOON ||
          list.list_type === SectionTypes.FREE_FOR_LIMITED ||
          list.list_type === SectionTypes.BOOKS_PREMIUM ||
          list.list_type === SectionTypes.MOST_POPULAR ||
          list.list_type === SectionTypes.NEW_RELEASES_LIST
        ) {
          updatedList.booksOrder = [...list.booksOrder]
            .sort((orderA, orderB) => orderA.order_number - orderB.order_number)
            .filter(({ book }) => book.book_id !== deletedOptionId)
            .map(({ book }, order_number) => ({
              book: { book_id: book.book_id },
              order_number,
            }))
        }
        if (list.list_type.includes(SectionTypes.GENRES)) {
          updatedList.genresOrder = list.genresOrder.filter(
            ({ genre }) => genre.genre_id !== deletedOptionId,
          )
        }
        if (list.list_type.includes('BANNER')) {
          updatedList.bannersOrder = list.bannersOrder
            .filter(({ banner }) => banner.banner_id !== deletedOptionId)
            .map(({ banner }, order_number) => ({
              banner: { banner_id: banner.banner_id },
              order_number,
            }))
        }
        if (list.list_type.includes('TAGS')) {
          updatedList.tagsOrder = list.tagsOrder.filter(
            ({ tag }) => tag.tag_id !== deletedOptionId,
          )
        }

        await dispatch(updateListThunk(updatedList))
        await apolloClient.query({
          query: GET_LIST,
          variables: { list_id: list.list_id },
          fetchPolicy: 'network-only',
        })
      }
    },
    [dispatch],
  )

  const deleteListSurveyLink = useCallback(
    (list, deletedLinkIndex) => {
      const isConfirm = window.confirm('Are you sure?')
      if (isConfirm) {
        const updatedList = {
          list_id: list.list_id,
          title: list.title,
          survey_links: list.survey_links.filter(
            (_, idx) => idx !== deletedLinkIndex,
          ),
        }
        dispatch(updateListThunk(updatedList))
      }
    },
    [dispatch],
  )

  const deleteList = useCallback(
    list_id => {
      const isConfirm = window.confirm('Are you sure?')
      if (isConfirm) {
        dispatch(deleteListThunk({ list_id }))
      }
    },
    [dispatch],
  )

  const moveSurveys = useCallback(
    (dragIndex, hoverIndex, list_id) => {
      dispatch(updateListSurveysOrder({ dragIndex, hoverIndex, list_id }))
    },
    [dispatch],
  )

  const updateListItemsOrder = useCallback(
    list => {
      const updList = {
        ...list,
        booksOrder: list.booksOrder.map(({ book }, order_number) => ({
          book: { book_id: book.book_id },
          order_number,
        })),
        tagsOrder: list.tagsOrder.map(({ tag }, order_number) => ({
          tag: { tag_id: tag.tag_id },
          order_number,
        })),
        genresOrder: list.genresOrder.map(({ genre }, order_number) => ({
          genre: { genre_id: genre.genre_id },
          order_number,
        })),
        bannersOrder: list.bannersOrder.map(({ banner }, order_number) => ({
          banner: { banner_id: banner.banner_id },
          order_number,
        })),
        survey_links: list.survey_links,
      }

      dispatch(updateListThunk(updList))
    },
    [dispatch],
  )

  const openGenreBooks = useCallback(
    genre_id => {
      setOpenedGenreId(genre_id)
      dispatch(fetchBooksByGenreIdThunk(genre_id))
    },
    [dispatch],
  )

  useEffect(() => {
    return () => {
      debouncedSearchChange.cancel()
    }
  }, [debouncedSearchChange])

  useEffect(() => {
    if (seriesBookSearch) {
      return handleFetchBooksBySearch()
    }
    setSearchedBooksList([])
  }, [handleFetchBooksBySearch, seriesBookSearch])

  return (
    <>
      {lists.length === 0 && <LoadingSpinner />}

      {lists.length > 0 && (
        <ListsPanelLayout
          isSearchedBooksLoading={isSearchedBooksLoading}
          filteredBanners={filteredBanners}
          closeAppendBookModal={closeAppendBookModal}
          closeAppendGenreModal={closeAppendGenreModal}
          deleteList={deleteList}
          deleteListContentOption={deleteListContentOption}
          filteredBooks={searchedBooksList}
          filteredGeneres={filteredGeneres}
          getIsSelectedBook={getIsSelectedBook}
          getIsSelectedGenre={getIsSelectedGenre}
          getSelectedGenreIndex={getSelectedGenreIndex}
          getSelectedBookIndex={getSelectedBookIndex}
          getSelectedBannerIndex={getSelectedBannerIndex}
          isOpenAppendBookModal={isOpenAppendBookModal}
          isOpenAppendGenreModal={isOpenAppendGenreModal}
          openAppendBookModal={openAppendBookModal}
          openAppendGenreModal={openAppendGenreModal}
          selectBook={selectBook}
          selectGenre={selectGenre}
          updateGenreList={updateGenreList}
          updateBooksList={updateBooksList}
          updateListItemsOrder={updateListItemsOrder}
          updatedList={updatedList}
          lists={lists}
          showAppendSurveyModal={showAppendSurveyModal}
          hideAppendSurveyModal={hideAppendSurveyModal}
          isShowAppendSurveyModal={isShowAppendSurveyModal}
          newSurvey={newSurvey}
          onNewSurveyChange={onNewSurveyChange}
          updateSurveySection={updateSurveySection}
          deleteListSurveyLink={deleteListSurveyLink}
          isShowAppendBannerModal={isShowAppendBannerModal}
          showAppendBannerModal={showAppendBannerModal}
          hideAppendBannerModal={hideAppendBannerModal}
          getIsSelectedBanner={getIsSelectedBanner}
          selectBanner={selectBanner}
          updateBannersSection={updateBannersSection}
          booksByGenreList={booksByGenreList}
          openGenreBooks={openGenreBooks}
          handleSeriesBookSearchChange={debouncedSearchChange}
          seriesBookSearch={seriesBookSearch}
          moveSurveys={moveSurveys}
          isShowTagModal={isShowTagModal}
          openTagModal={openTagModal}
          closeTagModal={closeTagModal}
          filteredTags={filteredTags}
          getIsSelectedTag={getIsSelectedTag}
          getSelectedTagIndex={getSelectedTagIndex}
          selectTag={selectTag}
          updateTagList={updateTagList}
          setShowDeleted={setShowDeleted}
          showDeleted={showDeleted}
        />
      )}
    </>
  )
}

export default ListPanel
