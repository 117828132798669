import { Flex, FormControl, FormLabel, Switch } from '@chakra-ui/react'
import React from 'react'

const FlowToggler = ({ isChapterUpload, setIsChapterUpload }) => {
  return (
    <Flex gap='20px' pt='14px' mb='10px'>
      <FormControl display='flex' alignItems='center'>
        <FormLabel htmlFor='content-toggler' mb='0'>
          For Book
        </FormLabel>
        <Switch
          id='content-toggler'
          value={isChapterUpload}
          onChange={() => setIsChapterUpload(curr => !curr)}
        />
        <FormLabel htmlFor='content-toggler' mb='0' ml='12px'>
          For Chapter
        </FormLabel>
      </FormControl>
    </Flex>
  )
}

export default FlowToggler
