import { cssVar } from '@chakra-ui/theme-tools'
import { colors } from '../colors'

const $bg = cssVar('tooltip-bg')
const $arrowBg = cssVar('popper-arrow-bg')

export const tooltipStyles = {
  baseStyle: {
    [$bg.variable]: '#141414',
    [$arrowBg.variable]: '#141414',
    arrowBg: '#141414',
    borderRadius: '12px',
    px: '20px',
    py: '12px',
    fontSize: '11px',
    lineHeight: '15px',
    color: '#B7B9BC',
    maxW: '450px',
    w: 'fit-content',
  },
}

export const createStory = {
  bg: '#F3F3FF',
  p: '12px',
  boxShadow: '0px 2px 5px 0px rgba(36, 28, 88, 0.20)',
  borderRadius: '8px',
  lineHeight: '18px',
  color: colors['primary-black-text'],
  w: 'fit-content',
  maxW: '294px',
  fontSize: '12px',
  letterSpacing: '-0.18px',
  fontFamily: `'Poppins', sans-serif`,
}

export const danger = {
  bg: colors['primitive-red-light-red-100'],
  p: '12px',
  boxShadow: '0px 2px 5px 0px rgba(36, 28, 88, 0.20)',
  borderRadius: '8px',
  lineHeight: '18px',
  color: colors['primary-black-text'],
  w: 'fit-content',
  fontSize: '12px',
  letterSpacing: '-0.18px',
  fontFamily: `'Poppins', sans-serif`,
}
