import { Box, Flex, Td, Text, Tr } from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import { colors } from 'shared/style/colors'
import PinIcon from './PinIcon'
import FilledPinIcon from './FilledPinIcon'

const ChapterCell = ({
  chapter,
  isLast,
  transactions,
  lastRead,
  handleUpdateLastReadData,
  handleClearLastReadData,
  book_id,
  user_id,
  handleUnlockChapter,
  handleLockChapter,
}) => {
  const [hover, setHover] = useState(false)
  const isUnlocked = transactions.find(
    tr => tr.chapter_id === chapter.chapter_id,
  )
  const isCurrentLastRead = lastRead.find(
    tr =>
      tr.chapter_id === chapter.chapter_id && tr.transaction_type !== 'premium',
  )

  const updateLastReadRecord = useCallback(async () => {
    await handleUpdateLastReadData(user_id, book_id, chapter.chapter_id)
  }, [book_id, chapter, handleUpdateLastReadData, user_id])

  const unlockBookChapter = useCallback(async () => {
    await handleUnlockChapter(user_id, book_id, chapter.chapter_id)
  }, [book_id, chapter, handleUnlockChapter, user_id])

  const lockBookChapter = useCallback(async () => {
    await handleLockChapter(user_id, book_id, chapter.chapter_id)
  }, [book_id, chapter, handleLockChapter, user_id])

  const clearLastRead = useCallback(async () => {
    await handleClearLastReadData(user_id, book_id)
  }, [book_id, handleClearLastReadData, user_id])

  return (
    <Tr
      style={{
        borderBottom: isLast
          ? '1px solid transparent'
          : `1px solid ${colors.stroke}`,
      }}
    >
      <Td w='20%' maxW='20%' p='7px'>
        {chapter.isFree && (
          <Flex
            borderRadius='20px'
            background='green.600'
            p='8px'
            align='center'
            justify={'center'}
            w='156px'
          >
            <Text
              size='poppins18'
              fontWeight={600}
              variant='primary-black-text'
              letterSpacing='-0.018px'
              color={colors.white}
            >
              FREE
            </Text>
          </Flex>
        )}

        {Boolean(isUnlocked) && (
          <Flex
            borderRadius='20px'
            background='green.300'
            p='8px'
            align='center'
            justify={'center'}
            w='156px'
            cursor='pointer'
            onClick={lockBookChapter}
          >
            <Text
              size='poppins18'
              fontWeight={600}
              variant='primary-black-text'
              letterSpacing='-0.018px'
              color={colors.white}
              cursor='pointer'
            >
              UNLOCKED
            </Text>
          </Flex>
        )}

        {!isUnlocked && !chapter.isFree && (
          <Flex
            borderRadius='20px'
            background='red.400'
            p='8px'
            align='center'
            justify={'center'}
            w='156px'
            cursor='pointer'
            onClick={unlockBookChapter}
          >
            <Text
              size='poppins18'
              fontWeight={600}
              variant='primary-black-text'
              letterSpacing='-0.018px'
              color={colors.white}
            >
              LOCKED
            </Text>
          </Flex>
        )}
      </Td>
      <Td w='15%' maxW='15%' p='7px'>
        <Text
          size='poppins18'
          fontWeight={600}
          variant='primary-black-text'
          lineHeight='46px'
          letterSpacing='-0.018px'
          textAlign='center'
          color={colors['primary-black-text']}
        >
          {chapter.chapter_order + 1}
        </Text>
      </Td>
      <Td w='45%' maxW='45%' p='7px'>
        <Text
          size='poppins18'
          fontWeight={600}
          variant='primary-black-text'
          lineHeight='46px'
          letterSpacing='-0.018px'
          textAlign='center'
          color={colors['primary-black-text']}
        >
          {chapter.title}
        </Text>
      </Td>

      <Td w='20%' maxW='20%' p='7px'>
        <Flex w='100%' justify='center'>
          <Box
            pos='relative'
            cursor='pointer'
            w='24px'
            h='24px'
            onClick={isCurrentLastRead ? clearLastRead : updateLastReadRecord}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {Boolean(isCurrentLastRead) && <FilledPinIcon />}
            {!isCurrentLastRead &&
              PinIcon(
                hover
                  ? colors['primary-violet-100']
                  : colors['primary-black-text'],
              )}
          </Box>
        </Flex>
      </Td>
    </Tr>
  )
}

export default ChapterCell
