import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import React, { memo } from 'react'
import {
  ButtonGroup,
  ButtonToolbar,
  Form,
  Modal,
  Button,
} from 'react-bootstrap'

export const createGenreValidationSchema = Yup.object({
  title: Yup.string().required('Required'),
  link: Yup.string().required('Required'),
  icon_link: Yup.string().required('Required'),
})

const CreateGenreModal = ({ isShow, onHide, onSubmit }) => (
  <Modal show={isShow} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>Add a new genre</Modal.Title>
    </Modal.Header>
    <Formik
      initialValues={{
        title: '',
        link: '',
        icon_link: '',
      }}
      validationSchema={createGenreValidationSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, handleBlur, errors, touched }) => (
        <FormikForm>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Genre name:</Form.Label>
              <Form.Control
                type='text'
                placeholder='Romance'
                name='title'
                value={values.title}
                onChange={handleChange}
                handleBlur={handleBlur}
                className='mb-3'
                isValid={touched.title && !errors.title}
              />

              <Form.Control.Feedback type='invalid'>
                Required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Genre display image link:</Form.Label>
              <Form.Control
                type='text'
                placeholder='https://...'
                name='link'
                value={values.link}
                onChange={handleChange}
                handleBlur={handleBlur}
                className='mb-3'
                isValid={touched.link && !errors.link}
              />
              <Form.Control.Feedback type='invalid'>
                Required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Genre display icon link:</Form.Label>
              <Form.Control
                type='text'
                placeholder='https://...'
                name='icon_link'
                value={values.icon_link}
                onChange={handleChange}
                handleBlur={handleBlur}
                className='mb-3'
                isValid={touched.icon_link && !errors.icon_link}
              />
              <Form.Control.Feedback type='invalid'>
                Required
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <ButtonToolbar>
              <ButtonGroup className='me-2'>
                <Button variant='primary' onClick={onHide}>
                  Cancel
                </Button>
              </ButtonGroup>
              <ButtonGroup className='me-2'>
                <Button type='submit' variant='primary'>
                  Save
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Modal.Footer>
        </FormikForm>
      )}
    </Formik>
  </Modal>
)

export default memo(CreateGenreModal)
