import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
} from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'

import { Button } from 'react-bootstrap'
import { MdCached, MdClear } from 'react-icons/md'
import { colors } from 'shared/style/colors'
import { clearCache, clearCacheByPrefix } from 'api/cache'
import AuthAlert from 'screens/Authorization/components/AuthAlert'

const CacheLayout = () => {
  const [prefix, setPrefix] = useState('')
  const toast = useToast()

  const handleCacheClear = useCallback(async () => {
    const isConfirm = window.confirm('Are you sure?')
    if (isConfirm) {
      await clearCache()
        .then(res => {
          toast({
            title: `${res.data}`,
            status: 'success',
            isClosable: true,
            duration: 1000,
            position: 'top',
          })
        })
        .catch(() => {
          toast({
            position: 'top',
            render: ({ onClose }) => (
              <Box mt='61px'>
                <AuthAlert
                  onClose={onClose}
                  title='Something went wrong!'
                  reason='Failed to clear all cache'
                />
              </Box>
            ),
            duration: 5000,
          })
        })
    }
  }, [toast])

  const handleCacheClearByPrefix = async () => {
    if (prefix.trim()) {
      await clearCacheByPrefix(prefix)
        .then(res => {
          toast({
            title: `${res.data}`,
            status: 'success',
            isClosable: true,
            duration: 1000,
            position: 'top',
          })
        })
        .catch(() => {
          toast({
            position: 'top',
            render: ({ onClose }) => (
              <Box mt='61px'>
                <AuthAlert
                  onClose={onClose}
                  title='Something went wrong!'
                  reason='Failed to clear all cache'
                />
              </Box>
            ),
            duration: 5000,
          })
        })
    }
  }

  const handlePrefixInput = e => {
    const prefixValue = e ? e.target.value : ''

    setPrefix(prefixValue)
  }

  return (
    <Flex p='20px' gap='20px' direction='column'>
      <Button
        variant='danger'
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 5,
          height: '44px',
          width: 'fit-content',
        }}
        onClick={handleCacheClear}
      >
        <MdCached />
        <span> Clear cache</span>
      </Button>

      <Box mb='24px' w='fit-content' minW='300px'>
        <Text size='poppins24' variant='primary-black-text'>
          Clear By Prefix
        </Text>

        <Flex gap='14px' align='center'>
          <InputGroup>
            <Input
              type='text'
              placeholder='...'
              variant='loginField'
              value={prefix}
              onChange={handlePrefixInput}
              width='500px'
            />

            {prefix && prefix.length > 0 && (
              <InputRightElement right='18px' top='12px' w='24px' h='24px'>
                <Icon
                  as={MdClear}
                  fill={colors['primary-violet-100']}
                  w='24px'
                  h='24px'
                  cursor='pointer'
                  onClick={() => handlePrefixInput(null)}
                />
              </InputRightElement>
            )}
          </InputGroup>

          <Button
            variant='danger'
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 5,
              height: '44px',
              width: 'fit-content',
              whiteSpace: 'nowrap',
            }}
            onClick={handleCacheClearByPrefix}
            disabled={!prefix}
          >
            <MdCached />
            <span> Clear By Prefix</span>
          </Button>
        </Flex>
      </Box>
    </Flex>
  )
}

export default CacheLayout
