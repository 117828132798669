import { Checkbox, Flex, FormControl, FormLabel } from '@chakra-ui/react'
import React, { useCallback } from 'react'

const ComponentSelect = ({
  value,
  handleToggle,
  generalTitle,
  forceTitle,
  id,
}) => {
  const toggleSelectAll = useCallback(() => {
    handleToggle({
      all: !value.all,
      force: false,
    })
  }, [value, handleToggle])

  const toggleSelectForce = useCallback(() => {
    handleToggle({
      all: !value.force ? true : value.all,
      force: !value.force,
    })
  }, [value, handleToggle])

  return (
    <Flex>
      <FormControl w='fit-content'>
        <FormLabel
          htmlFor={`select-${id}`}
          whiteSpace='nowrap'
          cursor='pointer'
        >
          {generalTitle}
        </FormLabel>
        <Checkbox
          size='lg'
          colorScheme='purple'
          isChecked={value.all}
          id={`select-${id}`}
          display='flex'
          onChange={toggleSelectAll}
        />
      </FormControl>

      <FormControl w='fit-content'>
        <FormLabel htmlFor={`force-${id}`} whiteSpace='nowrap' cursor='pointer'>
          {forceTitle}
        </FormLabel>

        <Checkbox
          size='lg'
          colorScheme='purple'
          isChecked={value.force}
          id={`force-${id}`}
          display='flex'
          onChange={toggleSelectForce}
        />
      </FormControl>
    </Flex>
  )
}

export default ComponentSelect
