import React, { memo, useEffect, useRef } from 'react'
import { Button } from 'react-bootstrap'
import { useDrag, useDrop } from 'react-dnd'
import './styles.scss'

const SurveyItem = ({
  link,
  deleteListSurveyLink,
  index,
  moveSurveys,
  list,
  updateListItemsOrder,
}) => {
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: list.list_id + list.list_type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / +'2'
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveSurveys(dragIndex, hoverIndex, list.list_id)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: list.list_id + list.list_type,
    item: () => {
      return { id: list.list_id + list.list_type, index }
    },
    end: () => {
      updateListItemsOrder(list)
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  drag(drop(ref))

  const opacity = isDragging ? '0.5' : 1
  const cursor = !isDragging ? 'grab' : ''

  useEffect(() => {
    if (isDragging) {
      document.body.style.cursor = 'grabbing'
      return
    }
    document.body.style.cursor = ''
  }, [isDragging])

  return (
    <div
      className='survey-link'
      ref={ref}
      style={{ opacity, cursor }}
      data-handler-id={handlerId}
    >
      <div>
        <a
          href={link}
          target='_blank'
          className='survey-link-info'
          rel='noreferrer'
        >
          {link}
        </a>
      </div>

      <Button
        variant='danger'
        onClick={() => deleteListSurveyLink(list, index)}
        style={{ height: 40, margin: 0 }}
      >
        Delete
      </Button>
    </div>
  )
}

export default memo(SurveyItem)
