import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { MdClear } from 'react-icons/md'
import { colors } from 'shared/style/colors'

const TitleSearch = ({ search, onChange }) => {
  const handleSearchInput = e => {
    const searchValue = e ? e.target.value : ''

    onChange(searchValue)
  }

  return (
    <Box mb='24px' w='fit-content' minW='300px'>
      <Text mb='12px' size='poppins16normal' variant='primary-black-text'>
        Title Search
      </Text>

      <InputGroup>
        <Input
          type='text'
          placeholder='Search...'
          variant='loginField'
          value={search}
          onChange={handleSearchInput}
        />

        {search && search.length > 0 && (
          <InputRightElement right='18px' top='12px' w='24px' h='24px'>
            <Icon
              as={MdClear}
              fill={colors['primary-violet-100']}
              w='24px'
              h='24px'
              cursor='pointer'
              onClick={() => handleSearchInput(null)}
            />
          </InputRightElement>
        )}
      </InputGroup>
    </Box>
  )
}

export default TitleSearch
