import { apolloClient } from 'app/hocs/with-apollo'
import {
  GET_BOOKS,
  GET_BOOKS_BY_SEARCH,
  GET_BOOKS_PREMIUM_BY_SEARCH,
  GET_COMING_SOON_BOOKS_BY_SEARCH,
  GET_LIMITED_BOOKS_BY_SEARCH,
} from './graphql'

class BookService {
  static async fetchBooks(variables) {
    const response = await apolloClient.query({
      query: GET_BOOKS,
      variables,
    })
    if (!response || !response.data) {
      throw new Error('Cannot get books list!')
    }
    return response.data.books
  }

  static async searchBooks(variables) {
    const response = await apolloClient.query({
      query: GET_BOOKS_BY_SEARCH,
      variables: { ...variables, limit: 10000 },
    })

    if (!response || !response.data) {
      throw new Error('Cannot get books list!')
    }
    return response.data.booksSearch
  }

  static async searchComingSoonBooks(variables) {
    const response = await apolloClient.query({
      query: GET_COMING_SOON_BOOKS_BY_SEARCH,
      variables: { ...variables, limit: 10000 },
    })
    if (!response || !response.data) {
      throw new Error('Cannot get books list!')
    }
    return response.data.booksSearch
  }

  static async searchPremiumBooks(variables) {
    const response = await apolloClient.query({
      query: GET_BOOKS_PREMIUM_BY_SEARCH,
      variables: { ...variables, limit: 10000 },
    })
    if (!response || !response.data) {
      throw new Error('Cannot get books list!')
    }
    return response.data.booksSearch
  }

  static async searchLimitedBooks(variables) {
    const response = await apolloClient.query({
      query: GET_LIMITED_BOOKS_BY_SEARCH,
      variables: { ...variables, limit: 10000 },
    })
    if (!response || !response.data) {
      throw new Error('Cannot get books list!')
    }
    return response.data.booksSearch
  }
}

export default BookService
