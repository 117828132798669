import axiosApiInstance from 'utils/axios-instance'

export const fetchAdvertisements = () => axiosApiInstance.get('advertisement')

export const createAdvertisement = advertisement =>
  axiosApiInstance.post('advertisement', advertisement)

export const updateAdvertisement = advertisement =>
  axiosApiInstance.put('advertisement', advertisement)

export const deleteAdvertisementById = addvertisement_id =>
  axiosApiInstance.delete('advertisement', { addvertisement_id })
