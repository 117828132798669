import { Td, Text, Tr } from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { colors } from 'shared/style/colors'

const UserCell = ({ user_id, email, isLast, isFirst, firstRow }) => {
  const [hover, setHover] = useState(false)
  const navigate = useNavigate()

  const manageUser = useCallback(() => {
    navigate(`/webapp/${user_id}`)
  }, [navigate, user_id])

  return (
    <Tr
      style={{
        borderBottom: isLast
          ? '1px solid transparent'
          : `1px solid ${colors.stroke}`,
        background: hover ? colors['primary-violet-100'] : 'none',
        cursor: 'pointer',
      }}
      ref={isFirst ? firstRow : null}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={manageUser}
    >
      <Td
        w='50%'
        maxW='50%'
        p='7px'
        borderTopLeftRadius='20px'
        borderBottomLeftRadius='20px'
      >
        <Text
          size='poppins18'
          fontWeight={600}
          variant='primary-black-text'
          lineHeight='46px'
          letterSpacing='-0.018px'
          color={hover ? colors.white : colors['primary-black-text']}
        >
          {user_id}
        </Text>
      </Td>
      <Td
        w='50%'
        maxW='50%'
        p='7px'
        borderTopRightRadius='20px'
        borderBottomRightRadius='20px'
      >
        <Text
          size='poppins18'
          fontWeight={600}
          variant='primary-black-text'
          lineHeight='46px'
          letterSpacing='-0.018px'
          textAlign='center'
          color={hover ? colors.white : colors['primary-black-text']}
        >
          {email || 'no email'}
        </Text>
      </Td>
    </Tr>
  )
}

export default UserCell
