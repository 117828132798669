const getEnvVar = key => {
  if (process.env[key] === undefined) {
    throw new Error(`Env variable ${key} is required`)
  }
  return process.env[key] || ''
}

export const API_URL = getEnvVar('REACT_APP_API_URL')
export const API_GRAPHQL_URL = getEnvVar('REACT_APP_API_GRAPHQL_URL')
export const API2_GRAPHQL_URL = getEnvVar('REACT_APP_API2_GRAPHQL_URL')
