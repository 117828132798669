import { createSlice } from '@reduxjs/toolkit'
import {
  createSeriesThunk,
  deleteSeriesThunk,
  fetchSeriesThunk,
  updateSeriesThunk,
} from './thunks'
import * as R from 'ramda'

export const STATE_KEY = 'series'

const initialState = {}

const seriesSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    updateSeriesBooksOrder: (state, action) => {
      const { dragIndex, hoverIndex, series_id } = action.payload
      const books = R.clone(state[series_id].booksOrder)
      const tmp = { ...books[dragIndex], order_number: hoverIndex }
      books[dragIndex] = { ...books[hoverIndex], order_number: dragIndex }
      books[hoverIndex] = tmp
      state[series_id].booksOrder = books
    },
  },
  extraReducers: {
    [fetchSeriesThunk.fulfilled]: (_, action) => {
      return R.indexBy(R.prop('series_id'), action.payload)
    },
    [createSeriesThunk.fulfilled]: (state, { payload: series }) => {
      state[series.series_id] = series
    },
    [deleteSeriesThunk.fulfilled]: (state, { payload: series_id }) => {
      delete state[series_id]
    },
    [updateSeriesThunk.fulfilled]: (state, { payload: series }) => {
      state[series.series_id] = series
    },
  },
})

export const { updateSeriesBooksOrder } = seriesSlice.actions

export const seriesReducer = seriesSlice.reducer
