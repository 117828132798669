import React from 'react'
import { Button } from 'react-bootstrap'

const BookItem = ({
  book,
  onDelete,
  draggableProps,
  dragHandleProps,
  elementRef,
}) => {
  return (
    <div
      className='list-book-item'
      ref={elementRef}
      {...draggableProps}
      {...dragHandleProps}
    >
      <img
        src={book.cover_link || '/images/book.png'}
        alt='book-cover'
        className='book__image'
      />
      <div className='book-info'>
        <div>{book.title}</div>
        <div>status: {book.status}</div>
      </div>
      <Button variant='danger' onClick={onDelete} style={{ height: 40 }}>
        Delete
      </Button>
    </div>
  )
}

export default BookItem
