import {
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Text,
  useToast,
} from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import ExistedAudio from '../ExistedAudio'
import SimpleBarReact from 'simplebar-react'
import { colors } from 'shared/style/colors'
import { removeChapterAudio } from 'api/books'

const BookAudioReview = ({ language, chapters, book_id, getAllChapters }) => {
  const [removeAudio, setRemoveAudio] = useState([])
  const toast = useToast()
  const shouldShow = language === 'en'

  const handleRemoveTrack = useCallback(chapter_id => {
    setRemoveAudio(curr => [...curr, chapter_id])
  }, [])

  const handleClearRemoved = useCallback(() => {
    setRemoveAudio([])
  }, [])

  const submitRemove = useCallback(async () => {
    for (const chapter_id of removeAudio) {
      await removeChapterAudio({
        chapter_id,
        code: language,
      })
    }

    toast({
      title: `Audio was succesfully removed for ${removeAudio.join(
        ', ',
      )} of ${language.toUpperCase()}`,
      status: 'success',
      isClosable: true,
      duration: 15000,
      position: 'top',
    })

    handleClearRemoved()

    await await getAllChapters({
      variables: {
        book_id,
      },
    })
  }, [
    toast,
    removeAudio,
    language,
    handleClearRemoved,
    book_id,
    getAllChapters,
  ])

  console.log(removeAudio)

  return (
    <Flex w='100%'>
      {!shouldShow && (
        <Flex w='100%' direction='column' justifyContent='center'>
          <Heading
            size='poppins24'
            variant='primary-black-text'
            lineHeight='38px'
            letterSpacing='0'
            textAlign='center'
          >
            Not Available
          </Heading>
          <Image
            src='/images/sadman.jpg'
            alt='sad man'
            mb='23px'
            maxH='330px'
            w='100%'
          />
        </Flex>
      )}

      {shouldShow && (
        <Flex
          bg={colors.white}
          direction='column'
          align='flex-start'
          alignSelf='flex-start'
          maxH='456px'
          w='100%'
          overflowY='hidden'
          p='12px'
          gap='20px'
        >
          <SimpleBarReact
            autoHide={true}
            style={{
              minHeight: '370px',
              maxHeight: '370px',
              width: '100%',
              overflowX: 'hidden',
            }}
          >
            <Flex direction='column' gap='20px'>
              {chapters.map(chapter => {
                const shouldHide =
                  removeAudio.some(ch => chapter.chapter_id === ch) ||
                  !chapter.audio_link

                return (
                  <Flex key={chapter.chapter_id} direction='column'>
                    <Flex gap='4px' align='center' borderRadius='8px'>
                      <Text
                        size='poppins18'
                        variant='primary-black-text'
                        fontWeight={400}
                        lineHeight='28px'
                        letterSpacing='-0.018px'
                        maxW='900px'
                        overflow='hidden'
                        textOverflow='ellipsis'
                        whiteSpace='nowrap'
                      >
                        {chapter.chapter_order}. {chapter.title} -{' '}
                        {chapter.chapter_id}
                      </Text>
                    </Flex>

                    {!shouldHide && (
                      <ExistedAudio
                        code={language}
                        src={chapter.audio_link}
                        handleRemoveTrack={handleRemoveTrack}
                        chapter_id={chapter.chapter_id}
                      />
                    )}

                    {shouldHide && (
                      <Flex
                        gap='4px'
                        align='center'
                        borderRadius='8px'
                        w='100%'
                      >
                        <Text
                          size='poppins18'
                          variant='light-text-text-danger'
                          fontWeight={600}
                          lineHeight='28px'
                          letterSpacing='-0.018px'
                          textAlign='center'
                          w='100%'
                        >
                          No audio
                        </Text>
                      </Flex>
                    )}

                    <Divider mt='6px' />
                  </Flex>
                )
              })}
            </Flex>
          </SimpleBarReact>

          <Flex alignSelf='flex-end' gap='12px'>
            <Button
              type='button'
              variant='outlineButton'
              w='204px'
              isDisabled={removeAudio.length === 0}
              onClick={handleClearRemoved}
            >
              Reset
            </Button>

            <Button
              type='button'
              variant='defaultButton'
              w='204px'
              isDisabled={removeAudio.length === 0}
              onClick={submitRemove}
            >
              Save
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default BookAudioReview
