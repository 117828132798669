import React, { useEffect, useRef } from 'react'
import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import SimpleBarReact from 'simplebar-react'
import UserCell from './UserCell'

const UsersTable = ({ items }) => {
  const firstRow = useRef(null)

  useEffect(() => {
    firstRow.current?.scrollIntoView({ block: 'center' })
  }, [items])

  return (
    <SimpleBarReact
      autoHide={true}
      style={{
        maxHeight: '100%',
        height: '100%',
        overflowX: 'hidden',
      }}
      id='authors-table-wrapper'
    >
      <TableContainer px='22px'>
        <Table variant='chapterTable'>
          <Thead>
            <Tr py='12px'>
              <Th w='50%' textAlign='center'>
                User Id
              </Th>
              <Th w='50%' textAlign='center'>
                Email
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.map((item, idx, all) => {
              const { user_id, email } = item
              const isLast = idx === all.length - 1
              const isFirst = idx === 0

              return (
                <UserCell
                  key={user_id}
                  user_id={user_id}
                  email={email}
                  isLast={isLast}
                  isFirst={isFirst}
                  firstRow={firstRow}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </SimpleBarReact>
  )
}

export default UsersTable
