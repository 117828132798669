import { Box, Flex, Text } from '@chakra-ui/react'
import SelectArrowIcon from 'components/UI/Icons/SelectArrowIcon'
import { ScreenTypes } from 'constants/screens'
import { memo, useCallback, useEffect, useState } from 'react'
import { colors } from 'shared/style/colors'
import SimpleBarReact from 'simplebar-react'

const ScreenTypeSelector = memo(({ place, toggleScreenType, isModal }) => {
  const [isLocalesShown, setIsLocalesShown] = useState(false)

  const screensTypesWrapper = `screen-type-select-wrapper${
    isModal ? '-modal' : ''
  }`

  const handleHideList = useCallback(() => {
    setIsLocalesShown(false)
  }, [])

  const handleTriggerChaptersList = useCallback(() => {
    if (isLocalesShown) {
      handleHideList()
    } else {
      setIsLocalesShown(true)
    }
  }, [handleHideList, isLocalesShown])

  const handleSelectType = useCallback(
    type => {
      toggleScreenType(type)
    },
    [toggleScreenType],
  )

  useEffect(() => {
    const handleClick = e => {
      const target = e.target

      if (
        target.id === screensTypesWrapper ||
        target.parentElement?.id === screensTypesWrapper ||
        target.parentNode?.parentElement?.id === screensTypesWrapper
      ) {
        handleTriggerChaptersList()
      } else {
        handleHideList()
      }
    }

    window.addEventListener('click', handleClick)

    return () => {
      window.removeEventListener('click', handleClick)
    }
  })

  return (
    <Flex
      pos='relative'
      p='12px 44px 12px 18px'
      w='100%'
      h='fit-content'
      minW='250px'
      maxW='400px'
      bg={colors['primary-violet-10']}
      borderRadius='8px'
      cursor='pointer'
      id={screensTypesWrapper}
    >
      <Text
        size='poppins18'
        variant={place ? 'primary-black-text' : 'primary-gray-100'}
        lineHeight='32px'
        fontWeight={400}
        maxW='438px'
        overflow='hidden'
        textOverflow='ellipsis'
        whiteSpace='nowrap'
      >
        {place || 'Select screen type'}
      </Text>

      <Box
        pos='absolute'
        right='10px'
        top='50%'
        transform={
          isLocalesShown ? 'translateY(-50%)' : 'rotate(180deg) translateY(50%)'
        }
      >
        {SelectArrowIcon()}
      </Box>

      {isLocalesShown && (
        <Flex
          bg={colors.white}
          direction='column'
          maxH='416px'
          w='100%'
          pos='absolute'
          borderRadius='8px'
          border={`1px solid ${colors.stroke}`}
          boxShadow='0px 2px 5px 0px rgba(37, 85, 255, 0.05)'
          bottom={0}
          left={0}
          zIndex={10}
          transform='translateY(100%)'
          overflowY='hidden'
        >
          <SimpleBarReact
            autoHide={true}
            style={{
              maxHeight: '260px',
              overflowX: 'hidden',
            }}
          >
            {Object.values(ScreenTypes).map(type => {
              const isSelected = type === place

              return (
                <Flex
                  key={type}
                  gap='4px'
                  onClick={() => handleSelectType(type)}
                  p='10px 12px'
                  align='center'
                  color={
                    colors[
                      isSelected ? 'primary-violet-100' : 'primary-black-text'
                    ]
                  }
                  bg={colors[isSelected ? 'primary-violet-20' : 'white']}
                  _hover={{
                    bg: colors['primary-violet-20'],
                    color: colors['primary-violet-100'],
                  }}
                >
                  <Text
                    size='poppins18'
                    variant='primary-black-text'
                    fontWeight={400}
                    lineHeight='32px'
                    letterSpacing='-0.018px'
                    maxW='500px'
                    cursor='pointer'
                    overflow='hidden'
                    textOverflow='ellipsis'
                    whiteSpace='nowrap'
                    color='inherit'
                  >
                    {type}
                  </Text>
                </Flex>
              )
            })}
          </SimpleBarReact>
        </Flex>
      )}
    </Flex>
  )
})

export default ScreenTypeSelector
