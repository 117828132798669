/* eslint-disable react-hooks/exhaustive-deps */
import { Flex, Text } from '@chakra-ui/react'
import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import SearchBar from '../SearchBar'
import useSearchQuery from 'hooks/useSearchQuery'
import { useNavigate } from 'react-router-dom'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import InfoElement from '../InfoElement'
import { colors } from 'shared/style/colors'
import Pagination from '../Pagination'
import UsersTable from '../UsersTable'
import { createPortal } from 'react-dom'
import SearchToggler from '../SearchToggler'

const basePath = `/webapp`
const LIMIT = 50
const uuidv4Regex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

const Layout = () => {
  const { query } = useSearchQuery()
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [allLoaded, setAllLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [visible, setVisible] = useState([])
  const [isSearchById, setIsSearchById] = useState(false)
  const [error, setError] = useState(false)

  const togglerWrapperEl = document.getElementById('search-by-toggler-wrapper')
  const searchBarWrapperEl = document.getElementById('search-error-wrapper')

  const shouldShowPagination = items.length >= LIMIT

  const changePage = useCallback(value => {
    setPage(curr => curr + value)
  }, [])

  const handleSwitch = useCallback(val => {
    setIsSearchById(val)
    setError(false)
    setAllLoaded(false)
    navigate(`${basePath}?query=`)
  }, [])

  const handleLoadUsers = useCallback(
    async (search, page) => {
      if (error) {
        return
      }

      setLoading(true)

      const where = {}

      if (isSearchById) {
        if (!uuidv4Regex.test(search)) {
          setItems([])
          setLoading(false)

          return
        }

        where.user_id = search
      } else {
        where.email = search
      }

      const { data } = await axios.post(
        `${process.env.REACT_APP_WEB_APP_URL}/users`,
        { limit: LIMIT, offset: LIMIT * page, where },
        {
          headers: {
            'x-auth': `Bearer ${process.env.REACT_APP_ADMIN_KEY}`,
          },
        },
      )

      if (data.total < LIMIT || data.users.length < LIMIT) {
        setAllLoaded(true)
      }

      setItems(curr => [...curr, ...data.users])
      setLoading(false)
    },
    [isSearchById, error],
  )

  const triggerSearch = useCallback(
    search => {
      setPage(0)
      setItems([])
      setAllLoaded(false)

      const searchValue = search?.trim()

      if (isSearchById) {
        setError(!uuidv4Regex.test(searchValue))
      } else {
        setError(false)
      }

      navigate(`${basePath}?query=${searchValue}`)
    },
    [isSearchById],
  )

  useEffect(() => {
    if (items.length > 0) {
      const usersToShow = items.slice(page * LIMIT, (page + 1) * LIMIT)

      setVisible(usersToShow)
    }
  }, [items, page])

  useEffect(() => {
    if (typeof query === 'string') {
      handleLoadUsers(query, page)
    } else {
      navigate(`${basePath}?query=`)
    }
  }, [query, isSearchById])

  useEffect(() => {
    if (page > 0 && !allLoaded && Math.ceil(items.length / LIMIT) <= page) {
      handleLoadUsers(query, page)
    }
  }, [page])

  useEffect(() => {
    setPage(0)
    setAllLoaded(false)
    setItems([])
    setVisible([])
  }, [])

  return (
    <Flex p='20px' gap='20px' direction='column'>
      <Flex h='100%' w='100%' direction='column' gap='12px' overflow='hidden'>
        {loading && <LoadingSpinner />}

        <SearchBar triggerSearch={triggerSearch} query={query} />

        {items.length === 0 && !loading && (
          <InfoElement
            title={
              isSearchById && !query
                ? 'Paste user id and press enter'
                : 'There are no users by your search'
            }
            image='/images/web_app/search.png'
          />
        )}

        {items.length !== 0 && !loading && (
          <Flex direction='column'>
            <Flex
              w='100%'
              maxH={`calc(100vH - 90px - 126px)`}
              border={`1px solid ${colors['primary-gray-10']}`}
              bg={colors.white}
              borderRadius='10px'
              direction='column'
            >
              <UsersTable items={visible} />

              {shouldShowPagination && (
                <Pagination
                  changePage={changePage}
                  page={page}
                  allLoaded={allLoaded}
                  total={items.length}
                  perPage={LIMIT}
                />
              )}
            </Flex>
          </Flex>
        )}
      </Flex>

      {togglerWrapperEl &&
        createPortal(
          <SearchToggler
            handleSwitch={handleSwitch}
            isSearchById={isSearchById}
            mainWidth={128}
            additionalWidth={100}
            mainTitle='User`s email'
            secondaryTitle='User`s ID'
          />,
          togglerWrapperEl,
        )}

      {searchBarWrapperEl &&
        isSearchById &&
        error &&
        query &&
        createPortal(
          <Text
            size='poppins14'
            fontWeight={400}
            letterSpacing='-0.01em'
            color={colors['primary-violet-100']}
            lineHeight='20px'
            mb='-20px'
          >
            {'Input ID is not valid'}
          </Text>,
          searchBarWrapperEl,
        )}
    </Flex>
  )
}

export default Layout
