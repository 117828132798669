import React, { memo } from 'react'
import { Accordion } from 'react-bootstrap'
import SeriesItem from '../SeriesItem'
import './styles.scss'

const SeriesList = ({
  series,
  handleSeriesDelete,
  handleOpenUpdateSeriesModal,
  handleSeriesBookDelete,
  handleSeriesBookOrderUpdate,
  handleSeriesBookOrderDragEnd,
}) => (
  <Accordion className='series-list'>
    {series.map(series => (
      <SeriesItem
        key={series.series_id}
        series={series}
        handleSeriesDelete={handleSeriesDelete}
        handleOpenUpdateSeriesModal={handleOpenUpdateSeriesModal}
        handleSeriesBookDelete={handleSeriesBookDelete}
        handleSeriesBookOrderUpdate={handleSeriesBookOrderUpdate}
        handleSeriesBookOrderDragEnd={handleSeriesBookOrderDragEnd}
      />
    ))}
  </Accordion>
)

export default memo(SeriesList)
