import { apolloClient } from 'app/hocs/with-apollo'
import {
  CREATE_LOCALIZATION,
  DELETE_LOCALIZATION,
  GET_LOCALIZATIONS,
  UPDATE_LOCALIZATION,
} from './graphql'

const LIMIT = 1000

class LocalizationsService {
  static async fetch(variables) {
    const response = await apolloClient.query({
      query: GET_LOCALIZATIONS,
      variables: {
        limit: LIMIT,
      },
    })
    if (!response || !response.data) {
      throw new Error('Cannot get localizations!')
    }

    return response.data.localizations
  }

  static async create(variables) {
    const response = await apolloClient.mutate({
      mutation: CREATE_LOCALIZATION,
      variables,
    })
    if (!response || !response.data) {
      throw new Error('Cannot create localization!')
    }

    return response.data.createLocalization
  }

  static async update(variables) {
    const response = await apolloClient.mutate({
      mutation: UPDATE_LOCALIZATION,
      variables,
    })
    if (!response || !response.data) {
      throw new Error('Cannot update localization!')
    }

    return response.data.updateLocalization
  }

  static async delete(variables) {
    const response = await apolloClient.mutate({
      mutation: DELETE_LOCALIZATION,
      variables,
    })
    if (!response || !response.data) {
      throw new Error('Cannot delete localization!')
    }

    return response.data.removeLocalization
  }
}

export default LocalizationsService
