import React, { memo, useCallback, useEffect, useState } from 'react'
import BookRecommendationItem from '../../../../components/BookRecomendationItem'
import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import { MdClear } from 'react-icons/md'
import { colors } from 'shared/style/colors'
import ReactPaginate from 'react-paginate'
import { debounce } from 'lodash'
import { DEBOUNCE_DELAY } from 'constants/app'
import LoadingSpinner from 'components/UI/LoadingSpinner'

const BOOKS_PER_PAGE = 5

const RecomendationSelector = ({
  booksList,
  handleBookAddRecommendation,
  handleQueryChange,
  isBooksLoaded,
}) => {
  const [visibleBooks, setVisibleBooks] = useState([])
  const [itemOffset, setItemOffset] = useState(0)
  const [search, setSearch] = useState('')

  const handlePageClick = event => {
    const newOffset = (event.selected * BOOKS_PER_PAGE) % booksList.length

    setItemOffset(newOffset)
  }

  const handleSearchInput = e => {
    const searchValue = e ? e.target.value : ''

    setSearch(searchValue)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchChange = useCallback(
    debounce(value => handleQueryChange(value), DEBOUNCE_DELAY),
    [],
  )

  useEffect(() => {
    debouncedSearchChange(search)
  }, [search, debouncedSearchChange])

  useEffect(() => {
    const endOffset = itemOffset + BOOKS_PER_PAGE

    setVisibleBooks(booksList.slice(itemOffset, endOffset))
  }, [itemOffset, booksList])

  const pageCount = Math.ceil(booksList.length / BOOKS_PER_PAGE)

  return (
    <Flex direction='column' gap='10px'>
      <Box mb='24px' w='fit-content' minW='300px'>
        <Text mb='12px' size='poppins16normal' variant='primary-black-text'>
          Book Search
        </Text>

        <InputGroup>
          <Input
            type='text'
            placeholder='Search...'
            variant='loginField'
            value={search}
            onChange={handleSearchInput}
          />

          {search && search.length > 0 && (
            <InputRightElement right='18px' top='12px' w='24px' h='24px'>
              <Icon
                as={MdClear}
                fill={colors['primary-violet-100']}
                w='24px'
                h='24px'
                cursor='pointer'
                onClick={() => handleSearchInput(null)}
              />
            </InputRightElement>
          )}
        </InputGroup>
      </Box>

      <Flex m='0 auto' justify='flex-start' mb='20px'>
        {search && booksList && pageCount > 1 && (
          <ReactPaginate
            onPageChange={handlePageClick}
            pageRangeDisplayed={4}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel='< '
            nextLabel=' >'
            pageClassName='page-item'
            pageLinkClassName='page-link'
            previousClassName='page-item'
            previousLinkClassName='page-link'
            nextClassName='page-item'
            nextLinkClassName='page-link'
            breakLabel='...'
            breakClassName='page-item'
            breakLinkClassName='page-link'
            containerClassName='pagination'
            activeClassName='active'
            renderOnZeroPageCount={null}
          />
        )}
      </Flex>

      <Box m='0 auto'>
        <Flex
          overflowY='auto'
          direction='column'
          gap='10px'
          maxH='50vH'
          w='fit-content'
        >
          {visibleBooks.length === 0 && search && !isBooksLoaded && (
            <Flex>
              <Text
                mb='12px'
                size='poppins16normal'
                variant='primary-black-text'
              >
                No books with this title
              </Text>
            </Flex>
          )}

          {isBooksLoaded && <LoadingSpinner />}

          {search &&
            visibleBooks.map(book => (
              <BookRecommendationItem
                key={book.book_id}
                handleBookAddRecommendation={() =>
                  handleBookAddRecommendation(book.book_id, book.title)
                }
                {...book}
              />
            ))}

          {!search && !isBooksLoaded && (
            <Flex>
              <Text
                mb='12px'
                size='poppins16normal'
                variant='primary-black-text'
              >
                Start search to load books
              </Text>
            </Flex>
          )}
        </Flex>
      </Box>
    </Flex>
  )
}

export default memo(RecomendationSelector)
