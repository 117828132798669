import { Flex, Heading, Image } from '@chakra-ui/react'

const InfoElement = ({ title, image }) => {
  return (
    <Flex py={'44px'} direction='column' w='100%' align='center' gap='24px'>
      <Image
        src={image}
        alt='no content'
        loading='eager'
        maxW={'150px'}
        maxH={'150px'}
        borderRadius='50%'
      />

      <Heading
        size={'poppins28'}
        variant='primary-black-text'
        maxW='451px'
        textAlign='center'
      >
        {title}
      </Heading>
    </Flex>
  )
}

export default InfoElement
