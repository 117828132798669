import {
  Button,
  Flex,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import 'simplebar-react/dist/simplebar.min.css'
import SimpleBarReact from 'simplebar-react'
import moment from 'moment'
import AuthSevice from 'services/auth'
import { colors } from 'shared/style/colors'
import { USER_ROLES } from 'constants/users'

export const STATUSES = {
  APPROVED: 'APPROVED',
  CANCELED: 'CANCELED',
  PENDING: 'PENDING',
  REMOVED: 'REMOVED',
}

const BlackListTable = ({
  items,
  updateRequestItem,
  removeRequestItem,
  update,
}) => {
  const firstRow = useRef(null)
  const [active, setActive] = useState(null)
  const [edited, setEdited] = useState(null)
  const isSupport = AuthSevice.role === USER_ROLES.SUPPORT

  const handleBlur = async () => {
    if (active && edited.trim() !== active.text) {
      await updateRequestItem(active.id, edited.trim())
    }

    setActive(null)
    setEdited(null)
  }

  const handleSelectCell = item => {
    if (item.currentStatus !== STATUSES.PENDING) {
      return
    }

    setActive(item)
    setEdited(item.text)
  }

  const handleInput = e => {
    setEdited(e.target.value.trim())
  }

  const handleApprove = async (id, status) => {
    await update(id, status ?? STATUSES.APPROVED)
  }

  const handleRemove = async id => {
    await update(id, STATUSES.REMOVED)
  }

  const handleRemoveRequest = useCallback(
    async (id, requestStatus) => {
      const isRequest = requestStatus === STATUSES.PENDING

      await removeRequestItem(id, isRequest)
    },
    [removeRequestItem],
  )

  useEffect(() => {
    if (firstRow.current) {
      firstRow.current.scrollIntoView({ block: 'center' })
    }
  }, [items])

  return (
    <SimpleBarReact
      autoHide={true}
      style={{
        maxHeight: '100%',
        height: '100%',
        overflowX: 'hidden',
      }}
      id='authors-table-wrapper'
    >
      <TableContainer px='22px'>
        <Table variant='chapterTable'>
          <Thead>
            <Tr py='12px'>
              <Th w='20%' textAlign='center'>
                Id
              </Th>
              <Th w='20%' textAlign='center'>
                Text
              </Th>
              <Th w='10%' textAlign='center'>
                Current Status
              </Th>
              <Th w='10%' textAlign='center'>
                Changed Status
              </Th>
              <Th w='10%' textAlign='center'>
                Date updated
              </Th>
              <Th w='30%' textAlign='center'>
                Actions
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.map((item, idx, all) => {
              const isLast = idx === all.length - 1
              const isFirst = idx === 0
              const updateDate = moment(item.updatedAt).format(
                'YYYY-MM-DD HH:mm',
              )
              const isActive = active?.id === item.id

              const {
                canCancel,
                canApprove,
                canRemove,
                canCreateRemoveRequest,
              } = (() => {
                const actions = {
                  canCancel: false,
                  canApprove: false,
                  canRemove: false,
                  canCreateRemoveRequest: false,
                }
                const { currentStatus, changedStatus } = item

                if (
                  (changedStatus && currentStatus !== changedStatus) ||
                  currentStatus === STATUSES.PENDING
                ) {
                  actions.canCancel = true
                }

                if (
                  isSupport &&
                  currentStatus === STATUSES.APPROVED &&
                  !changedStatus
                ) {
                  actions.canCreateRemoveRequest = true
                }

                if (!isSupport && currentStatus !== changedStatus) {
                  if (
                    changedStatus === STATUSES.APPROVED ||
                    changedStatus === STATUSES.REMOVED
                  ) {
                    actions.canApprove = true
                  }

                  if (!changedStatus && currentStatus === STATUSES.APPROVED) {
                    actions.canRemove = true
                  }
                }

                return actions
              })()

              return (
                <Tr
                  key={item.id}
                  style={{
                    borderBottom: isLast
                      ? '1px solid transparent'
                      : `1px solid ${colors.stroke}`,
                  }}
                  ref={isFirst ? firstRow : null}
                >
                  <Td w='20%' maxW='20%' p='7px 0'>
                    <Text
                      size='poppins14'
                      fontWeight={400}
                      variant='primary-black-text'
                      lineHeight='16px'
                      letterSpacing='-0.1px'
                      w='90%'
                      whiteSpace='break-spaces'
                    >
                      {item.id}
                    </Text>
                  </Td>
                  <Td
                    w='20%'
                    p={isActive ? '3px 12px 3px 0' : '12px 0'}
                    onClick={() => handleSelectCell(item)}
                  >
                    {isActive ? (
                      <Input
                        variant='loginField'
                        p='11px 18px'
                        h='100%'
                        value={edited}
                        onChange={handleInput}
                        autoFocus
                        onBlur={handleBlur}
                      />
                    ) : (
                      <>{item.text}</>
                    )}
                  </Td>
                  <Td w='10%' p='7px 0'>
                    <Text
                      size='poppins18'
                      fontWeight={400}
                      variant='primary-black-text'
                      lineHeight='46px'
                      letterSpacing='-0.018px'
                      textAlign='center'
                    >
                      {item.currentStatus}
                    </Text>
                  </Td>
                  <Td w='10%' p='7px 0'>
                    <Text
                      size='poppins18'
                      fontWeight={400}
                      variant='primary-black-text'
                      lineHeight='46px'
                      letterSpacing='-0.018px'
                      textAlign='center'
                    >
                      {item.changedStatus}
                    </Text>
                  </Td>
                  <Td w='10%' p='12px 0' textAlign='center'>
                    {updateDate}
                  </Td>
                  <Td w='30%' p='12px 0'>
                    <Flex gap='8px' w='100%' justify='center'>
                      {canCancel && (
                        <Button
                          variant='defaultButton'
                          p='6px 12px'
                          w='fit-content'
                          bg={colors['light-text-text-danger']}
                          _hover={{
                            bg: `${colors['light-red-700']} !important`,
                          }}
                          onClick={() =>
                            handleRemoveRequest(item.id, item.currentStatus)
                          }
                        >
                          Cancel
                        </Button>
                      )}
                      {canApprove && (
                        <Button
                          variant='defaultButton'
                          p='6px 12px'
                          w='fit-content'
                          onClick={() =>
                            handleApprove(item.id, item.changedStatus)
                          }
                        >
                          Approve
                        </Button>
                      )}
                      {canCreateRemoveRequest && (
                        <Button
                          variant='defaultButton'
                          p='6px 12px'
                          w='fit-content'
                          onClick={() => handleRemove(item.id)}
                        >
                          Remove
                        </Button>
                      )}
                      {canRemove && (
                        <Button
                          variant='defaultButton'
                          p='6px 12px'
                          w='fit-content'
                          bg={colors['light-text-text-danger']}
                          _hover={{
                            bg: `${colors['light-red-700']} !important`,
                          }}
                          onClick={() =>
                            handleRemoveRequest(item.id, item.currentStatus)
                          }
                        >
                          Remove
                        </Button>
                      )}
                    </Flex>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </SimpleBarReact>
  )
}

export default BlackListTable
