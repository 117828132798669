import { Flex, Heading, Icon, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { colors } from 'shared/style/colors'
import { TbArrowZigZag } from 'react-icons/tb'
import { createSelector } from '@reduxjs/toolkit'
import { getBannersList } from 'redux/slices/banners/selectors'
import { useSelector } from 'react-redux'
import { BannerTypes } from 'constants/banners'

const stateSelector = createSelector(getBannersList, banners => ({
  banners,
}))

const BannersContent = ({ banners }) => {
  const { banners: allBanners } = useSelector(stateSelector)

  return (
    <Flex gap='20px'>
      <Heading
        size='poppins20'
        variant='primary-black-text'
        lineHeight='38px'
        letterSpacing='0'
        textAlign='left'
        w='120px'
        minW='120px'
      >
        Banners{' '}
        <Icon as={TbArrowZigZag} w='24px' h='24px' transform='rotate(90deg)' />
      </Heading>

      <Flex>
        <Flex gap='10px' wrap='wrap' userSelect='none'>
          {banners.map(banner => {
            const bannerWithData = allBanners.find(
              el => el.banner_id === banner.bannerId,
            )
            const item = {
              ...banner,
              advertisement: bannerWithData.advertisement,
              book: bannerWithData.book,
            }

            return (
              <Flex
                key={item.bannerId}
                h='106px'
                gap='8px'
                border={`1px solid ${colors['primary-gray-30']}`}
                p='8px 12px'
                boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
                borderRadius='8px'
              >
                <Image
                  src={item.banner.coverUrl || '/images/book.png'}
                  alt='book-cover'
                  h='90px'
                  aspectratio='2/3'
                  objectFit='cover'
                />

                <Flex w='100%' gap='10px' direction='column'>
                  <Text
                    size='poppins14'
                    variant='primary-black-text'
                    fontWeight={600}
                  >
                    banner type: <b>{item.banner.type}</b>
                  </Text>

                  {item.banner.type === BannerTypes.BOOK && item.book && (
                    <>
                      {item.book.title && (
                        <Text
                          size='poppins14'
                          variant='primary-black-text'
                          alignSelf='end'
                        >
                          Book: {item.book.title}
                        </Text>
                      )}

                      {item.book.author && (
                        <Text
                          size='poppins14'
                          variant='primary-black-text'
                          alignSelf='end'
                        >
                          Author:{' '}
                          {`${item.book.author.first_name} ${item.book.author.last_name}`}
                        </Text>
                      )}
                    </>
                  )}

                  {item.banner.type === BannerTypes.ADVERTISEMENT &&
                    item.advertisement && (
                      <Text
                        size='poppins14'
                        variant='primary-black-text'
                        alignSelf='end'
                      >
                        Advertisement: {item.advertisement.title}
                      </Text>
                    )}
                </Flex>
              </Flex>
            )
          })}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default BannersContent
