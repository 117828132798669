import { Checkbox, Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { STATUSES } from '../BlackListTable'

const BlacklistFilters = ({ status }) => {
  const [isPending, setIsPending] = useState(false)
  const [isApproved, setIsApproved] = useState(false)
  const [isRemoved, setIsRemoved] = useState(false)
  const [isCanceled, setIsCanceled] = useState(false)
  const navigate = useNavigate()

  const handleUpdateQuery = ({
    isPending,
    isApproved,
    isRemoved,
    isCanceled,
  }) => {
    const newQuery = []

    if (isPending) {
      newQuery.push(STATUSES.PENDING)
    }

    if (isApproved) {
      newQuery.push(STATUSES.APPROVED)
    }

    if (isRemoved) {
      newQuery.push(STATUSES.REMOVED)
    }

    if (isCanceled) {
      newQuery.push(STATUSES.CANCELED)
    }

    setIsPending(isPending)
    setIsApproved(isApproved)
    setIsRemoved(isRemoved)
    setIsCanceled(isCanceled)

    navigate(
      `${window.location.pathname}?active=blacklist&status=${newQuery.join(
        ',',
      )}`,
    )
  }

  useEffect(() => {
    if (status) {
      const statuses = status.split(',')

      setIsPending(statuses.includes(STATUSES.PENDING))
      setIsRemoved(statuses.includes(STATUSES.REMOVED))
      setIsApproved(statuses.includes(STATUSES.APPROVED))
      setIsCanceled(statuses.includes(STATUSES.CANCELED))
    }
  }, [status])

  return (
    <Stack spacing={5} direction='row' mb='12px' p='12px'>
      <Checkbox
        size='lg'
        colorScheme='red'
        value={isPending}
        onChange={() => {
          handleUpdateQuery({
            isApproved,
            isRemoved,
            isPending: !isPending,
            isCanceled,
          })
        }}
      >
        Pending
      </Checkbox>
      <Checkbox
        size='lg'
        colorScheme='green'
        value={isApproved}
        onChange={() => {
          handleUpdateQuery({
            isApproved: !isApproved,
            isRemoved,
            isPending,
            isCanceled,
          })
        }}
      >
        Approved
      </Checkbox>
      <Checkbox
        size='lg'
        colorScheme='orange'
        value={isRemoved}
        onChange={() => {
          handleUpdateQuery({
            isApproved,
            isRemoved: !isRemoved,
            isPending,
            isCanceled,
          })
        }}
      >
        Removed
      </Checkbox>
      <Checkbox
        size='lg'
        colorScheme='pink'
        value={isCanceled}
        onChange={() => {
          handleUpdateQuery({
            isApproved,
            isRemoved,
            isPending,
            isCanceled: !isCanceled,
          })
        }}
      >
        Canceled
      </Checkbox>
    </Stack>
  )
}

export default BlacklistFilters
