import React, { memo } from 'react'
import styles from './styles.module.scss'

const DEFAULT_COLUMNS_COUNT = 2

const FormGroup = ({ columns = DEFAULT_COLUMNS_COUNT, children }) => (
  <div
    className={styles.formGroup}
    style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}
  >
    {children}
  </div>
)

export default memo(FormGroup)
