import React, { memo, useCallback } from 'react'

import classNames from 'classnames'
import styles from '../FormField/styles.module.scss'
import { useFormikContext } from 'formik'

const FormFileInput = ({ label }) => {
  const { setFieldValue, errors, touched } = useFormikContext()

  const handleFileUpload = useCallback(
    e => {
      const file = e.target.files[0]
      if (!file.type.includes('image/')) {
        alert('Invalid file type!')
        return
      }
      const formdata = new FormData()
      formdata.append('file', file)
      setFieldValue('file', formdata)
    },
    [setFieldValue],
  )

  return (
    <div className={styles.fieldContainer}>
      <label htmlFor='file' className={styles.label}>
        {label}:
      </label>
      <input
        id='file'
        name='file'
        className={classNames(styles.field, {
          [styles.fieldError]: errors.file && touched.file,
        })}
        type='file'
        onChange={handleFileUpload}
      />
      {errors.file && touched.file && (
        <p className={styles.error}>{errors.file}</p>
      )}
    </div>
  )
}

export default memo(FormFileInput)
