import { Flex } from '@chakra-ui/react'
import BlacklistModerationLayout from '../BlacklistModerationLayout'
import CommentsModerationLayout from '../CommentsModerationLayout'

const CommentsContent = ({ isComments, status }) => {
  return (
    <Flex h='100%' w='100%' overflow='hidden'>
      {isComments ? (
        <CommentsModerationLayout status={status} />
      ) : (
        <BlacklistModerationLayout status={status} />
      )}
    </Flex>
  )
}

export default CommentsContent
