import React, { useState } from 'react'
import {
  Box,
  Flex,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Button,
} from '@chakra-ui/react'
import { colors } from 'shared/style/colors'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

const LoginForm = ({
  email,
  onEmailChange,
  onPasswordChange,
  password,
  onLoginFormSubmit,
  isLoading,
}) => {
  const [passwordShown, setPasswordShown] = useState(false)

  const togglePasswordShow = () => {
    setPasswordShown(curr => !curr)
  }

  return (
    <Flex
      direction='column'
      bg={colors.white}
      align='center'
      borderRadius='8px'
      p='72px 50px 44px'
      boxShadow='0px 5px 15px 0px rgba(36, 28, 88, 0.20)'
      pos='relative'
      maxW='600px'
    >
      <Heading size='poppins25' variant='primary-black-text' mb='36px'>
        Welcome to Passion Admin!
      </Heading>

      <Box mb='24px' w='100%'>
        <Text mb='12px' size='poppins16normal' variant='primary-black-text'>
          Email address
        </Text>

        <Input
          type='email'
          placeholder='Enter Email'
          variant='loginField'
          onChange={onEmailChange}
          value={email}
        />

        <Text size='poppins12' variant='primary-gray-100' fontWeight={400}>
          Never share your email with anyone else!
        </Text>
      </Box>

      <Box mb='24px' w='100%'>
        <Text mb='12px' size='poppins16normal' variant='primary-black-text'>
          Password
        </Text>

        <InputGroup>
          <Input
            type={passwordShown ? 'text' : 'password'}
            placeholder='Enter Password'
            variant='loginField'
            value={password}
            onChange={onPasswordChange}
            id='password'
          />

          <InputRightElement right='18px' top='12px' w='24px' h='24px'>
            <Icon
              as={passwordShown ? AiOutlineEye : AiOutlineEyeInvisible}
              fill={colors['primary-violet-100']}
              w='24px'
              h='24px'
              cursor='pointer'
              onClick={togglePasswordShow}
            />
          </InputRightElement>
        </InputGroup>
      </Box>

      <Button
        variant='defaultButton'
        type='submit'
        onClick={onLoginFormSubmit}
        isDisabled={isLoading}
        className='login-button'
      >
        Log In
      </Button>
    </Flex>
  )
}

export default React.memo(LoginForm)
