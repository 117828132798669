import { apolloClient } from 'app/hocs/with-apollo'
import { CREATE_LIST, DELETE_LIST, GET_LISTS, UPDATE_LIST } from './graphql'

const LIMIT = 10000

class ListService {
  static async fetch(variables) {
    const response = await apolloClient.query({
      query: GET_LISTS,
      variables: {
        limit: LIMIT,
      },
    })
    if (!response || !response.data) {
      throw new Error('Cannot get lists!')
    }

    return response.data.lists
  }

  static async create(variables) {
    const response = await apolloClient.mutate({
      mutation: CREATE_LIST,
      variables,
    })
    if (!response || !response.data) {
      throw new Error('Cannot create list!')
    }

    return response.data.createList
  }

  static async update(variables) {
    const response = await apolloClient.mutate({
      mutation: UPDATE_LIST,
      variables,
    })
    if (!response || !response.data) {
      throw new Error('Cannot update list!')
    }

    return response.data.updateList
  }

  static async delete(variables) {
    const response = await apolloClient.mutate({
      mutation: DELETE_LIST,
      variables,
    })
    if (!response || !response.data) {
      throw new Error('Cannot update list!')
    }

    return response.data.removeList
  }
}

export default ListService
