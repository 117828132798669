import React, { useCallback, useEffect, useState } from 'react'
import { Accordion, Button } from 'react-bootstrap'
import SectionItem from '../SectionItem'
import CustomButton from 'components/UI/Button'
import styles from './styles.module.scss'
import DropDown from 'components/UI/DropDown'
import CopyLocalizationModal from '../Modals/CopyLocalizationModal'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Flex } from '@chakra-ui/react'
import LoadingSpinner from 'components/UI/LoadingSpinner'

const LocalizationItem = ({
  index,
  localization,
  handleLocalizationListsOrder,
  deleteLocalization,
  deleteListFromLocalization,
  openUpdateLocalizationModal,
  handleOnDragListLocalization,
  isLoading,
  refetchLocalizations,
  createLocalization,
  updateLocalization,
  isLoadingOn,
}) => {
  const [isShowDropDownMenu, setIsShowDropDownMenu] = useState(false)
  const [isShowLocalizationCopyModal, setIsShowLocalizationCopyModal] =
    useState(false)

  const handleLocalizationCopyModalShow = useCallback(() => {
    setIsShowDropDownMenu(false)
    setIsShowLocalizationCopyModal(true)
  }, [])

  const handleLocalizationCopyModalHide = useCallback(() => {
    setIsShowLocalizationCopyModal(false)
  }, [])

  const handleDropDownClose = useCallback(() => {
    setIsShowDropDownMenu(false)
  }, [])

  const handleDropDownToggle = useCallback(() => {
    setIsShowDropDownMenu(!isShowDropDownMenu)
  }, [isShowDropDownMenu])

  const handleLocalizationDelete = useCallback(async () => {
    await deleteLocalization(localization.localization_id)
    refetchLocalizations({
      page: 0,
      limit: 1000,
    })
  }, [deleteLocalization, localization.localization_id, refetchLocalizations])

  const handleLocalizationCopyCreate = useCallback(
    async ({ title }) => {
      const { data } = await createLocalization({
        variables: {
          localization: {
            title,
          },
        },
      })

      const newLocalization = {
        localization_id: data.createLocalization.localization_id,
        lists: localization.lists,
      }
      await updateLocalization({
        variables: {
          localization: newLocalization,
        },
      })
      const result = await refetchLocalizations({
        page: 0,
        limit: 1000,
      })
      console.log(result)
      handleLocalizationCopyModalHide()
    },
    [
      handleLocalizationCopyModalHide,
      localization,
      refetchLocalizations,
      createLocalization,
      updateLocalization,
    ],
  )

  useEffect(() => {
    if (isShowDropDownMenu) {
      document.body.addEventListener('scroll', handleDropDownToggle)

      return () => {
        document.body.removeEventListener('scroll', handleDropDownToggle)
      }
    }
  }, [isShowDropDownMenu, handleDropDownToggle])

  return (
    <>
      <div className={styles.localizationContainer}>
        <Accordion.Item eventKey={index} className={styles.accordionItem}>
          <Accordion.Header>
            <div style={{ flex: '1' }}>
              <b>Mainscreen:&nbsp;</b> {localization.title}
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <Accordion style={{ marginBottom: '12px' }}>
              {isLoading && <LoadingSpinner />}
              {!isLoading && (
                <DragDropContext
                  onDragEnd={result =>
                    handleOnDragListLocalization(result, localization)
                  }
                >
                  <Droppable
                    droppableId='list-localisation'
                    style={{ transform: 'none' }}
                  >
                    {provided => (
                      <Flex
                        direction='column'
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        userSelect='none'
                        gap='10px'
                        width='100%'
                      >
                        {[...localization.lists]
                          .sort(
                            (listA, listB) =>
                              listA.order_number - listB.order_number,
                          )
                          .map(({ list, order_number }, index) => (
                            <Draggable
                              key={`${list.list_id}-${localization.localization_id}-${index}`}
                              draggableId={`${list.list_id}-${localization.localization_id}-${index}`}
                              index={index}
                            >
                              {provided => (
                                <SectionItem
                                  list={list}
                                  listRef={provided.innerRef}
                                  draggableProps={provided.draggableProps}
                                  dragHandleProps={provided.dragHandleProps}
                                  handleLocalizationListsOrder={
                                    handleLocalizationListsOrder
                                  }
                                  deleteListFromLocalization={() =>
                                    deleteListFromLocalization(
                                      localization,
                                      order_number,
                                    )
                                  }
                                />
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </Flex>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </Accordion>

            <div>
              <Button
                variant={'primary'}
                onClick={() => {
                  openUpdateLocalizationModal(localization)
                }}
              >
                Add Section
              </Button>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <div className={styles.buttonContainer}>
          <CustomButton onClick={handleDropDownToggle} theme='white'>
            <img src='/images/ellipsis.svg' alt='ellipsis' />
          </CustomButton>
        </div>
        <DropDown
          handleDropDownClose={handleDropDownClose}
          show={isShowDropDownMenu}
          className='localization'
        >
          <DropDown.Option onClick={handleLocalizationCopyModalShow}>
            Copy
          </DropDown.Option>
          <DropDown.Option
            onClick={handleLocalizationDelete}
            className='delete'
          >
            Delete
          </DropDown.Option>
        </DropDown>
      </div>

      <CopyLocalizationModal
        localizationTitle={localization.title}
        isShow={isShowLocalizationCopyModal}
        onHide={handleLocalizationCopyModalHide}
        localization={localization}
        onSubmit={handleLocalizationCopyCreate}
        isLoadingOn={isLoadingOn}
      />
    </>
  )
}
export default LocalizationItem
