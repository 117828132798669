import LoadingSuspenseSpinner from 'components/UI/LoadingSuspenseSpinner'
import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'

/**
 * @hoc Инициализация роутера с провайдером для работы с get-параметрами
 */
const withRouter = component => () =>
  (
    <BrowserRouter>
      <Suspense fallback={<LoadingSuspenseSpinner />}>{component()}</Suspense>
    </BrowserRouter>
  )

export default withRouter
