export const SCREENS_TABS = {
  mainscreen: {
    id: '1',
    title: 'Mainscreen manager',
    description: 'API 1.0 Mainscreen manager',
    query: 'mainscreen',
    icon: '/images/screens/screens_old.png',
  },
  screens: {
    id: '2',
    title: 'Screens manager',
    description: 'API 2.0 Screens manager',
    query: 'screens',
    icon: '/images/screens/screens_new.png',
  },
}

// *uncomment to open new screens
export const ScreenTypes = Object.freeze({
  BOOK_PREVIEW: 'BOOK_PREVIEW',
  BOOK_READING: 'BOOK_READING',
  DISCOVER: 'DISCOVER',
  LIBRARY: 'LIBRARY',
  REWARDS: 'REWARDS',
  STORE: 'STORE',
  ONBOARDING: 'ONBOARDING',
  RANKING: 'RANKING',
})
