import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

const useSearchQuery = () => {
  const { search } = useLocation()

  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search])

  return useMemo(
    () => Object.fromEntries(urlSearchParams.entries()),
    [urlSearchParams],
  )
}

export default useSearchQuery
