import { createAsyncThunk } from '@reduxjs/toolkit'
import ListService from 'services/lists'

export const fetcListsThunks = createAsyncThunk(
  'lists/fetch',
  async () => await ListService.fetch(),
)

export const createListThunk = createAsyncThunk(
  'lists/create',
  async list => await ListService.create({ list }),
)

export const updateListThunk = createAsyncThunk(
  'lists/update',
  async list => await ListService.update({ list }),
)

export const deleteListThunk = createAsyncThunk(
  'lists/delete',
  async ({ list_id }) => {
    try {
      await ListService.delete({ list: { list_id } })
      return { list_id }
    } catch (error) {
      throw new Error('cant delete list')
    }
  },
)
