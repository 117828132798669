import React, { memo } from 'react'
import './styles.scss'

const BookRecommendationItem = ({
  cover_link,
  handleBookAddRecommendation,
  title,
}) => (
  <div className='book-recommendation' onClick={handleBookAddRecommendation}>
    <img
      src={cover_link}
      alt={`${title} cover`}
      className='book-recommendation__cover'
    />
    <p className='book-recommendation__title'>{title}</p>
  </div>
)

export default memo(BookRecommendationItem)
