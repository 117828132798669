import React, { useCallback, useMemo, useState } from 'react'
import BannersPanelLayout from './components/BannersPanelLayout'
import { useDispatch, useSelector } from 'react-redux'
import { getBannersList } from '../../redux/slices/banners/selectors'
import CreateBannerModal from './components/Modals/CreateBannerModal'
import {
  createBannerThunk,
  updateBannerCoverThunk,
} from '../../redux/slices/banners/thunks'
import { BannerTypes } from '../../constants/banners'
import { GET_BANNERS_ADMIN } from 'services/banners/graphql'
import { useQuery } from '@apollo/client'
import LoadingSpinner from 'components/UI/LoadingSpinner'

const BannersPanel = () => {
  const dispatch = useDispatch()

  const { data: bannersGraphql, loading: isBannersLoading } = useQuery(
    GET_BANNERS_ADMIN,
    {
      fetchPolicy: 'cache-and-network',
    },
  )

  const bannersApi = useSelector(getBannersList)

  const banners = useMemo(() => {
    if (!bannersGraphql) return bannersApi

    return bannersGraphql.items
      .map(item => {
        const withData =
          bannersApi.find(el => el.banner_id === item.banner_id) || null

        if (withData) {
          const { book, advertisement } = withData

          return {
            ...item,
            book,
            advertisement,
          }
        }

        return item
      })
      .reverse()
  }, [bannersApi, bannersGraphql])

  const [search, setSearch] = useState('')
  const [isShowBannerCreateModal, setIsShowBannerCreateModal] = useState(false)

  const handleSearchChange = useCallback(e => setSearch(e.target.value), [])

  const handleBannerCreateModalOpen = useCallback(
    () => setIsShowBannerCreateModal(true),
    [],
  )

  const handleBannerCreateModalClose = useCallback(
    () => setIsShowBannerCreateModal(false),
    [],
  )

  const createBannerHandler = useCallback(
    async ({ banner_type, file, attachment, cover_url }) => {
      const banner = {
        banner_type,
        cover_url,
      }

      if (banner_type === BannerTypes.BOOKS) {
        banner.book = attachment
      }
      if (banner_type === BannerTypes.ADVERTISEMENTS) {
        banner.advertisement = attachment
      }

      const { banner_id } = await dispatch(createBannerThunk(banner)).unwrap()

      if (file) {
        await dispatch(updateBannerCoverThunk({ banner_id, formdata: file }))
      }
      handleBannerCreateModalClose()
    },
    [dispatch, handleBannerCreateModalClose],
  )

  return (
    <>
      {isBannersLoading && <LoadingSpinner />}

      <BannersPanelLayout
        banners={banners}
        search={search}
        handleSearchChange={handleSearchChange}
        handleBannerCreateModalOpen={handleBannerCreateModalOpen}
      />

      <CreateBannerModal
        isShow={isShowBannerCreateModal}
        onHide={handleBannerCreateModalClose}
        onSubmit={createBannerHandler}
      />
    </>
  )
}

export default BannersPanel
