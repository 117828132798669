import { Flex, Heading, Image, Text } from '@chakra-ui/react'
import { SECTIONS_TABS } from 'constants/sections'
import { useNavigate } from 'react-router-dom'
import { colors } from 'shared/style/colors'

export const TabButton = ({ button, width, isActive }) => {
  const navigate = useNavigate()

  const handleTabNavigate = () => {
    if (isActive) {
      navigate(window.location.pathname)

      return
    }

    navigate(
      `${window.location.pathname}?active=${button.query}${
        button.query === SECTIONS_TABS.sections.query ? '&page=0' : ''
      }`,
    )
  }

  return (
    <Flex
      p='12px 24px'
      gap='24px'
      width={width}
      maxW='fit-content'
      align='center'
      border={`1px solid`}
      borderColor={
        isActive ? colors['primary-violet-100'] : colors['primary-gray-30']
      }
      bg={isActive ? 'rgba(241, 233, 252, 0.25)' : colors.white}
      borderRadius='6px'
      cursor='pointer'
      transition='all 0.4s ease'
      _hover={
        isActive
          ? {}
          : {
              bg: 'rgba(241, 233, 252, 0.25)',
              borderColor: colors['primary-violet-100'],
            }
      }
      onClick={handleTabNavigate}
    >
      <Image w='88px' h='88px' src={button.icon} />

      <Flex direction='column' gap='12px' py='6px'>
        <Heading size='poppins28' variant='primary-black-text'>
          {button.title}
        </Heading>

        <Text
          size='poppins16normal'
          variant='primary-black-text'
          lineHeight='28px'
          letterSpacing='-0.016px'
        >
          {button.description}
        </Text>
      </Flex>
    </Flex>
  )
}
