import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import './styles.scss'
import SearchBar from '../../../../../../components/UI/SearchBar'

export default React.memo(function AppendSectionModal({
  isShow,
  hideHandler,
  updatedLocalization,
  appendListToLocalization,
  lists,
  selectList,
  getIsSelectedList,
  getSelectedListIndex,
  searchLists,
  handleSearchListsChange,
}) {
  return (
    <Modal show={isShow} onHide={hideHandler}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div>Update &quot;{updatedLocalization.title}&quot; localization</div>
          <SearchBar search={searchLists} onChange={handleSearchListsChange} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='modal-section-list'>
          {lists.map(list => (
            <div
              key={list.list_id}
              className={`modal-section-container ${
                getIsSelectedList(list)
                  ? 'modal-section-container--selected'
                  : ''
              }`}
            >
              <div className='modal-section' onClick={() => selectList(list)}>
                <div className='modal-section__title'>
                  <b>Title:</b> {list.title}
                </div>
                <div className='modal-section__description'>
                  <b>Description:</b> {list.description}
                </div>
              </div>
              <div className='modal-section__index'>
                {getSelectedListIndex(list)}
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='primary' onClick={appendListToLocalization}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  )
})
