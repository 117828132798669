import { createAsyncThunk } from '@reduxjs/toolkit'
import LocalizationsService from 'services/localization'

const fetchLocalizationsThunk = createAsyncThunk(
  'localization/fetch',
  async () => await LocalizationsService.fetch(),
)

const createLocalizationThunk = createAsyncThunk(
  'localization/create',
  async localization => await LocalizationsService.create({ localization }),
)

const deleteLocalizationByIdThunk = createAsyncThunk(
  'localization/delete',
  async localization_id => {
    try {
      await LocalizationsService.delete({ localization: { localization_id } })
      return { localization_id }
    } catch (error) {
      throw new Error(error.message)
    }
  },
)

const updateLocalizationThunk = createAsyncThunk(
  'localization/update',
  async localization =>
    await await LocalizationsService.update({ localization }),
)

export {
  fetchLocalizationsThunk,
  createLocalizationThunk,
  deleteLocalizationByIdThunk,
  updateLocalizationThunk,
}
