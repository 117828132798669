const { gql } = require('@apollo/client')

export const GET_AUTHOR_CHAPTERS = gql`
  query AuthorChapters($book_id: String!) {
    chapters: authorChapters(book: { book_id: $book_id }) {
      chapter_id
      title
      status
      chapter_order
      audio_link
    }
  }
`

export const GET_CHAPTER_FIELDS = gql`
  query AuthorChapters($chapter_id: String!) {
    chapter: chapterAdmin(chapter: { chapter_id: $chapter_id }) {
      chapter_id
      audio_translation_links
    }
  }
`

export const GET_CHAPTER_BY_ID = gql`
  query ReadChapter($chapter_id: String!) {
    chapter: authorChapter(chapter: { chapter_id: $chapter_id }) {
      chapter_id
      title
      status
      chapter_order
      bookId
    }
  }
`
