import { Flex } from '@chakra-ui/react'
import React, { memo } from 'react'
import { Accordion, Button } from 'react-bootstrap'

const GenreItem = ({
  genre,
  deleteListContentOption,
  openGenreBooks,
  draggableProps,
  dragHandleProps,
  elementRef,
  list,
  booksByGenreList,
}) => {
  return (
    <Flex ref={elementRef} {...draggableProps} {...dragHandleProps}>
      <Accordion>
        <Accordion.Item onClick={() => openGenreBooks(genre.genre_id)}>
          <Accordion.Header>
            <Flex
              gap='20px'
              align='center'
              justify='space-between'
              w='100%'
              pr='30px'
            >
              <p>{genre.title}</p>
              <Button
                variant='danger'
                onClick={e => {
                  e.stopPropagation()
                  deleteListContentOption(list, genre.genre_id)
                }}
                style={{ height: 40, margin: 0 }}
              >
                Delete
              </Button>
            </Flex>
          </Accordion.Header>
          <Accordion.Body
            style={{
              alignItems: 'flex-start',
            }}
          >
            {booksByGenreList.map(book => (
              <div key={book.book_id} className='book'>
                <img
                  src={book.cover_link || '/images/book.png'}
                  alt='book-cover'
                  className='book__image'
                />
                <div className='book-info'>{book.title}</div>
              </div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Flex>
  )
}

export default memo(GenreItem)
