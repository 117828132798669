import React, { memo } from 'react'
import './styles.scss'

const SearchBar = ({ search, onChange, placeholder = 'Search...' }) => (
  <input
    type='search'
    className='search-bar'
    placeholder={placeholder}
    value={search}
    onChange={onChange}
  />
)

export default memo(SearchBar)
