import { Box, Flex, Input } from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import LocalesSelector from '../LocalesSelector'
import RemoveTranslationButton from '../RemoveTranslationButton'

const TranslationInput = ({
  availableLocales,
  handleTranslation,
  handleRemoveTranslation,
  item,
  index,
  location,
}) => {
  const [title, setTitle] = useState(item.value)

  const handleInputTitle = useCallback(
    e => {
      setTitle(e.target.value)

      handleTranslation(
        {
          locale: item.locale,
          value: e.target.value,
        },
        index,
      )
    },
    [handleTranslation, index, item],
  )

  const handleRemove = () => {
    handleRemoveTranslation(item.id)
  }

  const addLocales = useCallback(
    locale => {
      handleTranslation(
        {
          locale: locale.code,
          value: title,
        },
        index,
      )
    },
    [handleTranslation, index, title],
  )

  return (
    <Flex mb='8px' w='100%' gap='12px'>
      <Box w='200px' minW='200px'>
        <LocalesSelector
          availableLocales={availableLocales}
          addLocales={addLocales}
          locale={item.locale}
          prefix={`${index}-${location}`}
        />
      </Box>

      <Input
        type='text'
        placeholder='Title'
        variant='loginField'
        value={title}
        onChange={handleInputTitle}
      />

      <RemoveTranslationButton remove={handleRemove} />
    </Flex>
  )
}

export default TranslationInput
