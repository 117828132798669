export const COMMENTS_TABS = {
  comments: {
    title: 'Comments',
    description: 'User comments',
    icon: '/images/hero/folder.png',
  },
  blacklist: {
    title: 'Blacklist',
    description: 'Words and phrases',
    icon: '/images/hero/notebook.png',
  },
}

export const COMMENTS_STATUSES_TABS = {
  approved: {
    title: 'Approved',
    description: 'Approved comments',
    icon: '/images/hero/approved.png',
  },
  rejected: {
    title: 'Rejected',
    description: 'System rejected comments',
    icon: '/images/hero/rejected.png',
  },
  removed: {
    title: 'Removed',
    description: 'System removed comments',
    icon: '/images/hero/marker.png',
  },
}
