import {
  Box,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { colors } from 'shared/style/colors'
import SearchIcon from './SearchIcon'
import CancelSearchIcon from './CancelSearchIcon'

const SearchBar = ({ query, triggerSearch }) => {
  const [search, setSearch] = useState(query)

  const handleInput = useCallback(e => {
    setSearch(e.target.value)
  }, [])

  const handleSearch = useCallback(() => {
    triggerSearch(search)
  }, [search, triggerSearch])

  const clearSearch = useCallback(() => {
    setSearch('')
    triggerSearch('')
  }, [triggerSearch])

  useEffect(() => {
    const inputField = document.getElementById('admin-input-field')

    const checkEnterPress = e => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        handleSearch()
      }
    }

    inputField?.addEventListener('keypress', checkEnterPress)

    return () => {
      inputField?.removeEventListener('keypress', checkEnterPress)
    }
  })

  useEffect(() => {
    if (search !== query) {
      setSearch(query)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return (
    <Flex
      direction='column'
      p={'12px 24px 24px'}
      bg={colors.white}
      w='100%'
      borderRadius='6px'
      border={`1px solid ${colors.stroke}`}
      gap='12px'
      zIndex={3}
      id='admin-search-bar'
    >
      <Flex gap='12px'>
        <Heading
          size='poppins24'
          variant='primary-black-text'
          lineHeight='38px'
        >
          User search
        </Heading>

        <Box id='search-by-toggler-wrapper' />
      </Flex>

      <Flex>
        <InputGroup alignItems='center' gap='8px'>
          <InputLeftElement w='24px' h='24px' top='16px' left='16px'>
            <Box>{SearchIcon(colors['primary-violet-100'])}</Box>
          </InputLeftElement>

          <Input
            placeholder='Type search and press enter...'
            variant='loginField'
            p='12px 24px 12px 56px'
            h='72px'
            value={search}
            style={{
              caretColor: colors['primary-violet-100'],
            }}
            autoComplete='off'
            onChange={handleInput}
            id='admin-input-field'
          />

          {!!search && (
            <InputRightElement
              w='24px'
              h='24px'
              top='16px'
              right='16px'
              onClick={clearSearch}
              cursor='pointer'
            >
              {CancelSearchIcon()}
            </InputRightElement>
          )}
        </InputGroup>
      </Flex>

      <Box id='search-error-wrapper' />
    </Flex>
  )
}

export default SearchBar
