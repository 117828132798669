import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Select,
  Text,
} from '@chakra-ui/react'
import React, { useCallback, useMemo, useState } from 'react'
import { colors } from 'shared/style/colors'
import * as EmailValidator from 'email-validator'
import UserId from '../UserId'
import Email from '../Email'
import Coins from '../Coins'
import BackIcon from '../BackIcon'

const uuidv4Regex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

const CopyFrom = ({ handleLoadUserToCopy, copyUser, handleCopyData }) => {
  const [type, setType] = useState('email')
  const [target, setTarget] = useState('')
  const [focus, setFocus] = useState(false)
  const [isCopyLoading, setIsCopyLoading] = useState(false)
  const [hover, setHover] = useState(false)
  const [isHide, setIsHide] = useState(true)

  const isEmail = type === 'email'
  const isId = type === 'id'

  const error = useMemo(() => {
    if (!target.trim() || focus) {
      return false
    }

    if (isEmail && !EmailValidator.validate(target)) {
      return 'Input email is not valid'
    }

    if (isId && !uuidv4Regex.test(target)) {
      return 'Input ID is not valid'
    }

    return false
  }, [isEmail, isId, target, focus])

  const placeholder = useMemo(() => {
    if (isEmail) return 'example@gmail.com'

    if (isId) return 'xxxxxxxx-xxxx-xxx-xxx-xxxxxxxxxxxx'

    return 'Select field type'
  }, [isEmail, isId])

  const getUser = useCallback(() => {
    handleLoadUserToCopy({
      email: isEmail ? target : '',
      user_id: isId ? target : '',
    })
  }, [isEmail, isId, target, handleLoadUserToCopy])

  const handleCopy = useCallback(async () => {
    setIsCopyLoading(true)
    await handleCopyData()
    setIsCopyLoading(false)
  }, [handleCopyData])

  const handleSetType = useCallback(e => {
    setType(e.target.value)
  }, [])

  const handleSetTarget = useCallback(e => {
    setTarget(e.target.value)
  }, [])

  return (
    <Flex
      direction='column'
      justify='start'
      align='start'
      gap='12px'
      p='10px'
      border={`1px solid ${colors['primary-gray-10']}`}
      bg={colors.white}
      borderRadius='10px'
    >
      <Flex gap='12px'>
        <Heading
          size='poppins24'
          variant='primary-black-text'
          lineHeight='32px'
          textAlign='center'
          maxW='400px'
          fontWeight={500}
        >
          Copy From...
        </Heading>
        <Box
          m='auto'
          cursor='pointer'
          w='24px'
          h='24px'
          transform={`rotate(${isHide ? 0 : '-90deg'})`}
          onClick={() => setIsHide(curr => !curr)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {BackIcon(
            hover ? colors['primary-violet-100'] : colors['primary-black-text'],
          )}
        </Box>
      </Flex>

      {!isHide && (
        <>
          <Flex w='100%' align='center' gap='4px'>
            <Select w='140px' h='54px' value={type} onChange={handleSetType}>
              <option value='email'>Email</option>
              <option value='id'>ID</option>
            </Select>

            <Input
              placeholder={placeholder}
              variant='loginField'
              p='12px 24px'
              h='54px'
              w='360px'
              value={target}
              style={{
                caretColor: colors['primary-violet-100'],
              }}
              autoComplete='off'
              onChange={handleSetTarget}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
              disabled={!type}
            />

            <Button
              variant='defaultButton'
              w='fit-content'
              h='54px'
              minH='54px'
              isDisabled={!target || focus || error}
              onClick={getUser}
            >
              Get User
            </Button>
          </Flex>

          {copyUser && (
            <Flex
              w='100%'
              border={`1px solid ${colors['primary-gray-10']}`}
              bg={colors.white}
              borderRadius='10px'
              direction='column'
              p='10px'
              gap='12px'
            >
              <UserId user_id={copyUser.user_id} />
              <Email email={copyUser.email} />
              <Coins coins={copyUser.coin_balance} isStatic />
              <Button
                variant='defaultButton'
                w='204px'
                bg={colors['primary-emerald-100']}
                _hover={{
                  bg: `${colors['primary-emerald-120']} !important`,
                }}
                isDisabled={isCopyLoading}
                onClick={handleCopy}
              >
                {isCopyLoading ? 'Copying...' : 'Copy data'}
              </Button>
            </Flex>
          )}

          {error && (
            <Text
              size='poppins14'
              fontWeight={400}
              letterSpacing='-0.01em'
              color={colors['primary-violet-100']}
              lineHeight='20px'
            >
              {error}
            </Text>
          )}
        </>
      )}
    </Flex>
  )
}

export default CopyFrom
