import React, { memo } from 'react'

import { Button } from 'react-bootstrap'

const TagItem = ({
  tag,
  deleteSectionContentOption,
  draggableProps,
  dragHandleProps,
  elementRef,
  list,
}) => {
  return (
    <div
      className='tag'
      ref={elementRef}
      {...draggableProps}
      {...dragHandleProps}
    >
      <div className='tag-info'>{tag.title}</div>
      <Button
        variant='danger'
        onClick={e => {
          e.stopPropagation()
          deleteSectionContentOption(list, tag.tag_id)
        }}
        style={{ height: 40 }}
      >
        Delete
      </Button>
    </div>
  )
}

export default memo(TagItem)
